import * as React from 'react'
import TransactionsTable from './TransactionsTable'
import { IBookingTransactionTableData } from './interfaces'

interface ITransactionsPanelProps {
  data: IBookingTransactionTableData[]
  redirectToTransaction(id: string | number): void
}

class TransactionsPanel extends React.PureComponent<
  ITransactionsPanelProps,
  {}
> {
  public render(): JSX.Element {
    return (
      <TransactionsTable
        data={this.props.data}
        redirectToTransaction={this.props.redirectToTransaction}
      />
    )
  }
}

export default TransactionsPanel
