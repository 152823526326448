import { createSelector } from 'reselect'
import { get, memoize, omit } from 'lodash'
import { NAMESPACE } from './constants'
import { ITransactionsModel, ITransactionBooking } from './interfaces'
import { sitesSelectors } from '../sites'

const getState = (state: any) => state

const getTransactionsState = (state: any): ITransactionsModel =>
  state[NAMESPACE]

export const getTransactionsSchema = createSelector(
  getTransactionsState,
  transactions => transactions.schema,
)

export const getTransactionIds = createSelector(
  [getState, getTransactionsState],
  (state, transactions) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(transactions, `sites[${sitePath}].allIds`, [])
  },
)

export const getTransactions = createSelector(
  [getState, getTransactionsState],
  (state, transactions) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(transactions, `sites[${sitePath}].byId`, {})
  },
)

export const getTransactionsInOrder = createSelector(
  [getState, getTransactionsState],
  (state, transactions) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    const allIds = get(transactions, `sites[${sitePath}].allIds`, [])
    return allIds.map((id: any) =>
      get(transactions, `sites[${sitePath}].byId[${id}]`, {}),
    )
  },
)

export const getTransactionFilters = createSelector(
  [getState, getTransactionsState],
  (state, transactions) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(transactions, `sites[${sitePath}].byFilters`, {})
  },
)

export const getTransactionsByFilter = createSelector(
  [getState, getTransactionsState],
  (state, transactions) => (filter: string) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    const filterWithoutPage = omit(JSON.parse(filter), ['page'])
    const transactionsByFilter = get(
      transactions,
      `sites[${sitePath}].byFilters[${JSON.stringify(filterWithoutPage)}]`,
      [],
    )
    return transactionsByFilter.map((transactionId: number) =>
      getTransactionById(state)(transactionId),
    )
  },
)

export const getTransactionsByPage = createSelector(
  [getState, getTransactionsState],
  (state, transactions) => (page: number | string) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    const transactionsByPage = get(
      transactions,
      `sites[${sitePath}].byPage[${page}]`,
      [],
    )
    return transactionsByPage.map((transactionId: number) =>
      getTransactionById(state)(transactionId),
    )
  },
)

export const getTransactionBookingsTotal = createSelector(
  getState,
  state => (transactionId: number) => {
    const transaction = getTransactionById(state)(transactionId)
    if (!transaction) {
      return 0
    }
    return transaction.bookings.reduce(
      (acc: number, val: ITransactionBooking) => {
        if (!val) {
          return acc
        }
        acc = acc + val.total
        return acc
      },
      0,
    )
  },
)

export const getTransactionById = createSelector(
  getTransactions,
  transactions => (id: number | string) => get(transactions, `[${id}]`, null),
)

export const isLoading = createSelector(
  [getState, getTransactionsState],
  (state, transactions) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(transactions, `sites[${sitePath}].isLoading`, false)
  },
)

export const getPages = createSelector(
  [getState, getTransactionsState],
  (state, transactions) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(transactions, `sites[${sitePath}].pages`, false)
  },
)

export const getTransactionsSchemaMethods = createSelector(
  getTransactionsSchema,
  schema => get(schema, 'methods', []),
)

export const getPropertyEnumFromSchema = createSelector(
  getTransactionsSchema,
  schema => (property: string) =>
    get(schema, `schema.properties.${property}.enum`, []),
)

export const getPropertyDefaultFromSchema = createSelector(
  getTransactionsSchema,
  schema => (property: string) =>
    get(schema, `schema.properties.${property}.default`, []),
)

export const getCountriesFromSchema = createSelector(
  getTransactionsSchema,
  memoize(schema => get(schema, 'schema.properties.countries.enum', [])),
)

export const getCurrenciesFromSchema = createSelector(
  getTransactionsSchema,
  memoize(schema => get(schema, 'schema.properties.currencies.enum', [])),
)

export const getTransactionTypesFromSchema = createSelector(
  getTransactionsSchema,
  memoize(schema =>
    get(schema, 'schema.properties.transaction_types.enum', []),
  ),
)

export const getCardTypesFromSchema = createSelector(
  getTransactionsSchema,
  memoize(schema => get(schema, 'schema.properties.card_types.enum', [])),
)

export const getStatusFromSchema = createSelector(
  getTransactionsSchema,
  memoize(schema => get(schema, 'schema.properties.status.enum', [])),
)
