import React, { FC } from 'react'
import Modal, {
  IModalProps,
} from '../../../../views/components/molecules/Modal/Modal'
import { colors } from '../../../../views/styles/variables'
import styled from 'styled-components'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'

interface Props extends Omit<IModalProps, 'InnerComponent'> {
  userId: string
}

const ConfirmTwoFactorAuthModal: FC<Props> = ({
  className,
  userId,
  onClose,
  ...modalProps
}) => {
  const [step, setStep] = React.useState(1)
  const handleClose = React.useCallback(() => {
    setStep(1)
    if (onClose) {
      onClose()
    }
  }, [modalProps, onClose])

  const InnerComponent = React.useMemo(
    () => () => {
      switch (step) {
        case 1:
          return (
            <Step1
              className={className}
              userId={userId}
              enabled={modalProps.isOpen && step == 1}
              onNext={() => setStep(2)}
              onClose={onClose}
            />
          )
        case 2:
          return (
            <Step2
              className={className}
              userId={userId}
              enabled={modalProps.isOpen && step === 2}
              onNext={() => setStep(3)}
            />
          )
        case 3:
          return (
            <Step3
              className={className}
              userId={userId}
              enabled={modalProps.isOpen && step == 3}
              onConfirm={handleClose}
            />
          )
        default:
          return null
      }
    },
    [step, className, userId, modalProps.isOpen],
  )

  return (
    <Modal
      {...modalProps}
      onClose={handleClose}
      InnerComponent={<InnerComponent />}
    />
  )
}

export default styled(ConfirmTwoFactorAuthModal)`
  padding: 16px;
  max-height: 90vh;
  max-width: 500px;
  overflow: scroll;

  h3 {
    margin: 0 0 10px 0;
    border-bottom: 1px solid ${colors.grey.lighter};
  }

  footer {
    display: flex;
    border-top: 1px solid ${colors.grey.lighter};
    margin-top: 10px;
    padding-top: 10px;

    footer button {
      font-size: 1rem;
      padding: 6px 8px;
    }

    button:first-child {
      margin-right: 10px;
    }
  }
`
