import React, { useCallback, useMemo } from 'react'
import {
  CellProps,
  isIntegerControl,
  RankedTester,
  rankWith,
  and,
  UISchemaElement,
} from '@jsonforms/core'
import cx from 'classnames'
import { useJsonForms, withJsonFormsCellProps } from '@jsonforms/react'
import {
  VanillaRendererProps,
  withVanillaCellProps,
} from '@jsonforms/vanilla-renderers'
import { CurrencyInput } from '../../../../views/components'
import styled from 'styled-components'
import { convertFromCents, convertToCents } from '../../../../utils/currency'

const CurrencyCell = (props: CellProps & VanillaRendererProps) => {
  const [isFocused, setIsFocused] = React.useState(false)
  const { data, className, id, enabled, schema, uischema, path, handleChange } =
    props
  const ctx = useJsonForms()
  const currencyField = uischema?.options?.currency_field || 'currencies'
  const prefix = ctx.core?.data?.[currencyField]

  const value = useMemo(() => {
    if (data === 0 && !isFocused) {
      return '0.00'
    }
    return !!data ? convertFromCents(data) : ''
  }, [data])

  const _handleChange = useCallback(
    e => {
      const value = e.target.value
      if (value !== data) {
        handleChange(path, value ? convertToCents(value) : 0)
      }
    },
    [data],
  )

  const isFormFieldDisabled = () => {
    if (!enabled) {
      return true
    }
    const isReadOnly = (schema as any)?.readonly === true
    if (isReadOnly) {
      return true
    }
    return false
  }

  return (
    <CurrencyInput
      name={id}
      id={id}
      prefix={prefix}
      value={value}
      onChange={_handleChange}
      className={cx(className, 'field')}
      onFocus={() => setIsFocused(true)}
      disabled={isFormFieldDisabled()}
      // autoFocus={uischema.options && uischema.options.focus}
    />
  )
}
/**
 * Default tester for integer controls.
 * @type {RankedTester}
 */
export const currencyCellTester: RankedTester = rankWith(
  4,
  and(isIntegerControl, (uischema: UISchemaElement) => {
    return (
      uischema?.options?.Component === 'CurrencyControl' ||
      uischema?.options?.Component === 'BookingCurrencyControl' // TODO: remove this line after removing BookingCurrencyControl API side
    )
  }),
)

const StyledCurrencyCell = styled(CurrencyCell)`
  .has-prefix input {
    max-width: 100%;
    border-left: 0;
    width: 100% !important;
  }
`

export default withJsonFormsCellProps(withVanillaCellProps(StyledCurrencyCell))
