import * as React from 'react'
import { Box, FormField } from 'grommet'
import { withFormik, FormikProps, Form, Field } from 'formik'
import { Link } from 'react-router-dom'
import { Button, TextInput } from '../..'
import styled from 'styled-components'

interface IFormValues {
  email: string
}

export interface IForgotPasswordFormProps {
  forgotpassword: (email: string) => void
  className?: string
}

class ForgotPasswordForm extends React.Component<
  IForgotPasswordFormProps & FormikProps<IFormValues>,
  {}
> {
  public render() {
    const { handleChange, className } = this.props
    return (
      <Box
        fill={true}
        align="center"
        justify="center"
        pad="large"
        className={className}
      >
        <Box width="medium">
          <Form>
            <FormField>
              <Field
                component={TextInput}
                id="email"
                name="email"
                placeholder="Email"
                onChange={handleChange}
              />
            </FormField>
            <div className="forgot-password-button">
              <Button
                alignSelf="center"
                styleType="gradient"
                size="large"
                type="submit"
                label="Reset password"
              />
            </div>
            <div className="login">
              Go to <Link to="/login">login</Link>
            </div>
          </Form>
        </Box>
      </Box>
    )
  }
}

const StyledForgotPasswordForm = styled(ForgotPasswordForm)`
  input {
    border: none;
    &:focus {
      box-shadow: none;
    }
  }
  .forgot-password-button {
    button {
      padding: 17px 80px;
    }
  }
  .login {
    margin-top: 30px;
    text-align: center;
    font-size: 1rem;
    a {
      color: #2980b9;
    }
  }
`

export default withFormik<IForgotPasswordFormProps, IFormValues>({
  handleSubmit: ({ email }, { props }) => {
    props.forgotpassword(email)
  },
})(StyledForgotPasswordForm)
