import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { LocationState } from 'history'
import { get } from 'lodash'
import { ForgotPasswordForm } from '..'

export interface IForgotPasswordPageProps {
  isPasswordResetSent: boolean
  location: LocationState
  forgotpassword: (email: string) => void
}

export default class IForgotPasswordPage extends React.PureComponent<
  IForgotPasswordPageProps,
  {}
> {
  public render() {
    const { location, forgotpassword, isPasswordResetSent } = this.props
    let from = get(location, 'state.from', '/password-forgot-success')

    return isPasswordResetSent ? (
      <Redirect to={from} />
    ) : (
      <ForgotPasswordForm forgotpassword={forgotpassword} />
    )
  }
}
