import * as React from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import Notification, {
  INotificationProps,
} from '../../molecules/Notification/Notification'
import styled from 'styled-components'

interface INotificationsProps {
  className?: string
  notifications: INotificationProps[]
  onClose(id: string): void
}

const Notifications = (props: INotificationsProps) => {
  const handleOutsideClick = () => {
    props.notifications.forEach(n => {
      props.onClose(n.id)
    })
  }

  const notifications = props.notifications.map((notification, i) => {
    const handleClose = () => props.onClose(notification.id)
    const { id, message, type } = notification
    return (
      <Notification
        id={id}
        message={message}
        type={type}
        onClose={handleClose}
        key={i}
      />
    )
  })

  return (
    <div id="notifications-centre" className={props.className}>
      <OutsideClickHandler onOutsideClick={handleOutsideClick}>
        {notifications}
      </OutsideClickHandler>
    </div>
  )
}

const StyledNotifications = styled(Notifications)`
  width: 100%;
  z-index: 1000;
  > div {
    margin-bottom: 2px;
  }
`

export default StyledNotifications
