import { Box } from 'grommet'
import React from 'react'
import { Loader, Modal } from '../..'

type Props = {
  state: 'loading' | 'creating' | 'saving' | 'deleting' | 'idle'
}

const InnerComponent = ({ text }: { text: string }) => {
  return (
    <Box height="small" width="medium">
      <Loader text={text} />
    </Box>
  )
}

const LoadingModal = ({ state }: Props) => {
  const text = (() => {
    switch (state) {
      case 'loading':
        return 'Loading booking'
      case 'creating':
        return 'Creating booking'
      case 'saving':
        return 'Saving booking'
      case 'deleting':
        return 'Deleting booking'
      default:
        return ''
    }
  })()

  return (
    <Modal
      isOpen={state !== 'idle'}
      showCloseIcon={false}
      InnerComponent={<InnerComponent text={text} />}
    />
  )
}

export default LoadingModal
