import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { uniq, get, isEmpty } from 'lodash'
import { NAMESPACE } from './constants'
import { IPayout, IPayoutsState, IRequestPayoutsParams } from './interfaces'
import { parseLinkHeader } from '../../utils/http'

export const initialState: IPayoutsState = {
  schema: {},
  allIds: [],
  byId: {},
  aggregate: {},
  idsByRequestParams: {},
  loading: false,
  pages: {},
  byPage: {},
  isSingleLoading: false,
  isUpdateLoading: false,
}

export const payoutsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    requestPayoutsSchema: state => {},
    requestPayoutsSchemaSuccess: (state, action: PayloadAction<any>) => {
      state.schema = action.payload
    },
    requestPayouts: (state, action: PayloadAction<IRequestPayoutsParams>) => {
      state.loading = true
    },
    requestPayoutsSuccess: {
      reducer: (state, action: PayloadAction<IPayout[], string, any>) => {
        state.loading = false
        state.pages = parseLinkHeader(action.meta.responseHeaders?.link || '')
        if (
          action.meta.params.groupby === 'currencies' &&
          action.meta.params.aggregate === 'total'
        ) {
          state.aggregate = action.payload
        } else {
          state.allIds = action.payload.map(({ id }) => id)
          state.byPage = {
            ...state.byPage,
            [action.meta.params.page]: action.payload.map(({ id }) => id),
          }
          action.payload.forEach(payout => {
            state.byId[payout.id] = payout
          })
          const params = get(action, 'meta.params')
          const stringifiedParams = JSON.stringify(params)
          if (!isEmpty(params)) {
            state.idsByRequestParams[stringifiedParams] = action.payload.map(
              payload => payload.id,
            )
          }
        }
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },
    requestPayout: (state, action: PayloadAction<any>) => {
      state.isSingleLoading = true
    },
    requestPayoutSuccess: {
      reducer: (state, action: PayloadAction<any>) => {
        state.isSingleLoading = false
        state.byId[action.payload.id] = action.payload
        state.allIds = uniq(state.allIds.concat([action.payload.id]))
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },
    updatePayout: (state, action) => {
      state.isUpdateLoading = true
    },
    updatePayoutSuccess: {
      reducer: (state, action: PayloadAction<any>) => {
        state.isUpdateLoading = false
        state.byId[action.payload.id] = action.payload
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },
    /** @todo add extraReducer for http_error */
  },
})

const { actions, reducer } = payoutsSlice

export { actions, reducer }
