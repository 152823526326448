import React from 'react'
import styled from 'styled-components'
import Button from '../../atoms/Button/Button'

interface IPopupProps {
  className: string | undefined
  text: string
  okOnClickHandler(payload: any): void
  cancelOnClickHandler(): void
}

// need to include className for styled components to work
// https://www.styled-components.com/docs/basics#styling-any-components
const Popup = (props: IPopupProps) => {
  const { className, text, okOnClickHandler, cancelOnClickHandler } = props
  return (
    <div className={className}>
      <div className="popup">
        <div className="popup-inner">
          <div>{text}</div>
          <div className="popup-button-container">
            <Button
              styleType="primary"
              size="small"
              label="Ok"
              onClick={okOnClickHandler}
            />
            <Button
              styleType="danger"
              size="small"
              label="Cancel"
              onClick={cancelOnClickHandler}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledPopup = styled(Popup)`
  .popup {
    z-index: 1;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .popup-inner {
    color: red;
    font-size: 15px;
    padding: 20px;
    line-height: 20px;
    position: absolute;
    left: 35%;
    right: 35%;
    top: 28%;
    margin: auto;
    background: white;
    border: 1px solid red;
    border-radius: 5px;
    text-align: center;
  }
  .popup-button-container {
    display: flex;
    padding: 20px 0 0 0;
    justify-content: space-evenly;
  }
`

export default StyledPopup
