import { createSelector } from 'reselect'
import { get, map, upperFirst, reduce, memoize } from 'lodash'
import { channelSelectors } from '../../channels'
import { history } from '../../../../utils/router'
import { IChannelResponse } from '../../channels/interfaces'
import { optionToSelectAllChannels } from './constants'

const getState = (state: any) => state

// const getUIState = (state: any): any => state[NAMESPACE];

const getUIChannelState = (state: any) => state.ui.channels

export const isViewingChannel = createSelector(getState, state => {
  const {
    location: { pathname },
  } = history
  return pathname.includes('/channels/') && pathname.split('/').pop() !== 'new'
})

export const getCurrentPage = createSelector(getUIChannelState, uiChanneState =>
  get(uiChanneState, 'currentPage', 1),
)

export const getCurrentFilter = createSelector(
  getUIChannelState,
  uiChanneState => get(uiChanneState, 'currentFilter', ''),
)

export const getCurrentChannel = createSelector(
  getUIChannelState,
  uiChannelState => get(uiChannelState, 'currentChannel', null),
)

export const getChannelTableData = createSelector(getState, (state: any) =>
  memoize((page: number) => {
    const channels = channelSelectors.getChannelsByPage(state)(page)
    return channels.map((channel: IChannelResponse) => {
      const {
        id,
        name,
        currencies,
        account_type,
        protection_type,
        statement_period,
        account_mode,
        channel_status,
        suppliers,
      } = channel
      return {
        id,
        name,
        currencies,
        account_type,
        protection_type,
        statement_period,
        account_mode,
        channel_status,
        suppliers,
      }
    })
  }),
)

export const getChannelTableDataByChannelId = createSelector(
  getState,
  (state: any) =>
    memoize((channelId: number) => {
      const channel = channelSelectors.getChannelById(state)(channelId)
      return [
        {
          id: get(channel, 'id'),
          name: get(channel, 'name'),
          currencies: get(channel, 'currencies'),
          account_type: get(channel, 'account_type'),
          protection_type: get(channel, 'protection_type'),
          statement_period: get(channel, 'statement_period'),
          account_mode: get(channel, 'account_mode'),
          channel_status: get(channel, 'channel_status'),
          suppliers: get(channel, 'suppliers', []),
        },
      ]
    }),
)

export const getChannelsForSelectDropdown = createSelector(
  channelSelectors.getChannels,
  (channels: IChannelResponse[]) => {
    const channelsArray = map(channels, c => c.name).sort()
    return ['All Channels', ...channelsArray]
  },
)

export const getChannelsForSelectDropdownByFilter = createSelector(
  getState,
  (state: any) => (filter: string) => {
    const channels = channelSelectors.getChannelsByFilter(state)(filter)
    return map(channels, c => c.name).sort()
  },
)

export const getChannelsByFilterForSelectDropdown = createSelector(
  getState,
  (state: any) => (filter: string) => {
    const channels = channelSelectors.getChannelsByFilter(state)(filter)
    const channelsArray = map(channels, c => c.name).sort()
    return ['All Channels', ...channelsArray]
  },
)

export const getChannelsOrderedByNameForSelectDropdown = createSelector(
  channelSelectors.getChannelsOrderedByName,
  (channels: IChannelResponse[]) => {
    const channelsArray = map(channels, c => c.name).sort()
    return ['All Channels', ...channelsArray]
  },
)

export const getChannelsForMultipleSelectDropdown = createSelector(
  channelSelectors.getChannels,
  (channels: IChannelResponse[]) => {
    const channelsArray = map(channels, c => ({
      id: c.id,
      name: c.name,
    })).sort()
    return [optionToSelectAllChannels, ...channelsArray]
  },
)

export const getChannelsOnlyForSelectDropdown = createSelector(
  channelSelectors.getChannels,
  (channels: IChannelResponse[]) => {
    const channelsArray = map(channels, c => c.name).sort()
    return channelsArray
  },
)

export const getCurrenciesForSearchFilter = createSelector(
  channelSelectors.getCurrenciesFromSchema,
  (currencies: any) =>
    currencies.map((c: string) => ({
      value: c,
      label: c,
    })),
)

export const getAccountModesForSearchFilter = createSelector(
  channelSelectors.getAccountModesFromSchema,
  (accountModes: any) =>
    accountModes.map((am: string) => ({
      value: am,
      label: am,
    })),
)

export const getStatementPeriodsForSearchFilter = createSelector(
  channelSelectors.getStatementPeriodsFromSchema,
  (statementPeriods: any) =>
    statementPeriods.map((sp: string) => ({
      value: sp,
      label: sp,
    })),
)

export const getAccountTypesForSearchFilter = createSelector(
  channelSelectors.getAccountTypesFromSchema,
  (accountTypes: any) =>
    accountTypes.map((at: string) => ({
      value: at,
      label: at,
    })),
)

export const getServerErrors = createSelector(getUIChannelState, uiChannels =>
  get(uiChannels, 'serverErrors', null),
)

export const isFetching = createSelector(getUIChannelState, uiChannels =>
  get(uiChannels, 'isFetching', null),
)

export const isSaving = createSelector(getUIChannelState, uiChannels =>
  get(uiChannels, 'isSaving', null),
)

export const isCreating = createSelector(getUIChannelState, uiChannels =>
  get(uiChannels, 'isCreating', null),
)

export const isDeleting = createSelector(getUIChannelState, uiChannels =>
  get(uiChannels, 'isDeleting', null),
)
