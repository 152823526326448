import * as React from 'react'
import { Grommet, Box } from 'grommet'
import styled from 'styled-components'
import { get, find, first } from 'lodash'
import TextInput from '../../molecules/TextInput/TextInput'
import Select from '../../molecules/Select/Select'
import { FilterType } from './types'
import timfinityTheme from '../../../styles/timfinityTheme'
import search2 from '../../../../assets/icons/search-2.svg'

interface ISearchFilterProps {
  className?: string
  filters: FilterProps[]
  activeFilter: string
  activeFilterValue: string
  activeRangeFilterValue?: any /** @todo improve this */
  setFilter(filter: any): void
  setActiveFilterValue(filter: any): void
  setActiveRangeFilterValue?(filter: any): void
  paramsResetOnFilterOptionChange?(): void | null
}

interface ISearchFilterState {}

export type FilterProps = IBaseSearchFilterProps | ISelectFilterProps

interface IBaseSearchFilterProps {
  value: string
  label: string
  type: FilterType
  options?: any /** @todo remove this when possible! */
  onChange?(...args: any): void
}

export interface ISelectFilterOption {
  value: string
  label: string
}

export interface ISelectFilterProps extends IBaseSearchFilterProps {
  options: ISelectFilterOption[]
  onSearch?(term: string): void
  onClose?(): void
  onChange(option: any): void /** @todo update option type */
}

class SearchFilter extends React.Component<
  ISearchFilterProps,
  ISearchFilterState
> {
  public searchFilterSelectTheme = {
    grommet: {
      extend: () => `
                input::placeholder, input[value] {
                    padding: 5px 10px 10px 5px;
                    font-family: Lato;
                    font-style: normal;
                    font-size: 14px;
                    color: #000000;
                    opacity: 1; /* Firefox */
                },
                div button {
                    min-width: 174px;
                    border-right: 1px solid #063E5A;
                    padding: 2px 2px 0px 0px;
                    height: 40px;
                    background: #F1F1F1;
                }
            `,
    },
    select: {
      background: '#F1F1F1',
    },
  }

  public searchFilterSecondarySelectTheme = {
    grommet: {
      extend: () => `
                input::placeholder, input[value] {
                    padding: 10px 0px 10px 10px;
                    font-family: Lato;
                    font-style: italic;
                    font-size: 14px;
                    opacity: 0.5;
                },
                button {
                    width: 100%;
                    padding: 0px;
                    height: 40px;
                }
            `,
    },
    select: {
      background: 'none',
      icons: {
        color: '#FFFFFF',
      },
    },
  }

  public searchFilterTextTheme = {
    grommet: {
      extend: () => `
                input {
                    width: 120%;
                }
                input::placeholder {
                    padding: 12px 0px 12px 5px;
                    font-family: Lato;
                    font-style: italic;
                    font-size: 14px;
                },
            `,
    },
  }

  public searchFilterRangeTheme = {
    grommet: {
      extend: () => `
            input {
                width: 100%;
            }
            input::placeholder {
                padding: 12px 0px 12px 5px;
                font-family: Lato;
                font-style: normal;
                font-size: 13px;
            },
            `,
    },
  }

  /**
   * Getters
   */
  public getFilterOptions = (filters: any) => filters.map((f: any) => f.label)

  public getFilterOptionsObj = (options: any) => {
    return (label: string) => {
      return find(options, o => o.label === label)
    }
  }

  public getFilterOptionsObjByValue = (options: any) => {
    return (value: string) => {
      return find(options, o => o.value === value)
    }
  }

  public getActiveFilterObj = () =>
    find(this.props.filters, filter => filter.value === this.props.activeFilter)

  /**
   * Event Handlers
   */
  public handleFilterChange = (filter: any) => {
    const {
      activeFilter,
      setFilter,
      setActiveFilterValue,
      paramsResetOnFilterOptionChange,
    } = this.props

    setFilter(filter.value)

    // Set first open if select filter
    const firstActiveFilterOption: any = first(filter.options)
    if (firstActiveFilterOption) {
      setActiveFilterValue(firstActiveFilterOption.value)
    } else {
      setActiveFilterValue('')
    }

    if (paramsResetOnFilterOptionChange && activeFilter !== filter.value) {
      paramsResetOnFilterOptionChange()
    }
  }

  public handleSelectFilterChange = (filter: ISelectFilterOption) => {
    if (filter) {
      this.props.setActiveFilterValue(filter.value)
    }

    const activeFilter: FilterProps | undefined = this.getActiveFilterObj()
    if (activeFilter?.onChange) {
      activeFilter.onChange(filter.value)
    }
  }

  public handleTextFilterChange = (e: any) => {
    const { value = '' } = e.target
    this.props.setActiveFilterValue(value)
  }

  public handleRangeFilterChange = (e: any) => {
    const activeFilter: FilterProps | undefined = this.getActiveFilterObj()
    if (activeFilter) {
      const { activeRangeFilterValue, setActiveRangeFilterValue } = this.props
      const { name, value } = e.target
      const newActiveRangeFilterValue: any = {
        ...activeRangeFilterValue,
        [name]: value,
      }
      if (setActiveRangeFilterValue) {
        setActiveRangeFilterValue(newActiveRangeFilterValue)
      }
    }
  }

  /**
   * Helpers
   */
  public maybeRenderFilter = (filterValue: string, filter: any) => {
    return this.props.activeFilter === filterValue ? filter : null
  }

  public renderSelectFilter = (filter: any, i: number) => {
    const options = this.getFilterOptions(filter.options)
    const props: any = {
      key: i,
      plain: true,
      options,
      getOptionByName: this.getFilterOptionsObj(filter.options),
      onChange: this.handleSelectFilterChange,
      value: get(
        this.getFilterOptionsObjByValue(filter.options)(
          this.props.activeFilterValue,
        ),
        'label',
        '',
      ),
      searchFilterTheme: this.searchFilterSecondarySelectTheme,
    }
    if (filter.onSearch) {
      props.onSearch = filter.onSearch.bind(null, filter)
    }
    if (filter.onClose) {
      props.onClose = filter.onClose
    }

    return this.maybeRenderFilter(filter.value, <Select {...props} />)
  }

  public renderTextFilter = (filter: IBaseSearchFilterProps, i: number) => {
    return this.maybeRenderFilter(
      filter.value,
      <TextInput
        searchFilterTheme={this.searchFilterTextTheme}
        plain={true}
        key={i}
        name={filter.value}
        value={this.props.activeFilterValue}
        placeholder={filter.label}
        onChange={this.handleTextFilterChange}
      />,
    )
  }

  public renderRangeFilter = (filter: IBaseSearchFilterProps, i: number) => {
    return this.maybeRenderFilter(
      filter.value,
      <Box key={i} direction="row" fill="horizontal">
        <TextInput
          searchFilterTheme={this.searchFilterRangeTheme}
          plain={true}
          name="min"
          placeholder="Minimum"
          onChange={this.handleRangeFilterChange}
        />
        <TextInput
          searchFilterTheme={this.searchFilterRangeTheme}
          plain={true}
          name="max"
          placeholder="Maximum"
          onChange={this.handleRangeFilterChange}
        />
      </Box>,
    )
  }

  public renderFilters = () =>
    this.props.filters.map((filter: any, i) => {
      switch (filter.type) {
        case 'text':
          return this.renderTextFilter(filter, i)
        case 'select':
          return this.renderSelectFilter(filter, i)
        case 'range':
          return this.renderRangeFilter(filter, i)
        default:
          return null
      }
    })

  public render(): JSX.Element {
    const { className, filters, activeFilter } = this.props
    return (
      <Grommet className={className} theme={timfinityTheme}>
        <Select
          plain={true}
          searchFilterTheme={this.searchFilterSelectTheme}
          filters={filters}
          options={this.getFilterOptions(filters)}
          getOptionByName={this.getFilterOptionsObj(this.props.filters)}
          onChange={this.handleFilterChange}
          placeholder={filters[0].label}
          value={get(
            filters.find(f => f.value === activeFilter),
            'label',
            '',
          )}
          dropHeight="large"
        />
        {this.renderFilters()}
      </Grommet>
    )
  }
}

const StyledSearchFilter = styled(SearchFilter)`
  display: flex;
  max-width: 550px;
  border-radius: 5px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid #063e5a;
  background: #f1f1f1;

  > div:first-of-type {
    button {
      min-width: 260px;
      > div {
        background-color: #f1f1f1;
        &:before {
          content: '';
          background: url(${search2}) no-repeat;
          display: block;
          width: 16px;
          height: 16px;
          margin: 0 5px 0 10px;
        }
      }
    }
  }

  input,
  button {
    border-radius: 0;
  }

  button {
    border: none;
  }
`

export default StyledSearchFilter
