import { useCallback } from 'react'
import { get } from 'lodash'
import { useQueryParam } from 'use-query-params'
import { ObjectParam } from '../views/utils/queryParams'

export default function usePagination() {
  const [pagination, setPagination] = useQueryParam('pagination', ObjectParam)

  /**
   * Memoize a function to set page
   */
  const setCurrentPage = useCallback(
    (page: number) => {
      // type cast page to string
      setPagination({ ...pagination, page: String(page) }, 'replaceIn')
    },
    [setPagination, pagination],
  )

  /**
   * Get a param out the filters object
   * Usage: getFitlerParam('surname')
   */
  const getCurrentPage = useCallback(
    (defaultVal: string = '1') => {
      return get(pagination, ['page'], defaultVal)
    },
    [pagination],
  )

  return {
    pagination,
    setPagination,
    setCurrentPage,
    getCurrentPage,
  }
}
