import * as React from 'react'
import { Link } from 'react-router-dom'
import Grommet from 'grommet'

export interface IProps extends Grommet.AnchorProps {
  path: string
  label: string
}

class NavAnchor extends React.PureComponent<IProps> {
  public render() {
    const { path, label } = this.props
    return (
      <Link to={path} data-test-id={label}>
        {label}
      </Link>
    )
  }
}

export default NavAnchor
