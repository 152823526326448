import React from 'react'
import Select from 'react-select'
import {
  and,
  EnumCellProps,
  EnumOption,
  isOneOfEnumControl,
  RankedTester,
  rankWith,
  WithClassname,
} from '@jsonforms/core'
import {
  TranslateProps,
  withJsonFormsOneOfEnumCellProps,
  withTranslateProps,
} from '@jsonforms/react'
import styled from 'styled-components'

const ArrayOneOfEnumCell = (
  props: EnumCellProps &
    WithClassname &
    TranslateProps & { className?: string },
) => {
  const { id, enabled, options, className, path, data, schema, handleChange } =
    props

  const getOptionByValue = (value: string) => {
    const option = options?.find((option: any) => option.value === value)
    return option
  }

  const _handleChange = (option: any) => {
    console.log('option', option)
    if (option) {
      const values = option.map((o: any) => o.value)
      handleChange(path, values)
    } else {
      console.log('no option selected')
    }
  }

  const value = data?.map((d: any) => getOptionByValue(d))

  const isFormFieldDisabled = () => {
    if (!enabled) {
      return true
    }
    const isReadOnly = (schema as any)?.readonly === true
    if (isReadOnly) {
      return true
    }
    return false
  }

  return (
    <Select
      name={id}
      id={id}
      isClearable={true}
      isMulti={true}
      isDisabled={isFormFieldDisabled()}
      closeMenuOnSelect={false}
      value={value}
      className={className}
      options={options}
      onChange={_handleChange}
    />
  )
}

const StyledArrayOneOfEnumCell = styled(ArrayOneOfEnumCell)`
  button {
    width: 100%;

    input[value] {
      font-size: 15px;
      font-style: normal;
    }
  }
`

/**
 * Default tester for oneOf enum controls.
 * @type {RankedTester}
 */
export const arrayOneOfEnumCellTester: RankedTester = rankWith(
  4,
  and(isOneOfEnumControl, (uischema, schema) => {
    return schema.type === 'array'
  }),
)

export default withJsonFormsOneOfEnumCellProps(
  withTranslateProps(React.memo(StyledArrayOneOfEnumCell)),
  false,
)
