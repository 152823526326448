import { createSelector } from 'reselect'
import { get } from 'lodash'
import { NAMESPACE } from './constants'
import { IBatchesState } from './interfaces'

// const getState = (state: any) => state;

const getBatchesState = (state: any): IBatchesState => state[NAMESPACE]

const getBatchesSlice = (slice: keyof IBatchesState) =>
  createSelector(getBatchesState, batches => get(batches, [slice]))

export const getBatchesSchema = getBatchesSlice('schema')

export const getBatchIds = getBatchesSlice('allIds')

export const getBatches = getBatchesSlice('byId')

export const getByBatchesByCurrency = getBatchesSlice('byCurrency')

export const getBatchById = createSelector(
  getBatches,
  batches => (id: number) => get(batches, [id]),
)

export const getBatchByCurrency = createSelector(
  getByBatchesByCurrency,
  batchesByCurrency => (currency: string) => get(batchesByCurrency, [currency]),
)

export const getBatchesByStatus = createSelector(
  [getBatchesState],
  batchesState => (status: string) =>
    batchesState.allIds
      .map(id => batchesState.byId[id])
      .filter(batch => batch.status === status),
)

export const isLoading = getBatchesSlice('loading')

export const isMarkAsReleasedLoading = getBatchesSlice(
  'isMarkAsReleasedLoading',
)
