import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { LocationState } from 'history'
import { get } from 'lodash'
import { ForgotPasswordSuccessForm } from '..'

export interface IForgotPasswordPageProps {
  isPasswordResetSent: boolean
  location: LocationState
  redirect(path: string): void
}

export default class IForgotPasswordPage extends React.PureComponent<
  IForgotPasswordPageProps,
  {}
> {
  public render() {
    const { location, redirect, isPasswordResetSent } = this.props
    let from = get(location, 'state.from', '/password-forgot')

    return isPasswordResetSent ? (
      <ForgotPasswordSuccessForm redirect={redirect} />
    ) : (
      <Redirect to={from} />
    )
  }
}
