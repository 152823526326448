import { AnyAction } from '@reduxjs/toolkit'
import { ofType } from 'redux-observable'
import { Observable } from 'rxjs'
import { ignoreElements, map } from 'rxjs/operators'
import { redirect } from '../../../../utils/router'
import { supplierActions } from '../../suppliers'

export const handleSupplierCreationSuccess = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(supplierActions.createSupplierSuccess),
    map((action: any) => {
      redirect(`/suppliers/${action.payload.id}`)
    }),
    ignoreElements(),
  )

// @todo re-work this when another flow is introduced - e.g suppliers are updated on another route
export const handleSupplierUpdateSuccess = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(supplierActions.updateSupplierSuccess),
    map((action: any) => {
      redirect(`/suppliers/${action.payload.id}`)
    }),
    ignoreElements(),
  )

export const handleSupplierDeletionSuccess = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(supplierActions.deleteSupplierSuccess),
    map((action: any) => {
      redirect(`/suppliers`)
    }),
    ignoreElements(),
  )
