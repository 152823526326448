import { IUIPaymentsModel } from './interfaces'

const uiPaymentsModel: IUIPaymentsModel = {
  currentPage: 1,
  currentFilter: '',
  currentChannel: null,
  serverErrors: null,
}

export default uiPaymentsModel
