import { API_SITE_URL, API_ADMIN_URL } from '../../constants'

export const NAMESPACE = 'suppliers'

const baseUrl = `${API_SITE_URL}/${NAMESPACE}`
const adminBaseUrl = `${API_ADMIN_URL}/${NAMESPACE}`

export const ENDPOINTS = {
  BASE: baseUrl,
  ENTITY: `${baseUrl}/:id`,
  ADMIN_BASE: adminBaseUrl,
}
