import { handleActions } from 'redux-actions'
import { AnyAction } from 'redux'
import model from './model'
import * as actions from './actions'
import { ICurrencyModel } from './interfaces'

export default handleActions(
  {
    [actions.requestCurrencyDataSuccess]: (
      state: ICurrencyModel,
      action: AnyAction,
    ) => ({
      currencies: action.payload,
    }),
  },
  model,
)
