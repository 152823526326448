import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { sitesSelectors } from '../../../../state/modules/sites'
import { authSelectors } from '../../../../state/modules/auth'
import { Text } from '../..'
import AdminSiteSelector from './AdminSiteSelector'

interface ISiteSelectorProps {
  className?: string
}

const SiteSelector = (props: ISiteSelectorProps) => {
  const siteCount = useSelector(authSelectors.getSiteCount)
  const currentSiteName = useSelector(sitesSelectors.getCurrentSiteNameCropped)

  return siteCount > 1 ? (
    <AdminSiteSelector />
  ) : (
    <div style={{ marginRight: '10px' }}>
      <Text
        tag="h1"
        color="#fff"
        style={{ fontSize: '26px', marginBottom: '0' }}
      >
        {currentSiteName}
      </Text>
    </div>
  )
}

const StyledSiteSelector = styled(SiteSelector)`
  width: 300px;
  margin-right: 10px;
`

export default StyledSiteSelector
