import { handleActions } from 'redux-actions'
import * as actions from './actions'
import model from './model'
import { AnyAction } from 'redux'
import { IUINotificiationsModel } from './interfaces'

export default handleActions(
  {
    [actions.addNotification]: (
      state: IUINotificiationsModel = model,
      action: AnyAction,
    ) => ({
      ...state,
      notifications: [...state.notifications, action.payload],
    }),
    [actions.removeNotification]: (
      state: IUINotificiationsModel = model,
      action: AnyAction,
    ) => ({
      ...state,
      notifications: state.notifications.filter(
        (n: any) => n.id !== action.payload,
      ),
    }),
  },
  model,
)
