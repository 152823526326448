import * as React from 'react'
import { Modal } from '../..'
import {
  ITransaction,
  ICreateTransaction,
} from '../../../../state/modules/transactions/interfaces'
import RefundForm from './RefundForm'

interface IRefundModalProps {
  isOpen: boolean
  transaction: ITransaction
  isApplyingRefund: boolean
  formatCurrency(value: number | string, currencies: string): string
  getCurrencySymbol(currencyName: string): string
  onClose(): void
  onSubmit(params: ICreateTransaction): void
}

const RefundModal = (props: IRefundModalProps) => {
  const {
    isOpen,
    onClose,
    transaction,
    onSubmit,
    isApplyingRefund,
    formatCurrency,
    getCurrencySymbol,
  } = props

  const refundForm = () => (
    <RefundForm
      transaction={transaction}
      onSubmit={onSubmit}
      isApplyingRefund={isApplyingRefund}
      formatCurrency={formatCurrency}
      getCurrencySymbol={getCurrencySymbol}
    />
  )

  return (
    <Modal
      isOpen={isOpen}
      showCloseIcon={true}
      onClose={onClose}
      InnerComponent={refundForm()}
    />
  )
}

export default RefundModal
