import { createSelector } from 'reselect'
import { get } from 'lodash'
import { IUser } from '../../users/interfaces'
import { usersSelectors } from '../../users'
import { authSelectors } from '../../auth'
import { channelSelectors } from '../../channels'
import { history } from '../../../../utils/router'

const getState = (state: any) => state

const getUIUsersState = (state: any) => state.ui.users

export const getCurrentPage = createSelector(getUIUsersState, uiUsersState =>
  get(uiUsersState, 'currentPage', 1),
)

export const getCurrentChannel = createSelector(getUIUsersState, uiUsersState =>
  get(uiUsersState, 'currentChannel', null),
)

export const getUsersTableData = createSelector(
  getState,
  (state: any) => (page: number) => {
    const users = usersSelectors.getUsersByPage(state)(page)
    return users.map((user: IUser) => {
      const { id, name, username, user_email, type, channels } = user
      return {
        id,
        name,
        username,
        user_email,
        type,
        channels,
      }
    })
  },
)

export const getAdminUserTableData = createSelector(getState, (state: any) => {
  const currentUser = authSelectors.getAuthState(state)
  if (!currentUser) {
    return []
  }
  return [
    {
      name: 'Username',
      value: currentUser.username,
    },
    {
      name: 'Email',
      value: currentUser.user_email,
    },
    {
      name: 'Type',
      value: currentUser.type,
    },
  ]
})

export const getUserTableData = createSelector(
  getState,
  (state: any) => (userId: number) => {
    const user: IUser = usersSelectors.getUserById(state)(userId)
    if (!user) {
      return []
    }
    return [
      {
        name: 'Username',
        value: user.username,
      },
      {
        name: 'Email',
        value: user.user_email,
      },
      {
        name: 'Type',
        value: user.type,
      },
    ]
  },
)

export const getUserFormInitialValues = createSelector(
  [getState],
  state => (id: string) => {
    if (id !== 'new') {
      const user = usersSelectors.getUserById(state)(id)
      const channels = getUserChannels(state)(user)
      if (user) {
        return {
          name: user.name,
          username: user.username,
          user_email: user.user_email,
          usertype: user.usertype,
          password: user.password,
          channels: channels,
          sites: user.sites || [],
        }
      }
    }

    return {
      name: '',
      username: '',
      user_email: '',
      usertype: 'member_admin',
      password: '',
      channels: [],
      sites: [],
    }
  },
)

export const getServerErrors = createSelector(getUIUsersState, uiUsers =>
  get(uiUsers, 'serverErrors', null),
)

export const isSaving = createSelector(getUIUsersState, uiUsers =>
  get(uiUsers, 'isSaving', null),
)

export const isCreating = createSelector(getUIUsersState, uiUsers =>
  get(uiUsers, 'isCreating', null),
)

export const isDeleting = createSelector(getUIUsersState, uiUsers =>
  get(uiUsers, 'isDeleting', null),
)

export const getUserChannels = createSelector(
  getState,
  (state: any) => (user: any) => {
    const channelsArr: any = []
    if (user && user.hasOwnProperty('channels')) {
      user.channels.forEach((channel: any) => {
        let channelsObj = channelSelectors.getChannelById(state)(channel.id)
        if (channelsObj) {
          channelsArr.push({ id: channelsObj.id, name: channelsObj.name })
        }
      })
    }
    return channelsArr
  },
)

export const isViewingUser = createSelector(getState, state => {
  const {
    location: { pathname },
  } = history
  return pathname.includes('/users/') && pathname.split('/').pop() !== 'new'
})
