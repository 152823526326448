import { ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { Observable } from 'rxjs'
import { map, ignoreElements, pluck } from 'rxjs/operators'
import { channelActions } from '../../channels'
import { sitesSelectors } from '../../sites'
import { redirect } from '../../../../utils/router'
import { getCurrentFilter, getCurrentChannel } from './selectors'
import * as actions from './actions'
import { perPage } from './constants'
import { get as _get } from 'lodash'
import { liftPayload } from '../../../utils/rx-operators'
import { IUIChannelsModel } from './interfaces'
import { commentActions } from '../../comments'
import { CommentEntity, CommentType } from '../../comments/interfaces'

export const handleChannelDelete = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(channelActions.deleteChannelSuccess),
    map(() => sitesSelectors.getCurrentSitePath(state$.value)),
    map((path: string) => redirect(`/${path}/channels`)),
    ignoreElements(),
  )

export const handleChannelCreationSuccess = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(channelActions.createChannelSuccess),
    map(action => {
      const path = sitesSelectors.getCurrentSitePath(state$.value)
      redirect(`/${path}/channels/${action.payload.id}`)
    }),
    ignoreElements(),
  )

export const fetchDataOnPageChange = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.setCurrentPage),
    map(action => {
      const currentFilter = getCurrentFilter(state$.value)
      const currentChannel = getCurrentChannel(state$.value)
      let params: IUIChannelsModel = { per_page: perPage, page: action.payload }
      if (currentChannel) {
        params.include = currentChannel
      }
      if (currentFilter) {
        params = {
          ...params,
          ...JSON.parse(currentFilter),
        }
      }
      return channelActions.requestChannels(params)
    }),
  )

/**
 * @todo I'm not sure if we need this anymore. Double check and remove.
 */
export const fetchChannelsOnChannelChange = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.setChannel),
    liftPayload(),
    map(action => {
      const currentFilter = getCurrentFilter(state$.value)
      const channels = _get(action, 'id', null)
      let params: IUIChannelsModel = { per_page: perPage, page: 1 }
      if (channels) {
        params.include = channels
      }
      if (currentFilter) {
        params = {
          ...params,
          ...JSON.parse(currentFilter),
        }
      }
      return channelActions.requestChannels(params)
    }),
  )

export const refreshChangelogOnChannelUpdate = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(channelActions.updateChannelSuccess),
    pluck('payload'),
    map(payload =>
      commentActions.requestComments(
        { type: CommentType.Readonly },
        {
          entity: CommentEntity.Channels,
          entityId: payload.id,
          urlReplacements: { id: payload.id },
        },
      ),
    ),
  )
