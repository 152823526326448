export const sanitizeChannelFormValues = (values: any) => {
  const sanitizedValues = values

  for (const charge in sanitizedValues._tmt_charges) {
    if (sanitizedValues._tmt_charges.hasOwnProperty(charge)) {
      // catches any falsy _tmt_charges values and explicitly sets them to 0
      if (!sanitizedValues._tmt_charges[charge]) {
        sanitizedValues._tmt_charges[charge] = 0
      }
    }
  }

  return sanitizedValues
}
