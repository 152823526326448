import * as React from 'react'
import { DataTable, Anchor } from '../..'
import { useSelector } from 'react-redux'
import { channelSelectors } from '../../../../state/modules/channels'
import usePagination from '../../../../hooks/usePagination'
import { uiChannelsSelectors } from '../../../../state/modules/ui'
import { redirect } from '../../../../utils/router'
import { sitesSelectors } from '../../../../state/modules/sites'
import ChannelsPageService from './ChannelsPageService'

interface IContentProps {}

const Content = (props: IContentProps): JSX.Element => {
  const { setCurrentPage, getCurrentPage } = usePagination()
  const pages: TPages = useSelector(channelSelectors.getPages)
  const currentPage: number = parseInt(getCurrentPage())
  const isLoadingChannels: boolean = useSelector(channelSelectors.isLoading)
  const channelData: any = useSelector(uiChannelsSelectors.getChannelTableData)(
    currentPage,
  )
  const currentSitePath: string = useSelector(sitesSelectors.getCurrentSitePath)
  const currentSite = useSelector(sitesSelectors.getCurrentSite)
  const isProtectionOnly = currentSite?.protection_only

  const handleNext = () => setCurrentPage(currentPage + 1)

  const handlePrevious = () => setCurrentPage(currentPage - 1)

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      width: 80,
      isVisible: true,
    },
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (row: any) => {
        const redirectTo = () =>
          redirect(`/${currentSitePath}/channels/${row.original.id}`)
        return <Anchor onClick={redirectTo}>{row.value}</Anchor>
      },
      width: Math.round(window.innerWidth * 0.25),
      isVisible: true,
    },
    {
      Header: 'CCY',
      accessor: 'currencies',
      width: 80,
      isVisible: true,
    },
    {
      Header: 'Type',
      accessor: 'account_type',
      width: 180,
      isVisible: !isProtectionOnly,
    },
    {
      Header: 'Protection',
      accessor: 'protection_type',
      width: 180,
      isVisible: isProtectionOnly,
    },
    {
      Header: 'Payouts',
      accessor: 'statement_period',
      isVisible: !isProtectionOnly,
    },
    {
      Header: 'Mode',
      accessor: 'account_mode',
      width: 180,
      isVisible: true,
    },
    {
      Header: 'Status',
      accessor: 'channel_status',
      isVisible: true,
    },
  ].filter((column: any) => column.isVisible)

  return (
    <>
      <ChannelsPageService />
      <DataTable
        manual={true}
        fitContainer={true}
        data={channelData}
        columns={columns}
        loading={isLoadingChannels}
        pageSize={channelData.length}
        pages={pages.next ? currentPage + 1 : currentPage}
        page={currentPage}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />
    </>
  )
}

export default Content
