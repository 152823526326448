import React from 'react'
import {
  ActionMeta,
  GroupedOptionsType,
  OptionsType,
  OptionTypeBase,
  ValueType,
} from 'react-select'
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate'
import { colors } from '../../../styles/variables'

interface IAsyncSelectProps {
  className?: string
  initialOptions?:
    | GroupedOptionsType<OptionTypeBase>
    | OptionsType<OptionTypeBase>
  value?: any /** @todo */
  additional?: {
    page: number
    per_page: number
  }
  loadOptions: LoadOptions
  onChange: (
    value: ValueType<OptionTypeBase, boolean>,
    action: ActionMeta<OptionTypeBase>,
  ) => void
  [key: string]: any
}

const styles = {
  menu: (provided: any) => ({
    ...provided,
    fontSize: '14px',
  }),
  control: (provided: any) => ({
    ...provided,
    minHeight: '43px',
    borderRadius: '0',
    fontSize: '14px',
    cursor: 'pointer',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: state.isSelected ? colors.blue.dark : 'white',
    cursor: 'pointer',
  }),
}

const AsyncSelect = (props: IAsyncSelectProps): JSX.Element => {
  const { className, value, initialOptions, loadOptions, onChange, ...rest } =
    props
  return (
    <AsyncPaginate
      className={className}
      options={initialOptions}
      styles={styles}
      value={value}
      loadOptions={loadOptions}
      onChange={onChange}
      debounceTimeout={1000}
      {...rest}
    />
  )
}

export default AsyncSelect
