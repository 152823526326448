import * as React from 'react'
import Chart from 'react-apexcharts'

export interface IPieChartProps {
  series: number[]
  labels: string[]
  showLegend: boolean
  colors?: string[]
  stroke?: object
  dataLabels?: object
  tooltip?: object
  plotOptions?: object
  states?: object
}

class PieChart extends React.PureComponent<IPieChartProps, {}> {
  public render(): JSX.Element {
    const { series, labels, showLegend, ...otherOpts } = this.props
    const options = {
      labels,
      legend: showLegend,
      ...otherOpts,
    }
    return <Chart type="pie" options={options} series={series} />
  }
}

export default PieChart
