import { AnyAction } from 'redux'
import { combineEpics } from 'redux-observable'
import { authEpics, authReducer, authActions, authModel } from './modules/auth'
import { sitesEpics, sitesReducer, sitesModel } from './modules/sites'
import { uiReducer, uiEpics } from './modules/ui'
import {
  createChannelReducer,
  channelEpics,
  channelModel,
} from './modules/channels'
import {
  batchEpics,
  batchReducer,
  batchesInitialState,
} from './modules/batches'
import {
  bookingEpics,
  createBookingsReducer,
  bookingModel,
} from './modules/bookings'
import {
  transactionsEpics,
  createTransactionsReducer,
  transactionsModel,
} from './modules/transactions'
import {
  createPaymentsReducer,
  paymentsEpics,
  paymentsModel,
} from './modules/payments'
import {
  currencyEpics,
  currencyReducer,
  currencyModel,
} from './modules/currency'
import { statementEpics, statementModel } from './modules/statements'
import {
  payoutEpics,
  payoutsInitialState,
  payoutReducer,
} from './modules/payouts'
import { pspEpics, pspsInitialState, pspReducer } from './modules/psps'
import createStatementsReducer from './modules/statements/reducer'
import { uiModel } from './modules/ui/model'
import { usersEpics, createUsersReducer } from './modules/users'
import {
  globalSearchEpics,
  globalSearchModel,
  globalSearchReducer,
} from './modules/globalsearch'
import {
  ledgerEpics,
  ledgerInitialState,
  ledgerReducer,
} from './modules/ledger'
import {
  invoicesEpics,
  invoicesInitialState,
  invoicesReducer,
} from './modules/invoices'
import {
  supplierEpics,
  supplieresInitialState,
  supplierReducer,
} from './modules/suppliers'
import {
  allocationsEpics,
  allocationsInitialState,
  allocationsReducer,
} from './modules/allocations'
import { commentsInitialState, commentReducer } from './modules/comments/state'
import { commentEpics } from './modules/comments'

const systemEpics: any[] = [
  allocationsEpics,
  authEpics,
  batchEpics,
  bookingEpics,
  channelEpics,
  commentEpics,
  currencyEpics,
  invoicesEpics,
  paymentsEpics,
  payoutEpics,
  pspEpics,
  sitesEpics,
  statementEpics,
  transactionsEpics,
  usersEpics,
  globalSearchEpics,
  ledgerEpics,
  supplierEpics,
  ...uiEpics,
].map((e: any) => Object.keys(e).map((key: any) => e[key]))

export const rootEpic: any = combineEpics(...[].concat(...systemEpics))

const blankState = {
  ui: uiModel,
  allocations: allocationsInitialState,
  auth: authModel,
  batches: batchesInitialState,
  bookings: bookingModel,
  channels: channelModel,
  comments: commentsInitialState,
  currency: currencyModel,
  globalsearch: globalSearchModel,
  invoices: invoicesInitialState,
  ledger: ledgerInitialState,
  payments: paymentsModel,
  payouts: payoutsInitialState,
  psps: pspsInitialState,
  sites: sitesModel,
  statements: statementModel,
  suppliers: supplieresInitialState,
  transactions: transactionsModel,
}

export function rootReducer(state: any = {}, action: AnyAction) {
  if (action.type === authActions.loggedOut.toString()) {
    state = blankState
  }

  return {
    ui: uiReducer(state.ui, action),
    allocations: allocationsReducer(state.allocations, action),
    auth: authReducer(state.auth, action),
    batches: batchReducer(state.batches, action),
    bookings: createBookingsReducer(state)(state.bookings, action),
    channels: createChannelReducer(state)(state.channels, action),
    comments: commentReducer(state.comments, action),
    currency: currencyReducer(state.currency, action),
    globalsearch: globalSearchReducer(state)(state.globalsearch, action),
    invoices: invoicesReducer(state.invoices, action),
    ledger: ledgerReducer(state.ledger, action),
    payments: createPaymentsReducer(state)(state.payments, action),
    payouts: payoutReducer(state.payouts, action),
    psps: pspReducer(state.psps, action),
    sites: sitesReducer(state.sites, action),
    statements: createStatementsReducer(state)(state.statements, action),
    suppliers: supplierReducer(state.suppliers, action),
    transactions: createTransactionsReducer(state)(state.transactions, action),
    users: createUsersReducer(state)(state.users, action),
  }
}
