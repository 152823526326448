import * as React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { get, first } from 'lodash'
import { useEffectOnce } from 'react-use'
import SearchFilter from '../../organisms/SearchFilter/SearchFilter'
import { TextFilter, SelectFilter } from '../../organisms/SearchFilter/types'
import DateRangeFilter from '../../organisms/DateRangeFilter/DateRangeFilter'
import countries from '../../../../constants/countries'
import useFilter from '../../../../hooks/useFilter'
import useDateFilter from '../../../../hooks/useDateFilter'
import usePagination from '../../../../hooks/usePagination'

interface IBookingSearchFiltersProps {
  className?: string
}

const BookingSearchFilters = (
  props: IBookingSearchFiltersProps,
): JSX.Element => {
  const { setDateFilters, dateFilters } = useDateFilter()
  const { setCurrentPage } = usePagination()

  const getDateFilters = () => [
    {
      label: 'Created',
      value: 'created',
    },
    {
      label: 'Booking Date',
      value: 'date',
    },
  ]

  const handleOnConfirm = (values: any) => {
    const { startDate, endDate, startTime, endTime } = values
    if (startDate && endDate && startTime && endTime) {
      const { currentFilter } = values
      const dates =
        currentFilter.value === 'created'
          ? {
              before: endDate.format('YYYY-MM-DD') + endTime.format(' H:mm:ss'),
              after:
                startDate.format('YYYY-MM-DD') + startTime.format(' H:mm:ss'),
            }
          : {
              date_before:
                endDate.format('YYYY-MM-DD') + endTime.format(' H:mm:ss'),
              date_after:
                startDate.format('YYYY-MM-DD') + startTime.format(' H:mm:ss'),
            }
      setDateFilters(dates)
    } else if (startDate && endDate) {
      const { currentFilter } = values
      const dates =
        currentFilter.value === 'created'
          ? {
              before: endDate.format('YYYY-MM-DD 23:59:59'),
              after: startDate.format('YYYY-MM-DD 00:00:00'),
            }
          : {
              date_before: endDate.format('YYYY-MM-DD 23:59:59'),
              date_after: startDate.format('YYYY-MM-DD 00:00:00'),
            }
      setDateFilters(dates)
    }

    if (!startDate && !endDate) {
      // resetDates();
    }
    setCurrentPage(1)
  }

  const {
    filter,
    activeFilter,
    setActiveFilter,
    activeFilterValue,
    setActiveFilterValue,
  } = useFilter()

  const filters = [
    {
      value: 'include',
      label: 'Trust ID',
      type: 'text' as TextFilter,
      onChange: setActiveFilterValue,
    },
    {
      value: 'surname',
      label: 'Lead Traveller Surname',
      type: 'text' as TextFilter,
      onChange: setActiveFilterValue,
    },
    {
      value: 'email',
      label: 'Lead Traveller Email',
      type: 'text' as TextFilter,
      onChange: setActiveFilterValue,
    },
    {
      value: 'reference',
      label: 'Reference',
      type: 'text' as TextFilter,
      onChange: setActiveFilterValue,
    },
    {
      value: 'countries',
      label: 'Country',
      type: 'select' as SelectFilter,
      options: [{ value: 'all', label: 'All Countries' }, ...countries],
      onChange: setActiveFilterValue,
    },
    {
      value: 'status',
      label: 'Status',
      type: 'select' as SelectFilter,
      onChange: setActiveFilterValue,
      options: [
        {
          value: 'all',
          label: 'All statuses',
        },
        {
          value: 'paid',
          label: 'Paid',
        },
        {
          value: 'partial-payment',
          label: 'Partial Payment',
        },
        {
          value: 'unpaid',
          label: 'Unpaid',
        },
        {
          value: 'locked',
          label: 'Locked',
        },
        {
          value: 'authorized',
          label: 'Authorized',
        },
        {
          value: 'draft',
          label: 'Draft',
        },
      ],
    },
  ]

  const firstFilter = first(filters)
  const _activeFilter = activeFilter || get(firstFilter, 'value', '')

  /**
   * Set default active filter and value
   * If filter in query params, set that.
   * Else set first filter in array above as active filter
   */
  useEffectOnce(() => {
    const firstFilterKey =
      (filter && first(Object.keys(filter))) || get(filters, [0, 'value'], '')
    const firstFilterValue = (filter && first(Object.values(filter))) || ''
    setActiveFilter(firstFilterKey)
    setActiveFilterValue(firstFilterValue)
  })

  /**
   * Wrap `setActiveFilterValue` so we can reset pagination as well.
   */
  const _setActiveFilterValue = (val: any) => {
    setActiveFilterValue(val)
    setCurrentPage(1)
  }

  const startDate = get(dateFilters, 'date_after', get(dateFilters, 'after'))
  const endDate = get(dateFilters, 'date_before', get(dateFilters, 'before'))
  const startTime = get(dateFilters, 'date_after', get(dateFilters, 'after'))
  const endTime = get(dateFilters, 'date_before', get(dateFilters, 'before'))

  const dateFilterExclusions = ['include', 'email']
  const isDateFilterExclusionsActiveWithValue =
    dateFilterExclusions.includes(activeFilter) && activeFilterValue !== ''

  return (
    <div className={props.className}>
      <SearchFilter
        filters={filters}
        activeFilter={_activeFilter}
        setFilter={setActiveFilter}
        activeFilterValue={activeFilterValue}
        setActiveFilterValue={_setActiveFilterValue}
      />
      {startDate && endDate && (
        <DateRangeFilter
          plain={true}
          startDate={moment(startDate) || moment().subtract(2, 'weeks')}
          endDate={moment(endDate) || moment()}
          startTime={moment(startTime) || moment('00:00:00')}
          endTime={moment(endTime) || moment('23:59:59')}
          onConfirm={handleOnConfirm}
          filters={getDateFilters()}
          disabled={isDateFilterExclusionsActiveWithValue || false}
        />
      )}
    </div>
  )
}

export default styled(BookingSearchFilters)`
  display: flex;
  min-height: 44px;
  justify-content: space-between;

  > div:last-child {
    width: auto;
  }
`
