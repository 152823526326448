import React, { useCallback, useMemo, useState } from 'react'
import { Box } from 'grommet'
import { ArchiveTemplate, Loader, Text } from '../..'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import {
  channelConstants,
  channelSelectors,
} from '../../../../state/modules/channels'
import useChannelFilter from '../../../../hooks/useChannelFilter'
import DashboardProtectionOnly from './DashboardProtectionOnly'
import { sitesSelectors } from '../../../../state/modules/sites'
import DashboardProcessingOnly from './DashboardProcessingOnly'
import { useQuery } from '@tanstack/react-query'
import { IChannelMetrics } from './interfaces'
import { authActions, authSelectors } from '../../../../state/modules/auth'
import ChannelSelector from '../../stateful/ChannelSelector/ChannelSelector'
import { colors } from '../../../styles/variables'
import { transformMetrics } from './utils'
import { IChannelResponse } from '../../../../state/modules/channels/interfaces'
import { first } from 'lodash'

const Header = () => {
  const [channelId] = useChannelFilter()
  const channel = useSelector(state =>
    channelSelectors.getChannelById(state)(channelId || 0),
  )
  const channelName = channel?.name
  return (
    <Text tag="h1" color="#fff">
      Dashboard {channelName && ` | ${channelName}`}
    </Text>
  )
}

const fetchMetrics = async ({
  queryKey,
}: any): Promise<IChannelMetrics | Error> => {
  const [, { currentSitePath, channelId, authToken }] = queryKey
  const endpoint = `${channelConstants.ENDPOINTS.ENTITY.replace(
    ':sitePath',
    currentSitePath,
  ).replace(':id', channelId)}/metrics`
  const res = await fetch(endpoint, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (!res.ok) {
    const json = await res.json()
    throw json
  }
  return res.json()
}

const ChannelLoader = () => (
  <Box
    background="#fff"
    round="small"
    pad="medium"
    margin={{ top: '5px' }}
    style={{ minHeight: '80vh' }}
  >
    <Loader text="Loading channels..." />
  </Box>
)

const DashboardPageContent = () => {
  const dispatch = useDispatch()
  const [channelId] = useChannelFilter()
  const channel = useSelector(state =>
    channelSelectors.getChannelById(state)(channelId || 0),
  )
  const currency = channel?.currencies
  const currentSite = useSelector(sitesSelectors.getCurrentSite)
  const currentSitePath = useSelector(state =>
    sitesSelectors.getCurrentSitePath(state),
  )
  const authToken = useSelector(authSelectors.getToken)
  const isProtectionOnly = currentSite?.protection_only
  const { data, isLoading, isError } = useQuery(
    ['metrics', { currentSitePath, channelId, authToken }],
    fetchMetrics,
    {
      enabled: !!channelId,
      onError: (err: any) => {
        if (err?.code === 'auth_expired') {
          dispatch(authActions.loggedOut())
        }
      },
    },
  )

  return (
    <Box
      background="#fff"
      round="small"
      pad="medium"
      margin={{ top: '5px' }}
      style={{ minHeight: '80vh' }}
    >
      {!channelId && (
        <Box
          background="#cde3ee"
          pad="medium"
          border={{ color: colors.blue.medium, size: 'xsmall' }}
        >
          You currently only have test channels, create a draft channel to begin
          processing
        </Box>
      )}
      {channelId && isLoading && <Loader text="Fetching metrics..." />}
      {channelId &&
        !isLoading &&
        !isError &&
        (isProtectionOnly ? (
          <DashboardProtectionOnly
            currency={currency}
            metrics={transformMetrics(data as IChannelMetrics)}
          />
        ) : (
          <DashboardProcessingOnly
            currency={currency}
            metrics={transformMetrics(data as IChannelMetrics)}
          />
        ))}
    </Box>
  )
}

interface IDashboardBoardProps {
  className?: string
}

const DashboardPage = (props: IDashboardBoardProps): JSX.Element => {
  const [channelId, setChannelQueryParam] = useChannelFilter()
  const [isLoadingChannels, setIsLoadingChannels] = useState(true)
  const handleInitialChannelOptionsLoaded = useCallback(
    (channels: IChannelResponse[]) => {
      setIsLoadingChannels(false)
      if (channels.length > 0 && !channelId) {
        const firstChannel = first(channels)
        if (firstChannel) {
          setChannelQueryParam(firstChannel.id)
        }
      }
    },
    [channelId, setChannelQueryParam],
  )

  return useMemo(
    () => (
      <div className={props.className}>
        <ArchiveTemplate
          verticalOverflow="scroll"
          Header={Header}
          Content={isLoadingChannels ? ChannelLoader : DashboardPageContent}
          displayChannelSelector={true}
          CustomChannelSelector={
            <ChannelSelector
              isClearable={false}
              hideIfNoResults={true}
              extendedChannelRequestParams={{ account_mode_exclude: 'test' }}
              onInitialOptionsLoaded={handleInitialChannelOptionsLoaded}
            />
          }
        />
      </div>
    ),
    [handleInitialChannelOptionsLoaded, props.className, isLoadingChannels],
  )
}

const StyledDashboardPage = styled(DashboardPage)`
  width: 100%;
  overflow: scroll;
`

export default StyledDashboardPage
