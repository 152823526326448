import React from 'react'
import cx from 'classnames'
import {
  RankedTester,
  Test,
  UISchemaElement,
  computeLabel,
  ControlProps,
  isStringControl,
} from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import {
  VanillaRendererProps,
  withVanillaControlProps,
} from '@jsonforms/vanilla-renderers'
import SupplierSelector from '../../../../views/components/stateful/SupplierSelector'
import { Grommet } from 'grommet'
import timfinityTheme from '../../../../views/styles/timfinityTheme'
import styled from 'styled-components'
import { colors } from '../../../../views/styles/variables'
import { Button, TextInput } from '../../../../views/components'
import { useAtomValue } from 'jotai'
import { editingAtom } from '../../../../views/components/pages/SingleChannelPage/channelFormAtoms'
import ButtonField from '../../../../views/components/molecules/ButtonField/ButtonField'
import { useSelector } from 'react-redux'
import { authSelectors } from '../../../../state/modules/auth'

const ChannelSecretControl = (
  props: ControlProps & VanillaRendererProps & { className?: string },
) => {
  {
    const {
      className,
      handleChange,
      schema,
      uischema,
      id,
      enabled,
      path,
      data,
      visible,
      required,
      errors,
      label,
    } = props

    const isValid = errors.length === 0
    const isVisible = !!visible && (schema as any)?.visible !== false
    const isAdmin = useSelector(authSelectors.isAdmin)
    const isNewChannel = false // TODO: implement this

    const isEditing = useAtomValue(editingAtom)

    if (isNewChannel) {
      return null
    }

    if (!isVisible) {
      return null
    }

    return (
      <Grommet theme={timfinityTheme}>
        <div
          className={cx(className, {
            'has-error': !isValid,
          })}
        >
          <div className="input-control-cell">
            <ButtonField
              id="channel_secret"
              name="channel_secret"
              label={computeLabel(label, required || false, false)}
              // onChange={handleChange}
              onChange={console.log}
              value={data}
              className="field"
              text={`This will delete your channel secret and generate a new one.
You will need to update any code that generates authorisation strings
for the TMT Payment Modal with the newly generated channel secret
or you will not be able to process transactions.`}
              // okOnClickHandler={() => updateChannel(sanitizedValues)}
              okOnClickHandler={console.log}
              viewOnClickHandler={
                () => console.log('viewOnClickHandler')
                // getChannel({ id: values.id, channel_secret: 'reveal' })
              }
              isOnEditScreen={isEditing}
              viewChanged={isEditing}
              copyToClipboard={true}
              disabled={true}
              isAdmin={isAdmin}
            />
          </div>
        </div>
      </Grommet>
    )
  }
}

const { rankWith, and } = Test

/**
 * Alternative tester for an array that also checks whether the 'table'
 * option is set.
 * @type {RankedTester}
 */
export const channelSecretControlTester: RankedTester = rankWith(
  4,
  (uischema: UISchemaElement) => {
    return (uischema as any)?.scope === '#/properties/channel_secret'
  },
)

const StyledChannelSecretControl = styled(ChannelSecretControl)`
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;

  label {
    font-size: 15px;
    padding: 8px 0;
    margin-right: 0 !important;
  }

  .text-input-container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 676px;
  }

  #channel_secret {
    width: 100%;
  }

  .input-control-cell {
    width: 100%;
  }

  &.has-error {
    label {
      color: ${colors.red};
    }

    .validation {
      color: ${colors.red};
      margin-top: 5px;
    }

    input {
      border-color: ${colors.red} !important;
    }
  }
`

export default withVanillaControlProps(
  withJsonFormsControlProps(StyledChannelSecretControl),
)
