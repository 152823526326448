import React, { useCallback, useEffect, useMemo } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { map, get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { redirect } from '../../../../utils/router'
import { formatCurrency } from '../../../../utils/currency'
import useDateFilter from '../../../../hooks/useDateFilter'
import useFilter from '../../../../hooks/useFilter'
import { DataTable, Anchor } from '../..'
import {
  IRequestPayoutsParams,
  IRequestBatchesParams,
  IBatch,
  SettlementCurrency,
} from './interfaces'
import { format } from 'date-fns'
import {
  payoutActions,
  payoutSelectors,
} from '../../../../state/modules/payouts'
import { batchActions, batchSelectors } from '../../../../state/modules/batches'

interface IContentProps {}

interface IPendingPayoutRow {
  currency: string
  total: string
}

interface IExportedBatchRow {
  id: number
  date: string
  batch_number: number
  currency: string
  total: string
}

const Content = (props: IContentProps): JSX.Element => {
  const dispatch = useDispatch()
  const requestPayouts = useCallback(
    (params: IRequestPayoutsParams = {}) => {
      dispatch(payoutActions.requestPayouts(params))
    },
    [dispatch],
  )
  const requestBatches = useCallback(
    (params: IRequestBatchesParams = {}) => {
      dispatch(batchActions.requestBatches(params))
    },
    [dispatch],
  )

  /** @todo refactor to custom hook with TS interface for `PayoutStatus` */
  const [statusQuery = 'pending'] = useQueryParam('status', StringParam)

  const { dateFilters } = useDateFilter([], statusQuery === 'pending')
  const { filter } = useFilter()

  const settlement_currency = get(filter, 'settlement_currency')
  const batch_number = get(filter, 'batch_number')

  /**
   * Request Params
   * @todo rename SettlementCurrency type to Currency
   */
  const pendingParams: IRequestPayoutsParams = useMemo(
    () => ({
      groupby: 'currencies',
      aggregate: 'total',
      status: 'pending',
      settlement_currency:
        settlement_currency && settlement_currency !== 'all'
          ? (settlement_currency as SettlementCurrency)
          : undefined,
    }),
    [settlement_currency],
  )

  const exportedParams: IRequestBatchesParams = useMemo(
    () => ({
      status: 'exported',
      currencies:
        settlement_currency && settlement_currency !== 'all'
          ? (settlement_currency as SettlementCurrency)
          : undefined,
    }),
    [settlement_currency],
  )

  const releasedParams: IRequestBatchesParams = useMemo(
    () => ({
      status: 'released',
      batch_number,
    }),
    [batch_number],
  )

  const params: IRequestPayoutsParams | IRequestBatchesParams | {} =
    React.useMemo(() => {
      const dateParams = dateFilters ? dateFilters : {}
      switch (statusQuery) {
        case 'pending':
          return { ...pendingParams, ...dateParams }
        case 'exported':
          return { ...exportedParams, ...dateParams }
        case 'released':
          return { ...releasedParams, ...dateParams }
        default:
          return {}
      }
    }, [
      statusQuery,
      pendingParams,
      exportedParams,
      releasedParams,
      dateFilters,
    ])

  const isFetching = useSelector(
    statusQuery === 'pending'
      ? payoutSelectors.isLoading
      : batchSelectors.isLoading,
  )

  const data: any = useSelector(state =>
    statusQuery === 'pending'
      ? payoutSelectors.getPayoutsAggregate(state)
      : batchSelectors.getBatchesByStatus(state)(statusQuery),
  )

  useEffect(() => {
    statusQuery === 'pending'
      ? requestPayouts(params as IRequestPayoutsParams)
      : requestBatches(params as IRequestBatchesParams)
  }, [statusQuery, params, requestPayouts, requestBatches])

  const payoutData: IPendingPayoutRow[] | IExportedBatchRow[] | [] =
    React.useMemo(() => {
      switch (statusQuery) {
        case 'pending':
          return map(data, (total: number, currency: string) => ({
            currency,
            total: formatCurrency(total, true),
          }))
        default:
          return map(data, (batch: IBatch) => ({
            id: batch.id,
            date: batch.batch_date,
            batch_number: batch.batch_number,
            currency: batch.currencies,
            total: formatCurrency(batch.total, true),
          }))
      }
    }, [statusQuery, data])

  const columns = React.useMemo(() => {
    switch (statusQuery) {
      case 'pending':
        return [
          {
            Header: 'Currency',
            accessor: 'currency',
            Cell: (row: any) => {
              const redirectTo = () =>
                redirect(
                  `/payouts/batches/${row.original.currency}?status=${statusQuery}`,
                )
              return <Anchor onClick={redirectTo}>{row.value}</Anchor>
            },
          },
          {
            Header: 'Amount',
            accessor: 'total',
          },
        ]

      /**
       * Matches `exported` and `released`
       */
      default:
        return [
          {
            Header: 'Date',
            accessor: 'date',
            Cell: (row: any) => format(new Date(row.value), 'dd LLL yyyy'),
          },
          {
            Header: 'Batch Number',
            accessor: 'batch_number',
            Cell: (row: any) => {
              const redirectTo = () =>
                redirect(
                  `/payouts/batches/${row.original.id}?status=${statusQuery}`,
                )
              return <Anchor onClick={redirectTo}>{row.value}</Anchor>
            },
          },
          {
            Header: 'Currency',
            accessor: 'currency',
          },
          {
            Header: 'Amount',
            accessor: 'total',
          },
        ]
    }
  }, [statusQuery])

  return (
    <DataTable
      manual={true}
      fitContainer={true}
      data={payoutData}
      columns={columns}
      loading={isFetching}
      pageSize={payoutData.length}
    />
  )
}

export default Content
