import React from 'react'
import { CellProps, RankedTester, Test, UISchemaElement } from '@jsonforms/core'
import { withJsonFormsCellProps } from '@jsonforms/react'
import merge from 'lodash/merge'
import {
  VanillaRendererProps,
  withVanillaCellProps,
} from '@jsonforms/vanilla-renderers'
import styled from 'styled-components'
import ChannelSelector, {
  IChannelSelectorProps,
} from '../../../../views/components/stateful/ChannelSelector/ChannelSelector'
import { isArray } from 'lodash'
import { useSelector } from 'react-redux'
import { channelSelectors } from '../../../../state/modules/channels'
import { colors } from '../../../../views/styles/variables'

const ChannelCell = (props: CellProps & VanillaRendererProps) => {
  const {
    config,
    data,
    className,
    id,
    enabled,
    uischema,
    schema,
    path,
    handleChange,
  } = props
  const appliedUiSchemaOptions = merge({}, config, uischema.options)

  const channel = useSelector(state =>
    channelSelectors.getChannelById(state)(data),
  )

  const _handleChange: IChannelSelectorProps['onChange'] = channel => {
    if (isArray(channel)) {
      // TODO: handle multi
    } else {
      handleChange(path, channel?.id)
    }
  }

  const isFormFieldDisabled = () => {
    if (!enabled) {
      return true
    }
    const isReadOnly = (schema as any)?.readonly === true
    if (isReadOnly) {
      return true
    }
    return false
  }

  return (
    <div className={className}>
      <ChannelSelector
        id={id}
        isClearable={false}
        onChange={_handleChange}
        value={channel}
        disabled={isFormFieldDisabled()}
        hideOnCount={false}
      />
    </div>
  )
}

const { rankWith, and, isIntegerControl } = Test

/**
 * Default tester for text-based/string controls.
 * @type {RankedTester}
 */
export const channelCellTester: RankedTester = rankWith(
  4,
  and(isIntegerControl, (uischema: UISchemaElement) => {
    return uischema?.options?.Component === 'ChannelControl'
  }),
)

const StyledChannelCell = styled(ChannelCell)`
  width: 100%;

  .channel-selector {
    width: 100%;
    max-width: 676px;

    > div {
      border-radius: 4px;
      border-color: ${colors.blue.dark};
      font-size: 15px;
    }
  }
`

export default withJsonFormsCellProps(withVanillaCellProps(StyledChannelCell))
