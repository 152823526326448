import { IUIUsersModel } from './interfaces'

const uiUsersModel: IUIUsersModel = {
  currentPage: 1,
  currentChannel: null,
  serverErrors: null,
  isSaving: false,
  isCreating: false,
  isDeleting: false,
}

export default uiUsersModel
