import * as React from 'react'
import { Helmet } from 'react-helmet'
import {
  IChannelResponse,
  IRequestChannelsParams,
} from '../../../../state/modules/channels/interfaces'
import { SingleTemplate } from '../..'
import {
  CommentType,
  IComment,
} from '../../../../state/modules/comments/interfaces'
import { Accordion, Changelog } from '../..'
import styled from 'styled-components'
import { formatInTimeZone } from 'date-fns-tz'
import { Box } from 'grommet'
import ChannelForm from './ChannelForm'
import SingleChannelHeader from './SingleChannelHeader'

interface ISingleChannelPageProps {
  channel?: IChannelResponse
  currentSiteName: string
  match: any
  isAdmin: boolean
  readonlyComments: IComment[]
  channelSchemaMethods: string[]
  getChannel(params: IRequestChannelsParams): void
  getChannelSchema(): void
  redirect(path: string): void
  getComments(id: string, type: CommentType): void
}

class SingleChannelPage extends React.Component<ISingleChannelPageProps> {
  public componentDidMount() {
    const { match, getChannel, getChannelSchema, getComments } = this.props
    getChannelSchema()
    if (match.params.channelId !== 'new') {
      getChannel({ id: match.params.channelId, channel_secret: 'reveal' })
      getComments(match.params.channelId, CommentType.Readonly)
    }
  }

  public transformCommentsForComponent = (comments: IComment[]) => {
    return comments.map(comment => ({
      time: formatInTimeZone(
        new Date(comment.created_at),
        'GMT',
        'yyyy-LL-dd HH:mm:ss',
      ),
      user: comment.user ? comment.user.name : '',
      comment: comment.description,
    }))
  }

  public getAccordions = () => {
    const { channel, readonlyComments, isAdmin } = this.props
    if (!channel) {
      return []
    }
    if (!isAdmin) {
      return []
    }
    const panels = []
    panels.push({
      title: 'Changelog',
      Content: (
        <>
          <Box
            background="white"
            fill="horizontal"
            pad="none"
            round="none"
            className="changelog-list"
          >
            <Changelog
              comments={this.transformCommentsForComponent(readonlyComments)}
            />
          </Box>
        </>
      ),
      removePadding: true,
    })
    return panels
  }

  public render(): JSX.Element | null {
    const { channel, currentSiteName, match } = this.props
    return (
      <SingleTemplate currentSiteName={currentSiteName}>
        <Helmet>
          <title>{`Trust My Travel | Channels | ${channel?.name}`}</title>
        </Helmet>
        <ChannelForm channelId={match.params.channelId} />
        <div style={{ marginTop: '1.5rem' }}>
          <Accordion accordions={this.getAccordions()} />
        </div>
      </SingleTemplate>
    )
  }
}

const StyledSingleChannelPage = styled(SingleChannelPage)`
  .changelog-list {
    margin-bottom: 30px;
  }
`

export default StyledSingleChannelPage
