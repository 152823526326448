import React from 'react'
import { Button, Loader, Modal, Text } from '../../../views/components'
import { IModalProps } from '../../../views/components/molecules/Modal/Modal'
import { Box } from 'grommet'
import styled from 'styled-components'
import { colors } from '../../../views/styles/variables'
import { useGetRecoveryCodes } from '../api'

interface Props extends Omit<IModalProps, 'InnerComponent'> {
  className?: string
  userId: string
}

const ViewRecoveryCodes = ({ className, userId, ...modalProps }: Props) => {
  const { data, isLoading } = useGetRecoveryCodes({
    userId,
    enabled: modalProps.isOpen,
  })

  const InnerComponent = React.useMemo(
    () => () =>
      (
        <Box className={className}>
          <Text tag="h3" color={colors.blue.dark}>
            Backup Verification Codes
          </Text>
          <p>
            With 2FA enabled for your account, you'll need these backup codes if
            you ever lose your device. Without your device or a backup code,
            you'll have to contact support to recover your account.
          </p>
          {isLoading && <Loader text="Loading recovery codes..." />}
          {(data?.length || 0) > 0 && (
            <div data-test-id="recovery-codes">
              {data?.map((code, index) => (
                <Text key={index} tag="span" color={colors.blue.dark}>
                  {code}
                </Text>
              ))}
            </div>
          )}
          <footer>
            <Button
              styleType="primary"
              label="Got it"
              size="small"
              type="button"
              onClick={modalProps.onClose}
            />
          </footer>
        </Box>
      ),
    [data],
  )
  return <Modal {...modalProps} InnerComponent={<InnerComponent />} />
}

export default styled(ViewRecoveryCodes)`
  min-width: 400px;
  padding: 16px;

  max-width: 500px;
  overflow: hidden;

  h3 {
    margin: 0 0 10px 0;
    border-bottom: 1px solid ${colors.grey.lighter};
  }

  footer {
    display: flex;
    border-top: 1px solid ${colors.grey.lighter};
    margin-top: 10px;
    padding-top: 10px;

    footer button {
      font-size: 1rem;
      padding: 6px 8px;
    }

    button:first-child {
      margin-right: 10px;
    }
  }
`
