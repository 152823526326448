import { handleActions } from 'redux-actions'
import * as actions from './actions'
import model from './model'
import { AnyAction } from 'redux'
import { IUIBookingsModel } from './interfaces'
import { get as _get } from 'lodash'
import * as sitesActions from '../../sites/actions'
import { bookingActions } from '../../bookings'

export default handleActions(
  {
    [actions.setCurrentPage]: (state: IUIBookingsModel, action: AnyAction) => ({
      ...state,
      currentPage: action.payload,
    }),
    [actions.setCurrentFilter]: (
      state: IUIBookingsModel,
      action: AnyAction,
    ) => ({
      ...state,
      currentFilter: action.payload,
    }),
    [actions.setChannel]: (state: IUIBookingsModel, action: AnyAction) => {
      return {
        ...state,
        currentChannel: _get(action.payload, 'id', null),
      }
    },
    [sitesActions.siteSelected]: (state: IUIBookingsModel) => {
      return {
        ...state,
        currentFilter: '',
        currentChannel: null,
      }
    },
    [bookingActions.requestBooking]: (state: IUIBookingsModel) => ({
      ...state,
      isFetchingBooking: true,
    }),
    [bookingActions.requestBookingSuccess]: (state: IUIBookingsModel) => ({
      ...state,
      isFetchingBooking: false,
    }),
    [bookingActions.requestCreateBooking]: (state: IUIBookingsModel) => ({
      ...state,
      isCreatingBooking: true,
    }),
    [bookingActions.requestCreateBookingSuccess]: (
      state: IUIBookingsModel,
      action: AnyAction,
    ) => ({
      ...state,
      isCreatingBooking: false,
      lastCreatedBookingId: action.payload.id,
    }),
    [bookingActions.requestUpdateBooking]: (state: IUIBookingsModel) => ({
      ...state,
      isSaving: true,
    }),
    [bookingActions.requestUpdateBookingSuccess]: (
      state: IUIBookingsModel,
    ) => ({
      ...state,
      isSaving: false,
    }),
    [bookingActions.requestDeleteBooking]: (state: IUIBookingsModel) => ({
      ...state,
      isDeleting: true,
    }),
    [bookingActions.requestDeleteBookingSuccess]: (
      state: IUIBookingsModel,
    ) => ({
      ...state,
      isDeleting: false,
    }),
    http_error: (state: IUIBookingsModel, action: AnyAction) => {
      const { payload, meta } = action
      if (meta.action === bookingActions.requestCreateBooking.toString()) {
        return {
          ...state,
          isCreatingBooking: false,
        }
      }
      if (meta.action === bookingActions.requestUpdateBooking.toString()) {
        return {
          ...state,
          isSaving: false,
          serverErrors: payload.response,
        }
      }
      if (meta.action === bookingActions.requestDeleteBooking.toString()) {
        return {
          ...state,
          isDeleting: false,
          serverErrors: payload.response,
        }
      }
      return state
    },
  },
  model,
)
