import { JsonSchema } from '@jsonforms/core'
import { JsonFormsReactProps } from '@jsonforms/react'
import Ajv, { ErrorObject } from 'ajv'
import { useAtom } from 'jotai'
import { get, omit, uniqBy } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePrevious } from 'react-use'
import { authSelectors } from '../../../../state/modules/auth'
import { sitesActions, sitesSelectors } from '../../../../state/modules/sites'
import { uiSitesSelectors } from '../../../../state/modules/ui'
import { convertFromCents } from '../../../../utils/currency'
import {
  settingsFormDataAtom,
  settingsFormEditingAtom,
  settingsFormErrorsAtom,
} from './settingsPageAtoms'

const useSettingsPageForm = ({
  currentSitePath,
  ajv,
}: {
  currentSitePath: string
  ajv: Ajv
}) => {
  const dispatch = useDispatch()
  const isAdmin = useSelector(authSelectors.isAdmin)
  const site = useSelector(state =>
    sitesSelectors.getSiteByPath(state)(currentSitePath),
  )
  const sitesSchema = useSelector(sitesSelectors.getSitesSchema)

  const schema: JsonSchema = useMemo(() => {
    return omit(sitesSchema.schema, 'ui') as JsonSchema
  }, [sitesSchema])

  const uiSchema = useMemo(() => {
    return sitesSchema.schema?.ui
  }, [sitesSchema])

  const isNewSite = window.location.pathname.includes('sites/new')

  const setInitialValues = useMemo(
    () => (site: any) => {
      if (isNewSite) {
        const _tmg = Object.keys(
          schema?.properties?._tmg?.properties || {},
        ).reduce(
          (acc: any, key: string) => ({
            ...acc,
            [key]: get(
              schema?.properties?._tmg?.properties,
              `${key}.default`,
              '',
            ), // it seems only strings don't have a default value, so should be safe.
          }),
          {},
        )

        const _tmu = Object.keys(
          schema?.properties?._tmu?.properties || {},
        ).reduce((acc: any, key: string) => {
          const defaultVal = get(
            schema?.properties?._tmu?.properties,
            `${key}.default`,
          )

          if (defaultVal !== undefined) {
            acc[key] = defaultVal
          }
          return acc
        }, {})

        return {
          _business_unit: 'trust-protects',
          _gateway: schema.properties?._gateway?.default,
          protection_only: false,
          _tmg,
          _tmu,
        }
      }

      const _tmg = Object.keys(
        schema?.properties?._tmg?.properties || {},
      ).reduce((acc: any, key: string) => {
        const defaultVal = get(
          schema?.properties?._tmg?.properties,
          `${key}.default`,
          '',
        )
        const val = get(site, `_tmg.${key}`, defaultVal)

        return {
          ...acc,
          [key]: val === null ? defaultVal : val,
        }
      }, {})

      const _tmu = Object.keys(
        schema?.properties?._tmu?.properties || {},
      ).reduce((acc: any, key: string) => {
        const defaultVal = get(
          schema?.properties?._tmu?.properties,
          `${key}.default`,
        )
        const val = get(site, `_tmu.${key}`, defaultVal)

        return {
          ...acc,
          [key]: val === null ? defaultVal : val,
        }
      }, {})

      return {
        id: site?.id,
        name: site?.name,
        path: site?.path.replace(/\//g, ''),
        admin_email: site?.admin_email || '',
        admin_username: site?.admin_username || '',
        address: site?.address || '',
        _business_unit: site?._business_unit || 'trust-protects',
        _gateway: site?._gateway || schema.properties?._gateway?.default,
        protection_only: site?.protection_only || false,
        _tmg,
        _tmu,
      }
    },
    [currentSitePath, isNewSite],
  )

  const initialValues = setInitialValues(site)

  const [data, setData] = useAtom(settingsFormDataAtom)

  const isDirty = useMemo(() => {
    return JSON.stringify(data) !== JSON.stringify(initialValues)
  }, [data, initialValues])

  // set initial values when channelId changes
  useEffect(() => {
    setData(initialValues)
  }, [currentSitePath])

  const [errors, setErrors] = useAtom(settingsFormErrorsAtom)

  // Validation
  const validate = ajv.compile(schema)
  useEffect(() => {
    validate(data)
    setErrors(validate?.errors || [])
  }, [data])

  const [isEditing, setIsEditing] = useAtom(settingsFormEditingAtom)
  const [additionalErrors, setAdditionalErrors] = useState<ErrorObject[]>([])
  const isSaving = useSelector(uiSitesSelectors.isSaving)
  const isCreating = useSelector(uiSitesSelectors.isCreating)
  const wasSaving = usePrevious(isSaving)
  const wasCreating = usePrevious(isCreating)

  useEffect(() => {
    if (isNewSite) {
      setIsEditing(true)
    }
  }, [isNewSite, setIsEditing])

  useEffect(() => {}, [isSaving, wasSaving, isCreating, wasCreating])

  const addAdditionalError = (instancePath: string, message: string) => {
    const newError: ErrorObject = {
      // AJV style path to the property in the schema
      instancePath,
      // message to display
      message,
      schemaPath: '',
      keyword: '',
      params: {},
    }
    setAdditionalErrors(errors => uniqBy([...errors, newError], 'instancePath'))
  }

  const removeAdditionalError = (instancePath: string) => {
    setAdditionalErrors(errors =>
      errors.filter(e => e.instancePath !== instancePath),
    )
  }

  useEffect(() => {
    // validate email fields
  }, [data])

  const getLoadingState = useCallback(() => {
    if (isSaving) {
      return 'saving'
    }
    if (isCreating) {
      return 'creating'
    }

    return 'idle'
  }, [isSaving, isCreating])

  const handleChange: JsonFormsReactProps['onChange'] = useCallback(
    ({ data: newData, errors }) => {
      if (
        newData !== null &&
        JSON.stringify(newData) !== JSON.stringify(data)
      ) {
        setData(newData)
      }
    },
    [setData],
  )

  const transformData = useCallback(
    (data: any) => {
      if (!isAdmin) {
        return omit(data, ['name', 'protection_only', '_tmg', '_tmu'])
      }

      if (data._business_unit !== 'tmu') {
        return omit(data, ['_tmu'])
      }

      return {
        ...data,
        _tmu: {
          ...data._tmu,
          indemnity_limit: convertFromCents(data._tmu.indemnity_limit),
        },
      }
    },
    [isAdmin],
  )

  const handleSubmit = useCallback(() => {
    if (isNewSite) {
      dispatch(sitesActions.requestCreateSite(transformData(data)))
    } else {
      dispatch(sitesActions.requestUpdateSite(transformData(data)))
    }
  }, [currentSitePath, data, isNewSite, transformData])

  return {
    schema,
    uiSchema,
    site,
    initialValues,
    setInitialValues,
    errors: errors,
    isValid: (errors || []).length === 0,
    data,
    setData,
    isNewSite,
    isEditing,
    setIsEditing,
    isDirty,
    handleChange,
    getLoadingState,
    additionalErrors,
    setAdditionalErrors,
    handleSubmit,
  }
}

export default useSettingsPageForm
