import React, { FC, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Router, Switch, Route, matchPath } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { history } from '../state/store'
import { sitesActions, sitesSelectors } from './../state/modules/sites'
import { createRouteSimpleFactory, Loader } from './components'
import 'typeface-lato'
import { Box } from 'grommet'
import { uiAppActions, uiAppSelectors } from '../state/modules/ui'
import { authSelectors } from '../state/modules/auth'

interface IRoute {
  path: string
  exact: boolean
  title: string
  Component(): any
}

const lazyImport = (filename: string) => React.lazy(() => import(`${filename}`))

const titlePrefix = 'Trust My Travel'

const publicRoutes: IRoute[] = [
  {
    path: '/',
    exact: true,
    title: `${titlePrefix} | Login`,
    Component: () => lazyImport('./components/pages/LoginPage'),
  },
  {
    path: '/login',
    exact: true,
    title: `${titlePrefix} | Login`,
    Component: () => lazyImport('./components/pages/LoginPage'),
  },
  {
    path: '/password-forgot',
    exact: true,
    title: `${titlePrefix} | Forgot Password`,
    Component: () => lazyImport('./containers/ForgotPasswordPageContainer'),
  },
  {
    path: '/password-forgot-success',
    exact: true,
    title: `${titlePrefix} | Password Successfully Reset`,
    Component: () =>
      lazyImport('./containers/ForgotPasswordSuccessPageContainer'),
  },
  {
    path: '/password-reset',
    exact: true,
    title: `${titlePrefix} | Reset Password`,
    Component: () => lazyImport('./containers/ResetPasswordPageContainer'),
  },
]

export const privateRoutes: IRoute[] = [
  {
    path: '/:sitePath/dashboard',
    exact: true,
    title: `${titlePrefix} | Dashboard`,
    Component: () =>
      lazyImport('./components/pages/DashboardPage/DashboardPage'),
  },
  {
    path: '/:sitePath/channels',
    exact: true,
    title: `${titlePrefix} | Channels`,
    Component: () => lazyImport('./components/pages/ChannelsPage/ChannelsPage'),
  },
  {
    path: '/:sitePath/new/channel',
    exact: true,
    title: `${titlePrefix} | New Channel`,
    Component: () => lazyImport('./containers/SingleChannelPageContainer'),
  },
  {
    path: '/:sitePath/channels/:channelId',
    exact: true,
    title: `${titlePrefix} | Channel`, // overwritten in single channel component
    Component: () => lazyImport('./containers/SingleChannelPageContainer'),
  },
  {
    path: '/:sitePath/bookings',
    exact: true,
    title: `${titlePrefix} | Bookings`,
    Component: () => lazyImport('./components/pages/BookingsPage/BookingsPage'),
  },
  {
    path: '/:sitePath/bookings/:id',
    exact: true,
    title: `${titlePrefix} | Booking`, // overwritten in single booking component
    Component: () => lazyImport('./containers/SingleBookingPageContainer'),
  },
  {
    path: '/:sitePath/transactions',
    exact: true,
    title: `${titlePrefix} | Transactions`,
    Component: () =>
      lazyImport('./components/pages/TransactionsPage/TransactionsPage'),
  },
  {
    path: '/:sitePath/transactions/:id',
    exact: true,
    title: `${titlePrefix} | Transaction`, // overwritten in single transaction component
    Component: () => lazyImport('./containers/SingleTransactionsPageContainer'),
  },
  {
    path: '/:sitePath/allocations',
    exact: true,
    title: `${titlePrefix} | Allocations`,
    Component: () =>
      lazyImport('./components/pages/AllocationsPage/AllocationsPage'),
  },
  {
    path: '/:sitePath/allocations/:id',
    exact: true,
    title: `${titlePrefix} | Allocation`, // overwritten in single allocation component
    Component: () =>
      lazyImport(
        './components/pages/SingleAllocationsPage/SingleAllocationsPage',
      ),
  },
  {
    path: '/:sitePath/payments',
    exact: true,
    title: `${titlePrefix} | Payments`,
    Component: () => lazyImport('./components/pages/PaymentsPage/PaymentsPage'),
  },
  {
    path: '/:sitePath/statements',
    exact: true,
    title: `${titlePrefix} | Statements`,
    Component: () =>
      lazyImport('./components/pages/StatementsPage/StatementsPage'),
  },
  {
    path: '/:sitePath/invoices',
    exact: true,
    title: `${titlePrefix} | Invoices`,
    Component: () => lazyImport('./components/pages/InvoicesPage/InvoicesPage'),
  },
  {
    path: '/:sitePath/invoices/:id',
    exact: true,
    title: `${titlePrefix} | Invoice`, // overwritten in single invoice component
    Component: () =>
      lazyImport('./components/pages/SingleInvoicePage/SingleInvoicePage'),
  },
  {
    path: '/:sitePath/statements/:id',
    exact: true,
    title: `${titlePrefix} | Statement`, // overwritten in single statement component
    Component: () => lazyImport('./containers/SingleStatementPageContainer'),
  },
  {
    path: '/:sitePath/ledger',
    exact: true,
    title: `${titlePrefix} | Ledger`,
    Component: () => lazyImport('./components/pages/LedgerPage/LedgerPage'),
  },
  {
    path: '/search',
    exact: true,
    title: `${titlePrefix} | Search`,
    Component: () =>
      lazyImport('./components/pages/GlobalSearchPage/GlobalSearchPage'),
  },
  {
    path: '/profile',
    exact: true,
    title: `${titlePrefix} | Profile`,
    Component: () => lazyImport('./containers/ProfilePageContainer'),
  },
  {
    path: '/payouts',
    exact: true,
    title: `${titlePrefix} | Payouts`,
    Component: () => lazyImport('./components/pages/PayoutsPage/PayoutsPage'),
  },
  {
    path: '/payouts/:id',
    exact: true,
    title: `${titlePrefix} | Payout`, // overwritten in single payout component
    Component: () =>
      lazyImport(
        './components/pages/PayoutsPage/SinglePayoutPage/SinglePayoutPage',
      ),
  },
  {
    path: '/payouts/batches/:batch',
    exact: true,
    title: `${titlePrefix} | Batches`, // overwritten in batch component
    Component: () =>
      lazyImport(
        './components/pages/PayoutsPage/SingleBatchPage/SingleBatchPage',
      ),
  },
  {
    path: '/:sitePath/settings',
    exact: true,
    title: `${titlePrefix} | Settings`,
    Component: () => lazyImport('./components/pages/SettingsPage/SettingsPage'),
  },
  {
    path: '/:sitePath/analytics',
    exact: true,
    title: `${titlePrefix} | Analytics`,
    Component: () =>
      lazyImport('./components/pages/AnalyticsPage/AnalyticsPage'),
  },
  {
    path: '/sites/new',
    exact: true,
    title: `${titlePrefix} | New Site`,
    Component: () => lazyImport('./components/pages/SettingsPage/SettingsPage'),
  },
  {
    path: '/:sitePath/users',
    exact: true,
    title: `${titlePrefix} | Users`,
    Component: () => lazyImport('./containers/UsersPageContainer'),
  },
  {
    path: '/users/:id',
    exact: true,
    title: `${titlePrefix} | User`, // overwritten in single user component
    Component: () => lazyImport('./containers/SingleUsersPageContainer'),
  },
  {
    path: '/:sitePath/users/:id',
    exact: true,
    title: `${titlePrefix} | User`, // overwritten in single user component
    Component: () => lazyImport('./containers/SingleUsersPageContainer'),
  },
  {
    path: '/suppliers/:id',
    exact: true,
    title: `${titlePrefix} | Suppliers`, // overwritten in single supplier component
    Component: () =>
      lazyImport('./components/pages/SingleSuppliersPage/SingleSuppliersPage'),
  },
  {
    path: '/suppliers',
    exact: true,
    title: `${titlePrefix} | Suppliers`,
    Component: () =>
      lazyImport('./components/pages/SuppliersPage/SuppliersPage'),
  },
  {
    path: '/:sitePath/uploads',
    exact: true,
    title: `${titlePrefix} | My Uploads`,
    Component: () =>
      lazyImport('./components/pages/MyUploadsPage/MyUploadsPage'),
  },
  {
    path: '/:sitePath/upload/:id',
    exact: true,
    title: `${titlePrefix} | Upload Progress`,
    Component: () =>
      lazyImport('./components/pages/UploadCsvPage/UploadCsvPage'),
  },
  {
    path: '/logout',
    exact: true,
    title: `${titlePrefix} | Logout`,
    Component: () => lazyImport('./containers/LogoutPageContainer'),
  },
]

type ProtectedRoutes = Array<{
  path: string
  isProtected: boolean
}>

const Routes: FC = (props: any) => {
  const dispatch = useDispatch()
  const currentSitePath = useSelector(sitesSelectors.getCurrentSitePath)
  const isAppLoading = useSelector(uiAppSelectors.isLoading)
  const isAppBooted = useSelector(uiAppSelectors.isBooted)
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)
  const canUserViewSettings = useSelector(state =>
    sitesSelectors.getCurrentSitePermission(state)('view_settings'),
  )

  useEffect(() => {
    if (isAuthenticated && !isAppLoading && !isAppBooted) {
      dispatch(uiAppActions.init())
    }
  }, [isAuthenticated, isAppLoading, isAppBooted, dispatch])

  const protectedRoutes: ProtectedRoutes = [
    {
      path: '/:sitePath/allocations',
      isProtected: !canUserViewSettings,
    },
    {
      path: '/:sitePath/allocations/:id',
      isProtected: !canUserViewSettings,
    },
  ]

  const isRouteProtected = (route: IRoute) => {
    const protectedRoute = protectedRoutes.find(
      protectedRoute => protectedRoute.path === route.path,
    )
    return protectedRoute && protectedRoute.isProtected
  }

  const createRoute = createRouteSimpleFactory(
    props,
    currentSitePath,
    isAppLoading,
  )

  const renderPublicRoutes = () =>
    publicRoutes.map((r: IRoute) =>
      createRoute(r.path, r.Component(), r.path, r.exact, r.title),
    )

  const renderPrivateRoutes = () =>
    privateRoutes.map((r: IRoute) =>
      createRoute(
        r.path,
        r.Component(),
        r.path,
        r.exact,
        r.title,
        true,
        isRouteProtected(r),
      ),
    )

  return (
    <React.Suspense
      fallback={
        <Box height="100vh">
          <Loader text="Loading..." ms={300} />
        </Box>
      }
    >
      <Router history={history}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Switch>
            {renderPublicRoutes()}
            {renderPrivateRoutes()}
          </Switch>
        </QueryParamProvider>
      </Router>
    </React.Suspense>
  )
}

export default Routes
