import * as React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { get } from 'lodash'
import { Button, TextInput } from '..'
import { useDispatch, useSelector } from 'react-redux'
import { uiAppSelectors } from '../../../state/modules/ui'
import { authActions, authSelectors } from '../../../state/modules/auth'
import { sitesSelectors } from '../../../state/modules/sites'
import { Field, Form, Formik } from 'formik'
import { Box, FormField } from 'grommet'
import styled from 'styled-components'
import { ITokenRequest } from '../../../state/modules/auth/interfaces'
import { LocationState } from 'history'

export interface ILoginPageProps {
  className?: string
  location: LocationState
}

type Initialvalues = {
  username: string
  password: string
  two_factor_code?: string
}

const LoginPage: React.FC<ILoginPageProps> = ({ className, location }) => {
  const dispatch = useDispatch()
  const isLoggingIn = useSelector(uiAppSelectors.isLoggingIn) as boolean
  const serverErrors: any = useSelector(uiAppSelectors.loginErrors)
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)
  const currentSitePath = useSelector(sitesSelectors.getCurrentSitePath)
  const initialValues: Initialvalues = { username: '', password: '' }

  const handleLogin = (values: ITokenRequest) => {
    // if code is longer than 6 characters, assume it's a recovery code
    if ((values.two_factor_code?.length || 0) > 6) {
      values = {
        username: values.username,
        password: values.password,
        recovery_code: values.two_factor_code,
      }
    }
    dispatch(authActions.requestToken(values))
  }

  let from = get(location, 'state.from', `/${currentSitePath}/dashboard`)
  if (from.pathname === '/logout') {
    from = '/'
  }

  const isInvalidTwoFactorCode = [
    'invalid_2fa_code',
    'invalid_recovery_code',
  ].includes(serverErrors?.code)

  const requiresTwoFactor =
    (serverErrors &&
      serverErrors?.data?.status === 400 &&
      Object.keys(serverErrors?.data?.params).includes('two_factor_code')) ||
    isInvalidTwoFactorCode

  if (isAuthenticated) {
    return <Redirect to={from} />
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        handleLogin(values)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <Box
            fill={true}
            align="center"
            justify="center"
            pad="large"
            className={className}
          >
            <Box width="medium">
              {requiresTwoFactor && (
                <div>
                  <h2>Two Factor Authentication</h2>
                  <p>
                    Please enter the code from your authenticator app or a
                    recovery code.
                  </p>
                </div>
              )}
              {!requiresTwoFactor && serverErrors && (
                <div className="error-message">
                  {/* TODO: remove two_factor_error from server errors. There might be others? */}
                  <div
                    dangerouslySetInnerHTML={{ __html: serverErrors.message }}
                  />
                </div>
              )}
              <Form>
                {requiresTwoFactor ? (
                  <FormField>
                    <Field
                      component={TextInput}
                      id="two_factor_code"
                      name="two_factor_code"
                      type="two_factor_code"
                      placeholder="Two factor code"
                      onChange={handleChange}
                      disabled={isLoggingIn}
                      value={values.two_factor_code}
                      error={isInvalidTwoFactorCode ? 'Invalid code' : null}
                    />
                  </FormField>
                ) : (
                  <>
                    <FormField>
                      <Field
                        component={TextInput}
                        id="username"
                        name="username"
                        placeholder="Username"
                        onChange={handleChange}
                        value={values.username}
                      />
                    </FormField>
                    <FormField>
                      <Field
                        component={TextInput}
                        id="password"
                        name="password"
                        type="password"
                        placeholder="Password"
                        onChange={handleChange}
                        disabled={isLoggingIn}
                        value={values.password}
                      />
                    </FormField>
                  </>
                )}
                <div className="login-button">
                  <Button
                    alignSelf="center"
                    styleType="gradient"
                    size="large"
                    type="submit"
                    label={isLoggingIn ? 'Logging in...' : 'Login'}
                    disabled={isLoggingIn}
                  />
                </div>
                {!requiresTwoFactor && (
                  <div className="login-forgot-password">
                    Did you{' '}
                    <Link to="/password-forgot">forget your password?</Link>
                  </div>
                )}
              </Form>
            </Box>
          </Box>
        )
      }}
    </Formik>
  )
}

const StyledLoginPage = styled(LoginPage)`
  input {
    border: none;
    &:focus {
      box-shadow: none;
    }
  }

  .login-button {
    margin-top: 30px;
    display: block;

    button {
      width: 100%;
      padding: 20px 0;
    }
  }

  .login-forgot-password {
    margin-top: 30px;
    text-align: center;
    font-size: 1rem;
    a {
      color: #2980b9;
    }
  }
`

export default StyledLoginPage
