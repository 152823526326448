import { rgba } from 'polished'
import { colors } from './variables'
import { ThemeType } from 'grommet'

const baseSpacing = 20
const scale = 6

const baseFontSize = baseSpacing * 0.75 // ~15
const fontScale = baseSpacing / scale // ~3

const fontSizing = (factor: number) => ({
  size: `${baseFontSize + factor * fontScale}px`,
  height: `${baseSpacing + factor * fontScale}px`,
  // maxWidth chosen to be ~50 characters wide
  // see: https://ux.stackexchange.com/a/34125
  maxWidth: `${baseSpacing * (baseFontSize + factor * fontScale)}px`,
})

const timfinityTheme: ThemeType = {
  global: {
    font: {
      ...fontSizing(0),
      family: 'Lato, "Lucida Grande", Tahoma, Sans-Serif',
    },
    colors: {
      active: rgba(221, 221, 221, 0.5),
      black: '#000000',
      border: {
        dark: rgba(255, 255, 255, 0.33),
        light: rgba(0, 0, 0, 0.33),
      },
      brand: colors.blue.dark,
      control: {
        dark: 'accent-1',
        light: 'brand',
      },
      focus: colors.blue.light,
      placeholder: '#AAAAAA',
      selected: 'brand',
      text: {
        dark: '#f8f8f8',
        light: '#444444',
      },
      icon: {
        dark: '#f8f8f8',
        light: '#666666',
      },
      white: '#FFFFFF',
    },
  },
  select: {
    background: '#ffffff',
  },
  textInput: {
    extend: (props: any) => `
            font-weight: normal;
            ${
              props.disabled &&
              `
                background: ${colors.grey.lighter};
                cursor: not-allowed;
            `
            }
        `,
  },
  layer: {
    extend: (props: any) => `
            .close-button {
                position: absolute;
                top: 0;
                right: 0;

                button {
                    padding: 5px;
                }
            }
        `,
  },
  tabs: {
    header: {
      background: 'transparent',
      extend: (props: any) => `
                justify-content: flex-start;
                border-radius: 4px 4px 0 0;
                font-weight: bold;
            `,
    },
    panel: {
      extend: () => `
                background: #FFFFFF;
                color: ${colors.blue.dark}
                padding: 20px;
                border-radius: 0 0 8px 8px;
            `,
    },
  },
  tab: {
    active: {
      background: '#FFFFFF',
      color: colors.blue.dark,
    },
    color: '#FFFFFF',
    border: {
      size: '0',
    },
    hover: {
      color: 'rgba(255, 255, 255, 0.7)',
    },
    margin: {
      vertical: '0',
      horizontal: '0',
    },
    pad: '15px 20px',
    extend: () => `
            border-radius: 4px 4px 0 0;
        `,
  },
}

export default timfinityTheme
