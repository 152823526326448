import { handleActions, combineActions } from 'redux-actions'
import { get, uniq } from 'lodash'
import { AnyAction } from 'redux'
import { authActions } from '../auth'
import * as actions from './actions'
import model from './model'
import { ISitesModel, IRequestSitesResponseObject } from './interfaces'
import { sitesSelectors } from '.'
import { NAMESPACE } from './constants'

export default handleActions(
  {
    [actions.requestSitesSchemaSuccess]: (
      state: ISitesModel,
      action: AnyAction,
    ) => ({
      ...state,
      schema: action.payload,
    }),
    [actions.requestSitesSuccess]: (
      state: ISitesModel = model,
      action: AnyAction,
    ) => {
      const { payload, meta } = action
      const newState = {
        ...state,
        byId: {
          ...state.byId,
          ...payload.reduce((sites: any, site: IRequestSitesResponseObject) => {
            sites[site.id] = site
            return sites
          }, {}),
        },
        allIds: uniq([
          ...state.allIds,
          ...payload.map((site: IRequestSitesResponseObject) => site.id),
        ]),
      }

      const searchTerm = get(meta, 'params.search', '')

      if (searchTerm.length > 0) {
        newState.bySearchTerms = {
          ...state.bySearchTerms,
          ...{
            [searchTerm]: payload.map((s: IRequestSitesResponseObject) => s.id),
          },
        }
      }
      return newState
    },
    [actions.requestSiteSuccess]: (
      state: ISitesModel = model,
      action: AnyAction,
    ) => {
      const { payload } = action
      const newState = {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
        allIds: uniq([...state.allIds, payload.id]),
      }
      return newState
    },
    [authActions.requestTokenSuccess]: (
      state: ISitesModel = model,
      action: AnyAction,
    ) => ({
      ...state,
      currentSite: get(action, 'payload.default_site', null),
      previousSite: get(action, 'payload.default_site', null),
    }),
    [actions.updateSearchTerm]: (
      state: ISitesModel = model,
      action: AnyAction,
    ) => ({
      ...state,
      currentSearchTerm: action.payload,
    }),
    [actions.siteSelected]: (
      state: ISitesModel = model,
      action: AnyAction,
    ) => ({
      ...state,
      currentSite: action.payload,
      previousSite: sitesSelectors.getCurrentSite({ [NAMESPACE]: state }),
    }),
    [combineActions(
      actions.requestCreateSiteSuccess,
      actions.requestUpdateSiteSuccess,
    )]: (state: ISitesModel, action: AnyAction) => {
      const { payload } = action
      return {
        ...state,
        currentSite: payload,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
      }
    },
  },
  model,
)
