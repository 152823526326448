import * as React from 'react'
import {
  Grommet,
  Accordion as GrommetAccordian,
  AccordionPanel,
  Box,
} from 'grommet'
import styled from 'styled-components'
import timfinityTheme from '../../../styles/timfinityTheme'
import PanelHeader from './PanelHeader'
// import { FormSubtract, FormAdd } from 'grommet-icons';

interface IAccordionProps {
  accordions: IAccordion[]
  className?: string
}

interface IAccordionState {
  activeIndex: number[]
}

interface IAccordion {
  title: string
  Content: JSX.Element
  removePadding?: boolean
}

class Accordion extends React.Component<IAccordionProps, IAccordionState> {
  public state = {
    activeIndex: [0],
  }

  public handleActive = (newActiveIndex: number[]) =>
    this.setState({ activeIndex: newActiveIndex })

  public renderAccordions = () =>
    this.props.accordions.map((accordion: IAccordion, i: number) => {
      const boxProps = accordion.removePadding ? {} : { pad: 'medium' }
      return (
        <AccordionPanel
          key={i}
          header={
            <PanelHeader
              title={accordion.title}
              active={this.state.activeIndex.includes(i)}
            />
          }
        >
          <Box {...boxProps}>{accordion.Content}</Box>
        </AccordionPanel>
      )
    })

  public render() {
    const { activeIndex } = this.state
    const { className } = this.props
    return (
      <Grommet theme={timfinityTheme} className={className}>
        <GrommetAccordian
          activeIndex={activeIndex}
          onActive={this.handleActive}
        >
          {this.renderAccordions()}
        </GrommetAccordian>
      </Grommet>
    )
  }
}

const StyledAccordion = styled(Accordion)`
  & > div > div {
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 10px;
  }
`

export default StyledAccordion
