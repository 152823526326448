import React from 'react'
import { useSelector } from 'react-redux'
import { bookingSelectors } from '../../../../state/modules/bookings'
import { channelSelectors } from '../../../../state/modules/channels'
import { sitesSelectors } from '../../../../state/modules/sites'
import timfinityTheme from '../../../styles/timfinityTheme'
import { colors } from '../../../styles/variables'

type Props = {
  bookingId: string
  token?: string
  className?: string
}

const PaymentRequestButton = ({ bookingId, token, className }: Props) => {
  const getChannelFieldById = useSelector(channelSelectors.getChannelFieldById)
  const booking = useSelector(state =>
    bookingSelectors.getBookingById(state)(bookingId),
  )

  const canUserCreateBookings = useSelector(state =>
    sitesSelectors.getCurrentSitePermission(state)('create_bookings'),
  )

  const channelAccountMode =
    booking && getChannelFieldById(booking?.channels, 'account_mode')

  const isSiteProtectionOnly = useSelector(
    sitesSelectors.getCurrentSiteProtectionOnly,
  )

  const status = booking?.status || 'unpaid'

  if (
    status === 'paid' ||
    status === 'locked' ||
    status === 'authorized' ||
    !token ||
    !canUserCreateBookings ||
    channelAccountMode === 'protection-only' ||
    bookingId === 'new' ||
    isSiteProtectionOnly
  ) {
    return null
  }

  const href = `${process.env.REACT_APP_PR_ENDPOINT}/paymentrequest?token=${token}`

  const style = {
    backgroundColor: colors.green,
    color: timfinityTheme?.global?.colors?.white,
    border: 'none',
    borderRadius: '5px',
    textAlign: 'center',
    textDecoration: 'none',
    fontFamily: timfinityTheme?.global?.font?.family,
    fontSize: timfinityTheme?.global?.font?.size,
    lineHeight: timfinityTheme?.global?.font?.height,
    cursor: 'pointer',
    padding: '10px 18px',
    marginLeft: 'auto',
    width: 'fit-content',
  } as any

  return (
    <a href={href} style={style} target="_blank" rel="noopener noreferrer">
      Generate Payment Request
    </a>
  )
}

export default PaymentRequestButton
