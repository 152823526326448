import React from 'react'
import styled from 'styled-components'
import { DataTable } from '../../'

interface IChangelogProps {
  className?: string
  comments: Array<IComment>
}

interface IComment {
  time: string
  user: string
  comment: string
}

const Changelog = (props: IChangelogProps) => {
  const { className, comments } = props

  const columns = [
    {
      Header: 'Time',
      accessor: 'time',
      minWidth: 30,
    },
    {
      Header: 'User',
      accessor: 'user',
      minWidth: 30,
    },
    {
      Header: 'Comment',
      accessor: 'comment',
      style: {
        whiteSpace: 'break-spaces',
      },
    },
  ]

  return (
    <div className={className}>
      <DataTable
        manual={true}
        plain={true}
        columns={columns}
        data={comments}
        pageSize={comments.length}
        loading={false}
      />
    </div>
  )
}

export default styled(Changelog)`
  .ReactTable .rt-tbody .rt-tr,
  .ReactTable-striped .rt-tbody .rt-tr {
    align-items: flex-start;
  }

  .ReactTable .rt-tbody .rt-td {
    align-self: start;
  }
`
