import { createSelector } from 'reselect'
import { get } from 'lodash'
import { NAMESPACE } from './constants'
import { IPayoutsState } from './interfaces'

const getState = (state: any) => state

const getPayoutsState = (state: any): IPayoutsState => state[NAMESPACE]

const getPayoutsSlice = (slice: keyof IPayoutsState) =>
  createSelector(getPayoutsState, payouts => get(payouts, [slice]))

export const getPayoutsSchema = getPayoutsSlice('schema')

export const getPayoutIds = getPayoutsSlice('allIds')

export const getPayouts = getPayoutsSlice('byId')

export const getPayoutsAggregate = getPayoutsSlice('aggregate')

export const getPayoutById = createSelector(
  getPayouts,
  payouts => (id: number) => get(payouts, [id], {}),
)

export const getRequestParams = getPayoutsSlice('idsByRequestParams')

export const getPayoutsByRequestParams = createSelector(
  [getState, getRequestParams],
  (state, requestParams) => (params: string) =>
    get(requestParams, [params], []).map((id: number) =>
      getPayoutById(state)(id),
    ),
)

export const getPayoutsByPage = createSelector(
  [getState, getPayoutsState],
  (state, payouts) => (page: number | string) => {
    const payoutsByPage = get(payouts, `byPage[${page}]`, [])
    return payoutsByPage.map((payoutId: number) =>
      getPayoutById(state)(payoutId),
    )
  },
)

export const getPages = createSelector(
  [getPayoutsState],
  payouts => payouts.pages,
)

export const isLoading = getPayoutsSlice('loading')

export const isSingleLoading = getPayoutsSlice('isSingleLoading')

export const isUpdateLoading = getPayoutsSlice('isUpdateLoading')
