import * as React from 'react'
import { Moment } from 'moment'
import moment from 'moment'
import { range } from 'lodash'
import 'react-dates/initialize'
import { DateRangePicker as _DateRangePicker } from 'react-dates'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers'
import { MuiThemeProvider } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import './DateRangePicker.scss'
import Button from '../../atoms/Button/Button'

interface IDates {
  startDate: Moment | null
  endDate: Moment | null
}

export interface IDatesAndITimes extends IDates {
  startTime: Moment | null
  endTime: Moment | null
}

export interface IDateRangePickerProps extends IDatesAndITimes {
  onConfirm(selection: IDatesAndITimes): void
  disabled?: boolean
}

interface IState {
  initialDates: IDates
  focusedInput: null | boolean
  startDate: Moment | null
  endDate: Moment | null
  startTime: Moment | null
  endTime: Moment | null
}

class DateRangePicker extends React.Component<IDateRangePickerProps, {}> {
  public state = {
    // used for resetting to original value on cancel
    initialDates: {
      startDate: null,
      endDate: null,
    },
    focusedInput: null,
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
  }

  public componentDidMount() {
    // Set initial state from props
    const { startDate, endDate, startTime, endTime } = this.props
    this.setState({
      startDate,
      endDate,
      startTime,
      endTime,
      initialDates: {
        startDate,
        endDate,
      },
    })
  }

  public handleFocusChange = (focusedInput: any) => {
    if (focusedInput) {
      this.setState({ focusedInput })
    }
  }

  public handleSelectedStartTimeChange = (startTime: any) => {
    this.setState({ startTime })
  }

  public handleSelectedEndTimeChange = (endTime: any) => {
    this.setState({ endTime })
  }

  public returnPastYears = () => {
    const futureYears = range(moment().year(), moment().year() - 5)
    const futureYearsOptions = futureYears
      .map(i => {
        return (
          <option key={i.toString()} value={i}>
            {i}
          </option>
        )
      })
      .reverse()
    return futureYearsOptions
  }

  public returnFutureYears = () => {
    const futureYears = range(moment().year() + 1, moment().year() + 5)
    const futureYearsOptions = futureYears.map(i => {
      return (
        <option key={i.toString()} value={i}>
          {i}
        </option>
      )
    })
    return futureYearsOptions
  }

  // public renderMonthElement = (props: {
  //   month: Moment
  //   onMonthSelect: (currentMonth: Moment, newMonthVal: string) => void
  //   onYearSelect: (currentYear: Moment, newYearVal: string) => void
  //   isVisible: boolean
  // }) => {
  // TODO: fix type error
  public renderMonthElement = (props: any) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="monthSelector">
          <select
            value={props.month.month()}
            onChange={e => {
              props.onMonthSelect(props.month, e.target.value)
            }}
          >
            {moment.months().map((label, value) => (
              <option key={value.toString()} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className="yearSelector">
          <select
            value={props.month.year()}
            onChange={e => {
              props.onYearSelect(props.month, e.target.value)
            }}
          >
            {this.returnPastYears()}
            {this.returnFutureYears()};
          </select>
        </div>
      </div>
    )
  }

  public materialTheme = createTheme({
    palette: {
      primary: {
        main: '#27B0DD',
        contrastText: '#FFF',
      },
    },
  })

  public isSelectionSameDay = () => {
    const { startDate, endDate } = this.state
    return (
      moment(startDate).format('YYYY-MM-DD') ===
      moment(endDate).format('YYYY-MM-DD')
    )
  }

  public handleConfirm = () => {
    const newState: any = {
      focusedInput: null,
    }
    if (!this.isSelectionSameDay()) {
      newState.startTime = null
      newState.endTime = null
    }
    this.setState(newState, () => {
      this.props.onConfirm(this.state)
    })
  }

  public handleCancel = () => {
    this.setState({
      focusedInput: null,
      startDate: this.state.initialDates.startDate,
      endDate: this.state.initialDates.endDate,
    })
  }

  public renderConfirmationButtons = () => (
    <div className="confirmation-buttons">
      <Button
        styleType="primary"
        size="small"
        label="Ok"
        onClick={this.handleConfirm}
      />
      <Button
        styleType="danger"
        size="small"
        label="Cancel"
        onClick={this.handleCancel}
      />
    </div>
  )

  public calenderTimePickers = () => {
    return (
      <div className="dayTimePicker">
        <MuiThemeProvider theme={this.materialTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <TimePicker
              showTodayButton
              todayLabel="now"
              label="Select Start Time"
              value={this.state.startTime}
              minutesStep={5}
              onChange={this.handleSelectedStartTimeChange}
            />
            <TimePicker
              showTodayButton
              todayLabel="now"
              label="Select End Time"
              value={this.state.endTime}
              minutesStep={5}
              onChange={this.handleSelectedEndTimeChange}
            />
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
        {this.renderConfirmationButtons()}
      </div>
    )
  }

  public calenderConfirmationOnly = () => {
    return (
      <div className="confirmPicker">{this.renderConfirmationButtons()}</div>
    )
  }

  public handleDatesChange = (dates: any) => {
    this.setState({ ...dates })
  }

  public render(): JSX.Element {
    const { disabled } = this.props
    const { startDate, endDate } = this.state

    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <_DateRangePicker
        displayFormat="DD MMM YYYY"
        startDate={startDate}
        customArrowIcon={<span>&mdash;</span>}
        verticalSpacing={0}
        startDateId="startDate"
        endDate={endDate}
        endDateId="endDate"
        onDatesChange={this.handleDatesChange}
        focusedInput={this.state.focusedInput}
        onFocusChange={this.handleFocusChange}
        showClearDates={false}
        isOutsideRange={() => false}
        small={false}
        disabled={disabled}
        hideKeyboardShortcutsPanel={true}
        renderMonthElement={this.renderMonthElement}
        minimumNights={0}
        renderCalendarInfo={
          this.isSelectionSameDay()
            ? this.calenderTimePickers
            : this.calenderConfirmationOnly
        }
      />
    )
  }
}

export default DateRangePicker
