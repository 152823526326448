import React, { FC, useEffect, useState } from 'react'
import { useEnableTwoFactorAuth, useGetQRCode, useGetSecret } from '../../api'
import { Box } from 'grommet'
import { Button, Loader, Text, TextInput } from '../../../../views/components'
import Alert from '../../../../views/components/atoms/Alert/Alert'
import { colors } from '../../../../views/styles/variables'

type Props = {
  className?: string
  userId: string
  enabled: boolean
  onNext: () => void
  onClose?: () => void
}

const Step1: FC<Props> = ({ className, userId, enabled, onNext, onClose }) => {
  const [isTwoFactorAuthEabled, setIsTwoFactorAuthEabled] = useState(false)

  const { mutate, isLoading: isEnableLoading } = useEnableTwoFactorAuth({
    userId,
    onSuccess: () => {
      setIsTwoFactorAuthEabled(true)
    },
    onError(error) {
      onClose && onClose()
    },
  })

  useEffect(() => {
    mutate(null)
  }, [enabled])

  const {
    data,
    isLoading: isGetQRCodeLoading,
    isFetching,
  } = useGetQRCode({
    userId,
    enabled: enabled && isTwoFactorAuthEabled,
  })

  const { data: secretData, isLoading: isSecretLoading } = useGetSecret({
    userId,
    enabled: enabled && isTwoFactorAuthEabled,
  })

  return (
    <Box className={className}>
      <Text tag="h3" color={colors.blue.dark}>
        Two Factor Authentication - <small>Step 1 of 3</small>
      </Text>
      {isGetQRCodeLoading ||
      isEnableLoading ||
      isSecretLoading ||
      isFetching ? (
        <Loader text="Loading..." />
      ) : (
        <>
          <Alert intent="danger">
            If you are using this profile for API requests you will first need
            to configure API tokens and{' '}
            <strong>update your code, otherwise API requests will fail!</strong>
          </Alert>
          <p>
            <strong>Scan this QR code to verify a device.</strong>
          </p>
          {data?.svg && (
            <div
              dangerouslySetInnerHTML={{
                __html: data?.svg,
              }}
            />
          )}
          <p>
            <strong>or enter setup key manually</strong>
          </p>
          <TextInput
            value={secretData?.secretKey}
            name="two-factor-authentication-manual-entry"
            copyToClipboard
            disabled
          />
          <p>
            <strong>Don't have the app?</strong>
            <br />
            Google Authenticator is a free 2FA app. Download it on your mobile
            device by searching for "Google Authenticator" on the Play Store or
            App Store.
          </p>
          <footer>
            <Button
              styleType="secondary"
              label="Next"
              size="small"
              type="button"
              onClick={onNext}
            />
          </footer>
        </>
      )}
    </Box>
  )
}

export default Step1
