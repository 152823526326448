import { handleActions } from 'redux-actions'
import * as actions from './actions'
import model from './model'
import { AnyAction } from 'redux'
import { IUIPayoutsModel } from './interfaces'
import { get as _get } from 'lodash'

export default handleActions(
  {
    [actions.setCurrentPage]: (state: IUIPayoutsModel, action: AnyAction) => ({
      ...state,
      currentPage: action.payload,
    }),
  },
  model,
)
