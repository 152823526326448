import * as React from 'react'
import { Moment } from 'moment'
import 'react-dates/initialize'
import { SingleDatePicker as _SingleDatePicker } from 'react-dates'
import './SingleDatePicker.scss'

export interface ISingleDatePickerProps {
  disabled: boolean
  date: Moment | null
  withPortal?: boolean
  onDateChange(date: any): void
  isOutsideRange?(date: Moment): boolean
}

interface IFocused {
  focused: boolean | null
}

class SingleDatePicker extends React.Component<ISingleDatePickerProps, {}> {
  public state = {
    focused: false,
  }

  public handleFocusChange = (arg: IFocused) => {
    this.setState({ focused: arg.focused })
  }

  public render(): JSX.Element {
    const { disabled, date, withPortal, onDateChange, isOutsideRange } =
      this.props
    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <_SingleDatePicker
        id="singleDatePicker"
        focused={this.state.focused}
        onFocusChange={this.handleFocusChange}
        displayFormat="DD MMM YYYY"
        date={date}
        placeholder="Select Date"
        onDateChange={onDateChange}
        disabled={disabled}
        verticalSpacing={0}
        small={false}
        hideKeyboardShortcutsPanel={true}
        isOutsideRange={isOutsideRange}
        withPortal={withPortal}
      />
    )
  }
}

export default SingleDatePicker
