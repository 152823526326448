import * as React from 'react'
import { Box } from 'grommet'
import { AppBar } from '../../containers'
import SiteSelector from '../stateful/SiteSelectors/SiteSelector'

interface IUsersTemplateProps {
  verticalOverflow?: 'scroll' | 'hidden'
  Header(): JSX.Element
  Content(): JSX.Element
}

const UsersTemplate = (props: IUsersTemplateProps): JSX.Element => {
  const { Header, Content, verticalOverflow } = props
  const vOverflow = verticalOverflow || 'hidden'
  return (
    <Box fill={true} pad="medium" overflow={{ vertical: vOverflow }}>
      <AppBar>
        <SiteSelector className="site-selector" />
      </AppBar>
      <Box pad={{ vertical: 'medium' }} flex="grow">
        <Box>
          <Header />
        </Box>
        <Box fill="vertical">
          <Content />
        </Box>
      </Box>
    </Box>
  )
}

export default UsersTemplate
