import * as React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import styled from 'styled-components'
import { Dispatch, AnyAction } from 'redux'
import {
  uiTransactionsActions,
  uiTransactionsSelectors,
} from '../../../../../state/modules/ui/transactions'
import { DataTable } from '../../..'
import {
  transactionsInterfaces,
  transactionsSelectors,
} from '../../../../../state/modules/transactions'
interface IPaymentsAccordionPanelProps {
  className?: string
  transactionId: number
}
interface IPaymentsAccordionPanelContainerProps {
  data: any[]
  transaction: transactionsInterfaces.ITransaction
  fetchTransactionPayments(): void
}

class PaymentsAccordionPanel extends React.PureComponent<
  IPaymentsAccordionPanelProps & IPaymentsAccordionPanelContainerProps
> {
  public componentDidMount() {
    this.props.fetchTransactionPayments()
  }

  public getColumns = () => {
    return [
      {
        Header: 'Title',
        accessor: 'title',
        Cell: (row: any) => row.original.title,
      },
      {
        Header: 'Total',
        accessor: 'total',
      },
      {
        Header: 'Statement Date',
        accessor: 'statement_date',
        Cell: (row: any) =>
          moment(row.original.statement_date).format('YYYY-MM-DD'),
      },
    ]
  }

  public render(): JSX.Element {
    const { className, data } = this.props
    return (
      <div className={className}>
        <div className="single-transaction-table">
          <DataTable
            manual={true}
            plain={true}
            columns={this.getColumns()}
            data={data}
            pageSize={data.length}
            loading={false}
          />
        </div>
      </div>
    )
  }
}

const StyledPaymentsAccordionPanel = styled(PaymentsAccordionPanel)`
  .reverse-button {
    font-size: 14px !important;
  }
`

const mapStateToProps = (
  state: any,
  ownProps: IPaymentsAccordionPanelProps,
) => ({
  data: uiTransactionsSelectors.getTransactionPaymentsTableData(state)(
    ownProps.transactionId,
  ),
  transaction: transactionsSelectors.getTransactionById(state)(
    ownProps.transactionId,
  ),
})

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
  ownProps: IPaymentsAccordionPanelProps,
) => ({
  fetchTransactionPayments: () => {
    dispatch(
      uiTransactionsActions.fetchTransactionPayments(ownProps.transactionId),
    )
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StyledPaymentsAccordionPanel)
