import React from 'react'
import {
  EnumCellProps,
  EnumOption,
  isOneOfEnumControl,
  RankedTester,
  rankWith,
  WithClassname,
} from '@jsonforms/core'
import {
  TranslateProps,
  withJsonFormsOneOfEnumCellProps,
  withTranslateProps,
} from '@jsonforms/react'
import { Select } from '../../../../views/components'
import styled from 'styled-components'

const OneOfEnumCell = (
  props: EnumCellProps &
    WithClassname &
    TranslateProps & { className?: string },
) => {
  const { className, id, options, enabled, schema, path, data, handleChange } =
    props
  const getOptionByName = (name: string) => {
    const option = options?.find((option: any) => option.label === name)
    return option
  }

  const getOptionByValue = (value: string) => {
    const option = options?.find((option: any) => option.value === value)
    return option
  }

  const _handleChange = (option: EnumOption) => {
    handleChange(path, option.value)
  }

  const _options = options?.map(option => option.label) || []

  const value = getOptionByValue(data?.[0])?.label

  const isFormFieldDisabled = () => {
    if (!enabled) {
      return true
    }
    const isReadOnly = (schema as any)?.readonly === true
    if (isReadOnly) {
      return true
    }
    return false
  }

  return (
    <Select
      name={id}
      id={id}
      value={value}
      onChange={_handleChange}
      className={className}
      disabled={isFormFieldDisabled()}
      options={_options}
      getOptionByName={getOptionByName}
      // placeholder={props.placeholder}
    />
  )
}

const StyledOneOfEnumCell = styled(OneOfEnumCell)`
  button {
    width: 100%;

    input[value] {
      font-size: 15px;
      font-style: normal;
    }
  }
`

/**
 * Default tester for oneOf enum controls.
 * @type {RankedTester}
 */
export const oneOfEnumCellTester: RankedTester = rankWith(3, isOneOfEnumControl)

export default withJsonFormsOneOfEnumCellProps(
  withTranslateProps(React.memo(StyledOneOfEnumCell)),
  false,
)
