import classNames from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Button } from '../..'
import { bookingSelectors } from '../../../../state/modules/bookings'

type Props = {
  bookingId: string
  className?: string
}

const BookingFormStatus = ({ bookingId, className }: Props) => {
  const booking = useSelector(state =>
    bookingSelectors.getBookingById(state)(bookingId),
  )
  const status = booking?.status || 'unpaid'
  const statusProps: any = {}
  switch (status) {
    case 'paid':
      statusProps.styleType = 'primary'
      break
    case 'partial-payment':
    case 'pending':
      statusProps.styleType = 'warning'
      break
    case 'unpaid':
      statusProps.styleType = 'info'
      break
    case 'authorized':
      statusProps.styleType = 'auth'
      break
    case 'locked':
      statusProps.styleType = 'danger'
      break
  }

  return (
    <div className={classNames(className, 'status-button')}>
      <Button {...statusProps}>{status}</Button>
    </div>
  )
}

export default styled(BookingFormStatus)`
  display: flex;
  align-items: center;

  button {
    cursor: default;
  }
`
