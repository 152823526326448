import * as React from 'react'
import { Modal } from '../../..'
import {
  ITransaction,
  ICreateTransaction,
  IUpdateTransaction,
} from '../../../../../state/modules/transactions/interfaces'
import ChargebackForm from './ChargebackForm'

interface ICharbebackModalProps {
  isOpen: boolean
  transaction: ITransaction
  isApplyingChargeback: boolean
  chargebackReasons: string[]
  chargebackReasonDefault: string
  chargebackStatusOptions: string[]
  chargebackStatusDefault: string
  chargebackOutcomeOptions: string[]
  chargebackOutcomeDefault: string
  formatCurrency(value: number | string, currencies: string): string
  getCurrencySymbol(currencyName: string): string
  onClose(): void
  createTransaction(payload: ICreateTransaction): void
  updateTransaction(id: number, payload: IUpdateTransaction): void
}

const CharbebackModal = (props: ICharbebackModalProps) => {
  const {
    isOpen,
    onClose,
    transaction,
    isApplyingChargeback,
    chargebackReasons,
    chargebackReasonDefault,
    chargebackStatusOptions,
    chargebackStatusDefault,
    chargebackOutcomeOptions,
    chargebackOutcomeDefault,
    formatCurrency,
    getCurrencySymbol,
    createTransaction,
    updateTransaction,
  } = props

  const isScreenSmall = window.innerHeight < 800

  const form = (
    <ChargebackForm
      transaction={transaction}
      createTransaction={createTransaction}
      updateTransaction={updateTransaction}
      isApplyingChargeback={isApplyingChargeback}
      chargebackReasons={chargebackReasons}
      chargebackReasonDefault={chargebackReasonDefault}
      chargebackStatusOptions={chargebackStatusOptions}
      chargebackStatusDefault={chargebackStatusDefault}
      chargebackOutcomeOptions={chargebackOutcomeOptions}
      chargebackOutcomeDefault={chargebackOutcomeDefault}
      formatCurrency={formatCurrency}
      getCurrencySymbol={getCurrencySymbol}
      isScreenSmall={isScreenSmall}
    />
  )

  return (
    <Modal
      isOpen={isOpen}
      showCloseIcon={true}
      onClose={onClose}
      InnerComponent={form}
      disableOutsideClick={isScreenSmall}
    />
  )
}

export default CharbebackModal
