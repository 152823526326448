import * as React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { get, first } from 'lodash'
import { useEffectOnce } from 'react-use'
import { useSelector } from 'react-redux'
import { useQueryParam, StringParam } from 'use-query-params'
import SearchFilter, {
  FilterProps,
} from '../../organisms/SearchFilter/SearchFilter'
import { SelectFilter, TextFilter } from '../../organisms/SearchFilter/types'
import useFilter from '../../../../hooks/useFilter'
import { uiChannelsSelectors } from '../../../../state/modules/ui'
// import usePagination from '../../../../hooks/usePagination';
import useDateFilter from '../../../../hooks/useDateFilter'
import { DateRangeFilter } from '../..'

interface IPayoutsSearchProps {
  className?: string
}

const PayoutsSearch = (props: IPayoutsSearchProps): JSX.Element => {
  const [status = 'pending'] = useQueryParam('status', StringParam)
  const { setDateFilters, dateFilters } = useDateFilter()
  // const { setCurrentPage } = usePagination();
  const {
    filter,
    activeFilter,
    setActiveFilter,
    activeFilterValue,
    setActiveFilterValue,
  } = useFilter()

  const currencies: any = useSelector(
    uiChannelsSelectors.getCurrenciesForSearchFilter,
  )

  const filters =
    status === 'pending' || status === 'exported'
      ? [
          {
            value: 'settlement_currency',
            label: 'Currency',
            type: 'select' as SelectFilter,
            options: [{ value: 'all', label: 'All currencies' }, ...currencies],
            onChange: setActiveFilterValue,
          },
        ]
      : [
          {
            value: 'batch_number',
            label: 'Batch Number',
            type: 'text' as TextFilter,
            onChange: setActiveFilterValue,
          },
        ]

  // eslint-disable-next-line
  const firstFilter = first(filters as FilterProps[])
  const _activeFilter = activeFilter || get(firstFilter, 'value', '')

  /**
   * Set default active filter and value
   * If filter in query params, set that.
   * Else set first filter in array above as active filter
   */
  useEffectOnce(() => {
    const firstFilterKey =
      (filter && first(Object.keys(filter))) || get(filters, [0, 'value'], '')
    const firstFilterValue = (filter && first(Object.values(filter))) || ''
    setActiveFilter(firstFilterKey)
    setActiveFilterValue(firstFilterValue)
  })

  React.useEffect(() => {
    const firstFilterKey =
      (filter && first(Object.keys(filter))) || get(filters, [0, 'value'], '')
    setActiveFilter(firstFilterKey)
    setActiveFilterValue('')
    setDateFilters({})
    // should only run when status changes, ignoring other deps
    // eslint-disable-next-line
  }, [status])

  /**
   * Wrap `setActiveFilterValue` so we can reset pagination as well.
   */
  const _setActiveFilterValue = (val: any) => {
    setActiveFilterValue(val)
    // setCurrentPage(1);
  }

  const handleConfirm = (values: any) => {
    const { startDate, endDate, startTime, endTime } = values
    if (startDate && endDate && startTime && endTime) {
      setDateFilters({
        before: endDate.format('YYYY-MM-DD') + endTime.format(' H:mm:ss'),
        after: startDate.format('YYYY-MM-DD') + startTime.format(' H:mm:ss'),
      })
    } else if (startDate && endDate) {
      setDateFilters({
        before: endDate.format('YYYY-MM-DD 23:59:59'),
        after: startDate.format('YYYY-MM-DD 00:00:00'),
      })
    }
  }

  const startDate = get(dateFilters, 'after')
  const endDate = get(dateFilters, 'before')
  const startTime = get(dateFilters, 'startTime')
  const endTime = get(dateFilters, 'endTime')

  return (
    <div className={props.className}>
      <SearchFilter
        filters={filters}
        activeFilter={_activeFilter}
        setFilter={setActiveFilter}
        activeFilterValue={activeFilterValue}
        setActiveFilterValue={_setActiveFilterValue}
        // paramsResetOnFilterOptionChange={paramsResetOnFilterOptionChange}
      />
      {startDate && endDate && (
        <DateRangeFilter
          plain={true}
          disabled={status === 'pending'}
          startDate={
            status === 'pending'
              ? null
              : moment(startDate) || moment().subtract(2, 'weeks')
          }
          endDate={status === 'pending' ? null : moment(endDate) || moment()}
          startTime={moment(startTime) || moment('00:00:00')}
          endTime={moment(endTime) || moment('23:59:59')}
          filters={[
            {
              label: 'Date',
              value: 'date',
            },
          ]}
          onConfirm={handleConfirm}
        />
      )}
    </div>
  )
}

export default styled(PayoutsSearch)`
  display: flex;
  min-height: 44px;
  justify-content: space-between;
`
