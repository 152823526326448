import decode from 'jwt-decode'
import { Middleware, AnyAction } from 'redux'
import { httpError } from '../../utils/http'
import { getToken, isTokenExpired } from '../../../utils/auth'
import { authActions } from '../../modules/auth'

const tokenExpiry: Middleware =
  (store: any) => (next: any) => (action: AnyAction) => {
    const authToken = getToken()
    if (authToken) {
      const decodedAuthToken: any = decode(authToken)
      if (action.type === httpError.toString()) {
        if (isTokenExpired(decodedAuthToken)) {
          /** @todo add time-out feature. i.e. lockout notice */
          store.dispatch(authActions.loggedOut())
        }
      }
    }
    return next(action)
  }

export default tokenExpiry
