import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { get, uniq } from 'lodash'
import { parseLinkHeader } from '../../utils/http'
import {
  IAdjustLedgerResponse,
  ILedger,
  ILedgerModel,
  ILedgerRequestParams,
} from './interfaces'

const initialState: ILedgerModel = {
  schema: {},
  ledgeradhocSchema: {},
  byId: {},
  allIds: [],
  byChannelId: {},
  pages: {
    next: undefined,
    prev: undefined,
  },
  byPage: {},
  aggregates: {
    owing: 0,
  },
  loading: {
    requestLedger: false,
    adjustLedger: false,
    adjustLedgerAdHocEntry: false,
  },
}

const ledgerSlice = createSlice({
  name: 'ledger',
  initialState,
  reducers: {
    requestLedgerSchema: state => {},
    requestLedgerSchemaSuccess: (state, action: PayloadAction<any>) => {
      state.schema = action.payload
    },
    requestLedgerAdhocSchema: state => {},
    requestLedgerAdhocSchemaSuccess: (state, action: PayloadAction<any>) => {
      state.ledgeradhocSchema = action.payload
    },
    requestLedger: {
      reducer: (state, payload: any) => {
        state.loading.requestLedger = true
        state.allIds = []
        state.byId = {}
        state.byPage = {}
      },
      prepare: (payload: ILedgerRequestParams, meta?: any) => ({
        payload,
        meta,
      }),
    },
    requestLedgerSuccess: {
      reducer: (state, action: PayloadAction<ILedger[]>) => {
        state.loading.requestLedger = false

        const meta = get(action, ['meta'])
        const params = get(meta, ['params'])
        const channel = get(params, ['channels'])
        const page = get(params, ['page'])
        const ids = action.payload.map(entry => entry.id)

        action.payload.forEach(entry => {
          state.byId[entry.id] = entry
        })

        state.allIds = uniq([...state.allIds, ...ids])
        state.pages = parseLinkHeader(meta?.responseHeaders?.link || '')
        state.byPage[page] = ids

        if (channel) {
          state.byChannelId[channel] = ids
        }
      },
      prepare: (payload: ILedger[], meta: any) => ({ payload, meta }),
    },
    requestEntry: () => {},
    requestEntrySuccess: {
      reducer: () => {},
      prepare: (payload: ILedger, meta: any) => ({ payload, meta }),
    },
    adjustLedger: (state, { payload }) => {
      state.loading.adjustLedger = true
    },
    adjustLedgerSuccess: {
      reducer: (state, action: PayloadAction<IAdjustLedgerResponse>) => {
        state.loading.adjustLedger = false
      },
      prepare: (payload: IAdjustLedgerResponse, meta: any) => ({
        payload,
        meta,
      }),
    },
    adjustLedgerAdHocEntry: (state, { payload }) => {
      state.loading.adjustLedgerAdHocEntry = true
    },
    adjustLedgerAdHocEntrySuccess: {
      reducer: (state, action: PayloadAction<IAdjustLedgerResponse>) => {
        state.loading.adjustLedgerAdHocEntry = false
      },
      prepare: (payload: IAdjustLedgerResponse, meta: any) => ({
        payload,
        meta,
      }),
    },
    import: {
      reducer: () => {},
      prepare: (payload: any) => ({ payload }),
    },
    importSuccess: {
      reducer: () => {},
      prepare: (payload: any, meta: any) => ({ payload, meta }),
    },
    requestAggregate: {
      reducer: () => {},
      prepare: (payload: {
        status: 'publish' | 'pending'
        channels: number
        aggregate: string
      }) => ({ payload }),
    },
    requestAggregateSuccess: {
      reducer: (
        state,
        action: PayloadAction<{ total: number; status: 'publish' | 'pending' }>,
      ) => {
        if (action.payload.status === 'pending') {
          state.aggregates.owing = action.payload.total
        }
      },
      prepare: (
        payload: { total: number; status: 'publish' | 'pending' },
        meta: any,
      ) => ({ payload, meta }),
    },
  },
})

const { reducer, actions } = ledgerSlice

export { reducer, actions, initialState }
