import { createActions } from 'redux-actions'
import { NAMESPACE } from './constants'
import {
  IRequestChannelsParams,
  IChannelResponse,
  ICreateChannelRequest,
  IUpdateChannelRequest,
  IRequestChannelsSchemaParams,
} from './interfaces'

export const {
  [NAMESPACE]: {
    requestChannelsSchema,
    requestChannelsSchemaSuccess,
    requestChannels,
    requestChannelsSuccess,
    requestNextChannelsPage,
    requestChannel,
    requestChannelSuccess,
    createChannel,
    createChannelSuccess,
    updateChannel,
    updateChannelSuccess,
    deleteChannel,
    deleteChannelSuccess,
  },
} = createActions({
  [`${NAMESPACE}/request_channels_schema`]: (
    payload: IRequestChannelsSchemaParams,
  ) => payload,
  [`${NAMESPACE}/request_channels_schema_success`]: (payload: any) => payload,
  [`${NAMESPACE}/request_channels`]: (payload: IRequestChannelsParams) =>
    payload,
  [`${NAMESPACE}/request_channels_success`]: [
    (payload: IChannelResponse[]) => payload,
    (payload: any, meta: any) => meta,
  ],
  [`${NAMESPACE}/request_next_channels_page`]: (payload: any) => payload,
  [`${NAMESPACE}/request_channel`]: (payload: any) => payload,
  [`${NAMESPACE}/request_channel_success`]: (payload: IChannelResponse) =>
    payload,
  [`${NAMESPACE}/create_channel`]: (payload: ICreateChannelRequest) => payload,
  [`${NAMESPACE}/create_channel_success`]: (payload: any) => payload,
  [`${NAMESPACE}/update_channel`]: (payload: IUpdateChannelRequest) => payload,
  [`${NAMESPACE}/update_channel_success`]: (payload: any) => payload,
  [`${NAMESPACE}/delete_channel`]: (payload: any) => payload,
  [`${NAMESPACE}/delete_channel_success`]: (payload: any) => payload,
})
