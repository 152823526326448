import { createSelector } from 'reselect'
import { find } from 'lodash'
import { ICurrencyModel, ICurrency } from './interfaces'
import { NAMESPACE } from './constants'

const getState = (state: any) => state

const getCurrencyState = (state: any): ICurrencyModel => state[NAMESPACE]

export const getCurrencies = createSelector(
  getCurrencyState,
  currencyState => currencyState.currencies,
)

export const getCurrencyByName = createSelector(
  getCurrencies,
  (currencies: ICurrency[]) => (name: string) =>
    find(currencies, (c: ICurrency) => c.name === name),
)

export const getCurrencySymbol = createSelector(
  getState,
  state => (name: string) => {
    const currency = getCurrencyByName(state)(name)
    if (!currency) {
      return ''
    }
    return currency.symbol
  },
)

export const formatValueWithCurrency = createSelector(
  getState,
  state =>
    (value: number | string, currencyName: string = '') => {
      // convert from cents and cast to string
      let strValue = (parseInt(value as string, 0) / 100).toString()

      /**
       * Re-add stripped trailing 0.
       * @todo clean this up!
       */
      if (strValue.includes('.')) {
        const parts = strValue.split('.')
        if (parts[1].length === 1) {
          strValue = `${parts[0]}.${parts[1]}0`
        }
      }

      return currencyName ? `${currencyName} ${strValue}` : strValue
    },
)
