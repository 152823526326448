import { handleActions } from 'redux-actions'
import * as actions from './actions'
import model from './model'
import { AnyAction } from 'redux'
import { IUIChannelsModel } from './interfaces'
import { channelActions } from '../../channels'
import { get as _get } from 'lodash'
import * as sitesActions from '../../sites/actions'

export default handleActions(
  {
    http_error: (state: IUIChannelsModel, action: AnyAction) => {
      const { payload, meta } = action
      if (meta.action === channelActions.updateChannel.toString()) {
        return {
          ...state,
          isSaving: false,
          serverErrors: payload.response,
        }
      }
      if (meta.action === channelActions.createChannel.toString()) {
        return {
          ...state,
          isCreating: false,
          serverErrors: payload.response,
        }
      }
      if (meta.action === channelActions.deleteChannel.toString()) {
        return {
          ...state,
          isDeleting: false,
          serverErrors: payload.response,
        }
      }
      return state
    },
    [channelActions.requestChannel]: (state: IUIChannelsModel) => ({
      ...state,
      isFetching: true,
    }),
    [channelActions.requestChannelSuccess]: (state: IUIChannelsModel) => ({
      ...state,
      isFetching: false,
    }),
    [channelActions.createChannel]: (state: IUIChannelsModel) => ({
      ...state,
      isCreating: true,
    }),
    [channelActions.createChannelSuccess]: (state: IUIChannelsModel) => ({
      ...state,
      isCreating: false,
    }),
    [channelActions.updateChannel]: (state: IUIChannelsModel) => ({
      ...state,
      isSaving: true,
    }),
    [channelActions.updateChannelSuccess]: (state: IUIChannelsModel) => ({
      ...state,
      isSaving: false,
    }),
    [channelActions.deleteChannel]: (state: IUIChannelsModel) => ({
      ...state,
      isDeleting: true,
    }),
    [channelActions.deleteChannelSuccess]: (state: IUIChannelsModel) => ({
      ...state,
      isDeleting: false,
    }),
    [actions.clearServerErrors]: (state: IUIChannelsModel) => ({
      ...state,
      serverErrors: null,
    }),
    [actions.setCurrentPage]: (state: IUIChannelsModel, action: AnyAction) => ({
      ...state,
      currentPage: action.payload,
    }),
    [actions.setCurrentFilter]: (
      state: IUIChannelsModel,
      action: AnyAction,
    ) => ({
      ...state,
      currentFilter: action.payload,
    }),
    [actions.setChannel]: (state: IUIChannelsModel, action: AnyAction) => {
      return {
        ...state,
        currentChannel: _get(action.payload, 'id', null),
      }
    },
    [sitesActions.siteSelected]: (state: IUIChannelsModel) => {
      return {
        ...state,
        currentFilter: '',
        currentChannel: null,
      }
    },
  },
  model,
)
