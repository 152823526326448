import * as React from 'react'
import {
  Grommet,
  Table as GTable,
  TableBody as GTableBody,
  TableRow as GTableRow,
  TableCell,
} from 'grommet'
import timfinityTheme from '../../../styles/timfinityTheme'
import { colors } from '../../../styles/variables'
import styled from 'styled-components'

export interface ITableRow {
  name: string
  value: string | JSX.Element
  bold?: boolean
  className?: string
}

interface ITableRowProps {
  rows: ITableRow[]
}

interface ITableProps {
  data: ITableRow[]
  striped?: boolean
  className?: string
  bolded?: boolean
  subheader?: boolean
  total?: boolean
}

const TableRow = (props: ITableRow): JSX.Element => (
  <GTableRow className={props.className}>
    <TableCell>{props.name}</TableCell>
    <TableCell>{props.value}</TableCell>
  </GTableRow>
)

const TableBody = (props: ITableRowProps): JSX.Element => (
  <GTableBody>
    {props.rows.map((row: ITableRow, i: number) => (
      <TableRow
        key={i}
        name={row.name}
        value={row.value}
        className={row.bold ? 'bold' : ''}
      />
    ))}
  </GTableBody>
)

const Table = (props: ITableProps): JSX.Element => (
  <Grommet theme={timfinityTheme} className={props.className}>
    <GTable>
      <TableBody rows={props.data} />
    </GTable>
  </Grommet>
)

const StyledTable = styled(Table)`
  ${(props: any) => {
    const striped = props.striped === undefined ? true : props.striped
    let styles = `
            width: 100%;
            background: #fff;
            color: #000;
            td:nth-child(2) {
                text-align: right;
                float: right;
                > * {
                    display: flex;
                    align-items: flex-end;
                }
                > div {
                    display: block;
                    align-items: flex-end;
                }
            }
            tr.bold {
                font-weight: bold;
            }
        `
    if (striped) {
      styles =
        styles +
        `
                tr:nth-child(odd) {
                    background: ${colors.grey.lighter};
                }
            `
    } else {
      styles =
        styles +
        `
                tr {
                    border-top: 1px solid ${colors.grey.dark};
                }
            `
    }
    if (props.bolded) {
      styles =
        styles +
        `
            tr {
                font-weight: bold;
            }
        `
    }
    if (props.subheader) {
      styles =
        styles +
        `
            tr:first-child {
                font-weight: bold;
            }
        `
    }
    if (props.total) {
      styles =
        styles +
        `
            tr:not(:last-child) {
                border-top: 1px solid ${colors.grey.lighter};
            };
            tr:last-child {
                font-weight: bold;
            }
        `
    }
    return styles
  }}
`

export default StyledTable
