import { IUIBookingsModel } from './interfaces'

const uiBookingsModel: IUIBookingsModel = {
  currentPage: 1,
  currentFilter: '',
  currentChannel: null,
  serverErrors: null,
  isFetchingBooking: false,
  isCreatingBooking: false,
  isSaving: false,
  isDeleting: false,
  lastCreatedBookingId: null,
}

export default uiBookingsModel
