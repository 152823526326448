import * as React from 'react'
import { transactionsInterfaces } from '../../../../state/modules/transactions'
import classNames from 'classnames'
import { Box, Grid } from 'grommet'
import { startCase } from 'lodash'
import { AppBar } from '../../../containers'
import {
  Text,
  Button,
  Table,
  Accordion,
  Anchor,
  Comments,
  Changelog,
} from '../..'
import styled from 'styled-components'
import { ITableRow } from '../../organisms/Table/Table'
import RefundModal from './RefundModal'
import ChargebackModal from './Chargeback/ChargebackModal'
import ChargebackOverrideModal from './ChargebackOverride/ChargebackOverrideModal'
import {
  ICreateTransaction,
  IUpdateTransaction,
} from '../../../../state/modules/transactions/interfaces'
import BookingsAccordionPanel from './Accordions/BookingsAccordionPanel'
import TransactionAdjustmentsPanel from './Accordions/TransactionAdjustmentsPanel'
import PaymentsAccordionPanel from './Accordions/PaymentsAccordionPanel'
import ChargebackReversalModal from './ChargebackReversal/ChargebackReversalModal'
import ApplyCaptureModal from './ApplyCaptureModal/ApplyCaptureModal'
import ApplyVoidModal from './ApplyVoidModal/ApplyVoidModal'
import ApplyResendModal from './ApplyResendModal/ApplyResendModal'
import moment from 'moment'
import {
  CommentType,
  IAddCommentRequest,
  IComment,
} from '../../../../state/modules/comments/interfaces'
import { formatInTimeZone } from 'date-fns-tz'
import { Helmet } from 'react-helmet'
import { IFormValues as ICommentFormValues } from '../../organisms/Comments/CommentsForm'
import AllocationsAccordionPanel from './Accordions/AllocationsAccordionPanel'

interface ISingleTransactionsPageProps {
  className?: string
  channelCount: number
  transactionId: string
  transaction?: transactionsInterfaces.ITransaction
  transactionTableData: any[] /** @todo update type */
  transactionPayeeTables: {
    details: ITableRow[]
    method: ITableRow[]
  }
  transactionPayeeTableDetails: ITableRow[]
  pspTableData: ITableRow[]
  pspTableDataMemberAdmin: ITableRow[]
  currentSiteName: string
  channelAccountMode: boolean | string
  channelStatus: string
  match: any
  isAdmin: boolean
  isApplyingRefund: boolean
  isApplyingChargeback: boolean
  isApplyingChargebackOverride: boolean
  isApplyingReversal: boolean
  isApplyingCapture: boolean
  isApplyingVoid: boolean
  isApplyingResend: boolean
  chargebackReasons: string[]
  chargebackReasonDefault: string
  chargebackStatusOptions: string[]
  chargebackStatusDefault: string
  chargebackOutcomeOptions: string[]
  chargebackOutcomeDefault: string
  internalComments: IComment[]
  externalComments: IComment[]
  readonlyComments: IComment[]
  currentUserId: number
  canUserViewSettings: boolean
  transactionsSchemaMethods: string[]
  formatCurrency(value: number | string, currencies: string): string
  getCurrencySymbol(currencyName: string): string
  getTransaction(): void
  createTransaction(payload: ICreateTransaction): void
  updateTransaction(id: number, payload: IUpdateTransaction): void
  resendEmailReceipt(endpoint: string): void
  redirect(path: string): void
  getComments(id: string, type: CommentType): void
  addComment(id: string, body: IAddCommentRequest): void
  updateComment(
    transactionId: string,
    commentId: string,
    description: string,
  ): void
  deleteComment(transactionId: string, commentId: string): void
}

interface ISingleTransactionsPageState {
  isReversalModalOpen: boolean
  isRefundModalOpen: boolean
  isChargebackModalOpen: boolean
  isChargebackOverrideModalOpen: boolean
  isCaptureModalOpen: boolean
  isVoidModalOpen: boolean
  isResendModalOpen: boolean
  isCreateAllocationModalOpen: boolean
}

class SingleTransactionsPage extends React.Component<
  ISingleTransactionsPageProps,
  {}
> {
  public state = {
    isReversalModalOpen: false,
    isRefundModalOpen: false,
    isChargebackModalOpen: false,
    isChargebackOverrideModalOpen: false,
    isCaptureModalOpen: false,
    isVoidModalOpen: false,
    isResendModalOpen: false,
    isCreateAllocationModalOpen: false,
  }

  public componentDidMount() {
    const { isAdmin, transactionId, getComments, getTransaction } = this.props
    getTransaction()
    getComments(transactionId, CommentType.External)
    getComments(transactionId, CommentType.Readonly)

    if (isAdmin) {
      getComments(transactionId, CommentType.Internal)
    }
  }

  public componentDidUpdate(prevProps: ISingleTransactionsPageProps) {
    if (prevProps.isApplyingRefund && !this.props.isApplyingRefund) {
      this.toggleModal('refund')
      this.props.getTransaction()
    }
    if (prevProps.isApplyingChargeback && !this.props.isApplyingChargeback) {
      if (
        ['won', 'reversed'].includes(
          this.props.transaction?.outcome_status as string,
        )
      ) {
        this.toggleModal('reversal')
      }
      this.toggleModal('chargeback')
      this.props.getTransaction()
    }
    if (
      prevProps.isApplyingChargebackOverride &&
      !this.props.isApplyingChargebackOverride
    ) {
      this.toggleModal('chargeback-override')
      this.props.getTransaction()
    }
    if (prevProps.isApplyingReversal && !this.props.isApplyingReversal) {
      this.toggleModal('reversal')
      this.props.getTransaction()
    }
    if (prevProps.isApplyingCapture && !this.props.isApplyingCapture) {
      this.toggleModal('capture')
      this.props.getTransaction()
    }
    if (prevProps.isApplyingVoid && !this.props.isApplyingVoid) {
      this.toggleModal('void')
      this.props.getTransaction()
    }
    if (prevProps.isApplyingResend && !this.props.isApplyingResend) {
      this.toggleModal('resend')
      this.props.getTransaction()
    }
  }

  public renderActions = () => {
    const { transaction, isAdmin, channelAccountMode } = this.props
    if (!transaction) {
      return null
    }
    const {
      status,
      transaction_types,
      total,
      total_remaining,
      created,
      payment_methods,
      outcome_status,
    } = transaction
    const maybeDisplayRefund = status === 'complete' &&
      (transaction.psp !== 'spreedly' ||
        (transaction.psp === 'spreedly' && isAdmin)) &&
      (payment_methods !== 'bank-transfer' ||
        (payment_methods === 'bank-transfer' && isAdmin)) &&
      ['purchase', 'capture', 'retained_purchase'].includes(
        transaction_types,
      ) &&
      total_remaining > 0 && (
        <Button
          styleType="secondary"
          size="small"
          label="Apply refund"
          onClick={this.toggleModal.bind(null, 'refund')}
        />
      )
    const maybeDisplayChargeback = isAdmin &&
      status === 'complete' &&
      ['purchase', 'capture', 'retained_purchase'].includes(
        transaction_types,
      ) &&
      channelAccountMode !== 'protection-only' && (
        <Button
          styleType="secondary"
          size="small"
          label="Apply Chargeback"
          onClick={this.toggleModal.bind(null, 'chargeback')}
        />
      )
    const maybeDisplayOverrideChargeback = isAdmin &&
      status === 'complete' &&
      ['chargeback'].includes(transaction_types) &&
      total_remaining > 0 && (
        <Button
          styleType="secondary"
          size="small"
          label="Override Chargeback"
          onClick={this.toggleModal.bind(null, 'chargeback-override')}
        />
      )
    const maybeDisplayChargebackReversal = isAdmin &&
      status === 'complete' &&
      ['won', 'reversed'].includes(outcome_status as string) &&
      transaction_types === 'chargeback' &&
      total_remaining > 0 && (
        <Button
          styleType="secondary"
          size="small"
          label="Chargeback Reversal"
          onClick={this.toggleModal.bind(null, 'reversal')}
        />
      )
    const maybeDisplayCaptureAndVoid = isAdmin &&
      status === 'complete' &&
      transaction_types === 'authorize' &&
      total === total_remaining &&
      created > moment().subtract(7, 'days').format('YYYY-MM-DD 00:00:00') && (
        <>
          <Button
            styleType="secondary"
            size="small"
            label="Apply Capture"
            onClick={this.toggleModal.bind(null, 'capture')}
          />
          <Button
            styleType="danger"
            size="small"
            label="Apply Void"
            onClick={this.toggleModal.bind(null, 'void')}
          />
        </>
      )

    return (
      <>
        {maybeDisplayRefund}
        {maybeDisplayChargeback}
        {maybeDisplayOverrideChargeback}
        {maybeDisplayChargebackReversal}
        {maybeDisplayCaptureAndVoid}
      </>
    )
  }

  public renderPageTitle = () => {
    const { transaction } = this.props
    return !transaction ? null : (
      <Box direction="row" justify="between" className="page-title">
        <Text tag="h3" color="#fff">
          {transaction.title}
        </Text>
        <Box direction="row" justify="between">
          {this.renderActions()}
        </Box>
      </Box>
    )
  }

  public maybeRenderTransactionStatus = () => {
    const { transaction, channelStatus } = this.props
    const status = transaction ? transaction.status : ''
    const buttonStyle = () => {
      switch (status) {
        case 'locked':
          return 'danger'
        case 'failed':
        case 'incomplete':
          return 'plain'
        case 'pending':
          return 'warning'
        case 'expired':
          return 'auth'
        case 'complete':
        default:
          return 'primary'
      }
    }
    return (
      transaction?.transaction_types !== 'chargeback' && (
        <Box direction="row" justify="between" className="transaction-status">
          <Text tag="h3" color="#000">
            Transaction
          </Text>
          <Box direction="row" justify="between">
            {channelStatus && (
              <Button
                styleType="secondary"
                size="small"
                label={channelStatus.split('-').join(' ').replace('tmt', 'TMT')}
                style={{ textTransform: 'capitalize', marginRight: '10px' }}
              />
            )}
            <Button styleType={buttonStyle()} size="small" label={status} />
          </Box>
        </Box>
      )
    )
  }

  public maybeRenderChargebackStatus = () => {
    const { transaction, isAdmin } = this.props
    const openModal = () => {
      if (isAdmin) {
        this.toggleModal('chargeback')
      }
    }
    return (
      transaction?.transaction_types === 'chargeback' && (
        <Box direction="row" justify="between" className="chargeback-status">
          <Text tag="h3" color="#000">
            Chargeback
          </Text>
          <Box direction="row" justify="between">
            {transaction?.chargeback_status && (
              <Button
                onClick={openModal}
                styleType="primary"
                size="small"
                label={transaction?.chargeback_status}
                style={{ marginRight: '10px' }}
                data-test-id="chargeback-status-button"
              />
            )}
            {transaction?.outcome_status && (
              <Button
                onClick={openModal}
                styleType="warning"
                size="small"
                label={`Outcome: ${transaction?.outcome_status}`}
              />
            )}
          </Box>
        </Box>
      )
    )
  }

  public isPaymentCreditCardOrDlocal = () => {
    const { transaction } = this.props
    const paymentMethod = transaction && transaction.payment_methods
    return paymentMethod === 'credit-card' || paymentMethod === 'dlocal'
  }

  public renderPayeeTable = () => {
    const {
      transaction,
      transactionPayeeTables,
      transactionPayeeTableDetails,
      transactionsSchemaMethods,
    } = this.props

    const payeeEmailObj = {
      name: 'Email',
      value: transactionsSchemaMethods?.includes('PUT') ? (
        <Anchor
          data-test-id="payee-email-link"
          onClick={this.toggleModal.bind(null, 'resend')}
        >
          {transaction ? transaction.payee_email : ''}
        </Anchor>
      ) : transaction ? (
        transaction.payee_email
      ) : (
        ''
      ),
    }
    const transactionPayeeTableDetailsAdjusted =
      transactionPayeeTableDetails.slice(0)
    transactionPayeeTableDetailsAdjusted.splice(1, 0, payeeEmailObj)

    return !this.props.isAdmin ||
      (transaction &&
        ['vault', 'void', 'authorize'].includes(
          transaction.transaction_types,
        )) ||
      (transaction && ['failed'].includes(transaction.status)) ? (
      <div>
        <Text tag="h3" color="#000">
          Payee
        </Text>
        <Table data={transactionPayeeTables.details} />
      </div>
    ) : (
      <div>
        <Text tag="h3" color="#000">
          Payee
        </Text>
        <Table data={transactionPayeeTableDetailsAdjusted} />
      </div>
    )
  }

  public renderPSPTable = () => {
    const { pspTableData, pspTableDataMemberAdmin, transaction } = this.props
    const tableData = [
      {
        name: 'Payment Method',
        value:
          !transaction || !transaction.payment_methods
            ? ''
            : startCase(transaction.payment_methods.replace(/-/g, ' ')),
      },
      ...pspTableData,
    ]

    return !this.props.isAdmin ? (
      <div>
        <Text tag="h3" color="#000">
          PSP Data
        </Text>
        <Table data={pspTableDataMemberAdmin} />
      </div>
    ) : (
      <div>
        <Text tag="h3" color="#000">
          PSP Data
        </Text>
        <Table data={tableData} />
      </div>
    )
  }

  public renderTables = () => {
    const { transactionTableData, transactionPayeeTables } = this.props

    const maybeEnableRowModal = (data: any[]) =>
      data.map((row: any) => ({
        ...row,
        value: row?.modal ? (
          <Anchor onClick={this.toggleModal.bind(null, row.modal)}>
            {row.value}
          </Anchor>
        ) : (
          row.value
        ),
      }))

    return (
      <>
        <Grid
          columns={{ count: 2, size: 'auto' }}
          gap="small"
          data-test-id="transaction-table-data"
        >
          <Table data={maybeEnableRowModal(transactionTableData[0])} />
          <Table data={maybeEnableRowModal(transactionTableData[1])} />
        </Grid>
        <Grid
          columns={{ count: 2, size: 'auto' }}
          gap="small"
          className="payee-tables"
        >
          {this.renderPayeeTable()}
          {this.isPaymentCreditCardOrDlocal() ? (
            <div>
              <Text tag="h3" color="#000">
                Payee Method
              </Text>
              <Table data={transactionPayeeTables.method} />
            </div>
          ) : (
            this.renderPSPTable()
          )}
        </Grid>
        {this.isPaymentCreditCardOrDlocal() && (
          <Grid columns={{ count: 2, size: '50%' }}>
            {this.renderPSPTable()}
          </Grid>
        )}
      </>
    )
  }

  public toggleModal = (
    modal:
      | 'refund'
      | 'chargeback'
      | 'chargeback-override'
      | 'reversal'
      | 'capture'
      | 'void'
      | 'resend'
      | 'allocation',
  ) => {
    switch (modal) {
      case 'refund':
        this.setState({ isRefundModalOpen: !this.state.isRefundModalOpen })
        break
      case 'chargeback':
        this.setState({
          isChargebackModalOpen: !this.state.isChargebackModalOpen,
        })
        break
      case 'chargeback-override':
        this.setState({
          isChargebackOverrideModalOpen:
            !this.state.isChargebackOverrideModalOpen,
        })
        break
      case 'reversal':
        this.setState({ isReversalModalOpen: !this.state.isReversalModalOpen })
        break
      case 'capture':
        this.setState({ isCaptureModalOpen: !this.state.isCaptureModalOpen })
        break
      case 'void':
        this.setState({ isVoidModalOpen: !this.state.isVoidModalOpen })
        break
      case 'resend':
        this.setState({ isResendModalOpen: !this.state.isResendModalOpen })
        break
      case 'allocation':
        this.setState({
          isCreateAllocationModalOpen: !this.state.isCreateAllocationModalOpen,
        })
        break
    }
  }

  public closeAllocationModal = () => {
    this.setState({ isCreateAllocationModalOpen: false })
  }

  public handleAddComment = (
    type: CommentType,
    values: { comment: string },
  ) => {
    this.props.addComment(this.props.transactionId, {
      type,
      description: values.comment,
    })
  }

  public handleCommentUpdated = (id: number, values: ICommentFormValues) => {
    this.props.updateComment(
      this.props.transactionId,
      id.toString(),
      values.comment,
    )
  }

  public handleCommentDeleted = (id: number) => {
    this.props.deleteComment(this.props.transactionId, id.toString())
  }

  public transformCommentsForComponent = (comments: IComment[]) => {
    return comments.map(comment => ({
      id: comment.id,
      time: formatInTimeZone(
        new Date(comment.created_at),
        'GMT',
        'yyyy-LL-dd HH:mm:ss',
      ),
      user: comment.user ? comment.user.name : '',
      comment: comment.description,
      canEdit: comment.user && this.props.currentUserId === comment.user.id,
    }))
  }

  public getAccordions = () => {
    const {
      channelCount,
      transaction,
      externalComments,
      internalComments,
      readonlyComments,
      isAdmin,
      canUserViewSettings,
      getTransaction,
    } = this.props

    if (!transaction) {
      return []
    }

    const panels = []

    if (!['vault'].includes(transaction.transaction_types)) {
      panels.push({
        title: 'Bookings',
        Content: <BookingsAccordionPanel transactionId={transaction.id} />,
        removePadding: true,
      })
    }
    if (
      ['purchase', 'capture', 'retained_purchase'].includes(
        transaction.transaction_types,
      ) &&
      transaction.adjustments.length > 0
    ) {
      panels.push({
        title: 'Transaction Adjustments',
        Content: <TransactionAdjustmentsPanel transactionId={transaction.id} />,
        removePadding: true,
      })
    }

    if (canUserViewSettings) {
      panels.push({
        title: 'Allocations',
        removePadding: true,
        Content: (
          <>
            <AllocationsAccordionPanel
              transaction={transaction}
              channelCount={channelCount}
              canUserCreateAllocation={this.props.transactionsSchemaMethods?.includes(
                'PUT',
              )}
              getTransaction={getTransaction}
            />
          </>
        ),
      })
    }

    if (!['vault'].includes(transaction.transaction_types)) {
      panels.push({
        title: 'Credits & Debits',
        Content: (
          <>
            <PaymentsAccordionPanel transactionId={transaction.id} />
          </>
        ),
        removePadding: true,
      })
    }

    panels.push({
      title: 'Changelog',
      Content: (
        <>
          <Box
            background="white"
            fill="horizontal"
            pad="none"
            round="none"
            className="changelog-list"
          >
            <Changelog
              comments={this.transformCommentsForComponent(readonlyComments)}
            />
          </Box>
        </>
      ),
      removePadding: true,
    })
    panels.push({
      title: 'Public Comments',
      Content: (
        <>
          <Comments
            className="public-comments-list"
            comments={this.transformCommentsForComponent(externalComments)}
            commentType={CommentType.External}
            onNewCommentAdded={values =>
              this.handleAddComment(CommentType.External, values)
            }
            onCommentUpdated={this.handleCommentUpdated}
            onCommentDeleted={this.handleCommentDeleted}
          />
        </>
      ),
      removePadding: true,
    })
    if (isAdmin) {
      panels.push({
        title: 'Private Comments',
        Content: (
          <>
            <Comments
              className="private-comments-list"
              comments={this.transformCommentsForComponent(internalComments)}
              commentType={CommentType.Internal}
              onNewCommentAdded={values =>
                this.handleAddComment(CommentType.Internal, values)
              }
              onCommentUpdated={this.handleCommentUpdated}
              onCommentDeleted={this.handleCommentDeleted}
            />
          </>
        ),
        removePadding: true,
      })
    }
    return panels
  }

  public renderModals = () => {
    const {
      transaction,
      isApplyingChargeback,
      isApplyingChargebackOverride,
      isApplyingRefund,
      isApplyingReversal,
      isApplyingCapture,
      isApplyingVoid,
      isApplyingResend,
      chargebackReasons,
      chargebackReasonDefault,
      chargebackStatusOptions,
      chargebackStatusDefault,
      chargebackOutcomeOptions,
      chargebackOutcomeDefault,
      formatCurrency,
      getCurrencySymbol,
      createTransaction,
      updateTransaction,
    } = this.props
    return !transaction ? null : (
      <>
        <RefundModal
          isOpen={this.state.isRefundModalOpen}
          onClose={this.toggleModal.bind(null, 'refund')}
          transaction={transaction}
          onSubmit={this.props.createTransaction}
          isApplyingRefund={isApplyingRefund}
          formatCurrency={formatCurrency}
          getCurrencySymbol={getCurrencySymbol}
        />
        <ChargebackModal
          isOpen={this.state.isChargebackModalOpen}
          onClose={this.toggleModal.bind(null, 'chargeback')}
          transaction={transaction}
          createTransaction={createTransaction}
          updateTransaction={updateTransaction}
          isApplyingChargeback={isApplyingChargeback}
          chargebackReasons={chargebackReasons}
          chargebackReasonDefault={chargebackReasonDefault}
          chargebackStatusOptions={chargebackStatusOptions}
          chargebackStatusDefault={chargebackStatusDefault}
          chargebackOutcomeOptions={chargebackOutcomeOptions}
          chargebackOutcomeDefault={chargebackOutcomeDefault}
          formatCurrency={formatCurrency}
          getCurrencySymbol={getCurrencySymbol}
        />
        <ChargebackOverrideModal
          isOpen={this.state.isChargebackOverrideModalOpen}
          onClose={this.toggleModal.bind(null, 'chargeback-override')}
          transaction={transaction}
          onSubmit={this.props.createTransaction}
          isApplyingChargebackOverride={isApplyingChargebackOverride}
        />
        <ChargebackReversalModal
          isOpen={this.state.isReversalModalOpen}
          onClose={this.toggleModal.bind(null, 'reversal')}
          transaction={transaction}
          onSubmit={this.props.createTransaction}
          isApplyingChargeback={isApplyingReversal}
          formatCurrency={formatCurrency}
          getCurrencySymbol={getCurrencySymbol}
        />
        <ApplyCaptureModal
          isOpen={this.state.isCaptureModalOpen}
          onClose={this.toggleModal.bind(null, 'capture')}
          transaction={transaction}
          onSubmit={this.props.createTransaction}
          isApplyingCapture={isApplyingCapture}
          formatCurrency={formatCurrency}
          getCurrencySymbol={getCurrencySymbol}
        />
        <ApplyVoidModal
          isOpen={this.state.isVoidModalOpen}
          onClose={this.toggleModal.bind(null, 'void')}
          transaction={transaction}
          onSubmit={this.props.createTransaction}
          isApplyingVoid={isApplyingVoid}
          formatCurrency={formatCurrency}
          getCurrencySymbol={getCurrencySymbol}
        />
        <ApplyResendModal
          isOpen={this.state.isResendModalOpen}
          onClose={this.toggleModal.bind(null, 'resend')}
          transaction={transaction}
          onSubmit={this.props.resendEmailReceipt}
          isApplyingResend={isApplyingResend}
        />
      </>
    )
  }

  public render(): JSX.Element | null {
    const { className, transaction, currentSiteName, isAdmin } = this.props
    const classes = classNames(className, {
      'is-admin': isAdmin,
    })

    return !transaction ? null : (
      <div className={classes}>
        <Helmet>
          <title>{`Trust My Travel | Transactions | ${transaction?.title}`}</title>
        </Helmet>
        <AppBar>
          <Text tag="h1" color="white">
            {currentSiteName}
          </Text>
        </AppBar>
        {this.renderPageTitle()}
        <Box
          background="white"
          fill="horizontal"
          pad="medium"
          round="small"
          className="box"
        >
          {this.maybeRenderTransactionStatus()}
          {this.maybeRenderChargebackStatus()}
          {this.renderTables()}
          {/* {this.renderTransactionTables()}
                    {this.renderPayeeTables()}
                    {this.renderPSPTable()} */}
          {this.renderModals()}
        </Box>
        <Accordion accordions={this.getAccordions()} />
      </div>
    )
  }
}

const StyledSingleTransactionsPage = styled(SingleTransactionsPage)`
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  overflow: scroll;

  .page-title {
    button {
      margin-left: 10px;
    }
  }

  .box {
    margin-bottom: 20px;
  }

  .changelog-list {
    margin-bottom: 30px;
  }

  .chargeback-status,
  .transaction-status {
    margin-bottom: 20px;
    h3 {
      margin: 0;
    }
    button {
      cursor: default;
    }
  }

  &.is-admin {
    .chargeback-status {
      button {
        cursor: pointer;
      }
    }
  }

  .payee-tables {
    margin-bottom: 20px;
  }

  .payee-tables a {
    display: block;
  }
  .single-transaction-table {
    margin-bottom: 20px;
  }
`

export default StyledSingleTransactionsPage
