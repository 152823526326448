import { ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { Observable, of } from 'rxjs'
import { map, switchMap, mergeMap, mapTo } from 'rxjs/operators'
import * as actions from './actions'
import { ENDPOINTS } from './constants'
import {
  get as getRequest,
  catchRestError,
  post,
  putRequest,
  optionsRequest,
  deleteRequest,
} from '../../utils/http'
import { liftPayload } from '../../utils/rx-operators'
import RestfulResult from '../../utils/RestfulResult'
import { injectCurrentSitePath } from '../../utils/general'

/**
 * Get Users Schema when `actions.requestUsersSchema` action is dispatched
 * @param action$ Action observable
 * @param state$ State observable
 */
export const requestUsersSchema = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestUsersSchema),
    liftPayload(),
    switchMap((payload: any) =>
      of(payload).pipe(
        map(params => ({ params })),
        optionsRequest(
          injectCurrentSitePath(ENDPOINTS.BASE, state$.value),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(
                actions.requestUsersSchemaSuccess(p, response.getContext()),
              ),
            ),
          ),
        ),
        catchRestError(actions.requestUsersSchema.toString()),
      ),
    ),
  )

export const requestUsersEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestUsers),
    switchMap((action: any) =>
      of(action.payload).pipe(
        map(params => ({ params })),
        getRequest(injectCurrentSitePath(ENDPOINTS.BASE, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestUsersSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestUsers.toString()),
      ),
    ),
  )

export const requestUserEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestUser),
    liftPayload(),
    switchMap((payload: any) =>
      of(payload).pipe(
        map(urlReplacements => ({ urlReplacements })),
        getRequest(injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestUserSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestUser.toString()),
      ),
    ),
  )

export const requestAdminUsersEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestAdminUsers),
    switchMap((action: any) =>
      of(action.payload).pipe(
        map(params => ({ params })),
        getRequest(ENDPOINTS.ADMIN_BASE, state$.value),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestAdminUsersSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestAdminUsers.toString()),
      ),
    ),
  )

export const requestAdminUserEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestAdminUser),
    liftPayload(),
    switchMap((payload: any) =>
      of(payload).pipe(
        map(urlReplacements => ({ urlReplacements })),
        getRequest(ENDPOINTS.ADMIN_BASE, state$.value),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestAdminUserSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestAdminUser.toString()),
      ),
    ),
  )

/**
 * Create an user when `actions.createUser` action is dispatched.
 * @param action$ Action observable
 * @param state$ State observable
 */
export const createUserEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.createUser),
    liftPayload(),
    map(body => ({ body })),
    switchMap((payload: any) =>
      of(payload).pipe(
        post(injectCurrentSitePath(ENDPOINTS.BASE, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.createUserSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.createUser.toString()),
      ),
    ),
  )

/**
 * Update a user when `actions.updateUser` action is dispatched.
 * @param action$ Action observable
 * @param state$ State observable
 */
export const updateUserEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.updateUser),
    liftPayload(),
    map(body => ({ body, urlReplacements: { id: body.id } })),
    switchMap((payload: any) =>
      of(payload).pipe(
        putRequest(injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.updateUserSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.updateUser.toString()),
      ),
    ),
  )

/**
 * Delete a user when `actions.deleteUser` action is dispatched.
 * @param action$ Action observable
 * @param state$ State observable
 */
export const deleteUserEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.deleteUser),
    liftPayload(),
    map(body => ({ body, urlReplacements: { id: body.id } })),
    switchMap((payload: any) =>
      of(payload).pipe(
        deleteRequest(
          injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.deleteUserSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.deleteUser.toString()),
      ),
    ),
  )
