import React from 'react'
import DashboardMetricItem from './DashboardMetricItem'
import DashboardPageLayout from './DashboardPageLayout'
import { IChannelMetricsFormatted } from './interfaces'

type Props = {
  className?: string
  currency: string
  metrics: IChannelMetricsFormatted
}

const DashboardProtectionOnly = ({ className, currency, metrics }: Props) => {
  return (
    <DashboardPageLayout>
      <>
        <div>
          <h2>Invoices</h2>
          <DashboardMetricItem
            icon="invoice"
            title="Previous Invoice"
            value={
              metrics.last_invoice !== undefined
                ? `${currency} ${metrics.last_invoice}`
                : '-'
            }
          />
          <DashboardMetricItem
            icon="owing"
            title="Currently Owing"
            value={
              metrics.invoice_owing !== undefined
                ? `${currency} ${metrics.invoice_owing}`
                : '-'
            }
          />
          <DashboardMetricItem
            icon="invoice"
            title="Next Invoice Date"
            value={metrics.next_invoice_date || '-'}
          />
        </div>
        <div>
          <h2>Protection</h2>
          <DashboardMetricItem
            icon="transaction"
            title="Total Transaction Value This Week"
            value={
              metrics.weekly_transactions !== undefined
                ? `${currency} ${metrics.weekly_transactions}`
                : '0'
            }
          />
          <DashboardMetricItem
            icon="transaction"
            title="Total Transaction Value This Month"
            value={
              metrics.monthly_transactions !== undefined
                ? `${currency} ${metrics.monthly_transactions}`
                : '0'
            }
          />
          <DashboardMetricItem
            icon="calendar-cash"
            title="Total Booking Value This Week"
            value={
              metrics.weekly_bookings !== undefined
                ? `${currency} ${metrics.weekly_bookings}`
                : '0'
            }
          />
          <DashboardMetricItem
            icon="calendar-cash"
            title="Total Booking Value This Month"
            value={
              metrics.monthly_bookings !== undefined
                ? `${currency} ${metrics.monthly_bookings}`
                : '0'
            }
          />
        </div>
        <div>
          <h2>Resources</h2>
          <DashboardMetricItem
            icon="member-hub"
            value={
              <a
                href="https://www.tmumanagement.com/knowledgehub/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Knowledge Hub
              </a>
            }
          />
          <DashboardMetricItem
            icon="documentation"
            value={
              <a
                href="https://developer.trustmy.group/protection-only/#introduction"
                target="_blank"
                rel="noopener noreferrer"
              >
                Protection Only API Documentation
              </a>
            }
          />
        </div>
      </>
    </DashboardPageLayout>
  )
}

export default DashboardProtectionOnly
