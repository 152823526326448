import * as React from 'react'
import cx from 'classnames'
import { CurrencyInput, TextInput } from '../..'
import styled from 'styled-components'
import { colors } from '../../../styles/variables'
import { convertFromCents, convertToCents } from '../../../../utils/currency'

interface IChargesInputFieldProps {
  value?: string
  currency: string
  name: string
  disabled: boolean
  className: string | undefined
  disallowDecimals?: boolean
  error?: string
  getIsEditing(): boolean
  errorCheck(value: boolean): void
  onChange(e: any): void
}

interface IChargesInputFieldState {
  value?: string
  isEditingField: boolean
  errorState: string
}

class ChargesInputField extends React.Component<
  IChargesInputFieldProps,
  IChargesInputFieldState
> {
  public state: IChargesInputFieldState = {
    value: '',
    isEditingField: false,
    errorState: '',
  }

  public componentDidMount() {
    this.setState({ value: this.props.value })
  }

  public handleChange = (e: any) => {
    const { currency } = this.props
    this.setState({
      value:
        !!e.target.value && currency
          ? convertToCents(e.target.value).toString()
          : e.target.value,
      isEditingField: true,
      errorState: this.validate(e.target.value),
    })

    this.props.onChange({
      ...e,
      target: {
        ...e.target,
        value:
          !!e.target.value && currency
            ? convertToCents(e.target.value)
            : e.target.value,
      },
    })
  }

  public componentDidUpdate(
    prevProps: IChargesInputFieldProps,
    prevState: IChargesInputFieldState,
  ) {
    if (
      !prevState.value &&
      this.props.value === this.state.value &&
      this.state.isEditingField
    ) {
      this.setState({ isEditingField: false })
    }

    if (!this.state.value && !this.state.isEditingField) {
      this.setState({ value: '0' })
    }

    this.props.errorCheck(!!this.state.errorState)
  }

  public validate = (value: string) => {
    return this.props.disallowDecimals && /\D/g.test(value)
      ? 'Value must be a whole number'
      : !/^[0-9]*[.]?[0-9]*$/g.test(value)
      ? 'Value must be a whole or point-separated number'
      : ''
  }

  public render(): JSX.Element {
    const { name, disabled, currency, className, error } = this.props
    const niceName = name.replace('[', '_').replace(']', '_')

    return !currency ? (
      <div className={className}>
        <TextInput
          className="value"
          key={name}
          id={niceName}
          name={name}
          onChange={this.handleChange}
          disabled={disabled}
          value={this.state.value}
          error={error || this.state.errorState}
        />
      </div>
    ) : (
      <div className={className} id={`currency-container-${niceName}`}>
        <CurrencyInput
          className={cx('field', 'currency-field')}
          prefix={currency}
          name={name}
          id={niceName}
          onChange={this.handleChange}
          value={!!this.state.value ? convertFromCents(this.state.value) : ''}
          disabled={disabled}
          error={error || this.state.errorState}
        />
      </div>
    )
  }
}

const StyledChargesInputField = styled(ChargesInputField)`
  display: flex;
  align-items: center;

  .value {
    width: 100%;
    border-radius: 4px;
    border-color: ${colors.blue.dark};
    color: ${colors.blue.dark};
    font-size: 1rem;
  }

  .currency-field {
    width: 100%;
    .currency-input-container {
      margin-bottom: 0 !important;

      span:first-of-type {
        padding: 10px;
      }

      input {
        font-size: 1rem;
        padding: 10px;
        color: ${colors.blue.dark};
        width: 100% !important;
        min-width: auto;
        flex-grow: 1;
      }
    }
  }

  .value-error {
    color: ${colors.red};
    font-size: 11px;
    padding-left: 5px;
    white-space: normal;
  }
`

export default StyledChargesInputField
