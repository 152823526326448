import React from 'react'
import {
  CellProps,
  isDateControl,
  RankedTester,
  rankWith,
} from '@jsonforms/core'
import { withJsonFormsCellProps } from '@jsonforms/react'
import {
  VanillaRendererProps,
  withVanillaCellProps,
} from '@jsonforms/vanilla-renderers'
import SingleDatePicker from '../../../../views/components/molecules/SingleDatePicker/SingleDatePicker'
import moment, { Moment } from 'moment'
import styled from 'styled-components'
import { colors } from '../../../../views/styles/variables'

const DateCell = (
  props: CellProps & VanillaRendererProps & { className?: string },
) => {
  const { data, className, id, enabled, schema, path, handleChange } = props

  const _handleChange = (date: Moment) => {
    handleChange(path, moment(date).format('YYYY-MM-DD'))
  }

  const isFormFieldDisabled = () => {
    if (!enabled) {
      return true
    }
    const isReadOnly = (schema as any)?.readonly === true
    if (isReadOnly) {
      return true
    }
    return false
  }

  return (
    <div className={`${className} date-picker`}>
      <SingleDatePicker
        date={data ? moment(data) : null}
        onDateChange={_handleChange}
        // id={id}
        disabled={isFormFieldDisabled()}
        // autoFocus={uischema.options && uischema.options.focus}
      />
    </div>
  )
}
/**
 * Default tester for date controls.
 * @type {RankedTester}
 */
export const dateCellTester: RankedTester = rankWith(3, isDateControl)

const StyledDateCell = styled(DateCell)`
  &.invalid {
    .SingleDatePickerInput {
      border-color: ${colors.red} !important;
    }
  }

  .DateInput {
    background: none !important;
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
  }

  .DateInput_input {
    background: none !important;
    width: 100%;
    margin: 0 !important;
    padding: 11px !important;
    font-size: 15px;
    font-style: normal;
  }

  .DateInput_input__disabled {
    color: #575757;
  }
`

export default withJsonFormsCellProps(withVanillaCellProps(StyledDateCell))
