import { connect } from 'react-redux'
import { Dispatch, AnyAction } from 'redux'
import UserForm from './UserForm'
import { usersActions, usersSelectors } from '../../../../state/modules/users'
import { authSelectors } from '../../../../state/modules/auth'
import { uiUsersSelectors } from '../../../../state/modules/ui/users'
import { redirect } from '../../../../utils/router'
import { sitesSelectors } from '../../../../state/modules/sites'
import { uiChannelsSelectors } from '../../../../state/modules/ui'

interface IUserFormContainerOwnProps {
  className: string | undefined
  user: any
  userId: string
}

const mapStateToProps = (state: any, ownProps: IUserFormContainerOwnProps) => {
  const user = ownProps.user
    ? ownProps.user
    : uiUsersSelectors.getUserFormInitialValues(state)(ownProps.userId)
  const isCurrentUser =
    authSelectors.getAuthState(state).id?.toString() === ownProps.userId
  return {
    className: ownProps.className,
    user,
    userId: ownProps.userId,
    isCurrentUser,
    isTwoFactorAuthEnabled: usersSelectors.isTwoFactorAuthEnabled(state)(
      ownProps.userId,
    ),
    initialValues: uiUsersSelectors.getUserFormInitialValues(state)(
      ownProps.userId,
    ),
    serverErrors: uiUsersSelectors.getServerErrors(state),
    isSaving: uiUsersSelectors.isSaving(state),
    isCreating: uiUsersSelectors.isCreating(state),
    isDeleting: uiUsersSelectors.isDeleting(state),
    isAdmin: authSelectors.isAdmin(state),
    fieldsSchema: usersSelectors.getPropertiesFromSchema(state),
    currentSitePath: sitesSelectors.getCurrentSitePath(state),
    channels: uiUsersSelectors.getUserChannels(state)(user),
    allChannels:
      uiChannelsSelectors.getChannelsForMultipleSelectDropdown(state),
    isViewingChannel: uiUsersSelectors.isViewingUser(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, ownProps: any) => ({
  createUser: (payload: any) => dispatch(usersActions.createUser(payload)),
  updateUser: (payload: any) => dispatch(usersActions.updateUser(payload)),
  deleteUser: (payload: any) => dispatch(usersActions.deleteUser(payload)),
  redirect: (path: string) => redirect(path),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserForm)
