import * as React from 'react'
import styled from 'styled-components'
import { Image } from 'grommet'
import logo from '../../../../assets/images/logo-tmt-systems.svg'

interface ILogoProps {
  className?: string
}

const Logo = (props: ILogoProps): JSX.Element => {
  const { className } = props
  return (
    <Image className={className} src={logo} margin={{ vertical: 'medium' }} />
  )
}

const StyledLogo = styled(Logo)`
  max-height: 100px;
`

export default StyledLogo
