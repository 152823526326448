import React from 'react'
import {
  CellProps,
  isIntegerControl,
  RankedTester,
  rankWith,
} from '@jsonforms/core'
import { withJsonFormsCellProps } from '@jsonforms/react'
import {
  VanillaRendererProps,
  withVanillaCellProps,
} from '@jsonforms/vanilla-renderers'
import { TextInput } from '../../../../views/components'
import styled from 'styled-components'
import { colors } from '../../../../views/styles/variables'

const toNumber = (value: string) =>
  value === '' ? undefined : parseInt(value, 10)

const IntegerCell = (props: CellProps & VanillaRendererProps) => {
  const { data, className, id, enabled, schema, path, handleChange } = props

  const isFormFieldDisabled = () => {
    if (!enabled) {
      return true
    }
    const isReadOnly = (schema as any)?.readonly === true
    if (isReadOnly) {
      return true
    }
    return false
  }

  return (
    <TextInput
      name={id}
      id={id}
      type="number"
      value={data ?? ''}
      onChange={ev => handleChange(path, toNumber(ev.target.value))}
      className={className}
      disabled={isFormFieldDisabled()}
      // autoFocus={uischema.options && uischema.options.focus}
    />
  )
}
/**
 * Default tester for integer controls.
 * @type {RankedTester}
 */
export const integerCellTester: RankedTester = rankWith(3, isIntegerControl)

const StyledIntegerCell = styled(IntegerCell)`
  border-color: ${colors.blue.dark};

  &.invalid {
    border-color: ${colors.red} !important;
  }
`

export default withJsonFormsCellProps(withVanillaCellProps(StyledIntegerCell))
