import React, { ReactChild } from 'react'
import styled from 'styled-components'
import { colors } from '../../../styles/variables'
import DashboardIcon, { TDashboardIcon } from './DashboardIcon/DashboardIcon'

type Props = {
  className?: string
  icon?: TDashboardIcon
  title?: string
  value: string | ReactChild
}

const DashboardMetricItem = ({ className, icon, title, value }: Props) => {
  return (
    <div className={className}>
      {icon && <DashboardIcon icon={icon} />}
      <div>
        <h3>{title}</h3>
        <strong>{value}</strong>
      </div>
    </div>
  )
}

export default styled(DashboardMetricItem)`
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;

  img,
  span {
    margin-top: 5px;
  }

  h3 {
    font-size: 12px;
    font-weigh: normal;
    margin-top: 0;
    margin-bottom: 5px;
  }

  a {
    color: ${colors.blue.medium};
    text-decoration: none;
    transition: 0.3s all ease-in-out;

    &:hover,
    &:focus {
      color: ${colors.blue.dark};
    }
  }

  strong {
    font-size: 16px;
  }

  > div {
    margin-left: 10px;
  }
`
