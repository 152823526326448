import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { uniq } from 'lodash'
import { parseLinkHeader } from '../../utils/http'
import { NAMESPACE } from './constants'
import {
  ICreateSupplier,
  IReassignSupplier,
  IRequestSuppliersParams,
  ISupplier,
  ISuppliersState,
} from './interfaces'

export const initialState: ISuppliersState = {
  schema: {},
  allIds: [],
  byId: {},
  pages: {},
  isLoading: false,
  /**
   * Temporary edition to resolve suppliers dissapearing from supplier picker.
   * Needs a proper fix, but taking a bit too long.
   */
  allSuppliers: {},
}

export const suppliersSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    requestSuppliersSchema: () => {},
    requestSuppliersSchemaSuccess: {
      prepare: (payload, meta) => ({ payload, meta }),
      reducer: (state, action: PayloadAction<ISupplier[]>) => {
        state.schema = action.payload
      },
    },
    requestSuppliers: (
      state,
      action: PayloadAction<IRequestSuppliersParams>,
    ) => {
      state.isLoading = true
    },
    requestSuppliersSuccess: {
      prepare: (payload, meta) => ({ payload, meta }),
      reducer: (state, action: PayloadAction<ISupplier[], string, any>) => {
        state.isLoading = false
        state.pages = parseLinkHeader(action.meta.responseHeaders?.link || '')
        state.allIds = action.payload.map(supplier => supplier.id)
        state.byId = action.payload.reduce(
          (acc, s) => ({ ...acc, [s.id]: s }),
          {},
        )
        action.payload.forEach(supplier => {
          state.allSuppliers[supplier.id] = supplier
        })
      },
    },
    requestSupplier: (state, action: PayloadAction<{ id: string }>) => {},
    requestSupplierSuccess: {
      prepare: (payload, meta) => ({ payload, meta }),
      reducer: (state, action: PayloadAction<ISupplier>) => {
        state.allIds = uniq([...state.allIds, action.payload.id])
        state.byId[action.payload.id] = action.payload
        state.allSuppliers[action.payload.id] = action.payload
      },
    },
    createSupplier: (state, action: PayloadAction<ICreateSupplier>) => {},
    createSupplierSuccess: (state, action: PayloadAction<ISupplier>) => {
      state.allIds.push(action.payload.id)
      state.byId[action.payload.id] = action.payload
      state.allSuppliers[action.payload.id] = action.payload
    },
    updateSupplier: (state, action: PayloadAction<ISupplier>) => {},
    updateSupplierSuccess: (state, action: PayloadAction<ISupplier>) => {
      state.byId[action.payload.id] = action.payload
      state.allSuppliers[action.payload.id] = action.payload
    },
    deleteSupplier: (state, { payload }: PayloadAction<IReassignSupplier>) => {
      state.isLoading = true
    },
    deleteSupplierSuccess: (state, { payload }: PayloadAction<ISupplier>) => {
      state.isLoading = false
      state.byId[payload.id] = payload
    },
  },
  extraReducers: {
    http_error: (
      state: any,
      action: PayloadAction<any, string, { action: string }>,
    ) => {
      if (['suppliers/deleteSupplier'].includes(action.meta.action)) {
        state.isLoading = false
      }
    },
  },
})

const { actions, reducer } = suppliersSlice

export { actions, reducer }
