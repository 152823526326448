import { API_SITE_URL } from '../../state/constants'

const baseUrl = `${API_SITE_URL}/two-factor-auth`

export const ENDPOINTS = {
  ENABLE: `${baseUrl}`,
  DISABLE: `${baseUrl}/users/:id`,
  QR_CODE: `${baseUrl}/qr-code`,
  RECOVERY_CODES: `${baseUrl}/recovery-codes`,
  SECRET_KEY: `${baseUrl}/secret-key`,
  CONFIRM: `${baseUrl}/confirm`,
}

export type EndpointKeys = keyof typeof ENDPOINTS

export type Endpoints = {
  [key in EndpointKeys]: string
}
