import * as React from 'react'
import { Grommet, Box } from 'grommet'
import styled from 'styled-components'
import timfinityTheme from '../../../styles/timfinityTheme'
import { colors } from '../../../styles/variables'

export interface IFormFieldProps {
  className?: string
  label: string
  error?: string | boolean | undefined
}

class FormField extends React.Component<IFormFieldProps> {
  public render(): JSX.Element {
    const { children, className = '', label, error } = this.props

    return (
      <div className={className}>
        <Grommet theme={timfinityTheme}>
          <Box direction="row" align="center">
            <label>{label}</label>
            <div className="field-control">{children}</div>
          </Box>
          {error && <Box className="error">{error}</Box>}
        </Grommet>
      </div>
    )
  }
}

const StyledFormField = styled(FormField)`
  ${(props: IFormFieldProps) => {
    let extraStyles = ''

    return `
            label {
                margin-right: 20px;
            }
            .field-control {
                background: #fff;
                width: 100%;
            }
            .error {
                color: ${colors.red};
            }
            ${extraStyles}
        `
  }}
`

export default StyledFormField
