import * as React from 'react'
import { Box } from 'grommet'
import { AppBar } from '../../containers'
import SiteSelector from '../stateful/SiteSelectors/SiteSelector'

export default class ProfilePage extends React.PureComponent<{}, {}> {
  public render(): JSX.Element {
    return (
      <Box fill={true} pad="medium">
        <AppBar>
          <SiteSelector className="site-selector" />
        </AppBar>
      </Box>
    )
  }
}
