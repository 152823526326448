import * as React from 'react'
import { DataTable, Anchor, StatusText } from '../..'
import { redirect } from '../../../../utils/router'
import { useSelector } from 'react-redux'
import usePagination from '../../../../hooks/usePagination'
import { bookingSelectors } from '../../../../state/modules/bookings'
import { uiBookingsSelectors } from '../../../../state/modules/ui'
import { formatDate } from '../../../../utils/date'
import { sitesSelectors } from '../../../../state/modules/sites'
import BookingsPageService from './BookingsPageService'

interface IContentProps {}

const Content = (props: IContentProps): JSX.Element => {
  const { setCurrentPage, getCurrentPage } = usePagination()
  const pages: TPages = useSelector(bookingSelectors.getPages)
  const currentPage: number = parseInt(getCurrentPage())
  const isLoadingBookings: boolean = useSelector(bookingSelectors.isLoading)
  const bookingsData = useSelector(uiBookingsSelectors.getBookingsTableData)(
    currentPage,
  )
  const currentSitePath: string = useSelector(sitesSelectors.getCurrentSitePath)

  const handleNext = () => setCurrentPage(currentPage + 1)

  const handlePrevious = () => setCurrentPage(currentPage - 1)

  const columns = [
    {
      Header: 'Trust ID',
      accessor: 'trust_id',
      Cell: (row: any) => {
        const redirectTo = () =>
          redirect(`/${currentSitePath}/bookings/${row.original.id}`)
        return <Anchor onClick={redirectTo}>{row.original.trust_id}</Anchor>
      },
    },
    {
      Header: 'Created',
      accessor: 'created',
      Cell: (row: any) => formatDate(row.original.created, 'dd LLL yy'),
    },
    {
      Header: 'Surname',
      accessor: 'surname',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Reference',
      accessor: 'reference',
    },
    {
      Header: 'Travel Date',
      accessor: 'complete',
      Cell: (row: any) => formatDate(row.original.complete, 'dd LLL yy'),
    },
    {
      Header: 'Total',
      accessor: 'total',
      Cell: (row: any) => {
        return (
          <StatusText value={row.original.total} status={row.original.status} />
        )
      },
    },
    {
      Header: 'Unpaid',
      accessor: 'total_unpaid',
      Cell: (row: any) => {
        return (
          <StatusText
            value={row.original.total_unpaid}
            status={row.original.status}
          />
        )
      },
    },
  ]
  return (
    <>
      <BookingsPageService />
      <DataTable
        manual={true}
        fitContainer={true}
        data={bookingsData}
        columns={columns}
        loading={isLoadingBookings}
        pageSize={bookingsData.length}
        pages={pages.next ? currentPage + 1 : currentPage}
        page={currentPage}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />
    </>
  )
}

export default Content
