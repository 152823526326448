import React, { useCallback, useEffect, useState } from 'react'
import { Formik, Form, FormikProps, Field } from 'formik'
import * as yup from 'yup'
import styled from 'styled-components'
import { Box } from 'grommet'
import {
  Modal,
  Text,
  TextInput,
  Button,
  CurrencyInput,
  TextAreaInput,
  FormField,
  CountrySelector,
  Select,
  Loader,
} from '../..'
import { colors } from '../../../styles/variables'
import countries, { ICountry } from '../../../../constants/countries'
import { useDispatch, useSelector } from 'react-redux'
import { bookingSelectors } from '../../../../state/modules/bookings'
import { useEffectOnce } from 'react-use'
import { find, get, isEmpty } from 'lodash'
import { convertFromCents, convertToCents } from '../../../../utils/currency'
import { IBookingResponse } from '../../../../state/modules/bookings/interfaces'
import { ICreateTransaction } from '../../../../state/modules/transactions/interfaces'
import { IChannelResponse } from '../../../../state/modules/channels/interfaces'
import { transactionsActions } from '../../../../state/modules/transactions'
import { uiTransactionsSelectors } from '../../../../state/modules/ui/transactions'

interface IValues {
  firstname: string
  surname: string
  email: string
  currencies: string
  countries: string
  total_unpaid: number
  reference: string
  base_value: number
  // used for validation
  _total_unpaid: number
  _currencies: string
}

interface IProps {
  className?: string
  isOpen: boolean
  onClose(): void
  booking: IBookingResponse
  bookingChannel: IChannelResponse
  bookingChannelForexFeed: any /** @todo pdate type */
  initialValues: IValues
}

const validationSchema = yup.object({
  firstname: yup.string().required('First name is required.'),
  surname: yup.string().required('Last name is required.'),
  email: yup
    .string()
    .email('Must be a valid email.')
    .required('Email is required.'),
  currencies: yup.string().required('Currency is required.'),
  countries: yup.string().required('Country is required.'),
  total_unpaid: yup
    .string()
    .required('Recieved amount is required.')
    .test('total_unpaid', "Can't exceed booking total", function (val) {
      if (
        this.parent._currencies === this.parent.currencies &&
        val &&
        convertToCents(val) > convertToCents(this.parent._total_unpaid)
      ) {
        return new yup.ValidationError(
          `Can't exceed booking total of ${this.parent._total_unpaid}`,
          val,
          'total_unpaid',
        )
      }
      return true
    }),
  reference: yup.string().nullable(),
  _total_unpaid: yup.number(),
})

const transformValuesForSubmission = (
  values: IValues,
  booking: IBookingResponse,
  channelCurrency: string,
): ICreateTransaction => {
  const isBaseCurrencyEqual = values.currencies === booking.currencies
  return {
    payee_name: values.firstname,
    payee_surname: values.surname,
    payee_email: values.email,
    countries: values.countries,
    content: values.reference,
    channels: booking.channels,
    currencies: values.currencies,
    bookings: [
      {
        id: booking.id,
        currencies: channelCurrency,
        total: convertToCents(
          isBaseCurrencyEqual ? values.total_unpaid : values.base_value,
        ),
      },
    ],
    total: convertToCents(values.total_unpaid),
    psp: 'tmt-internal',
    payment_methods: 'bank-transfer',
    transaction_types: 'purchase',
  }
}

const ModalForm = ({
  className,
  initialValues,
  booking,
  bookingChannel,
  bookingChannelForexFeed,
  onClose,
}: {
  className: string
  initialValues: IValues
  booking: IBookingResponse
  bookingChannel: IChannelResponse
  bookingChannelForexFeed: any
  onClose(): void
}) => {
  const dispatch = useDispatch()
  const currenciesFromSchema = useSelector(
    bookingSelectors.getCurrenciesFromSchema,
  )
  const [currencies, setCurrencies] = useState([])
  const [wasSubmitting, setWasSubmitting] = useState(false)

  const serverErrors = useSelector(uiTransactionsSelectors.getServerErrors)
  const isSubmitting = useSelector(
    uiTransactionsSelectors.isCreateTransactionSubmitting,
  )

  useEffect(() => {
    isSubmitting && setWasSubmitting(true)
  }, [isSubmitting])

  useEffect(() => {
    if (wasSubmitting && !isSubmitting && serverErrors === null) {
      setWasSubmitting(false)
      onClose()
    }
  }, [wasSubmitting, isSubmitting, serverErrors, onClose])

  useEffectOnce(() => {
    setCurrencies(currenciesFromSchema)
  })

  const handleCurrencySearch = useCallback(
    (term: string) => {
      setCurrencies(
        currenciesFromSchema.filter((c: string) =>
          c.toLowerCase().includes(term.toLowerCase()),
        ),
      )
    },
    [currenciesFromSchema],
  )

  const resetCurrencies = useCallback(() => {
    setCurrencies(currenciesFromSchema)
  }, [currenciesFromSchema])

  const handleSubmit = useCallback(
    (values: IValues) => {
      const transactionPayload = transformValuesForSubmission(
        values,
        booking,
        bookingChannel.currencies,
      )
      dispatch(transactionsActions.requestCreateTransaction(transactionPayload))
    },
    [booking, bookingChannel.currencies, dispatch],
  )

  return (
    <Box pad="medium" className={className} style={{ overflowY: 'scroll' }}>
      {isSubmitting ? (
        <Loader text="Creating payment..." />
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({
            handleChange,
            setFieldValue,
            values,
            errors,
            touched,
          }: FormikProps<IValues>) => {
            const handleTotalChange = (e: any) => {
              const value = e.target.value
              setFieldValue('total_unpaid', value)
              const rate = find(
                get(bookingChannelForexFeed, ['rates'], {}),
                (currency: any) => currency.symbol === values.currencies,
              )
              if (rate) {
                const baseValue = convertToCents(value / rate.rate)
                setFieldValue('base_value', convertFromCents(baseValue))
              } else {
                setFieldValue('base_value', '')
              }
            }

            const handleBaseValueChange = (e: any) =>
              setFieldValue('base_value', e.target.value)

            const countryVal = countries.find(c => c.value === values.countries)

            const handleCurrencyChange = (name: string, val: any) => {
              setFieldValue(name, val)
              const rate = find(
                get(bookingChannelForexFeed, ['rates'], {}),
                (currency: any) => currency.symbol === val,
              )
              if (rate) {
                const newTotal = convertToCents(values.total_unpaid * rate.rate)
                setFieldValue('total_unpaid', convertFromCents(newTotal))
                setFieldValue(
                  'base_value',
                  convertFromCents(booking.total_unpaid),
                )
              } else {
                setFieldValue(
                  'total_unpaid',
                  convertFromCents(booking.total_unpaid),
                )
              }
            }

            return (
              <Form id="pay-via-bank-transfer">
                <div className="form-header">
                  <Text tag="h3" color={colors.blue.dark}>
                    Bank Transfer
                  </Text>
                  <Text tag="p" color={colors.blue.dark}>
                    You are about to mark this booking as paid via bank
                    transfer. Please provide the payment details to continue.
                  </Text>
                </div>

                <TextInput
                  name="firstname"
                  onChange={handleChange}
                  className="field"
                  label="First Name"
                  value={values.firstname}
                  error={errors.firstname}
                />

                <TextInput
                  name="surname"
                  onChange={handleChange}
                  className="field"
                  label="Last Name"
                  value={values.surname}
                  error={errors.surname}
                />

                <TextInput
                  name="email"
                  onChange={handleChange}
                  className="field"
                  label="Email"
                  value={values.email}
                  error={errors.email}
                />

                <FormField
                  label="Country"
                  className="field select-control"
                  error={touched.countries && errors.countries}
                >
                  <CountrySelector
                    value={countryVal ? countryVal.label : ''}
                    onSelect={(country: ICountry) =>
                      setFieldValue('countries', country.value)
                    }
                  />
                </FormField>

                <Box direction="row" className="received-row">
                  <Field
                    component={Select}
                    id="currencies"
                    name="currencies"
                    onChange={handleCurrencyChange.bind(null, 'currencies')}
                    options={currencies}
                    onSearch={handleCurrencySearch}
                    label="Received"
                    value={values.currencies}
                    error={touched.currencies && errors.currencies}
                    className="field select-control"
                    onClose={resetCurrencies}
                  />

                  <CurrencyInput
                    prefix={values.currencies}
                    name="total_unpaid"
                    onChange={handleTotalChange}
                    className="field currency-input"
                    value={values.total_unpaid}
                    error={errors.total_unpaid}
                  />
                </Box>

                {values.currencies !== booking.currencies && (
                  <CurrencyInput
                    prefix={booking.currencies}
                    name="base_value"
                    onChange={handleBaseValueChange}
                    label="Base value"
                    className="field currency-input"
                    value={values.base_value}
                    error={errors.base_value}
                  />
                )}

                <TextAreaInput
                  name="reference"
                  onChange={handleChange}
                  className="field"
                  label="Notes"
                  value={values.reference}
                  error={errors.reference}
                />

                <footer>
                  <Button
                    disabled={!isEmpty(errors)}
                    styleType="secondary"
                    label="Pay"
                    size="small"
                    type="submit"
                  />
                </footer>
              </Form>
            )
          }}
        </Formik>
      )}
    </Box>
  )
}

const PayViaBankTransferModal = ({
  className,
  isOpen,
  onClose,
  initialValues,
  booking,
  bookingChannel,
  bookingChannelForexFeed,
}: IProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      InnerComponent={
        <ModalForm
          className={className || ''}
          initialValues={initialValues}
          booking={booking}
          bookingChannel={bookingChannel}
          bookingChannelForexFeed={bookingChannelForexFeed}
          onClose={onClose}
        />
      }
      showCloseIcon={true}
    />
  )
}

export default styled(PayViaBankTransferModal)`
  .form-header {
    border-bottom: 1px solid ${colors.grey.lighter};
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  .field {
    margin-bottom: 10px;
    position: relative;

    .error {
      margin-left: 130px;
    }
  }
  .field label {
    margin-right: 30px;
    min-width: 100px;
  }
  .select-control {
    button {
      width: 100%;
    }
  }
  .currency-input {
    .error {
      display: block;
      width: 100%;
    }
  }
  footer {
    border-top: 1px solid ${colors.grey.lighter};
    text-align: right;
    margin-top: 10px;
    padding-top: 10px;
  }
  .received-row {
    .select-control {
      min-width: 50%;
    }
    .currency-input {
      width: 70%;
      padding-left: 10px;
    }
  }
`
