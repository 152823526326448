import { useDispatch, useSelector } from 'react-redux'
import { useAxios } from '../../utils/axios'
import { ENDPOINTS } from './constants'
import { ApiToken } from './types'
import { sitesSelectors } from '../../state/modules/sites'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { uiNotificationActions } from '../../state/modules/ui'

export const useFetchApiTokens = () => {
  const axios = useAxios()
  const currentSitePath = useSelector(sitesSelectors.getCurrentSitePath)
  const endpoint = ENDPOINTS.BASE.replace(':sitePath', currentSitePath)

  const queryData = useQuery(['apiTokens'], async () => {
    const { data } = await axios.get(endpoint)
    return data
  })

  return queryData
}

export const useCreateApiToken = ({
  onSuccess,
}: {
  onSuccess: (data: any) => void
}) => {
  const dispatch = useDispatch()
  const axios = useAxios()
  const currentSitePath = useSelector(sitesSelectors.getCurrentSitePath)
  const endpoint = ENDPOINTS.BASE.replace(':sitePath', currentSitePath)
  const queryClient = useQueryClient()

  // TODO: fix types
  const queryData = useMutation<ApiToken, any, any>({
    mutationFn: async ({ name, expires_at }) => {
      const { data } = await axios.post<ApiToken>(endpoint, {
        name,
        expires_at,
      })
      return data
    },
    onSuccess: data => {
      onSuccess(data)
      queryClient.invalidateQueries({
        queryKey: ['apiTokens'],
      })
    },
    // TODO: move this to a global queryCache error handler
    onError: ({ response }) => {
      dispatch(
        uiNotificationActions.addNotification({
          message: response.data.message,
          type: 'error',
        }),
      )
    },
  })

  return queryData
}

export const useRevokeApiToken = ({
  onMutate,
  onSuccess,
}: {
  onMutate: () => void
  onSuccess: () => void
}) => {
  const axios = useAxios()
  const currentSitePath = useSelector(sitesSelectors.getCurrentSitePath)

  const queryData = useMutation<string, any, any>({
    mutationFn: async id => {
      const endpoint = ENDPOINTS.ENTITY.replace(
        ':sitePath',
        currentSitePath,
      ).replace(':id', id.toString())

      const { data } = await axios.delete(endpoint)
      return data
    },
    onMutate: () => {
      onMutate()
    },
    onSuccess: () => {
      onSuccess()
    },
  })

  return queryData
}

export const useRevokeAllApiTokens = ({
  onSuccess,
}: {
  onSuccess: () => void
}) => {
  const axios = useAxios()
  const currentSitePath = useSelector(sitesSelectors.getCurrentSitePath)
  const endpoint = ENDPOINTS.BASE.replace(':sitePath', currentSitePath)
  const queryClient = useQueryClient()

  const queryData = useMutation({
    mutationFn: async () => {
      const { data } = await axios.delete(endpoint)
      return data
    },
    onMutate: () => {
      queryClient.setQueryData(['apiTokens'], () => [])
    },
    onSuccess: () => {
      onSuccess()
    },
  })

  return queryData
}
