import { Box } from 'grommet'
import React from 'react'
import Loader from '../../atoms/Loader/Loader'
import Modal from '../../molecules/Modal/Modal'

type Props = {
  loadingState: string
}

const SettingsPageFormLoadingModal = ({ loadingState }: Props) => {
  let text
  if (loadingState === 'loading') {
    text = 'Loading...'
  }

  if (loadingState === 'saving') {
    text = 'Saving...'
  }
  if (loadingState === 'creating') {
    text = 'Creating site...'
  }

  return (
    <Modal
      isOpen={loadingState !== 'idle'}
      showCloseIcon={false}
      InnerComponent={
        <Box height="small" width="medium">
          <Loader text={text} />
        </Box>
      }
    />
  )
}

export default SettingsPageFormLoadingModal
