import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import AsyncSelect from '../../molecules/AsyncSelect/AsyncSelect'
import {
  IChannelResponse,
  IRequestChannelsParams,
} from '../../../../state/modules/channels/interfaces'
import { useSelector } from 'react-redux'
import useFetchHelper from '../../molecules/AsyncSelect/useFetchHelper'
import {
  channelActions,
  channelConstants,
  channelSelectors,
} from '../../../../state/modules/channels'
import useChannelFilter from '../../../../hooks/useChannelFilter'
import styled from 'styled-components'
import { sitesSelectors } from '../../../../state/modules/sites'
import { matchPath } from 'react-router'
import { get } from 'lodash'

export interface IChannelSelectorProps {
  className?: string
  isClearable?: boolean
  placeholder?: string
  onChange?(value: IChannelResponse | IChannelResponse[]): void
  // onChange: (value: ValueType<IChannelResponse, boolean>, action: ActionMeta<IChannelResponse>) => void;
  value?: IChannelResponse | IChannelResponse[]
  disabled?: boolean
  id?: string
  isMulti?: boolean
  closeMenuOnSelect?: boolean
  hideOnCount?: boolean
  hideIfNoResults?: boolean
  extendedChannelRequestParams?: IRequestChannelsParams
  onInitialOptionsLoaded?: (options: IChannelResponse[]) => void
}
interface IOption {
  value: string | number
  label: string
}

const transformOption = (channel: IChannelResponse): IOption => {
  return {
    value: channel.id,
    label: channel.name,
  }
}

const PER_PAGE = 20

const ChannelSelector = ({
  className,
  isClearable,
  placeholder = 'Select channel...',
  onChange,
  value,
  disabled,
  isMulti = false,
  hideOnCount = true,
  hideIfNoResults = false,
  extendedChannelRequestParams = {},
  onInitialOptionsLoaded,
  ...props
}: IChannelSelectorProps) => {
  const [channelId, setChannelId] = useChannelFilter()
  const { request } = useFetchHelper(
    channelConstants.ENDPOINTS.BASE,
    channelActions.requestChannelsSuccess,
  )
  const [initialOptions, setInitialOptions] = useState([])
  const [hasRequestedInitialOptions, setHasRequestedInitialOptions] =
    useState(false)
  const getChannelById = useSelector(channelSelectors.getChannelById)
  const currentChannel = getChannelById(channelId as number)

  const currentSite = useSelector(sitesSelectors.getCurrentSite)
  const currentSitePath = currentSite?.path.replace(/\//g, '')

  const match = matchPath(window.location.pathname, {
    path: '/:sitePath/',
    exact: false,
    strict: false,
  })
  const urlSitePath = get(match, 'params.sitePath')

  const getInitialOptions = useCallback(async () => {
    return await request({
      per_page: PER_PAGE,
      page: 1,
      ...extendedChannelRequestParams,
    })
  }, [request, extendedChannelRequestParams])

  useEffect(() => {
    const fetchAndSetInitialOptions = async () => {
      const { options } = await getInitialOptions()
      if (options) {
        setInitialOptions(options.map(transformOption))
        if (onInitialOptionsLoaded) {
          onInitialOptionsLoaded(options)
        }
      }
    }
    /**
     * Wait until the current site in state matches the URL site path
     * before fetching the initial options. Fixes https://app.clickup.com/t/2r78w2r
     */
    if (currentSitePath === urlSitePath && !hasRequestedInitialOptions) {
      setHasRequestedInitialOptions(true)
      fetchAndSetInitialOptions()
    }
  }, [
    currentSitePath,
    urlSitePath,
    getInitialOptions,
    hasRequestedInitialOptions,
    onInitialOptionsLoaded,
  ])

  const loadOptions = async (search: string, prevOptions: any) => {
    const params: any = {
      order_by: 'name',
      per_page: PER_PAGE,
      page: prevOptions.length / PER_PAGE + 1,
      ...extendedChannelRequestParams,
    }

    if (search) {
      params.search = search
    }
    const res = await request(params)

    return {
      ...res,
      options: res.options.map(transformOption),
    }
  }

  const handleChange = (value: any, action: any) => {
    if (onChange) {
      if (isMulti) {
        if (value) {
          const channels = value.map((v: IOption) => getChannelById(v.value))
          onChange(channels)
        } else {
          onChange([])
        }
      } else {
        const channel: IChannelResponse = getChannelById(value.value)

        onChange(channel)
      }
    } else if (!value) {
      setChannelId(undefined)
    } else {
      const channel: IChannelResponse = getChannelById(value.value)
      channel && setChannelId(channel.id)
    }
  }

  const currentValue = isMulti
    ? value
      ? value.map((val: IChannelResponse) => transformOption(val))
      : currentChannel && transformOption(currentChannel)
    : value
    ? transformOption(value as IChannelResponse)
    : currentChannel && transformOption(currentChannel)

  const channelCount = useSelector(sitesSelectors.getCurrentSiteChannelCount)

  if (hideIfNoResults && channelCount === 0) {
    return null
  }

  return channelCount > 1 || !hideOnCount ? (
    <AsyncSelect
      className={classNames([className, 'channel-selector'])}
      isClearable={isClearable}
      placeholder={placeholder}
      initialOptions={initialOptions}
      loadOptions={loadOptions}
      onChange={handleChange}
      value={currentValue}
      isDisabled={disabled}
      isMulti={isMulti}
      {...props}
    />
  ) : null
}

export default styled(ChannelSelector)`
  width: 300px;
  margin-right: 10px;
`
