import { ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { Observable, of } from 'rxjs'
import { map, switchMap, mergeMap, mapTo, pluck } from 'rxjs/operators'
import {
  get,
  catchRestError,
  post,
  optionsRequest,
  putRequest,
} from '../../utils/http'
import { liftPayload } from '../../utils/rx-operators'
import RestfulResult from '../../utils/RestfulResult'
import { injectCurrentSitePath } from '../../utils/general'
import { actions } from './state'
import { ENDPOINTS } from './constants'
import {
  IAdjustLedgerParams,
  IAdjustLedgerResponse,
  ILedgerRequestParams,
} from './interfaces'

export const requestLedgerSchemaEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestLedgerSchema),
    switchMap(action =>
      of(action).pipe(
        optionsRequest(
          injectCurrentSitePath(ENDPOINTS.BASE, state$.value),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(mapTo(actions.requestLedgerSchemaSuccess(p))),
          ),
        ),
        catchRestError(actions.requestLedgerSchema.toString()),
      ),
    ),
  )

export const requestLedgerAdhocSchemaEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestLedgerAdhocSchema),
    switchMap(action =>
      of(action).pipe(
        optionsRequest(
          injectCurrentSitePath(ENDPOINTS.LEDGER_ADHOC, state$.value),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(mapTo(actions.requestLedgerAdhocSchemaSuccess(p))),
          ),
        ),
        catchRestError(actions.requestLedgerAdhocSchema.toString()),
      ),
    ),
  )

export const requestLedgerEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestLedger),
    liftPayload(),
    switchMap((payload: ILedgerRequestParams) =>
      of(payload).pipe(
        map(params => ({ params })),
        get(injectCurrentSitePath(ENDPOINTS.BASE, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestLedgerSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestLedger.toString()),
      ),
    ),
  )

export const requestLedgerEntry = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestEntry),
    liftPayload(),
    switchMap((payload: any) =>
      of(payload).pipe(
        map(urlReplacements => ({ urlReplacements })),
        get(injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestEntrySuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestLedger.toString()),
      ),
    ),
  )

export const adjustLedger = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.adjustLedger),
    liftPayload(),
    switchMap((payload: IAdjustLedgerParams) =>
      of(payload).pipe(
        map(body => ({ body })),
        post(
          injectCurrentSitePath(ENDPOINTS.ADJUST_LEDGER, state$.value),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.adjustLedgerSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.adjustLedgerSuccess.toString()),
      ),
    ),
  )

export const adjustLedgerAdHocEntry = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.adjustLedgerAdHocEntry),
    pluck('payload'),
    switchMap((payload: { id: number; title: string }) =>
      of(payload).pipe(
        map(body => ({ body, urlReplacements: { id: body.id } })),
        putRequest(
          injectCurrentSitePath(ENDPOINTS.LEDGER_ADHOC_ENTITY, state$.value),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(
                actions.adjustLedgerAdHocEntrySuccess(p, response.getContext()),
              ),
            ),
          ),
        ),
        catchRestError(actions.adjustLedgerAdHocEntry.toString()),
      ),
    ),
  )

export const requestAggregate = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestAggregate),
    liftPayload(),
    switchMap((payload: any) =>
      of(payload).pipe(
        map((params: any) => ({ params })),
        get(injectCurrentSitePath(ENDPOINTS.BASE, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(
                actions.requestAggregateSuccess(
                  { ...p, status: payload.status },
                  response.getContext(),
                ),
              ),
            ),
          ),
        ),
        catchRestError(actions.requestAggregate.toString()),
      ),
    ),
  )

// @todo update per_page when pagination is in use.
export const refetchLedgerOnceAdjusted = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.adjustLedgerSuccess),
    liftPayload(),
    map((payload: IAdjustLedgerResponse) =>
      actions.requestLedger({
        channels: payload.channels,
        status: 'pending',
        order: 'asc',
        per_page: 200,
      }),
    ),
  )

export const refetchLedgerOnceEntryIsAdjusted = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.adjustLedgerAdHocEntrySuccess),
    liftPayload(),
    map((payload: IAdjustLedgerResponse) =>
      actions.requestLedger({
        channels: payload.channels,
        status: 'pending',
        order: 'asc',
        per_page: 100,
        page: 1,
      }),
    ),
  )
