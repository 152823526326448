import { useQueryParam, NumberParam } from 'use-query-params'

/**
 * Usage:
 * const [channel, setChannel] = useChannel()
 * setChannel(123)
 * console.log(channel) => 123
 */
export default function useChannelFilter() {
  return useQueryParam('channel', NumberParam)
}
