import { Box } from 'grommet'
import React from 'react'
import * as yup from 'yup'
import { Form, withFormik, FormikBag, FormikProps, FastField } from 'formik'
import { Text, Modal, FileInput, Button } from '../../'
import { colors } from '../../../styles/variables'
import styled from 'styled-components'
import { useCallback } from 'react'
import Loader from '../../atoms/Loader/Loader'
import { get } from 'lodash'

interface IFormValues {
  csv: any
}
interface IUploadCSVModalProps {
  className?: string
  isOpen: boolean
  initialValues: IFormValues
  onClose?(): void
  onSubmit(values: IFormValues): void
  acceptedFileTypes?: [string]
  isLoading: boolean
}

const validationSchema = yup.object().shape({
  csv: yup
    .mixed()
    .test('csv', 'Invalid file type, CSV required.', function (val) {
      return get(val, 'type') === 'text/csv'
    })
    .required('CSV Required.'),
})

const UploadCSVModal = (
  props: IUploadCSVModalProps & FormikProps<IFormValues>,
) => {
  const {
    className,
    isOpen,
    onClose,
    isLoading,
    errors,
    setFieldValue,
    isValid,
  } = props

  const handleChange = useCallback(
    (files: any) => {
      if (files) {
        setFieldValue('csv', files[0])
      } else {
        setFieldValue('csv', null)
      }
    },
    [setFieldValue],
  )

  return (
    <Modal
      isOpen={isOpen}
      showCloseIcon={true}
      onClose={onClose}
      InnerComponent={
        <div>
          <Box pad="medium" className={className}>
            {isLoading ? (
              <Loader text="Redirecting..." />
            ) : (
              <Form className="form">
                <Text tag="h3" color={colors.blue.dark}>
                  Upload
                </Text>
                <FastField
                  component={FileInput}
                  name="csv"
                  error={errors?.csv}
                  onChange={handleChange}
                />
                <footer>
                  <Button
                    disabled={!isValid}
                    styleType="secondary"
                    label="Import"
                    size="small"
                    type="submit"
                  />
                </footer>
              </Form>
            )}
          </Box>
        </div>
      }
    />
  )
}

const StyledUploadCSVModal = styled(UploadCSVModal)`
  min-width: 400px;
  h3 {
    margin: 0 0 20px 0;
    border-bottom: 1px solid ${colors.grey.lighter};
  }
  .field {
    margin-bottom: 10px;
    position: relative;
  }
  .field label {
    margin-right: 30px;
    min-width: 50px;
  }
  footer {
    border-top: 1px solid ${colors.grey.lighter};
    text-align: right;
    margin-top: 20px;
    padding-top: 10px;
  }
  footer button {
    font-size: 1rem;
    padding: 6px 8px;
  }
  .field .error {
    display: block;
    position: absolute;
    right: 0;
    top: 30px;
    bottom: 30px;
    font-size: 12px;
  }
`

export default withFormik<IUploadCSVModalProps, IFormValues>({
  enableReinitialize: true,
  validationSchema,
  validateOnBlur: true,
  mapPropsToValues: (props: any) => props.initialValues,
  handleSubmit: (
    values: IFormValues,
    bag: FormikBag<IUploadCSVModalProps, any>,
  ) => {
    bag.props.onSubmit(values)
  },
})(StyledUploadCSVModal)
