import { ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { Observable } from 'rxjs'
import { map, ignoreElements, mergeMap, pluck } from 'rxjs/operators'
import * as actions from './actions'
import { getCurrentFilter, getCurrentChannel } from './selectors'
import { bookingActions } from '../../bookings'
import { perPage } from './constants'
import { transactionsActions, transactionsInterfaces } from '../../transactions'
import { IUIBookingsModel } from './interfaces'
import { get as _get } from 'lodash'
import { liftPayload } from '../../../utils/rx-operators'
import { sitesSelectors } from '../../sites'
import { redirect } from '../../../../utils/router'
import { commentActions } from '../../comments/state'
import { CommentEntity, CommentType } from '../../comments/interfaces'

export const handleBookingDelete = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(bookingActions.requestDeleteBookingSuccess),
    map(() => sitesSelectors.getCurrentSitePath(state$.value)),
    map((path: string) => redirect(`/${path}/bookings`)),
    ignoreElements(),
  )

export const handleBookingCreationSuccess = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(bookingActions.requestCreateBookingSuccess),
    map(action => {
      const path = sitesSelectors.getCurrentSitePath(state$.value)
      redirect(`/${path}/bookings/${action.payload.id}`)
      // redirect(`/${path}/bookings`);
    }),
    ignoreElements(),
  )

export const fetchDataOnPageChange = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.setCurrentPage),
    map(action => {
      const currentFilter = getCurrentFilter(state$.value)
      const currentChannel = getCurrentChannel(state$.value)
      let params: IUIBookingsModel = { per_page: perPage, page: action.payload }
      if (currentChannel) {
        params.channels = currentChannel
      }
      if (currentFilter) {
        params = {
          ...params,
          ...JSON.parse(currentFilter),
        }
      }
      return bookingActions.requestBookings(params)
    }),
  )

export const fetchBookingTransactionPayments = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(bookingActions.requestBookingSuccess),
    map(action =>
      transactionsActions.requestTransactionsWithPayments({
        include: action.payload.transaction_ids.join(','),
      }),
    ),
  )

export const fetchBookingsOnChannelChange = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.setChannel),
    liftPayload(),
    map(action => {
      const currentFilter = getCurrentFilter(state$.value)
      const channels = _get(action, 'id', null)
      let params: IUIBookingsModel = { per_page: perPage, page: 1 }
      if (channels) {
        params.channels = channels
      }
      if (currentFilter) {
        params = {
          ...params,
          ...JSON.parse(currentFilter),
        }
      }
      return bookingActions.requestBookings(params)
    }),
  )

export const refetchBookingsOnCreateTransactionSuccess = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(transactionsActions.requestCreateTransactionSuccess),
    liftPayload(),
    mergeMap(payload =>
      payload.bookings.map(
        (booking: transactionsInterfaces.ITransactionBooking) =>
          bookingActions.requestBooking({ id: booking.id }),
      ),
    ),
  )

export const refreshChangelogOnBookingUpdate = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(bookingActions.requestUpdateBookingSuccess),
    pluck('payload'),
    map(payload =>
      commentActions.requestComments(
        { type: CommentType.Readonly },
        {
          entity: CommentEntity.Bookings,
          entityId: payload.id,
          urlReplacements: { id: payload.id },
        },
      ),
    ),
  )
