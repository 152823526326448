import * as React from 'react'
import { get, isEmpty } from 'lodash'
import { IBookingResponse } from '../../../../state/modules/bookings/interfaces'
import { Accordion, Button, Changelog, Comments, SingleTemplate } from '../..'
import TransactionsPanel from './TransactionsPanel'
import { ITransaction } from '../../../../state/modules/transactions/interfaces'
import { generatePaymentRequestToken } from '../../../../utils/paymentRequestApp'
import {
  IBookingTransactionTableData,
  IBookingPaymentsTableData,
} from './interfaces'
import PaymentsPanel from './PaymentsPanel'
import { IChannelResponse } from '../../../../state/modules/channels/interfaces'
import {
  CommentType,
  IAddCommentRequest,
  IComment,
} from '../../../../state/modules/comments/interfaces'
import { formatInTimeZone } from 'date-fns-tz'
import { Helmet } from 'react-helmet'
import { IFormValues as ICommentFormValues } from '../../organisms/Comments/CommentsForm'
import BookingForm from './BookingForm'

interface ISingleBookingPageProps {
  isAdmin: boolean
  bookingId: string
  booking: IBookingResponse
  bookingChannel: IChannelResponse
  bookingChannelForexFeed: any /** @todo pdate type */
  channelAccountMode: boolean | string
  bookingTransactions: ITransaction[]
  bookingTransactionsTableData: IBookingTransactionTableData[]
  bookingPaymentsTableData: IBookingPaymentsTableData[]
  currentSiteName: string
  currentSitePath: string
  canUserCreateBookings: boolean
  isSiteProtectionOnly: boolean
  className?: string
  internalComments: IComment[]
  externalComments: IComment[]
  readOnlyComments: IComment[]
  currentUserId: number
  getBooking(): void
  getBookingSchema(): void
  getTransactions(ids: number[]): void
  redirect(path: string): void
  getComments(id: string, type: CommentType): void
  addComment(id: string, body: IAddCommentRequest): void
  updateComment(
    transactionId: string,
    commentId: string,
    description: string,
  ): void
  deleteComment(transactionId: string, commentId: string): void
}

interface ISingleBookingPageState {
  isFetchingTransactions: boolean
  fetchingToken: boolean
  token: string
  isPayViaBankTransferModalOpen: boolean
}

class SingleBookingPage extends React.Component<
  ISingleBookingPageProps,
  ISingleBookingPageState
> {
  public state = {
    isFetchingTransactions: false,
    fetchingToken: false,
    token: '',
    isPayViaBankTransferModalOpen: false,
  }

  public componentDidMount() {
    const { isAdmin, bookingId, booking, getBooking, getComments } = this.props
    if (bookingId !== 'new') {
      getBooking()
      getComments(bookingId, CommentType.External)
      getComments(bookingId, CommentType.Readonly)
      if (isAdmin) {
        getComments(bookingId, CommentType.Internal)
      }
    }
  }

  public componentDidUpdate(prevProps: ISingleBookingPageProps) {
    const {
      booking,
      bookingTransactionsTableData,
      bookingId,
      currentSitePath,
    } = this.props
    if (booking !== null) {
      const { transaction_ids = [] } = booking
      if (
        booking !== null &&
        transaction_ids.length !== bookingTransactionsTableData.length &&
        !this.state.isFetchingTransactions
      ) {
        this.setState({ isFetchingTransactions: true })
      }
      if (
        booking.status !== 'paid' &&
        booking.status !== 'locked' &&
        this.state.token === '' &&
        !this.state.fetchingToken &&
        this.props.canUserCreateBookings
      ) {
        this.setState({ fetchingToken: true })
        generatePaymentRequestToken(bookingId, booking.date, currentSitePath)
          .then(responseToken => {
            const token = responseToken ? responseToken : ''
            this.setState({ token, fetchingToken: false })
          })
          .catch(err => {
            // console.log(`Error: ${err}`);
          })
      }
    }
  }

  public handleAddComment = (
    type: CommentType,
    values: { comment: string },
  ) => {
    this.props.addComment(this.props.bookingId, {
      type,
      description: values.comment,
    })
  }

  public handleCommentUpdated = (id: number, values: ICommentFormValues) => {
    this.props.updateComment(
      this.props.bookingId,
      id.toString(),
      values.comment,
    )
  }

  public handleCommentDeleted = (id: number) => {
    this.props.deleteComment(this.props.bookingId, id.toString())
  }

  public transformCommentsForComponent = (comments: IComment[]) => {
    return comments.map(comment => ({
      id: comment.id,
      time: formatInTimeZone(
        new Date(comment.created_at),
        'GMT',
        'yyyy-LL-dd HH:mm:ss',
      ),
      user: comment.user ? comment.user.name : '',
      comment: comment.description,
      canEdit: comment.user && this.props.currentUserId === comment.user.id,
    }))
  }

  public getAccordions = () => {
    const {
      redirect,
      currentSitePath,
      bookingTransactionsTableData,
      bookingPaymentsTableData,
      externalComments,
      readOnlyComments,
      isAdmin,
      internalComments,
      bookingId,
    } = this.props
    const panels = []
    const redirectToTransaction = (id: string | number) => {
      redirect(`/${currentSitePath}/transactions/${id}`)
    }
    if (bookingTransactionsTableData.length !== 0) {
      panels.push({
        title: 'Transactions',
        Content: (
          <TransactionsPanel
            data={bookingTransactionsTableData}
            redirectToTransaction={redirectToTransaction}
          />
        ),
      })
      panels.push({
        title: 'Credits & Debits',
        Content: <PaymentsPanel data={bookingPaymentsTableData} />,
      })
    }
    if (bookingId !== 'new') {
      panels.push({
        title: 'Changelog',
        Content: (
          <>
            <Changelog
              className="changelog-list"
              comments={this.transformCommentsForComponent(readOnlyComments)}
            />
          </>
        ),
      })
      panels.push({
        title: 'Public Comments',
        Content: (
          <>
            <Comments
              className="public-comments-list"
              comments={this.transformCommentsForComponent(externalComments)}
              commentType={CommentType.External}
              onNewCommentAdded={values =>
                this.handleAddComment(CommentType.External, values)
              }
              onCommentUpdated={this.handleCommentUpdated}
              onCommentDeleted={this.handleCommentDeleted}
            />
          </>
        ),
      })
      if (isAdmin) {
        panels.push({
          title: 'Private Comments',
          Content: (
            <>
              <Comments
                className="private-comments-list"
                comments={this.transformCommentsForComponent(internalComments)}
                commentType={CommentType.Internal}
                onNewCommentAdded={values =>
                  this.handleAddComment(CommentType.Internal, values)
                }
                onCommentUpdated={this.handleCommentUpdated}
                onCommentDeleted={this.handleCommentDeleted}
              />
            </>
          ),
        })
      }
    }
    return panels
  }

  public render(): JSX.Element | null {
    const {
      bookingId,
      booking,
      bookingChannel,
      bookingChannelForexFeed,
      currentSiteName,
    } = this.props
    return (
      <SingleTemplate currentSiteName={currentSiteName}>
        <Helmet>
          <title>{`Trust My Travel | Bookings | ${booking?.title}`}</title>
        </Helmet>
        <BookingForm bookingId={bookingId} token={this.state.token} />
        <Accordion accordions={this.getAccordions()} />
      </SingleTemplate>
    )
  }
}

export default SingleBookingPage
