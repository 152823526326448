import * as React from 'react'
import styled from 'styled-components'
import { get, first } from 'lodash'
import { useEffectOnce } from 'react-use'
import { useSelector } from 'react-redux'
import SearchFilter from '../../organisms/SearchFilter/SearchFilter'
import { TextFilter, SelectFilter } from '../../organisms/SearchFilter/types'
import countries from '../../../../constants/countries'
import useFilter from '../../../../hooks/useFilter'
import usePagination from '../../../../hooks/usePagination'
import { supplierSelectors } from '../../../../state/modules/suppliers'

interface ISuppliersSearchFiltersProps {
  className?: string
}

const SuppliersSearchFilters = (
  props: ISuppliersSearchFiltersProps,
): JSX.Element => {
  const { setCurrentPage } = usePagination()

  const {
    filter,
    activeFilter,
    setActiveFilter,
    activeFilterValue,
    setActiveFilterValue,
  } = useFilter()

  const supplierCategories = useSelector(
    supplierSelectors.getSupplierCategoriesForSelectFilter,
  )

  const filters = [
    {
      value: 'include',
      label: 'Code',
      type: 'text' as TextFilter,
      onChange: setActiveFilterValue,
    },
    {
      value: 'categories',
      label: 'Category',
      type: 'select' as SelectFilter,
      options: [
        { value: 'all', label: 'All Categories' },
        ...supplierCategories,
      ],
      onChange: setActiveFilterValue,
    },
    {
      value: 'name',
      label: 'Name',
      type: 'text' as TextFilter,
      onChange: setActiveFilterValue,
    },
    {
      value: 'countries',
      label: 'Country',
      type: 'select' as SelectFilter,
      options: [{ value: 'all', label: 'All Countries' }, ...countries],
      onChange: setActiveFilterValue,
    },
  ]

  const firstFilter = first(filters)
  const _activeFilter = activeFilter || get(firstFilter, 'value', '')

  /**
   * Set default active filter and value
   * If filter in query params, set that.
   * Else set first filter in array above as active filter
   */
  useEffectOnce(() => {
    const firstFilterKey =
      (filter && first(Object.keys(filter))) || get(filters, [0, 'value'], '')
    const firstFilterValue = (filter && first(Object.values(filter))) || ''
    setActiveFilter(firstFilterKey)
    setActiveFilterValue(firstFilterValue)
  })

  /**
   * Wrap `setActiveFilterValue` so we can reset pagination as well.
   */
  const _setActiveFilterValue = (val: any) => {
    setActiveFilterValue(val)
    setCurrentPage(1)
  }

  return (
    <div className={props.className}>
      <SearchFilter
        filters={filters}
        activeFilter={_activeFilter}
        setFilter={setActiveFilter}
        activeFilterValue={activeFilterValue}
        setActiveFilterValue={_setActiveFilterValue}
      />
    </div>
  )
}

export default styled(SuppliersSearchFilters)`
  display: flex;
  min-height: 44px;
  justify-content: space-between;
  margin: 20px 0;

  > div:last-child {
    width: auto;
  }
`
