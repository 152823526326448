import React, { FC } from 'react'
import ConfirmationModal from '../../views/components/organisms/ConfirmationModal/ConfirmationModal'

type Props = {
  isOpen: boolean
  title: string
  text: string
  onClose: () => void
  onRevoke: () => void
}

const ApiTokens: FC<Props> = ({ isOpen, title, text, onClose, onRevoke }) => {
  return (
    <ConfirmationModal
      isOpen={isOpen}
      showCloseIcon={false}
      onClose={onClose}
      title={title}
      onConfirm={onRevoke}
    >
      <p>{text}</p>
    </ConfirmationModal>
  )
}

export default ApiTokens
