import { IUIModel } from './interfaces'
import uiAppModel from './app/model'
import uiBookingsModel from './bookings/model'
import uiChannelsModel from './channels/model'
import uiNotificationsModel from './notifications/model'
import uiStatementsModel from './statements/model'
import uiTransactionsModel from './transactions/model'
import uiSitesModel from './sites/model'
import uiGlobalSearchModel from './globalsearch/model'
import uiPaymentsModel from './payments/model'

export const uiModel: IUIModel = {
  app: uiAppModel,
  bookings: uiBookingsModel,
  channels: uiChannelsModel,
  notifications: uiNotificationsModel,
  statements: uiStatementsModel,
  transactions: uiTransactionsModel,
  sites: uiSitesModel,
  globalsearch: uiGlobalSearchModel,
  payments: uiPaymentsModel,
}
