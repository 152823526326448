import { ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { AnyAction } from 'redux'
import { switchMap, mergeMap, mapTo } from 'rxjs/operators'
import RestfulResult from '../../utils/RestfulResult'
import * as actions from './actions'
import { catchRestError, get } from '../../utils/http'
import { ENDPOINTS } from './constants'
import { injectCurrentSitePath } from '../../utils/general'

export const requestCurrenciesEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestCurrencyData),
    switchMap((payload: any) =>
      of(payload).pipe(
        get(injectCurrentSitePath(ENDPOINTS.BASE, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(
                actions.requestCurrencyDataSuccess(p, response.getContext()),
              ),
            ),
          ),
        ),
        catchRestError(actions.requestCurrencyData.toString()),
      ),
    ),
  )
