import { IUIChannelsModel } from './interfaces'

const uiChannelsModel: IUIChannelsModel = {
  isFetching: false,
  isSaving: false,
  isCreating: false,
  isDeleting: false,
  currentPage: 1,
  currentFilter: '',
  currentChannel: null,
  serverErrors: null,
}

export default uiChannelsModel
