import { API_SITE_URL } from '../../constants'

export const NAMESPACE = 'sites'

const baseUrl = `${API_SITE_URL}/sites`

export const ENDPOINTS = {
  BASE: baseUrl,
  MEMBER_BASE: `${API_SITE_URL}/sites/`,
  ENTITY: `${baseUrl}/:id`,
  MEMBER_ENTITY: `${API_SITE_URL}/sites/:id`,
}
