import * as React from 'react'
import { FormikProps, Form, Field, withFormik } from 'formik'
import { omit } from 'lodash'
import classNames from 'classnames'
import * as yup from 'yup'
import {
  ITransaction,
  ICreateTransaction,
  IUpdateTransaction,
  TransactionTypes,
} from '../../../../../state/modules/transactions/interfaces'
import {
  Text,
  TextInput,
  Button,
  Loader,
  CurrencyInput,
  FormField,
  Select,
} from '../../..'
import moment, { Moment } from 'moment'
import SingleDatePicker from '../../../molecules/SingleDatePicker/SingleDatePicker'
import { colors } from '../../../../styles/variables'
import styled from 'styled-components'
import { addTrailingZero, convertToCents } from '../../../../../utils/currency'

interface IChargebackFormProps {
  transaction: ITransaction
  className?: string
  isApplyingChargeback: boolean
  chargebackReasons: string[]
  chargebackReasonDefault: string
  chargebackStatusOptions: string[]
  chargebackStatusDefault: string
  chargebackOutcomeOptions: string[]
  chargebackOutcomeDefault: string
  isScreenSmall: boolean
  createTransaction(payload: ICreateTransaction): void
  updateTransaction(id: number, payload: IUpdateTransaction): void
  formatCurrency(value: number | string, currencies: string): string
  getCurrencySymbol(currencyName: string): string
}

interface IFormValues {
  total: string
  arn_number: string
  channels: number
  bookings: any[]
  currencies: string
  transaction_types: string
  linked_id: number
  reason_code: string
  chargeback_status: string
  outcome_status: string
  chargeback_date: Moment | null
  challenge_date: Moment | null
}

const validationSchema = yup.object().shape({
  total: yup.number().required('Please add a chargeback amount!'),
  // need a normal function to maintain lexical scope for acccess to parent object.
  // tslint:disable-next-line:only-arrow-functions
  // @todo
  /*.test('is-valid', 'Cannot exceed total', function(val) {
            if (parseFloat(val) * 100 > this.parent.total) {
                return this.createError({ path: this.path, message: 'Cannot exceed total' });
            }
            return true;
        })*/
})

const ChargebackForm = (
  props: IChargebackFormProps & FormikProps<IFormValues>,
) => {
  const {
    handleChange,
    setFieldValue,
    values,
    touched,
    errors,
    isScreenSmall,
    isApplyingChargeback,
    chargebackReasons,
    chargebackStatusOptions,
    chargebackOutcomeOptions,
    transaction,
    getCurrencySymbol,
  } = props
  const classes = classNames(props.className, {
    'is-submitting': isApplyingChargeback,
  })

  const isChargeback = transaction.transaction_types === 'chargeback'

  const handleCurrencyChange = (e: any) =>
    setFieldValue('total', e.target.value)
  const startDate = moment().add('-30', 'd')
  const handleDateOfChargebackChange = (date: Moment) =>
    props.setFieldValue('chargeback_date', date)
  const handleChallengeByChange = (date: Moment) =>
    props.setFieldValue('challenge_date', date)
  const handleSelectChange = (val: string, name: string) =>
    setFieldValue(name, val)

  return (
    <div className={classes}>
      {isApplyingChargeback && (
        <Loader
          text={`${isChargeback ? 'Updating' : 'Applying'} chargeback...`}
        />
      )}
      <Form className="form" id="chargeback-form">
        <Text tag="h3" color={colors.blue.dark}>
          Chargeback
        </Text>
        <CurrencyInput
          prefix={getCurrencySymbol(transaction.currencies)}
          name="total"
          disabled={isChargeback}
          onChange={handleCurrencyChange}
          className="field"
          label="Chargeback"
          value={values.total}
          error={errors.total}
        />
        <Field
          component={TextInput}
          className="field"
          id="arn_number"
          name="arn_number"
          onChange={handleChange}
          value={values.arn_number}
          label="ARN"
          error={touched.arn_number && errors.arn_number}
        />
        <Field
          component={Select}
          className="field select-control"
          id="reason_code"
          name="reason_code"
          options={chargebackReasons}
          onChange={(val: string) => handleSelectChange(val, 'reason_code')}
          value={values.reason_code}
          label="Reason"
          error={touched.reason_code && errors.reason_code}
        />
        <Field
          component={Select}
          className="field select-control"
          id="chargeback_status"
          name="chargeback_status"
          options={chargebackStatusOptions}
          onChange={(val: string) =>
            handleSelectChange(val, 'chargeback_status')
          }
          value={values.chargeback_status}
          label="Status"
          error={touched.chargeback_status && errors.chargeback_status}
        />
        <Field
          component={Select}
          className="field select-control"
          id="outcome_status"
          name="outcome_status"
          options={chargebackOutcomeOptions}
          onChange={(val: string) => handleSelectChange(val, 'outcome_status')}
          value={values.outcome_status}
          label="Outcome"
          error={touched.outcome_status && errors.outcome_status}
        />
        <FormField
          label="Date of Chargeback"
          className="date-picker field"
          error={touched.chargeback_date && errors.chargeback_date}
        >
          <Field
            component={SingleDatePicker}
            id="chargeback_date"
            name="chargeback_date"
            onDateChange={handleDateOfChargebackChange}
            date={values.chargeback_date}
            label="Date of Chargeback"
            isOutsideRange={(d: Moment) => d.isBefore(startDate)}
            withPortal={isScreenSmall}
          />
        </FormField>
        <FormField
          label="Challenge by"
          className="date-picker field"
          error={touched.challenge_date && errors.challenge_date}
        >
          <Field
            component={SingleDatePicker}
            id="challenge_date"
            name="challenge_date"
            onDateChange={handleChallengeByChange}
            date={values.challenge_date}
            label="Challenge by"
            isOutsideRange={(d: Moment) => d.isBefore(startDate)}
            withPortal={isScreenSmall}
          />
        </FormField>
        <footer>
          <Button
            styleType="secondary"
            label="Apply"
            size="small"
            type="submit"
          />
        </footer>
      </Form>
    </div>
  )
}

const StyledChargebackForm = styled(ChargebackForm)`
  margin: 10px;
  &.is-submitting {
    form {
      display: none;
    }
  }
  h3 {
    margin: 0 0 10px 0;
    border-bottom: 1px solid ${colors.grey.lighter};
  }
  .field {
    margin-bottom: 10px;
  }
  .field label {
    margin-right: 20px;
    width: 150px;
  }
  .currency-input-container label {
    margin-right: 29px;
  }
  .select-control {
    button {
      width: 100%;
    }
  }
  #code {
    border: 1px solid #063e5a;
  }
  footer {
    border-top: 1px solid ${colors.grey.lighter};
    text-align: right;
    margin-top: 10px;
    padding-top: 10px;
  }
  footer button {
    font-size: 1rem;
    padding: 6px 8px;
  }
  .field .error {
    display: block;
    position: absolute;
    right: 10px;
    top: 150px;
    bottom: 30px;
    font-size: 12px;
  }
  #content::placeholder {
    font-size: 14px;
    font-weight: normal;
  }
`

export default withFormik<IChargebackFormProps, IFormValues>({
  validationSchema,
  validateOnBlur: true,
  validateOnChange: true,
  mapPropsToValues: (props: IChargebackFormProps) => {
    const {
      transaction,
      chargebackReasonDefault,
      chargebackStatusDefault,
      chargebackOutcomeDefault,
    } = props

    const { id, channels, bookings, currencies, total, transaction_types } =
      transaction

    const isChargeback = transaction_types === 'chargeback'

    return {
      channels,
      bookings: bookings.map(b => omit(b, ['reference'])),
      currencies,
      transaction_types: 'chargeback',
      linked_id: id,
      total: addTrailingZero(total / 100),
      arn_number: transaction?.arn_number || '',
      reason_code: isChargeback
        ? transaction?.reason_code || ''
        : chargebackReasonDefault,
      chargeback_status: isChargeback
        ? transaction?.chargeback_status || ''
        : chargebackStatusDefault,
      outcome_status: isChargeback
        ? transaction?.outcome_status || ''
        : chargebackOutcomeDefault,
      chargeback_date: isChargeback
        ? moment(transaction?.chargeback_date || Date.now())
        : moment(),
      challenge_date: isChargeback
        ? moment(transaction?.challenge_date || Date.now())
        : null,
    }
  },
  handleSubmit: (values: IFormValues, { props }) => {
    const { transaction, createTransaction, updateTransaction } = props
    const total = convertToCents(values.total)
    const payload: ICreateTransaction = {
      total,
      channels: values.channels,
      bookings: values.bookings.map((booking: any) => ({
        ...booking,
        total: transaction.forex_rate
          ? convertToCents(
              parseFloat(values.total) / parseFloat(transaction.forex_rate),
            )
          : total,
      })),
      currencies: values.currencies,
      transaction_types: values.transaction_types as TransactionTypes,
      linked_id: values.linked_id,
      arn_number: values.arn_number,
      chargeback_status: values.chargeback_status,
      outcome_status: values.outcome_status,
      reason_code: values.reason_code,
      challenge_date: values.challenge_date
        ? values.challenge_date.format('YYYY-MM-DD')
        : undefined,
      chargeback_date: values.chargeback_date
        ? values.chargeback_date.format('YYYY-MM-DD')
        : undefined,
    }
    if (transaction.transaction_types === 'chargeback') {
      updateTransaction(transaction.id, payload)
    } else {
      createTransaction(payload)
    }
  },
})(StyledChargebackForm)
