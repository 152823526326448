import { handleActions } from 'redux-actions'
import { omit } from 'lodash'
import { AnyAction } from 'redux'
import * as actions from './actions'
import model from './model'
import { IGlobalSearchModel, IGlobalSearchResult } from './interfaces'
import { parseLinkHeader } from '../../utils/http'

/** @todo move to util functions */
const getFiltersKey = (meta: any) => JSON.stringify(omit(meta.params, ['page']))

export default function globalSearchReducer(rootState: any) {
  return handleActions(
    {
      [actions.requestGlobalSearch]: (
        state: IGlobalSearchModel,
        action: AnyAction,
      ) => {
        return {
          ...state,
          isLoading: true,
        }
      },
      [actions.requestGlobalSearchSuccess]: (
        state: IGlobalSearchModel,
        action: AnyAction,
      ) => {
        const { payload, meta } = action
        return {
          ...state,
          isLoading: false,
          pages: parseLinkHeader(meta.responseHeaders?.link || ''),
          byId: {
            ...payload.reduce(
              (
                globalsearch: IGlobalSearchResult[],
                search: IGlobalSearchResult,
              ) => {
                globalsearch[search.id] = search
                return globalsearch
              },
              {},
            ),
          },
          allIds: payload.map(
            (globalsearch: IGlobalSearchResult) => globalsearch.id,
          ),
          byPage: {
            ...state.byPage,
            [action.meta.params.page]: payload.map(
              (globalsearch: IGlobalSearchResult) => globalsearch.id,
            ),
          },
          byFilters: {
            [getFiltersKey(meta)]: payload.map(
              (globalsearch: IGlobalSearchResult) => globalsearch.id,
            ),
          },
        }
      },
    },
    model,
  )
}
