import { handleActions } from 'redux-actions'
import * as actions from './actions'
import model from './model'
import { AnyAction } from 'redux'
import { IUIPaymentsModel } from './interfaces'
import { get as _get } from 'lodash'
import * as sitesActions from '../../sites/actions'

export default handleActions(
  {
    [actions.setCurrentPage]: (state: IUIPaymentsModel, action: AnyAction) => ({
      ...state,
      currentPage: action.payload,
    }),
    [actions.setCurrentFilter]: (
      state: IUIPaymentsModel,
      action: AnyAction,
    ) => ({
      ...state,
      currentFilter: action.payload,
    }),
    [actions.setChannel]: (state: IUIPaymentsModel, action: AnyAction) => {
      return {
        ...state,
        currentChannel: _get(action.payload, 'id', null),
      }
    },
    [sitesActions.siteSelected]: (state: IUIPaymentsModel) => {
      return {
        ...state,
        currentFilter: '',
        currentChannel: null,
      }
    },
  },
  model,
)
