import React, { FC, useState } from 'react'
import { Grommet, Table, TableBody, TableRow, TableCell, Box } from 'grommet'
import styled from 'styled-components'
import { useQueryClient } from '@tanstack/react-query'
import { isArray } from 'lodash'
import { format } from 'date-fns'
import {
  useFetchApiTokens,
  useRevokeAllApiTokens,
  useRevokeApiToken,
} from './data'
import timfinityTheme from '../../views/styles/timfinityTheme'
import { Button, Loader } from '../../views/components'
import { colors } from '../../views/styles/variables'
import RevokeTokenConfirmation from './RevokeTokenConfirmation'
import CreateApiTokenModal from './CreateApiTokenModal'

type Props = {
  className?: string
}

const ApiTokens: FC<Props> = props => {
  const [revokeConfirmation, setRevokeConfirmation] = useState<{
    id: number | null
  }>({ id: null })
  const [isRevokeAllConfirmationOpen, setIsRevokeAllConfirmationOpen] =
    useState(false)
  const [isCreateTokenModalOpen, setIsCreateTokenModalOpen] = useState(false)

  const queryClient = useQueryClient()

  const { data, isLoading, isError } = useFetchApiTokens()

  const onRevokeSuccess = () => {
    setRevokeConfirmation({ id: null })
  }

  const onRevokeMutate = () => {
    queryClient.setQueryData(['apiTokens'], (data: any) => {
      if (data) {
        return data.filter((token: any) => token.id !== revokeConfirmation.id)
      }
    })
  }

  const revokeTokenQuery = useRevokeApiToken({
    onMutate: onRevokeMutate,
    onSuccess: onRevokeSuccess,
  })

  const revokeAllTokensQuery = useRevokeAllApiTokens({
    onSuccess: () => {
      setIsRevokeAllConfirmationOpen(false)
    },
  })

  if (isError) {
    return (
      <Box pad="medium">
        <p className="error">
          Something went wrong while fetching your tokens.
        </p>
      </Box>
    )
  }

  return (
    <Grommet theme={timfinityTheme} className={props.className}>
      <div className="api-tokens-header">
        <p>Tokens you have generated that can be used to access the API.</p>
        <div>
          {isArray(data) && data.length < 2 && (
            <Button
              size="small"
              styleType="plain"
              label="Generate new token"
              onClick={() => setIsCreateTokenModalOpen(true)}
            />
          )}
          <Button
            size="small"
            styleType="danger"
            label="Revoke all tokens"
            disabled={!isArray(data) || data.length === 0}
            onClick={() => setIsRevokeAllConfirmationOpen(true)}
          />
        </div>
      </div>

      <Box pad="medium" height={'70vh'}>
        {isLoading ? (
          <Loader text="Fetching tokens..." />
        ) : (
          isArray(data) &&
          data.length > 0 && (
            <Table className="api-tokens-table">
              <TableBody>
                {data.map((token, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <strong>{token.name}</strong>
                      <br />
                      {!token.expires_at ? (
                        <small className="no-expiration">
                          This token has no expiration date.
                        </small>
                      ) : (
                        <small>
                          Expires on{' '}
                          <strong>
                            <em>
                              {format(
                                new Date(token.expires_at),
                                'EEE, MMM d yyyy',
                              )}
                            </em>
                          </strong>
                        </small>
                      )}
                    </TableCell>

                    <TableCell>
                      {token.last_used_at && (
                        <span>
                          Last used{' '}
                          <strong>
                            {format(
                              new Date(token.last_used_at),
                              'EEE, MMM d yyyy',
                            )}
                          </strong>
                        </span>
                      )}
                    </TableCell>

                    <TableCell className="actions">
                      <Button
                        size="small"
                        onClick={() => {
                          setRevokeConfirmation({ id: token.id })
                        }}
                        label="Revoke"
                        styleType="danger"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )
        )}
      </Box>

      <RevokeTokenConfirmation
        isOpen={revokeConfirmation.id !== null}
        title="Revoke token"
        text={`Are you sure you want to revoke this token?`}
        onClose={() => setRevokeConfirmation({ id: null })}
        onRevoke={() => {
          if (revokeConfirmation.id) {
            revokeTokenQuery.mutate(revokeConfirmation.id)
          }
        }}
      />

      <RevokeTokenConfirmation
        isOpen={isRevokeAllConfirmationOpen}
        title="Revoke all tokens"
        text="Are you sure you want to revoke all tokens?"
        onClose={() => setIsRevokeAllConfirmationOpen(false)}
        onRevoke={() => {
          revokeAllTokensQuery.mutate()
        }}
      />

      <CreateApiTokenModal
        isOpen={isCreateTokenModalOpen}
        onClose={() => setIsCreateTokenModalOpen(false)}
      />
    </Grommet>
  )
}

export default styled(ApiTokens)`
  .api-tokens-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;
      align-items: center;

      button {
        margin-left: 1rem;
      }
    }
  }

  .error {
    color: ${colors.red};
  }

  .no-expiration {
    color: ${colors.amber};
  }

  .api-tokens-table {
    width: 100%;
    margin-top: 20px;

    tr {
      border-top: ${colors.grey.light} 1px solid;
    }

    td {
      padding: 10px 16px;
    }

    .actions {
      text-align: right;
      width: 100px;
      padding-right: 0;
    }
  }

  p {
    margin-bottom: 1rem;
  }
`
