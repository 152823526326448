import { ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { Observable, of } from 'rxjs'
import { map, switchMap, mergeMap, mapTo } from 'rxjs/operators'
import { flatten } from 'lodash'
import * as actions from './actions'
import { ENDPOINTS } from './constants'
import { get, catchRestError, deleteRequest } from '../../utils/http'
import { formatTrustId, liftPayload } from '../../utils/rx-operators'
import RestfulResult from '../../utils/RestfulResult'
import { IRequestPaymentsParams } from './interfaces'
import { injectCurrentSitePath } from '../../utils/general'
import { transactionsActions, transactionsInterfaces } from '../transactions'

export const requestPaymentsEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestPayments),
    liftPayload(),
    switchMap((payload: IRequestPaymentsParams) =>
      of(payload).pipe(
        formatTrustId(),
        map(params => ({ params })),
        get(injectCurrentSitePath(ENDPOINTS.BASE, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestPaymentsSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestPayments.toString()),
      ),
    ),
  )

export const requestPaymentEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestPayment),
    liftPayload(),
    switchMap((payload: any) =>
      of(payload).pipe(
        map(urlReplacements => ({ urlReplacements })),
        get(injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestPaymentSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestPayment.toString()),
      ),
    ),
  )

export const deletePaymentEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.deletePayment),
    switchMap((action: any) =>
      of(action.payload).pipe(
        map(urlReplacements => ({ urlReplacements })),
        deleteRequest(
          injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mergeMap(() => [
                actions.deletePaymentSuccess(p, {
                  context: response.getContext(),
                  request: action.payload,
                }),
                transactionsActions.requestTransaction({
                  id: action.meta.transactionId,
                }),
              ]),
            ),
          ),
        ),
        catchRestError(actions.deletePayment.toString()),
      ),
    ),
  )

/**
 * Fetch payments from `transaction.payment_ids`
 */
export const requestPaymentsFromTransactions = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(transactionsActions.requestTransactionsWithPaymentsSuccess),
    liftPayload(),
    map((transactions: transactionsInterfaces.ITransaction[]) =>
      flatten(transactions.map(transaction => transaction.payment_ids)),
    ),
    map((ids: number[]) => actions.requestPayments({ include: ids.join(',') })),
  )
