import * as React from 'react'
import { Box } from 'grommet/components/Box'
import { Button, TextInput } from '../..'
import { ITextInputProps } from '../TextInput/TextInput'
import styled from 'styled-components'

interface IButtonFieldV2Props {
  onClickHandler(): void
  onChange(e: any): void
  className: string
  validateValue(value: any): string
}

interface IButtonFieldV2State {
  showFieldButton: boolean
  showField: boolean
  viewButtonClicked: boolean
  errors: string
}

const initialState: IButtonFieldV2State = {
  showFieldButton: true,
  showField: false,
  viewButtonClicked: false,
  errors: '',
}

class ButtonFieldV2 extends React.Component<
  IButtonFieldV2Props & ITextInputProps,
  IButtonFieldV2State
> {
  constructor(props: IButtonFieldV2Props & ITextInputProps) {
    super(props)
    this.state = {
      ...initialState,
    }
  }

  public renderFieldButton = () => {
    const { label, className } = this.props
    return this.state.showFieldButton ? (
      <Box direction="row" align="center" className={className}>
        <label>{label}</label>
        <div>
          <Button
            styleType="secondary"
            label={`Change ${label}`}
            size="small"
            onClick={() => {
              this.setState({ viewButtonClicked: true })
              this.setState({
                showFieldButton: !this.state.showFieldButton,
                showField: !this.state.showField,
                viewButtonClicked: !this.state.viewButtonClicked,
              })
            }}
          />
        </div>
      </Box>
    ) : null
  }

  public handleOnClick = () => {
    const { validateValue, onClickHandler, value } = this.props
    const errors = validateValue(value)
    this.setState({ errors })
    if (errors) {
      return
    }
    onClickHandler()
    this.resetView()
  }

  public getError = () => {
    const { errors } = this.state
    return errors && <span className="error">{errors}</span>
  }

  public displayEditAndCancelButtons = () => {
    return (
      <div className="button-field">
        <Button
          styleType="primary"
          label="Save"
          size="small"
          onClick={() => this.handleOnClick()}
        />
        <Button
          styleType="danger"
          label="Cancel"
          size="small"
          onClick={() => this.resetView()}
        />
      </div>
    )
  }

  public renderField = () => {
    const { className } = this.props
    return this.state.showField ? (
      <div className={className}>
        <TextInput {...this.props} className="button-field" />
        {this.displayEditAndCancelButtons()}
        {this.getError()}
      </div>
    ) : null
  }

  public resetView = () => {
    this.setState({
      ...initialState,
    })
  }

  public render() {
    return (
      <>
        {this.renderFieldButton()}
        {this.renderField()}
      </>
    )
  }
}

const StyledButtonFieldV2 = styled(ButtonFieldV2)`
  display: flex;
  .button-field {
    display: flex;
    label + div {
      width: 400px !important;
      min-width: 200px !important;
    }
    button {
      width: 88px;
      margin 0 0 0 12px;
    }
  }
`

export default StyledButtonFieldV2
