import { ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { Observable, of } from 'rxjs'
import { map, switchMap, mergeMap, mapTo } from 'rxjs/operators'
import * as actions from './actions'
import * as selectors from './selectors'
import { ENDPOINTS } from './constants'
import {
  get,
  post,
  putRequest,
  optionsRequest,
  deleteRequest,
  catchRestError,
} from '../../utils/http'
import { liftPayload } from '../../utils/rx-operators'
import RestfulResult from '../../utils/RestfulResult'
import { injectCurrentSitePath } from '../../utils/general'
import {
  IRequestChannelsParams,
  IRequestChannelsSchemaParams,
} from './interfaces'
import { perPage } from '../ui/channels/constants'
import { omit } from 'lodash'
import { channelSelectors } from '.'
import { buildRequestPayload } from '../../../modules/jsonForms/utils'

/**
 * Get Channels Schema when `actions.requestChannelsSchema` action is dispatched
 * @param action$ Action observable
 * @param state$ State observable
 */
export const requestChannelsSchema = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestChannelsSchema),
    liftPayload(),
    switchMap((payload: IRequestChannelsSchemaParams) =>
      of(payload).pipe(
        map(params => ({ params })),
        optionsRequest(
          injectCurrentSitePath(ENDPOINTS.BASE, state$.value),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(
                // TODO: remove this mock once ready.
                // The mock is hard coded to work on the `tmt-test` site on channel ID 3329
                // channelSchemaMock,
                actions.requestChannelsSchemaSuccess(p, response.getContext()),
              ),
            ),
          ),
        ),
        catchRestError(actions.requestChannelsSchema.toString()),
      ),
    ),
  )

/**
 * Get channels when `actions.requestChannels` action is dispatched.
 * @param action$ Action observable
 * @param state$ State obserable
 */
export const requestChannelsEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestChannels),
    liftPayload(),
    switchMap((payload: IRequestChannelsParams) =>
      of(payload).pipe(
        map(params => ({ params })),
        get(injectCurrentSitePath(ENDPOINTS.BASE, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestChannelsSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestChannels.toString()),
      ),
    ),
  )

export const fetchNextChannelsPage = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestNextChannelsPage),
    map(() => {
      const lastFetchedPageNumber: string | undefined =
        selectors.getLastFetchedPageNumber(state$.value)
      const nextPageNumber = lastFetchedPageNumber
        ? parseInt(lastFetchedPageNumber) + 1
        : 0
      return actions.requestChannels({
        per_page: perPage,
        page: nextPageNumber,
      })
    }),
  )

/**
 * Get a channel when `actions.requestChannel` action is dispatched.
 * @param action$ Action observable
 * @param state$ State observable
 */
export const requestChannelEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestChannel),
    liftPayload(),
    switchMap((payload: any) =>
      of(payload).pipe(
        map(urlReplacementsAndParams => ({ urlReplacementsAndParams })),
        get(injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestChannelSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestChannel.toString()),
      ),
    ),
  )

const sanitizeChannelPayload = (schema: any) => (source$: Observable<any>) =>
  source$.pipe(
    map((request: { body: any; urlReplacements: any }) => {
      const { body, urlReplacements } = request
      const payload = buildRequestPayload(
        omit(body, ['_global_gateway_key']),
        schema,
      )
      return {
        body: payload,
        urlReplacements,
      }
    }),
  )

/**
 * Create a channel when `actions.createChannel` action is dispatched.
 * @param action$ Action observable
 * @param state$ State observable
 */
export const createChannelEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.createChannel),
    liftPayload(),
    map(body => ({ body })),
    switchMap((payload: any) =>
      of(payload).pipe(
        sanitizeChannelPayload(
          channelSelectors.getChannelsSchema(state$.value),
        ),
        post(injectCurrentSitePath(ENDPOINTS.BASE, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.createChannelSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.createChannel.toString()),
      ),
    ),
  )

/**
 * Update a channel when `actions.updateChannel` action is dispatched.
 * @param action$ Action observable
 * @param state$ State observable
 */
export const updateChannelEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.updateChannel),
    liftPayload(),
    map(data => {
      const { channel_secret, ...body } = data
      return channel_secret === 'update'
        ? { body, urlReplacements: { id: body.id }, params: { channel_secret } }
        : { body, urlReplacements: { id: body.id } }
    }),
    switchMap((payload: any) =>
      of(payload).pipe(
        sanitizeChannelPayload(
          channelSelectors.getChannelsSchema(state$.value),
        ),
        putRequest(injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.updateChannelSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.updateChannel.toString()),
      ),
    ),
  )

/**
 * Update a channel when `actions.deleteChannel` action is dispatched.
 * @param action$ Action observable
 * @param state$ State observable
 */
export const deleteChannelEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.deleteChannel),
    liftPayload(),
    map(body => ({ body, urlReplacements: { id: body.id } })),
    switchMap((payload: any) =>
      of(payload).pipe(
        deleteRequest(
          injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.deleteChannelSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.deleteChannel.toString()),
      ),
    ),
  )
