import * as React from 'react'
import { useSelector } from 'react-redux'
import usePagination from '../../../../hooks/usePagination'
import { transactionsSelectors } from '../../../../state/modules/transactions'
import { uiTransactionsSelectors } from '../../../../state/modules/ui/transactions'
import { DataTable, Anchor, StatusText } from '../..'
import { ITransactionRow } from './interfaces'
import { formatDate } from '../../../../utils/date'
import { redirect } from '../../../../utils/router'
import { sitesSelectors } from '../../../../state/modules/sites'
import TransactionsPageService from './TransactionsPageService'

interface IContentProps {}

const Content = (props: IContentProps): JSX.Element => {
  const { setCurrentPage, getCurrentPage } = usePagination()
  const pages: TPages = useSelector(transactionsSelectors.getPages)
  const currentPage: number = parseInt(getCurrentPage())
  const isLoadingTransactions: boolean = useSelector(
    transactionsSelectors.isLoading,
  )
  const transactionData: ITransactionRow[] = useSelector(
    uiTransactionsSelectors.getTransactionsTableData,
  )(currentPage)
  const currentSitePath: string = useSelector(sitesSelectors.getCurrentSitePath)
  const currentSite = useSelector(sitesSelectors.getCurrentSite)
  const isProtectionOnly = currentSite?.protection_only

  const handleNext = () => setCurrentPage(currentPage + 1)

  const handlePrevious = () => setCurrentPage(currentPage - 1)

  const columns = [
    {
      Header: 'Trust ID',
      accessor: 'trust_id',
      Cell: (row: any) => {
        const redirectTo = () =>
          redirect(`/${currentSitePath}/transactions/${row.original.id}`)
        return <Anchor onClick={redirectTo}>{row.value}</Anchor>
      },
      isVisible: true,
    },
    {
      Header: 'Created',
      accessor: 'created',
      Cell: (row: any) => formatDate(row.original.created, 'dd LLL yyyy HH:mm'),
      isVisible: true,
    },
    {
      Header: 'Surname',
      accessor: 'payee_surname',
      isVisible: true,
    },
    {
      Header: 'Email',
      accessor: 'payee_email',
      isVisible: true,
    },
    {
      Header: 'Reference',
      accessor: 'reference',
      isVisible: true,
    },
    {
      Header: 'Type',
      accessor: 'transaction_types',
      isVisible: true,
    },
    {
      Header: 'Card Type',
      accessor: 'card_type',
      Cell: (row: any) => {
        return <>{row.original.card_types}</>
      },
      isVisible: !isProtectionOnly,
    },
    {
      Header: 'Total',
      accessor: 'total',
      Cell: (row: any) => {
        return (
          <StatusText value={row.original.total} status={row.original.status} />
        )
      },
      isVisible: true,
    },
    {
      Header: 'Last Four',
      accessor: 'last_four_digits',
      isVisible: true,
    },
  ].filter((column: any) => column.isVisible)

  return (
    <>
      <TransactionsPageService />
      <DataTable
        manual={true}
        fitContainer={true}
        data={transactionData}
        columns={columns}
        loading={isLoadingTransactions}
        pageSize={transactionData.length}
        pages={pages.next ? currentPage + 1 : currentPage}
        page={currentPage}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />
    </>
  )
}

export default Content
