import { Box } from 'grommet'
import React, { useCallback, useEffect, useState } from 'react'
import Text from '../../atoms/Text/Text'
import Button from '../../atoms/Button/Button'
import useChannelForm from './useChannelForm'
import Ajv from 'ajv'
import { useSelector } from 'react-redux'
import { channelSelectors } from '../../../../state/modules/channels'
import { redirect } from '../../../../utils/router'
import { sitesSelectors } from '../../../../state/modules/sites'
import ConfirmationModal from '../../organisms/ConfirmationModal/ConfirmationModal'

type Props = {
  channelId: string
  ajv: Ajv
}

const SingleChannelHeader = ({ channelId, ajv }: Props) => {
  const {
    data,
    setData,
    isDirty,
    isValid,
    initialValues,
    isViewingChannel,
    isEditing,
    deleteChannel,
    setIsEditing,
    isDeletingChannel,
    wasDeletingChannel,
    handleSubmit,
  } = useChannelForm({
    channelId,
    ajv,
  })
  const name = data?.name
  const channelSchemaMethods = useSelector(
    channelSelectors.getChannelsSchemaMethods,
  )
  const currentSitePath = useSelector(sitesSelectors.getCurrentSitePath)
  const [isDeleteConfirmationActive, setIsDeleteConfirmationActive] =
    useState(false)

  const toggleEdit = (e?: any) => {
    if (e) {
      e.preventDefault()
    }
    setIsEditing(!isEditing)
  }

  useEffect(() => {
    if (wasDeletingChannel && !isDeletingChannel) {
      setIsDeleteConfirmationActive(false)
    }
  }, [isDeletingChannel, wasDeletingChannel])

  const handleCancel = useCallback(() => {
    if (isEditing) {
      setIsEditing(false)
      setData(initialValues)
    } else {
      redirect(`/${currentSitePath}/bookings`)
    }
  }, [isEditing, setIsEditing, setData, initialValues, currentSitePath])

  const toggleDeleteConfirmation = (e?: any) => {
    if (e) {
      e.preventDefault()
    }
    setIsDeleteConfirmationActive(!isDeleteConfirmationActive)
  }

  return (
    <>
      <Box
        direction="row"
        justify="between"
        align="center"
        className="section-header"
      >
        <Text tag="h2" color="white">
          {channelId !== 'new' ? name : 'Create Channel'}
        </Text>
        <Box direction="row" className="actions">
          {isViewingChannel && !isEditing ? (
            <>
              {channelSchemaMethods?.includes('PUT') && (
                <Button
                  styleType="secondary"
                  label="Edit"
                  size="small"
                  type="button"
                  onClick={toggleEdit}
                />
              )}
              {channelSchemaMethods?.includes('DELETE') && (
                <Button
                  styleType="danger"
                  label="Delete"
                  size="small"
                  onClick={toggleDeleteConfirmation}
                />
              )}
            </>
          ) : (
            <>
              <Button
                // disabled={!isValid || !isDirty}
                disabled={!isValid}
                styleType="primary"
                label="Save"
                size="small"
                type="button"
                onClick={handleSubmit}
              />
              <Button
                styleType="danger"
                label="Cancel"
                size="small"
                onClick={handleCancel}
              />
            </>
          )}
        </Box>
      </Box>
      <ConfirmationModal
        isOpen={isDeleteConfirmationActive}
        title="Delete Channel"
        showCloseIcon={true}
        onConfirm={deleteChannel}
        onClose={() => setIsDeleteConfirmationActive(false)}
      >
        <Text tag="p" color="#000">
          Are you sure you want to delete this channel?
        </Text>
      </ConfirmationModal>
    </>
  )
}

export default SingleChannelHeader
