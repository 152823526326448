import * as React from 'react'
import { useSelector } from 'react-redux'
import { DataTable, Anchor } from '../..'
import { IStatementRow } from './interfaces'
import usePagination from '../../../../hooks/usePagination'
import { statementSelectors } from '../../../../state/modules/statements'
import { uiStatementSelectors } from '../../../../state/modules/ui'
import { redirect } from '../../../../utils/router'
import { sitesSelectors } from '../../../../state/modules/sites'
import StatementsPageService from './StatementsPageService'

interface IContentProps {}

const Content = (props: IContentProps): JSX.Element => {
  const { getCurrentPage, setCurrentPage } = usePagination()
  const pages: TPages = useSelector(statementSelectors.getPages)
  const currentPage: number = parseInt(getCurrentPage())
  const isLoadingStatements: boolean = useSelector(statementSelectors.isLoading)
  const getStatementData: any = useSelector(
    uiStatementSelectors.getStatementsTableData,
  )
  const statementData: IStatementRow[] = getStatementData(currentPage)
  const currentSitePath: string = useSelector(sitesSelectors.getCurrentSitePath)

  const handleNext = () => setCurrentPage(currentPage + 1)

  const handlePrevious = () => setCurrentPage(currentPage - 1)

  const columns = [
    {
      Header: 'Date',
      accessor: 'statement_date',
    },
    {
      Header: 'Title',
      accessor: 'title',
      Cell: (row: any) => {
        const redirectTo = () =>
          redirect(`/${currentSitePath}/statements/${row.original.id}`)
        return <Anchor onClick={redirectTo}>{row.value}</Anchor>
      },
    },
    {
      Header: 'Amount',
      accessor: 'total',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
  ]

  return (
    <>
      <StatementsPageService />
      <DataTable
        manual={true}
        fitContainer={true}
        data={statementData}
        columns={columns}
        loading={isLoadingStatements}
        pageSize={statementData.length}
        pages={pages.next ? currentPage + 1 : currentPage}
        page={currentPage}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />
    </>
  )
}

export default Content
