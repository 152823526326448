import * as React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { get, first } from 'lodash'
import { useEffectOnce } from 'react-use'
import { useSelector } from 'react-redux'
import SearchFilter from '../../organisms/SearchFilter/SearchFilter'
import { SelectFilter, TextFilter } from '../../organisms/SearchFilter/types'
import DateRangeFilter from '../../organisms/DateRangeFilter/DateRangeFilter'
import countries from '../../../../constants/countries'
import useFilter from '../../../../hooks/useFilter'
import useDateFilter from '../../../../hooks/useDateFilter'
import { uiTransactionsSelectors } from '../../../../state/modules/ui/transactions'
import usePagination from '../../../../hooks/usePagination'

interface ITransactionSearchFiltersProps {
  className?: string
}
const TransactionSearchFilters = (
  props: ITransactionSearchFiltersProps,
): JSX.Element => {
  const { setDateFilters, dateFilters } = useDateFilter()
  const { setCurrentPage } = usePagination()

  const getDateFilters = () => [
    {
      label: 'Transaction Date',
      value: 'date',
    },
  ]

  const handleOnConfirm = (values: any) => {
    const { startDate, endDate, startTime, endTime } = values
    if (startDate && endDate && startTime && endTime) {
      setDateFilters({
        before: endDate.format('YYYY-MM-DD') + endTime.format(' H:mm:ss'),
        after: startDate.format('YYYY-MM-DD') + startTime.format(' H:mm:ss'),
      })
    } else if (startDate && endDate) {
      setDateFilters({
        before: endDate.format('YYYY-MM-DD 23:59:59'),
        after: startDate.format('YYYY-MM-DD 00:00:00'),
      })
    }

    if (!startDate && !endDate) {
      // resetDates();
    }
    setCurrentPage(1)
  }

  const {
    filter,
    activeFilter,
    setActiveFilter,
    activeFilterValue,
    setActiveFilterValue,
  } = useFilter()

  const transactionTypes: any = useSelector(
    uiTransactionsSelectors.getTransactionTypesForSearchFilter,
  )
  const statuses: any = useSelector(
    uiTransactionsSelectors.getStatusForSearchFilter,
  )
  const currencies: any = useSelector(
    uiTransactionsSelectors.getCurrenciesForSearchFilter,
  )

  const filters = [
    {
      value: 'include',
      label: 'Trust ID',
      type: 'text' as TextFilter,
      onChange: setActiveFilterValue,
    },
    {
      value: 'payee_surname',
      label: 'Payee Surname',
      type: 'text' as TextFilter,
      onChange: setActiveFilterValue,
    },
    {
      value: 'payee_email',
      label: 'Payee Email',
      type: 'text' as TextFilter,
      onChange: setActiveFilterValue,
    },
    {
      value: 'countries',
      label: 'Payee Country',
      type: 'select' as SelectFilter,
      options: [{ value: 'all', label: 'All Countries' }, ...countries],
      onChange: setActiveFilterValue,
    },
    {
      value: 'reference',
      label: 'Reference',
      type: 'text' as TextFilter,
      onChange: setActiveFilterValue,
    },
    {
      value: 'transaction_types',
      label: 'Transaction Type',
      type: 'select' as SelectFilter,
      onChange: setActiveFilterValue,
      options: [
        { value: 'all', label: 'All Transaction Types' },
        ...transactionTypes,
      ],
    },
    {
      value: 'currencies',
      label: 'Currency',
      type: 'select' as SelectFilter,
      onChange: setActiveFilterValue,
      options: [{ value: 'all', label: 'All currencies' }, ...currencies],
    },
    {
      value: 'bin_number',
      label: 'BIN Number',
      type: 'text' as TextFilter,
      onChange: setActiveFilterValue,
    },
    {
      value: 'last_four_digits',
      label: 'Last Four Digits',
      type: 'text' as TextFilter,
      onChange: setActiveFilterValue,
    },
    {
      value: 'status',
      label: 'Status',
      type: 'select' as SelectFilter,
      onChange: setActiveFilterValue,
      options: [{ value: 'all', label: 'All Statuses' }, ...statuses],
    },
  ]

  const firstFilter = first(filters)
  const _activeFilter = activeFilter || get(firstFilter, 'value', '')

  /**
   * Set default active filter and value
   * If filter in query params, set that.
   * Else set first filter in array above as active filter
   */
  useEffectOnce(() => {
    const firstFilterKey =
      (filter && first(Object.keys(filter))) || get(filters, [0, 'value'], '')
    const firstFilterValue = (filter && first(Object.values(filter))) || ''

    if (!activeFilter) {
      setActiveFilter(firstFilterKey)
    }
    if (!activeFilterValue) {
      setActiveFilterValue(firstFilterValue)
    }
  })

  /**
   * Wrap `setActiveFilterValue` so we can reset pagination as well.
   */
  const _setActiveFilterValue = (val: any) => {
    setActiveFilterValue(val)
    setCurrentPage(1)
  }

  const startDate = get(dateFilters, 'after', get(dateFilters, 'after'))
  const endDate = get(dateFilters, 'before', get(dateFilters, 'before'))
  const startTime = get(dateFilters, 'after', get(dateFilters, 'after'))
  const endTime = get(dateFilters, 'before', get(dateFilters, 'before'))
  const dateFilterExclusions = ['include', 'payee_email']
  const isDateFilterExclusionsActiveWithValue =
    dateFilterExclusions.includes(activeFilter) && activeFilterValue !== ''

  return (
    <div className={props.className}>
      <SearchFilter
        filters={filters}
        activeFilter={_activeFilter}
        setFilter={setActiveFilter}
        activeFilterValue={activeFilterValue}
        setActiveFilterValue={_setActiveFilterValue}
      />
      {startDate && endDate && (
        <DateRangeFilter
          plain={true}
          startDate={moment(startDate) || moment().subtract(2, 'weeks')}
          endDate={moment(endDate) || moment()}
          startTime={moment(startTime) || moment('00:00:00')}
          endTime={moment(endTime) || moment('23:59:59')}
          filters={getDateFilters()}
          onConfirm={handleOnConfirm}
          disabled={isDateFilterExclusionsActiveWithValue || false}
        />
      )}
    </div>
  )
}

export default styled(TransactionSearchFilters)`
  display: flex;
  min-height: 44px;
  justify-content: space-between;

  > div:last-child {
    width: auto;
  }
`
