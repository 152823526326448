import { createActions } from 'redux-actions'
import { NAMESPACE } from './constants'

export const {
  ui: {
    channels: {
      clearServerErrors,
      setCurrentPage,
      setCurrentFilter,
      setChannel,
    },
  },
} = createActions({
  [`${NAMESPACE}/clear_server_errors`]: () => null,
  [`${NAMESPACE}/set_current_page`]: (page: number) => page,
  [`${NAMESPACE}/set_current_filter`]: (filter: string) => filter,
  [`${NAMESPACE}/set_channel`]: (payload: any) => payload,
})
