import * as React from 'react'
import styled from 'styled-components'
import { Box } from 'grommet'
import { ArchiveTemplate, Button, Text } from '../..'
import Content from './Content'
import SearchFilters from './SearchFilters'
import { useSelector } from 'react-redux'
import { sitesSelectors } from '../../../../state/modules/sites'
import { get } from 'lodash'
import { TransactionsUploader } from './TransactionsUploader'

interface ITransactionsPageProps {
  className?: string
}

interface IHeaderProps {
  isProtectionOnly: boolean
  isUploadCSVModalOpen: boolean
  onUploadClick(): void
  setUploadCSVModalOpen(open: boolean): void
}

const Header = ({
  isProtectionOnly,
  isUploadCSVModalOpen,
  onUploadClick,
  setUploadCSVModalOpen,
}: IHeaderProps): JSX.Element => (
  <>
    <Box justify="between" direction="row" className="page-intro">
      <Text tag="h1" color="white">
        Transactions
      </Text>
      {isProtectionOnly && (
        <Button
          label="Bulk Upload"
          styleType="secondary"
          onClick={onUploadClick}
        />
      )}
    </Box>
    <SearchFilters className="search-filters" />
    <TransactionsUploader
      isUploadCSVModalOpen={isUploadCSVModalOpen}
      setUploadCSVModalOpen={setUploadCSVModalOpen}
    />
  </>
)

const TransactionsPage = (props: ITransactionsPageProps): JSX.Element => {
  const currentSitePath = useSelector(sitesSelectors.getCurrentSitePath)
  const currentSite = useSelector(state =>
    sitesSelectors.getSiteByPath(state)(currentSitePath),
  )
  const isProtectionOnly = get(currentSite, ['protection_only'], false)
  const [isUploadCSVModalOpen, setUploadCSVModalOpen] = React.useState(false)

  const handleUploadClick = React.useCallback(() => {
    setUploadCSVModalOpen(true)
  }, [setUploadCSVModalOpen])

  const MemoHeader = React.useMemo(
    () => () =>
      (
        <Header
          isProtectionOnly={isProtectionOnly}
          onUploadClick={handleUploadClick}
          isUploadCSVModalOpen={isUploadCSVModalOpen}
          setUploadCSVModalOpen={setUploadCSVModalOpen}
        />
      ),
    [handleUploadClick, isProtectionOnly, isUploadCSVModalOpen],
  )

  const MemoContent = React.useMemo(() => () => <Content />, [])

  /**
   * @todo fix the need for memo here.
   * It's used to prevent an infinite loop, but first prize is to fix the root of the problem.
   */
  return React.useMemo(
    () => (
      <div className={props.className}>
        <ArchiveTemplate
          verticalOverflow="scroll"
          Header={MemoHeader}
          Content={MemoContent}
          displayChannelSelector={true}
        />
      </div>
    ),
    [MemoContent, MemoHeader, props.className],
  )
}

const StyledTransactionsPage = styled(TransactionsPage)`
  width: 100%;
  overflow: scroll;

  .page-intro {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .search-filters {
    margin-bottom: 10px;
  }

  .status-text {
    cursor: default;
  }
`

export default StyledTransactionsPage
