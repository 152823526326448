import { IGlobalSearchModel } from './interfaces'

const globalSearchModel: IGlobalSearchModel = {
  schema: {},
  isLoading: false,
  pages: {
    next: undefined,
    prev: undefined,
  },
  byId: {},
  allIds: [],
  byPage: {},
  byFilters: {},
}

export default globalSearchModel
