import React, { useEffect } from 'react'
import { Modal } from '../..'
import { transactionsInterfaces } from '../../../../state/modules/transactions'
import CreateAllocationForm from './CreateAllocationForm'

interface ICreateAllocationModalProps {
  transaction: transactionsInterfaces.ITransaction
  isOpen: boolean
  isAddingAllocation: boolean
  onClose(): void
}

const CreateAllocationModal = (props: ICreateAllocationModalProps) => {
  const { transaction, isOpen, onClose, isAddingAllocation } = props

  // close modal when payments change. Assumes that the allocation loaded new payments
  useEffect(() => {
    onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction.payment_ids])

  const createAllocationForm = () => (
    <CreateAllocationForm
      transaction={transaction}
      isAddingAllocation={isAddingAllocation}
    />
  )

  return (
    <Modal
      isOpen={isOpen}
      showCloseIcon={true}
      onClose={onClose}
      InnerComponent={createAllocationForm()}
      disableOutsideClick={true}
    />
  )
}

export default CreateAllocationModal
