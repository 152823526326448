import * as React from 'react'
import {
  Grommet,
  Button as GrommetButton,
  ButtonProps,
  TipProps,
} from 'grommet'
import styled from 'styled-components'
import { colors, size } from '../../../styles/variables'
import timfinityTheme from '../../../styles/timfinityTheme'

interface IButtonProps extends ButtonProps {
  className?: string
  tip?: TipProps | string
  styleType:
    | 'primary'
    | 'secondary'
    | 'gradient'
    | 'warning'
    | 'danger'
    | 'plain'
    | 'info'
    | 'auth'
    | 'link'
  disabled?: boolean
  icon?: JSX.Element
  label?: React.ReactNode
  onClick?: (...args: any[]) => any
  type?: 'button' | 'reset' | 'submit'
  size?: 'small' | 'medium' | 'large'
  alignSelf?: 'start' | 'center' | 'end' | 'stretch'
  id?: string
  style?: object
  title?: string
}

const Button = (props: IButtonProps): JSX.Element => {
  // lift style before spreading props
  const { styleType, ...otherProps } = props
  return (
    <Grommet theme={timfinityTheme}>
      <GrommetButton {...otherProps} color="base" />
    </Grommet>
  )
}

const StyledButton = styled(Button)`
  ${(props: IButtonProps) => {
    let extraStyles: string = ''
    switch (props.styleType) {
      case 'primary':
        extraStyles += `background-color: ${colors.green};`
        break
      case 'secondary':
        extraStyles += `background-color: ${colors.blue.light};`
        break
      case 'gradient':
        extraStyles +=
          'background: linear-gradient(90deg, #2980B9 65.44%, #B4EC51 101.26%);'
        break
      case 'warning':
        extraStyles += `background-color: ${colors.amber};`
        break
      case 'danger':
        extraStyles += `background-color: ${colors.red};`
        break
      case 'plain':
        extraStyles += `
                    background-color: ${colors.grey.dark};
                `
        break
      case 'info':
        extraStyles += `
                    background-color: ${colors.blue.medium};
                `
        break
      case 'auth':
        extraStyles += `
                    background-color: ${colors.purple};
                `
        break
      case 'link':
        extraStyles += `
                    padding: 0 !important;
                    background: none;
                    color: ${colors.blue.midDark};
                    font-size: 1rem !important;
                `
    }
    const { small, medium, large } = size
    if (props.size) {
      switch (props.size) {
        case 'small':
          extraStyles += small
          break
        case 'medium':
          extraStyles += medium
          break
        case 'large':
          extraStyles += large
          break
      }
    }

    if (!props.label && props.icon) {
      extraStyles += `
                background: transparent;
                padding: 0;
            `
    }

    if (props.alignSelf) {
      switch (props.alignSelf) {
        case 'center':
          extraStyles += 'display: inline-block;'
          break
      }
    }

    return `
            color: white;
            font-weight: normal;
            border: none;
            border-radius: 4px;
            text-transform: capitalize;
            padding: 10px 18px;
            ${extraStyles}
        `
  }}
`

export default StyledButton
