import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useEffectOnce } from 'react-use'
import AsyncSelect from '../molecules/AsyncSelect/AsyncSelect'
import useFetchHelper from '../molecules/AsyncSelect/useFetchHelper'
import { IPsp } from '../../../state/modules/psps/interfaces'
import {
  pspActions,
  pspConstants,
  pspSelectors,
} from '../../../state/modules/psps'

interface IPspSelectorProps {
  className?: string
  isClearable?: boolean
  placeholder?: string
  onChange?(value: string | string[]): void
  value?: string | string[]
  disabled?: boolean
  id?: string
  isMulti?: boolean
  closeMenuOnSelect?: boolean
}
interface IOption {
  value: string | number
  label: string
}

const PER_PAGE = 20

const PspSelector = ({
  className,
  isClearable,
  placeholder = 'Select PSP',
  onChange,
  value,
  disabled,
  isMulti = false,
  ...props
}: IPspSelectorProps) => {
  const dispatch = useDispatch()
  const { request } = useFetchHelper(
    pspConstants.ENDPOINTS.BASE,
    pspActions.requestPspsSuccess,
  )
  const [initialOptions, setInitialOptions] = useState([])
  const getPspById = useSelector(pspSelectors.getPspById)

  const getInitialOptions = async () => {
    return await request({ per_page: PER_PAGE, page: 1 })
  }

  const transformOption = (psp: IPsp): IOption => {
    return (
      psp && {
        value: psp.id,
        label: psp.name,
      }
    )
  }

  useEffectOnce(() => {
    const setOptions = async () => {
      const { options } = await getInitialOptions()
      setInitialOptions(options.map((psp: IPsp) => transformOption(psp)))
    }
    setOptions()
  })

  useEffect(() => {
    if (isMulti && Array.isArray(value)) {
      value.length > 0 &&
        dispatch(pspActions.requestPsps({ name: value.join(',') }))
    }
  }, [dispatch, value, isMulti])

  const loadOptions = async (name: string, prevOptions: any) => {
    const params: any = {
      order_by: 'name',
      per_page: PER_PAGE,
      page: prevOptions.length / PER_PAGE + 1,
    }
    if (name) {
      params.name = name
    }
    const res = await request(params)
    return {
      ...res,
      options: res.options.map((psp: IPsp) => transformOption(psp)),
    }
  }

  const handleChange = (value: any, action: any) => {
    if (onChange) {
      if (isMulti) {
        if (value) {
          const psps = value.map((v: IOption) => v.value)
          onChange(psps)
        } else {
          onChange([])
        }
      } else {
        onChange(value.value)
      }
    } else {
      console.log('No onChange prop passed.')
    }
  }

  const currentValue = isMulti
    ? value &&
      Array.isArray(value) &&
      value.map((val: string) => transformOption(getPspById(val)))
    : value && transformOption(getPspById(value as string))

  return (
    <AsyncSelect
      className={classNames([className, 'psp-selector'])}
      isClearable={isClearable}
      placeholder={placeholder}
      initialOptions={initialOptions}
      loadOptions={loadOptions}
      onChange={handleChange}
      value={currentValue}
      isDisabled={disabled}
      isMulti={isMulti}
      {...props}
    />
  )
}

export default styled(PspSelector)`
  width: auto;
`
