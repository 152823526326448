import { get, map } from 'lodash'
import { createSelector } from 'reselect'
import { sitesSelectors } from '../sites'
import { CommentEntity, CommentType, IComment } from './interfaces'
import { commentsInitialState } from './state'
// import { NAMESPACE } from './constants';

const getState = (state: any) => state

export const getCommentsSlice = createSelector(getState, state =>
  get(state, ['comments'], commentsInitialState),
)

export const getCommentsFromCurrentSite = createSelector(
  [getState, getCommentsSlice],
  (state, comments) => {
    const currentSite = sitesSelectors.getCurrentSitePath(state)
    return get(comments, ['sites', currentSite, 'byId'])
  },
)

export const getCommentsByTypeFromCurrentSite = createSelector(
  getState,
  state => (type: CommentType) => {
    const comments = getCommentsFromCurrentSite(state)
    return map(comments, comment => comment).filter(
      comment => comment.type === type,
    )
  },
)

export const getCommentsByTypeForEntity = createSelector(
  getState,
  state => (type: CommentType, entity: CommentEntity, entityId: number) => {
    const comments: IComment[] = getCommentsByTypeFromCurrentSite(state)(type)
    return comments.filter(
      comment =>
        comment.comment_entity === entity && comment.entity_id === entityId,
    )
  },
)

export const isAddingComment = createSelector(
  getCommentsSlice,
  comments => comments.isAddingComment,
)
