import { ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { sitesActions } from '../../sites'

export const handleSiteCreationSuccess = (
  action$: Observable<AnyAction>,
): any =>
  action$.pipe(
    ofType(
      sitesActions.requestCreateSiteSuccess,
      sitesActions.requestUpdateSiteSuccess,
    ),
    map(action => {
      return sitesActions.siteSelected(action.payload)
    }),
  )
