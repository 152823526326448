const get = (name: string): string | null => localStorage.getItem(name)
const set = (name: string, value: any): void =>
  localStorage.setItem(name, value)

export const getItem = (name: string): string | false => {
  const item = get(name)
  if (!item) {
    return false
  }
  try {
    const parsedItem = JSON.parse(item)
    return parsedItem
  } catch (error) {
    return item
  }
}

export const setItem = (name: string, value: string | object) => {
  if (typeof value === 'object') {
    set(name, JSON.stringify(value))
  } else {
    set(name, value)
  }
}

export const removeItem = (name: string) =>
  !!get(name) && localStorage.removeItem(name)
