import { handleActions, combineActions } from 'redux-actions'
import { get, uniq, omit } from 'lodash'
import { AnyAction } from 'redux'
import * as actions from './actions'
import model from './model'
import { IStatementsModel, IStatementResponse } from './interfaces'
import { sitesSelectors } from '../sites'
import { parseLinkHeader } from '../../utils/http'

/** @todo move to util functions */
const getCurrentSitePathState = (state: any, currentSitePath: string) =>
  get(state, `sites[${currentSitePath}]`, {})
const getAllIds = (state: any, currentSitePath: string) =>
  get(state, `sites[${currentSitePath}].allIds`, [])
const getById = (state: any, currentSitePath: string) =>
  get(state, `sites[${currentSitePath}].byId`, {})
const getFiltersKey = (meta: any) =>
  JSON.stringify(omit(meta.params, ['per_page', 'page']))
const getByFilters = (state: any, currentSitePath: string) =>
  get(state, `sites[${currentSitePath}].byFilters`, {})
const getByPage = (state: any, currentSitePath: string) =>
  get(state, `sites[${currentSitePath}].byPage`, {})

export default function createStatementsReducer(rootState: any) {
  const currentSitePath = sitesSelectors.getCurrentSitePath(rootState)

  return handleActions(
    {
      /** @todo reset state when site is changed, keep schema */
      [actions.requestStatementsSchemaSuccess]: (
        state: IStatementsModel,
        action: AnyAction,
      ) => ({
        ...state,
        schema: action.payload,
      }),
      [actions.requestStatements]: (
        state: IStatementsModel,
        action: AnyAction,
      ) => {
        return {
          ...state,
          sites: {
            ...state.sites,
            [currentSitePath]: {
              ...getCurrentSitePathState(state, currentSitePath),
              isLoading: true,
            },
          },
        }
      },
      [actions.requestStatementsSuccess]: (
        state: IStatementsModel,
        action: AnyAction,
      ) => {
        const { payload, meta } = action

        return {
          ...state,
          sites: {
            ...state.sites,
            [currentSitePath]: {
              ...getCurrentSitePathState(state, currentSitePath),
              isLoading: false,
              pages: parseLinkHeader(meta.responseHeaders?.link || ''),
              byId: {
                ...getById(state, currentSitePath),
                ...payload.reduce(
                  (
                    channels: IStatementResponse[],
                    channel: IStatementResponse,
                  ) => {
                    channels[channel.id] = channel
                    return channels
                  },
                  {},
                ),
              },
              allIds: uniq([
                ...getAllIds(state, currentSitePath),
                ...payload.map((channel: IStatementResponse) => channel.id),
              ]),
              byPage: {
                ...getByPage(state, currentSitePath),
                [meta.params.page]: payload.map(
                  (channel: IStatementResponse) => channel.id,
                ),
              },
              byFilters: {
                ...getByFilters(state, currentSitePath),
                [getFiltersKey(meta)]: payload.map(
                  (channel: IStatementResponse) => channel.id,
                ),
              },
            },
          },
        }
      },
      [combineActions(
        actions.requestStatementSuccess,
        actions.updateStatementSuccess,
        actions.requestStatementCsvSuccess,
      )]: (state: IStatementsModel, action: AnyAction) => {
        const { payload } = action
        return {
          ...state,
          sites: {
            ...state.sites,
            [currentSitePath]: {
              ...getCurrentSitePathState(state, currentSitePath),
              byId: {
                ...getById(state, currentSitePath),
                [payload.id]: payload,
              },
              allIds: uniq([...getAllIds(state, currentSitePath), payload.id]),
            },
          },
        }
      },
    },
    model,
  )
}
