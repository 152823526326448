import React from 'react'
import {
  CellProps,
  isDateTimeControl,
  RankedTester,
  rankWith,
} from '@jsonforms/core'
import { withJsonFormsCellProps } from '@jsonforms/react'
import {
  VanillaRendererProps,
  withVanillaCellProps,
} from '@jsonforms/vanilla-renderers'
import { formatDate } from '../../../../utils/date'
import { TextInput } from '../../../../views/components'
import styled from 'styled-components'
import { colors } from '../../../../views/styles/variables'

const DateTimeCell = (props: CellProps & VanillaRendererProps) => {
  const { data, className, id, enabled, schema, path, handleChange } = props
  const toISOString = (inputDateTime: string) => {
    return inputDateTime === '' ? '' : inputDateTime + ':00.000Z'
  }

  const isFormFieldDisabled = () => {
    if (!enabled) {
      return true
    }
    const isReadOnly = (schema as any)?.readonly === true
    if (isReadOnly) {
      return true
    }
    return false
  }

  if ((props.schema as any)?.readonly === true) {
    return (
      <TextInput
        name={id}
        id={id}
        value={formatDate(data, 'EEE dd LLL yyyy')}
        // onChange={ev => handleChange(path, toISOString(ev.target.value))}
        className={className}
        disabled={isFormFieldDisabled()}
        // autoFocus={uischema.options && uischema.options.focus}
      />
    )
  }

  return <p>DateTimeCell condition not handled</p>
}
/**
 * Default tester for datetime controls.
 * @type {RankedTester}
 */
export const dateTimeCellTester: RankedTester = rankWith(3, isDateTimeControl)

const StyledDateTimeCell = styled(DateTimeCell)`
  border-color: ${colors.blue.dark};
  font-size: 15px !important;
`

export default withJsonFormsCellProps(withVanillaCellProps(StyledDateTimeCell))
