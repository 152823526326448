import { ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import * as actions from './actions'
import { getCurrentFilter, getCurrentChannel } from './selectors'
import { paymentsActions } from '../../payments'
import { perPage } from './constants'
import { IUIPaymentsModel } from './interfaces'
import { get as _get } from 'lodash'
import { liftPayload } from '../../../utils/rx-operators'

export const fetchDataOnPageChange = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.setCurrentPage),
    map(action => {
      const currentFilter = getCurrentFilter(state$.value)
      const currentChannel = getCurrentChannel(state$.value)
      let params: IUIPaymentsModel = { per_page: perPage, page: action.payload }
      if (currentChannel) {
        params.channels = currentChannel
      }
      if (currentFilter) {
        params = {
          ...params,
          ...JSON.parse(currentFilter),
        }
      }
      return paymentsActions.requestPayments(params)
    }),
  )

export const fetchPaymentsOnChannelChange = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.setChannel),
    liftPayload(),
    map(action => {
      const currentFilter = getCurrentFilter(state$.value)
      const channels = _get(action, 'id', null)
      let params: IUIPaymentsModel = { per_page: perPage, page: 1 }
      if (channels) {
        params.channels = channels
      }
      if (currentFilter) {
        params = {
          ...params,
          ...JSON.parse(currentFilter),
        }
      }
      return paymentsActions.requestPayments(params)
    }),
  )
