import { handleActions } from 'redux-actions'
import { AnyAction } from 'redux'
import * as actions from './actions'
import model from './model'
import { IUIGlobalSearchModel } from './interfaces'
import * as sitesActions from '../../sites/actions'

export default handleActions(
  {
    [actions.clearServerErrors]: (state: IUIGlobalSearchModel) => ({
      ...state,
      serverErrors: null,
    }),
    http_error: (state: IUIGlobalSearchModel, action: AnyAction) => {
      return state
    },
    [sitesActions.siteSelected]: (state: IUIGlobalSearchModel) => {
      return {
        ...state,
        currentFilter: '',
        currentChannel: null,
      }
    },
  },
  model,
)
