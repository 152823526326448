import { colors } from './variables'

export const buttonLink = `
    padding: 0;
    outline: 0;
    border: none;
    background: none;
    color: ${colors.blue.medium}
    font-weight: 600;
    cursor: pointer;

    &:hover,
    &:active {
        text-decoration: underline;
    }
`
