import { createSelector } from 'reselect'
import { get } from 'lodash'
import { NAMESPACE } from './constants'
import { IGlobalSearchModel } from './interfaces'

const getState = (state: any) => state

const getGlobalSearchState = (state: any): IGlobalSearchModel =>
  state[NAMESPACE]

export const getGlobalSearch = createSelector(
  [getGlobalSearchState],
  globalsearch => {
    return get(globalsearch, 'byId', {})
  },
)

export const getGlobalSearchInOrder = createSelector(
  [getGlobalSearchState],
  globalsearch => {
    const allIds = globalsearch.allIds
    return allIds.map((id: any) => get(globalsearch, `byId[${id}]`))
  },
)

export const getGlobalSearchByFilter = createSelector(
  [getState, getGlobalSearchState],
  (state, globalsearch) => (filter: string) => {
    const globalSearchByFilter = get(globalsearch, `byFilters[${filter}]`, [])
    return globalSearchByFilter.map((globalSearchId: number) =>
      getGlobalSearchById(state)(globalSearchId),
    )
  },
)

export const getGlobalSearchByPage = createSelector(
  [getState, getGlobalSearchState],
  (state, globalsearch) => (page: number | string) => {
    const globalSearchByPage = get(globalsearch, `byPage[${page}]`, [])
    return globalSearchByPage.map((globalsearchId: number) =>
      getGlobalSearchById(state)(globalsearchId),
    )
  },
)

export const getGlobalSearchById = createSelector(
  getGlobalSearch,
  globalsearch => {
    return (id: number | string) => get(globalsearch, `[${id}]`, null)
  },
)

export const getPages = createSelector(
  [getGlobalSearchState],
  globalsearch => globalsearch.pages,
)

export const isLoading = createSelector(
  [getState, getGlobalSearchState],
  (state, globalsearch) => get(globalsearch, 'isLoading', false),
)
