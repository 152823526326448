import * as React from 'react'
import { Box } from 'grommet'

interface IHeaderProps {
  background: string
}

class Header extends React.PureComponent<IHeaderProps, {}> {
  public render(): JSX.Element {
    const { background, children, ...props } = this.props

    return (
      <Box
        gridArea="header"
        tag="header"
        direction="row"
        align="center"
        justify="between"
        background={background}
        pad="medium"
        {...props}
      >
        {children}
      </Box>
    )
  }
}

export default Header
