import { handleActions, combineActions } from 'redux-actions'
import * as actions from './actions'
import model from './model'
import { IAuthModel } from './interfaces'
import { AnyAction } from 'redux'

export default handleActions(
  {
    [combineActions(
      actions.requestTokenSuccess,
      actions.requestTokenRefreshSuccess,
    )]: (state = model, action: AnyAction) => {
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
      }
    },
    [actions.requestPasswordForgotSuccess]: (
      state: IAuthModel,
      action: AnyAction,
    ) => {
      const { email } = action.payload
      return {
        ...state,
        email,
      }
    },
  },
  model,
)
