import { createSlice, createSelector } from '@reduxjs/toolkit'
import { allocationsActions } from '../../allocations'

type TUIAllocations = {
  isLoading: boolean
}

const uiAllocationsState = createSlice({
  name: 'uiAllocations',
  initialState: {
    isLoading: false,
  } as TUIAllocations,
  reducers: {},
  extraReducers: {
    [allocationsActions.requestAllocations.toString()]: state => {
      state.isLoading = false
    },
  },
})

const getUiAllocationsState = (state: any) => state.ui.allocations

export const uiAllocationsSelectors = {
  isLoading: createSelector(getUiAllocationsState, as => as.isLoading),
}

const { actions: uiAllocationsActions, reducer: uiAllocationsReducer } =
  uiAllocationsState

export { uiAllocationsActions, uiAllocationsReducer }
