import { handleActions } from 'redux-actions'
import { get, omit, uniq } from 'lodash'
import { AnyAction } from 'redux'
import * as actions from './actions'
import model from './model'
import { IPaymentsModel, IPayment } from './interfaces'
import { sitesSelectors } from '../sites'
import { mapPaymentsToIds } from './utils'
import { parseLinkHeader } from '../../utils/http'

/** @todo move to util functions */
const getCurrentSitePathState = (state: any, currentSitePath: string) =>
  get(state, `sites[${currentSitePath}]`, {})
const getAllIds = (state: any, currentSitePath: string) =>
  get(state, `sites[${currentSitePath}].allIds`, [])
const getById = (state: any, currentSitePath: string) =>
  get(state, `sites[${currentSitePath}].byId`, {})
const getFiltersKey = (meta: any) =>
  JSON.stringify(omit(meta.params, ['per_page', 'page']))
const getByFilters = (state: any, currentSitePath: string) =>
  get(state, `sites[${currentSitePath}].byFilters`, {})
const getByPage = (state: any, currentSitePath: string) =>
  get(state, `sites[${currentSitePath}].byPage`, {})

export default function createPaymentsReducer(rootState: any) {
  const currentSitePath = sitesSelectors.getCurrentSitePath(rootState)

  return handleActions(
    {
      // [sitesActions.siteSelected]: () => model, // Reset state when site is changed.
      [actions.requestPayments]: (state: IPaymentsModel, action: AnyAction) => {
        return {
          ...state,
          sites: {
            ...state.sites,
            [currentSitePath]: {
              ...getCurrentSitePathState(state, currentSitePath),
              isLoading: true,
            },
          },
        }
      },
      [actions.requestPaymentsSuccess]: (
        state: IPaymentsModel,
        action: AnyAction,
      ) => {
        const { payload, meta } = action
        return {
          ...state,
          sites: {
            ...state.sites,
            [currentSitePath]: {
              ...getCurrentSitePathState(state, currentSitePath),
              isLoading: false,
              pages: parseLinkHeader(meta.responseHeaders?.link || ''),
              byId: {
                ...getById(state, currentSitePath),
                ...payload.reduce((payments: IPayment[], payment: IPayment) => {
                  payments[payment.id] = payment
                  return payments
                }, {}),
              },
              allIds: uniq([
                ...getAllIds(state, currentSitePath),
                ...mapPaymentsToIds(payload),
              ]),
              byPage: {
                ...getByPage(state, currentSitePath),
                [meta.params.page]: payload.map(
                  (payment: IPayment) => payment.id,
                ),
              },
              byFilters: {
                ...getByFilters(state, currentSitePath),
                [getFiltersKey(meta)]: mapPaymentsToIds(payload),
              },
            },
          },
        }
      },
      [actions.requestPaymentSuccess]: (
        state: IPaymentsModel,
        action: AnyAction,
      ) => {
        const { payload } = action
        return {
          ...state,
          sites: {
            ...state.sites,
            [currentSitePath]: {
              ...getCurrentSitePathState(state, currentSitePath),
              byId: {
                ...getById(state, currentSitePath),
                [payload.id]: payload,
              },
              allIds: uniq([...getAllIds(state, currentSitePath), payload.id]),
            },
          },
        }
      },
    },
    model,
  )
}
