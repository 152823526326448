import React from 'react'
import DashboardMetricItem from './DashboardMetricItem'
import DashboardPageLayout from './DashboardPageLayout'
import { IChannelMetricsFormatted } from './interfaces'

type Props = {
  className?: string
  currency: string
  metrics: IChannelMetricsFormatted
}

const DashboardProcessingOnly = ({ className, currency, metrics }: Props) => {
  return (
    <DashboardPageLayout>
      <>
        <div>
          <h2>Unreleased Funds</h2>
          <DashboardMetricItem
            title="Unreported Funds"
            icon="transaction"
            value={
              metrics.unreported_transactions !== undefined
                ? `${currency} ${metrics.unreported_transactions}`
                : '-'
            }
          />
          <DashboardMetricItem
            title="Pending Statements"
            icon="statement"
            value={
              metrics.pending_statements !== undefined
                ? `${currency} ${metrics.pending_statements}`
                : '-'
            }
          />
          <DashboardMetricItem
            title="Ledger"
            icon="statement"
            value={
              metrics.ledger !== undefined
                ? `${currency} ${metrics.ledger}`
                : '-'
            }
          />
          <DashboardMetricItem
            title="Reserve"
            icon="statement"
            value={
              metrics.reserve !== undefined
                ? `${currency} ${metrics.reserve}`
                : '-'
            }
          />
        </div>
        <div>
          <h2>Payout</h2>
          <DashboardMetricItem
            title="Last Amount Released"
            icon="release"
            value={
              metrics.last_payout !== undefined
                ? `${currency} ${metrics.last_payout}`
                : '0'
            }
          />
          <DashboardMetricItem
            title="Last Date Released"
            icon="calendar-cash"
            value={metrics.last_payout_date || '-'}
          />
          <DashboardMetricItem
            title="Next Payout Date"
            icon="calendar-cash"
            value={metrics.next_payout_date || '-'}
          />
          {metrics.next_payout_date && (
            <small>
              Funds can take up to 5 working days to reach your account
            </small>
          )}
        </div>
        <div>
          <h2>Resources</h2>
          <DashboardMetricItem
            icon="member-hub"
            value={
              <a
                href="https://trustmytravel.com/knowledgehub/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Knowledge Hub
              </a>
            }
          />
          <DashboardMetricItem
            icon="documentation"
            value={
              <a
                href="https://developer.trustmy.group/payment-modal/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Payment Modal Integration Documentation
              </a>
            }
          />
        </div>
      </>
    </DashboardPageLayout>
  )
}

export default DashboardProcessingOnly
