import * as React from 'react'
import { FormikProps, Form, FastField, withFormik } from 'formik'
import { omit } from 'lodash'
import classNames from 'classnames'
import * as yup from 'yup'
import {
  ITransaction,
  ICreateTransaction,
} from '../../../../../state/modules/transactions/interfaces'
import { Text, Button, Loader, CurrencyInput } from '../../..'
import { TextArea } from 'grommet'
import { colors } from '../../../../styles/variables'
import styled from 'styled-components'
import { addTrailingZero, convertToCents } from '../../../../../utils/currency'

interface IChargebackReversalFormProps {
  transaction: ITransaction
  className?: string
  isApplyingChargeback: boolean
  formatCurrency(value: number | string, currencies: string): string
  getCurrencySymbol(currencyName: string): string
  onSubmit(params: ICreateTransaction): void
}

interface IFormValues {
  total: string
  content: string
  channels: number
  bookings: any[]
  currencies: string
  transaction_types: string
  linked_id: number
}

const validationSchema = yup.object().shape({
  total: yup.number().required('Please add a chargeback reversal amount!'),
  // need a normal function to maintain lexical scope for acccess to parent object.
  // tslint:disable-next-line:only-arrow-functions
  // @todo
  /*.test('is-valid', 'Cannot exceed total', function(val) {
		console.log(val);
		if (parseFloat(val) * 100 > this.parent.total_remaining) {
			return this.createError({ path: this.path, message: 'Cannot exceed total' });
		}
		return true;
	})*/
})

const ChargebackReversalForm = (
  props: IChargebackReversalFormProps & FormikProps<IFormValues>,
) => {
  const {
    handleChange,
    setFieldValue,
    values,
    touched,
    errors,
    transaction,
    isApplyingChargeback,
    getCurrencySymbol,
  } = props
  const classes = classNames(props.className, {
    'is-submitting': isApplyingChargeback,
  })

  const handleCurrencyChange = (e: any) =>
    setFieldValue('total', e.target.value)

  return (
    <div className={classes}>
      {isApplyingChargeback && <Loader text="Apply reversal..." />}
      <Form className="form">
        <Text tag="h3" color={colors.blue.dark}>
          Reversal
        </Text>
        <CurrencyInput
          prefix={getCurrencySymbol(transaction.currencies)}
          name="total"
          onChange={handleCurrencyChange}
          className="field"
          label="Reversal"
          value={values.total}
          error={errors.total}
        />
        <FastField
          component={TextArea}
          id="content"
          name="content"
          onChange={handleChange}
          label="Notes"
          value={values.content}
          error={touched.content && errors.content}
          className="field"
          placeholder="Notes"
        />
        <footer>
          <Button
            styleType="secondary"
            label="Apply"
            size="small"
            type="submit"
          />
        </footer>
      </Form>
    </div>
  )
}

const StyledChargebackReversalForm = styled(ChargebackReversalForm)`
  margin: 10px;
  &.is-submitting {
    form {
      display: none;
    }
  }
  h3 {
    margin: 0 0 10px 0;
    border-bottom: 1px solid ${colors.grey.lighter};
  }
  .field {
    margin-bottom: 10px;
  }
  .field label {
    margin-right: 20px;
  }
  footer {
    border-top: 1px solid ${colors.grey.lighter};
    text-align: right;
    margin-top: 10px;
    padding-top: 10px;
  }
  footer button {
    font-size: 1rem;
    padding: 6px 8px;
  }
  .field .error {
    display: block;
    position: absolute;
    right: 10px;
    top: 150px;
    bottom: 30px;
    font-size: 12px;
  }
  #content::placeholder {
    font-size: 14px;
    font-weight: normal;
  }
`

export default withFormik<IChargebackReversalFormProps, IFormValues>({
  validationSchema,
  validateOnBlur: true,
  validateOnChange: true,
  mapPropsToValues: (props: IChargebackReversalFormProps) => {
    const { transaction } = props
    const { id, channels, bookings, currencies, total } = transaction
    return {
      channels,
      bookings: bookings.map(b => omit(b, ['reference'])),
      currencies,
      transaction_types: 'chargeback-reversal',
      linked_id: id,
      total: addTrailingZero(total / 100),
      content: '',
    }
  },
  handleSubmit: (values: any, { props }) => {
    const { transaction } = props
    const total = convertToCents(values.total)
    const params = {
      ...values,
      total,
      bookings: values.bookings.map((booking: any) => ({
        ...booking,
        total: transaction.forex_rate
          ? convertToCents(values.total / parseFloat(transaction.forex_rate))
          : total,
      })),
    }
    props.onSubmit(params)
  },
})(StyledChargebackReversalForm)
