import * as React from 'react'
import { connect } from 'react-redux'
import { Dispatch, AnyAction } from 'redux'
import {
  uiTransactionsActions,
  uiTransactionsSelectors,
} from '../../../../../state/modules/ui/transactions'
import { DataTable, Anchor } from '../../..'
import { redirect } from '../../../../../utils/router'
import { sitesSelectors } from '../../../../../state/modules/sites'

interface IBookingsAccordionPanelProps {
  transactionId: number
}

interface IBookingsAccordionPanelContainerProps {
  // data: Array<{
  //     title: string;
  //     reference: string;
  //     total: number;
  // }>;
  data: any[]
  currentSitePath: string
  fetchTransactionBookings(): void
  redirect(path: string): void
}

class BookingsAccordionPanel extends React.PureComponent<
  IBookingsAccordionPanelProps & IBookingsAccordionPanelContainerProps,
  {}
> {
  public componentDidMount() {
    this.props.fetchTransactionBookings()
  }

  public getColumns = () => [
    {
      Header: 'Title',
      accessor: 'title',
      Cell: (row: any) => {
        const redirectTo = () => {
          this.props.redirect(
            `/${this.props.currentSitePath}/bookings/${row.original.id}`,
          )
        }
        return <Anchor onClick={redirectTo}>{row.original.title}</Anchor>
      },
    },
    {
      Header: 'Reference',
      accessor: 'reference',
    },
    {
      Header: 'Total',
      accessor: 'total',
    },
  ]

  public render(): JSX.Element {
    const { data } = this.props
    return (
      <div className="single-transaction-table">
        <DataTable
          manual={true}
          plain={true}
          columns={this.getColumns()}
          data={data}
          pageSize={data.length}
          loading={false}
        />
      </div>
    )
  }
}

const mapStateToProps = (
  state: any,
  ownProps: IBookingsAccordionPanelProps,
) => ({
  data: uiTransactionsSelectors.getTransactionBookingsTableData(state)(
    ownProps.transactionId,
  ),
  currentSitePath: sitesSelectors.getCurrentSitePath(state),
})

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
  ownProps: IBookingsAccordionPanelProps,
) => ({
  fetchTransactionBookings: () =>
    dispatch(
      uiTransactionsActions.fetchTransactionBookings(ownProps.transactionId),
    ),
  redirect: (path: string) => redirect(path),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BookingsAccordionPanel)
