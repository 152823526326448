import { useMemo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { get } from 'lodash'
import moment from 'moment'
import useChannelFilter from '../../../../hooks/useChannelFilter'
import useFilter from '../../../../hooks/useFilter'
import usePagination from '../../../../hooks/usePagination'
import useDateFilter from '../../../../hooks/useDateFilter'
import {
  bookingInterfaces,
  bookingActions,
} from '../../../../state/modules/bookings'
import { uiBookingsConstants } from '../../../../state/modules/ui/bookings'

const BookingsPageService = () => {
  const dispatch = useDispatch()

  const defaultPagination = {
    per_page: uiBookingsConstants.perPage,
    page: 1,
  }

  const defaultDateFilters = useMemo(
    () => ({
      after: moment().subtract(2, 'weeks').format('YYYY-MM-DD 00:00:00'),
      before: moment().format('YYYY-MM-DD 23:59:59'),
    }),
    [],
  )

  const { filter } = useFilter()

  const { pagination, setPagination } = usePagination()

  const { dateFilters, setDateFilters, isFilterExcluded } = useDateFilter([
    'include',
    'email',
  ])

  const [currentChannel] = useChannelFilter()

  useEffectOnce(() => {
    // Set default pagination params if they don't exist in URL
    if (!pagination) {
      setPagination(defaultPagination)
    }

    // Set date filters if they don't exist in URL
    if (!dateFilters) {
      setDateFilters(defaultDateFilters)
    } else if (isFilterExcluded) {
      setDateFilters({})
    }
  })

  useEffect(() => {
    const params: bookingInterfaces.IRequestBookingsParams =
      filter && get(Object.values(filter), [0]) === 'all'
        ? {
            ...pagination,
            ...dateFilters,
          }
        : {
            ...filter,
            ...pagination,
            ...dateFilters,
          }

    if (currentChannel) {
      params.channels = currentChannel
    }

    dispatch(bookingActions.requestBookings(params))
  }, [dispatch, filter, pagination, currentChannel, dateFilters])

  return null
}

export default BookingsPageService
