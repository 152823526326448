import { IUITransactionsModel } from './interfaces'

const uiTransactionsModel: IUITransactionsModel = {
  currentPage: 1,
  currentFilter: '',
  currentChannel: null,
  isApplyingRefund: false,
  isApplyingChargeback: false,
  isApplyingChargebackOverride: false,
  isApplyingReversal: false,
  isApplyingCapture: false,
  isApplyingVoid: false,
  isApplyingResend: false,
  isAddingAllocation: false,
  isUpdatingTransaction: false,
  isCreateTransactionSubmitting: false,
  isReversingPayment: false,
  serverErrors: null,
}

export default uiTransactionsModel
