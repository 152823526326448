import React, { useCallback } from 'react'
import { Box } from 'grommet'
import { Formik, Form, FormikProps, Field } from 'formik'
import classNames from 'classnames'
import * as yup from 'yup'
import styled from 'styled-components'
import {
  Text,
  Button,
  Loader,
  Select,
  CurrencyInput,
  FormField,
  TextInput,
  TextAreaInput,
} from '../..'
import moment, { Moment } from 'moment'
import { find, get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import SingleDatePicker from '../../molecules/SingleDatePicker/SingleDatePicker'
import { convertFromCents, convertToCents } from '../../../../utils/currency'
import { colors } from '../../../styles/variables'
import { IChannelResponse } from '../../../../state/modules/channels/interfaces'
import ChannelSelector from '../../stateful/ChannelSelector/ChannelSelector'
import {
  transactionsActions,
  transactionsInterfaces,
} from '../../../../state/modules/transactions'
import { channelSelectors } from '../../../../state/modules/channels'
import { bookingSelectors } from '../../../../state/modules/bookings'

interface IProps {
  className?: string
  transaction: transactionsInterfaces.ITransaction
  isAddingAllocation: boolean
}
interface IValues {
  title: string
  reference: string
  description: string
  channels: any // @todo - Fix type
  currencies: string
  base_total: number
  total: number
  operator: string
  statement_date?: Moment | null
}

const validationSchema = yup.object({
  title: yup.string().max(256, 'Title must be at most 256 characters'),
  reference: yup.string().max(256, 'Reference must be at most 256 characters'),
  description: yup
    .string()
    .max(1024, 'Description must be at most 1024 characters'),
  channels: yup.object().required('Channel is required').nullable(),
  total: yup
    .string()
    .required('Allocation amount is required.')
    .test('total', "Can't be a negative value", function (val: any) {
      if (parseFloat(val) < 0) {
        return new yup.ValidationError(
          "Can't enter a negative total.",
          val,
          'total',
        )
      }
      if (parseFloat(val) === 0) {
        return new yup.ValidationError(
          'Allocation amount is required',
          val,
          'total',
        )
      }
      if (this.parent.operator === 'percent' && parseFloat(val) > 100) {
        return new yup.ValidationError(
          'Allocation percent can\t exceed 100%',
          val,
          'total',
        )
      }
      return true
    }),
})

const transformValuesForSubmission = (values: IValues) => {
  const allocation: any = {
    channels: values.channels.id,
    currencies: values.currencies,
    total:
      values.operator === 'flat' ? convertToCents(values.total) : values.total,
    operator: values.operator,
    statement_date: moment(values.statement_date).format('YYYY-MM-DD'),
  }

  if (values.title) {
    allocation.title = values.title
  }
  if (values.reference) {
    allocation.reference = values.reference
  }
  if (values.description) {
    allocation.description = values.description
  }

  return {
    allocations: [allocation],
  }
}

const CreateAllocationForm = (props: IProps) => {
  const { transaction, isAddingAllocation } = props

  const transactionChannel: IChannelResponse | undefined = useSelector(state =>
    channelSelectors.getChannelById(state)(transaction.channels),
  )

  const initialValues = {
    title: '',
    reference: '',
    description: '',
    channels: null,
    base_total: 0, // used for forex conversion
    currencies: transactionChannel ? transactionChannel.currencies : '',
    total: 0,
    operator: 'flat',
    statement_date: moment(),
  }

  const dispatch = useDispatch()
  const classes = classNames(props.className, {
    'is-submitting': isAddingAllocation,
  })

  const handleSubmit = useCallback(
    (values: IValues) => {
      const transactionPayload = transformValuesForSubmission(values)
      dispatch(
        transactionsActions.addAllocation({
          urlReplacements: { id: transaction.id },
          body: transactionPayload,
        }),
      )
    },
    [dispatch, transaction],
  )

  const getBookingById = useSelector(bookingSelectors.getBookingById)
  const getChannelFieldById = useSelector(channelSelectors.getChannelFieldById)

  return (
    <Box className={props.className}>
      <Box>
        {isAddingAllocation ? (
          <Loader text="Creating allocation..." />
        ) : (
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({
              handleChange,
              setFieldValue,
              values,
              errors,
              touched,
            }: FormikProps<IValues>) => {
              const handleForexConversion = (
                channel: IChannelResponse,
                total: number = values.total,
              ) => {
                if (values.operator !== 'flat' || !transactionChannel) return

                const channelForexFeed = getChannelFieldById(
                  channel.id,
                  'forex_feed',
                )
                const rate = find(
                  get(channelForexFeed, ['rates'], {}),
                  (currency: any) =>
                    currency.symbol === transactionChannel.currencies,
                )
                if (rate && total > 0) {
                  const baseTotal = convertToCents(total * rate.rate)
                  setFieldValue('base_total', convertFromCents(baseTotal))
                } else {
                  setFieldValue('base_total', 0)
                }
              }

              const handleChannelSelect = (channel: IChannelResponse) => {
                setFieldValue('channels', channel)
                setFieldValue('currencies', channel.currencies)
                if (
                  transactionChannel &&
                  transactionChannel.currencies !== channel.currencies
                ) {
                  if (values.channels) {
                    handleForexConversion(channel)
                  }
                }

                if (channel.account_type === 'protected-processing') {
                  setFieldValue('statement_date', moment())
                } else if (channel.account_type === 'trust') {
                  const transactionBooking = get(transaction, ['bookings', 0])
                  if (transactionBooking) {
                    const booking = getBookingById(transactionBooking.id)
                    const bookingDate = get(booking, ['date'])
                    if (bookingDate) {
                      setFieldValue(
                        'statement_date',
                        moment(bookingDate).subtract(
                          channel._trust_override,
                          'days',
                        ),
                      )
                    }
                  }
                }
              }

              const handleCurrencyChange = (e: any) => {
                setFieldValue('total', e.target.value)
                if (values.channels) {
                  handleForexConversion(values.channels, e.target.value)
                }
              }

              const handleStatementDateChange = (date: Moment) => {
                setFieldValue('statement_date', date)
              }

              return (
                <div className={classes}>
                  <Form className="form">
                    <Text tag="h3" color={colors.blue.dark}>
                      Create Allocation
                    </Text>
                    <FormField
                      label="Title"
                      className="title field"
                      error={errors.title as any}
                    >
                      <Field
                        className="field"
                        component={TextInput}
                        id="title"
                        name="title"
                        onChange={handleChange}
                        disabled={false}
                        value={values.title}
                      />
                    </FormField>
                    <FormField
                      label="Reference"
                      className="reference field"
                      error={errors.reference as any}
                    >
                      <Field
                        className="field"
                        component={TextInput}
                        id="reference"
                        name="reference"
                        onChange={handleChange}
                        disabled={false}
                        value={values.reference}
                      />
                    </FormField>
                    <FormField
                      label="Description"
                      className="description field"
                      error={errors.description as any}
                    >
                      <Field
                        className="field"
                        component={TextAreaInput}
                        id="description"
                        name="description"
                        onChange={handleChange}
                        disabled={false}
                        value={values.description}
                      />
                    </FormField>
                    <FormField
                      label="Channel"
                      className="field"
                      error={errors.channels as any}
                    >
                      <ChannelSelector
                        id="allocation-channels"
                        isClearable={false}
                        onChange={handleChannelSelect}
                        value={values.channels}
                        disabled={false}
                        placeholder="Select Channel..."
                      />
                    </FormField>
                    <FormField label="Type" className="type field">
                      <Field
                        component={Select}
                        id="operator"
                        name="operator"
                        onChange={(val: string) =>
                          setFieldValue('operator', val)
                        }
                        options={['flat', 'percent']}
                        value={values.operator}
                      />
                    </FormField>
                    <FormField
                      label="Total"
                      className="total field"
                      error={errors.total as any}
                    >
                      <Field
                        className="field"
                        component={CurrencyInput}
                        prefix={
                          values.operator === 'percent'
                            ? '%'
                            : values.currencies
                        }
                        id="total"
                        name="total"
                        onChange={handleCurrencyChange}
                        disabled={false}
                        value={values.total}
                      />
                    </FormField>
                    {values.operator === 'flat' && values.base_total > 0 && (
                      <FormField
                        label="Total in Base"
                        className="total field"
                        error={errors.total as any}
                      >
                        <Field
                          className="field"
                          component={CurrencyInput}
                          prefix={
                            transactionChannel
                              ? transactionChannel.currencies
                              : ''
                          }
                          id="base_total"
                          name="base_total"
                          onChange={console.log}
                          disabled={true}
                          value={values.base_total}
                        />
                      </FormField>
                    )}
                    <FormField
                      label="Statement Date"
                      className="date-picker field"
                      error={
                        touched.statement_date && (errors.statement_date as any)
                      }
                    >
                      <Field
                        component={SingleDatePicker}
                        id="statement_date"
                        name="statement_date"
                        onDateChange={handleStatementDateChange}
                        disabled={!values.channels}
                        date={values.statement_date}
                        withPortal={true}
                        label="Statement Date"
                      />
                    </FormField>
                    <footer>
                      <Button
                        styleType="secondary"
                        label="Add"
                        size="small"
                        type="submit"
                      />
                    </footer>
                  </Form>
                </div>
              )
            }}
          </Formik>
        )}
      </Box>
    </Box>
  )
}

export default styled(CreateAllocationForm)`
  > div {
    padding: 24px;
  }
  h3 {
    margin: 0 0 10px 0;
    padding: 0 0 10px 0;
    border-bottom: 1px solid ${colors.grey.lighter};
  }
  form {
    label {
      width: 150px;
    }
    .currency-input-container label {
      margin-right: 20px;
    }

    > .field {
      margin-bottom: 16px;
    }

    .field {
      .currency-input-container {
        margin-bottom: 0;
      }

      .field-control,
      .field button,
      .field input {
        max-width: 200px;
      }

      #description {
        border-color: ${colors.grey.dark};
      }
    }
    footer {
      border-top: 1px solid ${colors.grey.lighter};
      text-align: right;
      padding-top: 10px;
    }
    footer button {
      font-size: 1rem;
      padding: 6px 8px;
    }
    #allocation-channels {
      width: 100%;
      max-width: 700px;
      > div {
        border-radius: 4px;
        border-color: rgba(0, 0, 0, 0.33);
      }
    }
    #content::placeholder {
      font-size: 14px;
      font-weight: normal;
    }
    .date-picker .DateInput {
      background: none;
      width: 100%;
      max-width: 100%;
    }

    .DateInput_input {
      background: none;
      width: 100%;
    }
    .DateInput_input__disabled {
      color: #575757;
    }
  }
`
