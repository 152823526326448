import maxBy from 'lodash/maxBy'
import React from 'react'
import cx from 'classnames'
import {
  computeLabel,
  ControlProps,
  ControlState,
  isControl,
  isDescriptionHidden,
  NOT_APPLICABLE,
  RankedTester,
  rankWith,
} from '@jsonforms/core'
import {
  Control,
  DispatchCell,
  withJsonFormsControlProps,
} from '@jsonforms/react'
import merge from 'lodash/merge'
import {
  VanillaRendererProps,
  withVanillaControlProps,
} from '@jsonforms/vanilla-renderers'
import styled from 'styled-components'
import { Grommet } from 'grommet'
import timfinityTheme from '../../../../views/styles/timfinityTheme'
import { colors } from '../../../../views/styles/variables'

export class InputControl extends Control<
  ControlProps & VanillaRendererProps & { className?: string },
  ControlState & { showErrors: boolean }
> {
  componentDidMount() {
    this.setState({ showErrors: false })
  }

  componentDidUpdate(prevProps: ControlProps, prevState: ControlState) {
    if (prevState.isFocused && !this.state.isFocused) {
      this.setState({ showErrors: true })
    }
  }

  render() {
    const {
      className,
      classNames,
      description,
      id,
      errors,
      label,
      uischema,
      schema,
      rootSchema,
      visible,
      enabled,
      required,
      path,
      cells,
      config,
    } = this.props

    const isValid = errors.length === 0 || !this.state.showErrors

    const isVisible = !!visible && (schema as any)?.visible !== false

    const divClassNames = [classNames?.validation]
      .concat(isValid ? classNames?.description : classNames?.validationError)
      .join(' ')

    const appliedUiSchemaOptions = merge({}, config, uischema.options)
    const showDescription = !isDescriptionHidden(
      visible,
      description,
      this.state.isFocused,
      appliedUiSchemaOptions.showUnfocusedDescription,
    )

    const testerContext = {
      rootSchema: rootSchema,
      config: config,
    }

    const cell = maxBy(cells, r => r.tester(uischema, schema, testerContext))

    if (
      cell === undefined ||
      cell.tester(uischema, schema, testerContext) === NOT_APPLICABLE
    ) {
      console.warn('No applicable cell found.', uischema, schema)
      return null
    } else {
      if (!isVisible) {
        return null
      }

      return (
        <Grommet theme={timfinityTheme}>
          <div
            className={cx(classNames?.wrapper, className, {
              'has-error': !isValid,
            })}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            id={id}
          >
            <label htmlFor={path} className={classNames?.label}>
              {computeLabel(
                label,
                required || false,
                appliedUiSchemaOptions.hideRequiredAsterisk,
              )}
            </label>
            <div className="input-control-cell">
              <DispatchCell
                uischema={uischema}
                schema={schema}
                path={path}
                id={path}
                enabled={enabled}
              />
              <div className={divClassNames}>
                {/* {!isValid ? errors : showDescription ? description : null} */}
                {!isValid ? errors : null}
              </div>
            </div>
          </div>
        </Grommet>
      )
    }
  }
}

export const inputControlTester: RankedTester = rankWith(2, isControl)

const StyledInputControl = styled(InputControl)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  label {
    font-size: 15px;
    padding: 8px 0;
  }

  .input-control-cell {
    width: 100%;

    > *,
    .input-control button,
    .text-input-container {
      max-width: 676px;
      width: 100%;
    }
  }

  &.has-error {
    label {
      color: ${colors.red};
    }

    .validation {
      color: ${colors.red};
      margin-top: 5px;
    }

    input {
      border-color: ${colors.red} !important;
    }

    .channel-selector > div {
      border-color: ${colors.red};
    }
  }
`

export default withVanillaControlProps(
  withJsonFormsControlProps(StyledInputControl),
)
