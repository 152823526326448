import { ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { Observable, of } from 'rxjs'
import { map, switchMap, mergeMap } from 'rxjs/operators'
import { get, uniq } from 'lodash'
import * as actions from './actions'
import { ENDPOINTS } from './constants'
import { get as getRequest, catchRestError } from '../../utils/http'
import RestfulResult from '../../utils/RestfulResult'
import { injectCurrentSitePath } from '../../utils/general'
import { IGlobalSearchResult } from './interfaces'
import { TMT_URL } from '../../constants'
import { sitesActions } from '../sites'

const getSitesToFetch = (results: IGlobalSearchResult[]) =>
  uniq(
    results.map((result: IGlobalSearchResult) => {
      const link = get(result, ['_links', 'self', 0, 'href'])
      const sitePath = get(link.replace(TMT_URL || '', '').split('/'), [1])
      return sitePath
    }),
  )

export const requestGlobalSearchEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestGlobalSearch),
    switchMap((action: any) =>
      of(action.payload).pipe(
        map(params => ({ params })),
        getRequest(injectCurrentSitePath(ENDPOINTS.BASE, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mergeMap(() => {
                const context = response.getContext()
                const sitesToFetch = getSitesToFetch(p)
                const actionsOut = [
                  actions.requestGlobalSearchSuccess(p, context),
                ]

                if (sitesToFetch.length > 0) {
                  sitesToFetch.forEach((path: string) => {
                    actionsOut.push(sitesActions.requestSites({ site: path }))
                  })
                }
                return actionsOut
              }),
            ),
          ),
        ),
        catchRestError(actions.requestGlobalSearch.toString()),
      ),
    ),
  )
