import { AnyAction, PayloadAction } from '@reduxjs/toolkit'
import { ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { map, mapTo, mergeMap, pluck, switchMap } from 'rxjs/operators'
import {
  get,
  catchRestError,
  putRequest,
  deleteRequest,
  optionsRequest,
} from '../../utils/http'
import { injectCurrentSitePath } from '../../utils/general'
import { actions } from './state'
import { ENDPOINTS } from './constants'
import RestfulResult from '../../utils/RestfulResult'
import {
  IRequestAllocationsParams,
  IUpdateAllocationPayload,
} from './interfaces'
import { formatTrustId } from '../../utils/rx-operators'

export const requestAllocationsSchemaEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestAllocationsSchema),
    switchMap(action =>
      of(action).pipe(
        optionsRequest(
          injectCurrentSitePath(ENDPOINTS.BASE, state$.value),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(mapTo(actions.requestAllocationsSchemaSuccess(p))),
          ),
        ),
        catchRestError(actions.requestAllocationsSchema.toString()),
      ),
    ),
  )

export const requestAllocationsEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestAllocations),
    pluck('payload'),
    switchMap((payload: IRequestAllocationsParams) =>
      of(payload).pipe(
        formatTrustId(),
        map(params => ({ params })),
        get(injectCurrentSitePath(ENDPOINTS.BASE, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(
                actions.requestAllocationsSuccess(p, response.getContext()),
              ),
            ),
          ),
        ),
        catchRestError(actions.requestAllocations.toString()),
      ),
    ),
  )

export const requestAllocationEpic = (
  action$: Observable<PayloadAction<{ id: number }>>,
  state$: any,
) =>
  action$.pipe(
    ofType(actions.requestAllocation.toString()),
    pluck('payload'),
    switchMap(payload =>
      of(payload).pipe(
        map(urlReplacements => ({ urlReplacements })),
        get(injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(mapTo(actions.requestAllocationSuccess(p))),
          ),
        ),
        catchRestError(actions.requestAllocation.toString()),
      ),
    ),
  )

export const updateAllocationEpic = (
  action$: Observable<PayloadAction<IUpdateAllocationPayload>>,
  state$: any,
) =>
  action$.pipe(
    ofType(actions.updateAllocation.toString()),
    pluck('payload'),
    switchMap(payload =>
      of(payload).pipe(
        map(body => ({ body, urlReplacements: { id: body.id } })),
        putRequest(injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(mapTo(actions.updateAllocationSuccess(p))),
          ),
        ),
        catchRestError(actions.updateAllocation.toString()),
      ),
    ),
  )

export const reverseAllocation = (
  action$: Observable<PayloadAction<{ id: number }>>,
  state$: any,
) =>
  action$.pipe(
    ofType(actions.reverseAllocation.toString()),
    pluck('payload'),
    switchMap(payload =>
      of(payload).pipe(
        map(body => ({ body, urlReplacements: { id: body.id } })),
        deleteRequest(
          injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mergeMap(() => [
                actions.reverseAllocationSuccess(p),
                actions.requestAllocation({ id: payload.id }),
              ]),
            ),
          ),
        ),
        catchRestError(actions.reverseAllocation.toString()),
      ),
    ),
  )
