import * as React from 'react'
import { Anchor as GrommetAnchor } from 'grommet'
import styled from 'styled-components'
import { colors } from '../../../styles/variables'
import { PolymorphicType } from 'grommet/utils'

interface IProps {
  a11yTitle?: string
  alignSelf?: 'start' | 'center' | 'end' | 'stretch'
  href?: string
  icon?: JSX.Element
  onClick?: (...args: any[]) => any
  children: React.ReactChild | string
  className?: string
  as?: PolymorphicType
}

const Anchor = (props: IProps): JSX.Element => {
  const { children, ...otherProps } = props
  return <GrommetAnchor {...otherProps}>{children}</GrommetAnchor>
}

const StyledAnchor = styled(Anchor)`
  color: ${colors.blue.medium};
`

export default StyledAnchor
