import * as React from 'react'
import { Text } from '../'
import { Box } from 'grommet'
import { AppBar } from '../../containers'

interface IGlobalSearchTemplateProps {
  verticalOverflow?: 'scroll' | 'hidden'
  Header(): JSX.Element
  Content(): JSX.Element
}

const GlobalSearchTemplate = (
  props: IGlobalSearchTemplateProps,
): JSX.Element => {
  const { Header, Content, verticalOverflow } = props
  const vOverflow = verticalOverflow || 'hidden'
  return (
    <Box fill={true} pad="medium" overflow={{ vertical: vOverflow }}>
      <AppBar>
        <Box justify="between" direction="row" className="page-title">
          <Text tag="h1" color="white">
            Search
          </Text>
        </Box>
      </AppBar>
      <Box pad={{ vertical: 'medium' }} flex="grow">
        <Box>
          <Header />
        </Box>
        <Box fill="vertical">
          <Content />
        </Box>
      </Box>
    </Box>
  )
}

export default GlobalSearchTemplate
