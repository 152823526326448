import decode from 'jwt-decode'
import { get } from 'lodash'
import { differenceInMinutes } from 'date-fns'
import { successCodes } from './http'
import store from '../store'
import { getToken, setLastRequestTimestamp } from '../../utils/auth'
import { requestTokenRefresh } from '../modules/auth/actions'

export default class RestfulResult {
  private result: any
  private context: any

  constructor(result: any, context: any) {
    this.result = result
    this.context = context
  }

  public map(projector: any) {
    return projector(this.get())
  }

  public mapOrFail(projector: any) {
    return projector(this.getOrFail())
  }

  public maybeRefreshToken() {
    const token = getToken()
    if (token) {
      const decodedToken = decode(token)
      // * 1000 to convert to milliseconds. Token exp is in seconds.
      const exp: number = get(decodedToken, 'exp', false) * 1000
      const result = differenceInMinutes(new Date(exp), new Date())
      /** @todo hack of note! clean this up. */
      if (
        result < 6 &&
        !this.result.request.url.includes('jwt-auth/v1/token/refresh')
      ) {
        store.dispatch(requestTokenRefresh())
      }
    }
  }

  public getContext() {
    return this.context
  }

  public get() {
    return this.result.response
  }

  private getOrFail() {
    const response = this.get()
    if (
      successCodes.filter((code: number) => this.result.status === code)
        .length >= 1
    ) {
      // successful request, store timestamp
      setLastRequestTimestamp()
      this.maybeRefreshToken()
      return response
    }

    throw this.result
  }
}
