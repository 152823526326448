import { createSelector } from 'reselect'
import { get, memoize } from 'lodash'
import { NAMESPACE } from './constants'
import { IBookingsModel } from './interfaces'
import { sitesSelectors } from '../sites'

const getState = (state: any) => state

const getBookingsState = (state: any): IBookingsModel => state[NAMESPACE]

export const getBookingIds = createSelector(
  [getState, getBookingsState],
  (state, bookings) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(bookings, `sites[${sitePath}].allIds`, [])
  },
)

export const getBookings = createSelector(
  [getState, getBookingsState],
  (state, bookings) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(bookings, `sites[${sitePath}].byId`, {})
  },
)

export const getBookingsInOrder = createSelector(
  [getState, getBookingsState],
  (state, bookings) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    const allIds = get(bookings, `sites[${sitePath}].allIds`, [])
    return allIds.map((id: any) =>
      get(bookings, `sites[${sitePath}].byId[${id}]`, {}),
    )
  },
)

export const getBookingsByFilter = createSelector(
  [getState, getBookingsState],
  (state, bookings) => (filter: string) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    const bookingsByFilter = get(
      bookings,
      `sites[${sitePath}].byFilters[${filter}]`,
      [],
    )
    return bookingsByFilter.map((bookingId: number) =>
      getBookingById(state)(bookingId),
    )
  },
)

export const getBookingsByPage = createSelector(
  [getState, getBookingsState],
  (state, bookings) => (page: number | string) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    const bookingsByPage = get(
      bookings,
      `sites[${sitePath}].byPage[${page}]`,
      [],
    )
    return bookingsByPage.map((bookingId: number) =>
      getBookingById(state)(bookingId),
    )
  },
)

export const getBookingById = createSelector(getBookings, bookings => {
  return (id: number | string) => get(bookings, `[${id}]`, null)
})

export const getBookingTransactionIds = createSelector(
  getState,
  s => (id: number | string) => {
    const booking = getBookingById(s)(id)
    return get(booking, 'transaction_ids', [])
  },
)

export const isLoading = createSelector(
  [getState, getBookingsState],
  (state, bookings) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(bookings, `sites[${sitePath}].isLoading`, false)
  },
)

export const getPages = createSelector(
  [getState, getBookingsState],
  (state, bookings) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(bookings, `sites[${sitePath}].pages`, false)
  },
)

export const getBookingsSchema = createSelector(
  getBookingsState,
  bookings => bookings.schema,
)

export const getBookingsSchemaMethods = createSelector(
  getBookingsSchema,
  memoize(schema => get(schema, 'methods', [])),
)

export const getCountriesFromSchema = createSelector(
  getBookingsSchema,
  memoize(schema => get(schema, 'schema.properties.countries.enum', [])),
)

export const getPropertiesFromSchema = createSelector(
  getBookingsSchema,
  memoize(schema => get(schema, 'schema.properties', {})),
)

export const getCurrenciesFromSchema = createSelector(
  getBookingsSchema,
  memoize(schema => get(schema, 'schema.properties.currencies.enum', [])),
)
