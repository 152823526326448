import React from 'react'
import cx from 'classnames'
import {
  RankedTester,
  Test,
  UISchemaElement,
  computeLabel,
  ControlProps,
} from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import {
  VanillaRendererProps,
  withVanillaControlProps,
} from '@jsonforms/vanilla-renderers'
import SupplierSelector from '../../../../views/components/stateful/SupplierSelector'
import { Grommet } from 'grommet'
import timfinityTheme from '../../../../views/styles/timfinityTheme'
import styled from 'styled-components'
import { colors } from '../../../../views/styles/variables'

const SuppliersArrayControl = (
  props: ControlProps & VanillaRendererProps & { className?: string },
) => {
  {
    const {
      className,
      handleChange,
      schema,
      id,
      enabled,
      path,
      data,
      visible,
      required,
      errors,
      label,
    } = props

    const isValid = errors.length === 0
    const isVisible = !!visible && (schema as any)?.visible !== false

    const isFormFieldDisabled = () => {
      if (!enabled) {
        return true
      }
      const isReadOnly = (schema as any)?.readonly === true
      if (isReadOnly) {
        return true
      }
      return false
    }

    if (!isVisible) {
      return null
    }

    return (
      <Grommet theme={timfinityTheme}>
        <div
          className={cx(className, {
            'has-error': !isValid,
          })}
          hidden={!visible}
        >
          <label htmlFor={path}>
            {computeLabel(label, required || false, false)}
          </label>
          <div className="input-control-cell">
            <SupplierSelector
              id="suppliers"
              className="suppliers-array-control-input"
              onChange={value => {
                handleChange(path, value)
              }}
              value={data}
              isMulti={true}
              isClearable={true}
              closeMenuOnSelect={false}
              placeholder="All suppliers"
              disabled={isFormFieldDisabled()}
            />
          </div>
        </div>
      </Grommet>
    )
  }
}

const { or, isObjectArrayControl, isPrimitiveArrayControl, rankWith, and } =
  Test

/**
 * Alternative tester for an array that also checks whether the 'table'
 * option is set.
 * @type {RankedTester}
 */
export const suppliersArrayControlTester: RankedTester = rankWith(
  4,
  and(
    or(isObjectArrayControl, isPrimitiveArrayControl),
    (uischema: UISchemaElement) => {
      return uischema?.options?.Component === 'SuppliersArrayControl'
    },
  ),
)

const StyledSuppliersArrayControl = styled(SuppliersArrayControl)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  label {
    font-size: 15px;
    padding: 8px 0;
  }

  .input-control-cell {
    width: 100%;
  }

  .suppliers-array-control-input {
    width: 100%;
    max-width: 676px;

    > div {
      border-radius: 4px;
      border-color: ${colors.blue.dark};
    }
  }

  &.has-error {
    label {
      color: ${colors.red};
    }

    .validation {
      color: ${colors.red};
      margin-top: 5px;
    }

    input {
      border-color: ${colors.red} !important;
    }
  }
`

export default withVanillaControlProps(
  withJsonFormsControlProps(StyledSuppliersArrayControl),
)
