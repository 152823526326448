import { createActions } from 'redux-actions'
import { NAMESPACE } from './constants'

export const {
  ui: {
    transactions: {
      clearServerErrors,
      setCurrentPage,
      setCurrentFilter,
      fetchTransactionBookings,
      fetchTransactionAdjustments,
      fetchTransactionPayments,
      setChannel,
    },
  },
} = createActions({
  [`${NAMESPACE}/clear_server_errors`]: () => null,
  [`${NAMESPACE}/set_current_page`]: (page: number) => page,
  [`${NAMESPACE}/set_current_filter`]: (filter: string) => filter,
  [`${NAMESPACE}/fetch_transaction_bookings`]: (transactionId: number) =>
    transactionId,
  [`${NAMESPACE}/fetch_transaction_adjustments`]: (transactionId: number) =>
    transactionId,
  [`${NAMESPACE}/fetch_transaction_payments`]: (transactionId: number) =>
    transactionId,
  [`${NAMESPACE}/set_channel`]: (payload: any) => payload,
})
