import React from 'react'
import { Grommet, Select as GrommetSelect, SelectProps } from 'grommet'
import timfinityTheme from '../../../styles/timfinityTheme'
import { colors } from '../../../styles/variables'
import styled from 'styled-components'

export interface ILazyLoadSelectProps extends SelectProps {
  className?: string
  placeholder?: string
  error?: string
}

const LazyLoadSelect = (props: ILazyLoadSelectProps): JSX.Element => {
  const { className, ...rest } = props
  return (
    <Grommet theme={timfinityTheme} className={className}>
      <GrommetSelect {...rest} multiple={false} dropHeight="medium" />
    </Grommet>
  )
}

const StyledLazyLoadSelect = styled(LazyLoadSelect)`
  ${(props: ILazyLoadSelectProps) => `
        box-sizing: border-box;
        height: 100%;
        border-radius: 5px;
        label {
            margin-right: 20px;
        }
        span {
            font-size: 1.1rem;
            line-height: 1.1rem;
        }
        input::placeholder, input[value] {
            font-family: Lato;
            font-style: italic;
            font-weight: normal;
            padding: 11px;
            line-height: 1.1rem;
            font-size: 1.1rem;
            margin-left: 5px;
            color: ${props.error ? colors.red : 'inherit'};
            opacity: 1; /* Firefox */
        }
        button {
            border: 1px solid ${props.error ? colors.red : colors.blue.dark};
            &[disabled] {
                opacity: 1;
                &, input {
                    cursor: not-allowed;
                }
                > div {
                    background: ${colors.grey.lighter};
                }
            }
        }
        .error {
            color: ${colors.red};
            font-size: 15px;
        }
        ${
          props.options.length === 1
            ? `
            [class*=StyledIcon] {
                display: none;
            }`
            : ''
        }
    `}
`

export default StyledLazyLoadSelect
