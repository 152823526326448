import { createActions } from 'redux-actions'
import { NAMESPACE } from './constants'
import { IRequestUsersParams, IRequestUsersMeta, IUser } from './interfaces'

export const {
  [NAMESPACE]: {
    requestUsersSchema,
    requestUsersSchemaSuccess,
    requestUsers,
    requestUsersSuccess,
    requestUser,
    requestUserSuccess,
    requestAdminUsers,
    requestAdminUsersSuccess,
    requestAdminUser,
    requestAdminUserSuccess,
    createUser,
    createUserSuccess,
    updateUser,
    updateUserSuccess,
    deleteUser,
    deleteUserSuccess,
  },
} = createActions({
  [`${NAMESPACE}/request_users_schema`]: () => '',
  [`${NAMESPACE}/request_users_schema_success`]: (payload: any) => payload,
  [`${NAMESPACE}/request_users`]: [
    (payload: IRequestUsersParams) => payload,
    (payload: IRequestUsersParams, meta: IRequestUsersMeta) => meta,
  ],
  [`${NAMESPACE}/request_users_success`]: [
    (payload: IUser[]) => payload,
    (payload: any, meta: any) => meta,
  ],
  [`${NAMESPACE}/request_user`]: (payload: any) => payload,
  [`${NAMESPACE}/request_user_success`]: (payload: IUser) => payload,
  [`${NAMESPACE}/request_admin_users`]: [
    (payload: IRequestUsersParams) => payload,
    (payload: IRequestUsersParams, meta: IRequestUsersMeta) => meta,
  ],
  [`${NAMESPACE}/request_admin_users_success`]: [
    (payload: IUser[]) => payload,
    (payload: any, meta: any) => meta,
  ],
  [`${NAMESPACE}/request_admin_user`]: (payload: any) => payload,
  [`${NAMESPACE}/request_admin_user_success`]: (payload: IUser) => payload,
  [`${NAMESPACE}/create_user`]: (payload: any) => payload,
  [`${NAMESPACE}/create_user_success`]: (payload: IUser) => payload,
  [`${NAMESPACE}/update_user`]: (payload: any) => payload,
  [`${NAMESPACE}/update_user_success`]: (payload: IUser) => payload,
  [`${NAMESPACE}/delete_user`]: (payload: any) => payload,
  [`${NAMESPACE}/delete_user_success`]: (payload: IUser) => payload,
})
