export const colors = {
  green: '#67BE5A',
  blue: {
    light: '#27B0DD',
    medium: '#4c84a0',
    midDark: '#2980B9',
    dark: '#063E5A',
  },
  purple: '#c252bb',
  red: '#D0021B',
  amberLight: '#ffeed1',
  amber: '#F5A623',
  amberDark: '#a86600',
  grey: {
    dark: '#9B9B9B',
    light: '#D3D3D3',
    lighter: '#F1F1F1',
  },
  text: '#444444',
}

export const size = {
  sx: 'font-size: 1rem;',
  small: 'padding: 10px 15px; font-size: 1.2rem;',
  medium: 'padding: 15px 15px; font-size: 1.4rem;',
  large:
    'padding: 17px 112px; font-size: 1.6rem; font-weight: bold; text-transform: uppercase;',
}

export const chartColors = [
  '#063e5a',
  '#005f78',
  '#008183',
  '#00a276',
  '#67be5a',
  '#00beb2',
]
