import { combineReducers } from 'redux'
import { uiAppReducer, uiAppEpics } from './app'
import { uiAllocationsReducer } from './allocations/state'
import { uiChannelsEpics, uiChannelsReducer } from './channels'
import { uiBookingsReducer, uiBookingsEpics } from './bookings'
import { uiTransactionsReducer, uiTransactionsEpics } from './transactions'
import { uiStatementEpics, uiStatementReducer } from './statements'
import { uiNotificationReducer, uiNotificationEpics } from './notifications'
import { uiSitesReducer, uiSitesEpics } from './sites'
import { uiUsersReducer, uiUsersEpics } from './users'
import { uiGlobalSearchReducer } from './globalsearch'
import { uiPaymentsEpics, uiPaymentsReducer } from './payments'
import { uiSuppliersReducer } from './suppliers/state'
import { uiSuppliersEpics } from './suppliers'
import { uiPayoutsEpics, uiPayoutsReducer } from './payouts'

export const uiEpics: any[] = [
  uiAppEpics,
  uiBookingsEpics,
  uiChannelsEpics,
  uiStatementEpics,
  uiTransactionsEpics,
  uiNotificationEpics,
  uiPaymentsEpics,
  uiPayoutsEpics,
  uiSitesEpics,
  uiSuppliersEpics,
  uiUsersEpics,
].map((e: any) => Object.keys(e).map((key: any) => e[key]))

export const uiReducer = combineReducers({
  app: uiAppReducer,
  allocations: uiAllocationsReducer,
  bookings: uiBookingsReducer,
  channels: uiChannelsReducer,
  statements: uiStatementReducer,
  transactions: uiTransactionsReducer,
  notifications: uiNotificationReducer,
  payments: uiPaymentsReducer,
  payouts: uiPayoutsReducer,
  sites: uiSitesReducer,
  suppliers: uiSuppliersReducer,
  users: uiUsersReducer,
  globalsearch: uiGlobalSearchReducer,
})

export { uiAppActions, uiAppInterfaces, uiAppSelectors } from './app'

export {
  uiBookingsActions,
  uiBookingsInterfaces,
  uiBookingsSelectors,
} from './bookings'

export {
  uiChannelsActions,
  uiChannelsInterfaces,
  uiChannelsSelectors,
} from './channels'

export {
  uiStatementActions,
  uiStatementInterfaces,
  uiStatementSelectors,
} from './statements'

export {
  uiGlobalSearchActions,
  uiGlobalSearchInterfaces,
  uiGlobalSearchSelectors,
} from './globalsearch'

export {
  uiPaymentsActions,
  uiPaymentsInterfaces,
  uiPaymentsSelectors,
} from './payments'

export {
  uiPayoutsActions,
  uiPayoutsInterfaces,
  uiPayoutsSelectors,
} from './payouts'

export {
  uiNotificationActions,
  uiNotificationInterfaces,
} from './notifications'

export { uiSitesInterfaces, uiSitesSelectors } from './sites'
