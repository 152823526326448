import { useState, useCallback } from 'react'
import { useQueryParam } from 'use-query-params'
import { ObjectParam } from '../views/utils/queryParams'
import { useDebouncedEffect } from './useDebouncedEffect'

export interface IFilter {
  [key: string]: string | number | undefined
}

export default function useFilter() {
  const [filter, _setFilter] = useQueryParam('filter', ObjectParam)

  const [activeFilter, setActiveFilter] = useState('')
  const [activeFilterValue, setActiveFilterValue] = useState('')

  const setFilter = useCallback(
    (filter: IFilter) => {
      _setFilter(filter, 'replaceIn')
    },
    [_setFilter],
  )

  useDebouncedEffect(
    () => {
      if (!activeFilter) {
        return false
      }

      if (activeFilterValue) {
        setFilter({ [activeFilter]: activeFilterValue })
      } else {
        setFilter({})
      }
    },
    1000,
    [activeFilter, activeFilterValue],
  )

  return {
    filter,
    setFilter,
    activeFilter,
    setActiveFilter,
    activeFilterValue,
    setActiveFilterValue,
  }
}
