import { ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { Observable, of } from 'rxjs'
import { map, switchMap, mergeMap, mapTo } from 'rxjs/operators'
import * as actions from './actions'
import { ENDPOINTS } from './constants'
import {
  get,
  catchRestError,
  optionsRequest,
  post,
  putRequest,
  deleteRequest,
} from '../../utils/http'
import { formatTrustId, liftPayload } from '../../utils/rx-operators'
import RestfulResult from '../../utils/RestfulResult'
import { IRequestBookingsParams } from './interfaces'
import { injectCurrentSitePath } from '../../utils/general'

/**
 * Get Bookings Schema when `actions.requestChannelsSchema` action is dispatched
 * @param action$ Action observable
 * @param state$ State observable
 */
export const requestBookingsSchema = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestBookingsSchema),
    liftPayload(),
    switchMap((payload: IRequestBookingsParams) =>
      of(payload).pipe(
        map(params => ({ params })),
        optionsRequest(
          injectCurrentSitePath(ENDPOINTS.BASE, state$.value),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(
                actions.requestBookingsSchemaSuccess(p, response.getContext()),
              ),
            ),
          ),
        ),
        catchRestError(actions.requestBookingsSchema.toString()),
      ),
    ),
  )

export const requestBookingsEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestBookings),
    liftPayload(),
    switchMap((payload: IRequestBookingsParams) =>
      of(payload).pipe(
        formatTrustId(),
        map(params => ({ params })),
        get(injectCurrentSitePath(ENDPOINTS.BASE, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestBookingsSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestBookings.toString()),
      ),
    ),
  )

export const requestBookingEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestBooking),
    liftPayload(),
    switchMap((payload: any) =>
      of(payload).pipe(
        map(urlReplacements => ({ urlReplacements })),
        get(injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestBookingSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestBooking.toString()),
      ),
    ),
  )

export const requestCreateBookingEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestCreateBooking),
    liftPayload(),
    switchMap((payload: any) =>
      of(payload).pipe(
        map(body => ({ body })),
        post(injectCurrentSitePath(ENDPOINTS.BASE, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(
                actions.requestCreateBookingSuccess(p, response.getContext()),
              ),
            ),
          ),
        ),
        catchRestError(actions.requestCreateBooking.toString()),
      ),
    ),
  )

export const requestUpdateBookingEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestUpdateBooking),
    liftPayload(),
    switchMap((payload: any) =>
      of(payload).pipe(
        map(body => ({ body, urlReplacements: { id: body.id } })),
        putRequest(injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(
                actions.requestUpdateBookingSuccess(p, response.getContext()),
              ),
            ),
          ),
        ),
        catchRestError(actions.requestUpdateBooking.toString()),
      ),
    ),
  )

export const requestDeleteBookingEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestDeleteBooking),
    liftPayload(),
    switchMap((payload: any) =>
      of(payload).pipe(
        map(body => ({ body, urlReplacements: { id: body.id } })),
        deleteRequest(
          injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(
                actions.requestDeleteBookingSuccess(p, response.getContext()),
              ),
            ),
          ),
        ),
        catchRestError(actions.requestDeleteBooking.toString()),
      ),
    ),
  )
