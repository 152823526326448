import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { uniq, get } from 'lodash'
import { parseLinkHeader } from '../../utils/http'
import { authActions } from '../auth'
import { sitesActions } from '../sites'
import { NAMESPACE } from './constants'
import { IInvoice, IInvoicesState, IRequestInvoicesParams } from './interfaces'

export const initialState: IInvoicesState = {
  schema: {},
  isLoading: false,
  isSingleLoading: false,
  sites: {
    /**
         [sitePath]: {
            allIds: [],
            byId: {},
            idsByParams: {},
            pages: 0
         }
         */
  },
}

const defaultSiteState = {
  allIds: [],
  byId: {},
  idsByParams: {},
  pages: {},
}

export const InvoicesSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    requestInvoicesSchema: state => {},
    requestInvoicesSchemaSuccess: (state, action: PayloadAction<any>) => {
      state.schema = action.payload
    },
    requestInvoices: (state, action: PayloadAction<IRequestInvoicesParams>) => {
      state.isLoading = true
    },
    requestInvoicesSuccess: {
      reducer: (state, action: PayloadAction<IInvoice[], string, any>) => {
        state.isLoading = false
        const ids = action.payload.map(inv => inv.id)
        const { currentSite } = action.meta
        state.sites[currentSite].allIds = ids
        state.sites[currentSite].byId = {}
        action.payload.forEach(inv => {
          state.sites[currentSite].byId[inv.id] = inv
        })
        state.sites[currentSite].pages = parseLinkHeader(
          action.meta.responseHeaders?.link || '',
        )
        /** @todo idsByParams */
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },
    requestInvoice: (state, action: PayloadAction<{ id: number }>) => {
      state.isSingleLoading = true
    },
    requestInvoiceSuccess: {
      reducer: (state, action: PayloadAction<IInvoice, string, any>) => {
        state.isSingleLoading = false
        const { currentSite } = action.meta
        state.sites[currentSite].allIds = uniq(
          state.sites[currentSite].allIds.concat([action.payload.id]),
        )
        state.sites[currentSite].byId[action.payload.id] = action.payload
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },
  },
  extraReducers: {
    [authActions.requestTokenSuccess]: (
      state,
      action: PayloadAction<IInvoice, string, any>,
    ) => {
      const currentSitePath = get(
        action.payload,
        ['default_site', 'path'],
        '',
      ).replace(/\//g, '')
      state.sites[currentSitePath] = defaultSiteState
    },
    [sitesActions.siteSelected]: (
      state,
      action: PayloadAction<IInvoice, string, any>,
    ) => {
      const currentSitePath = get(action.payload, ['path']).replace(/\//g, '')
      state.sites[currentSitePath] = defaultSiteState
    },
  },
})

const { actions, reducer } = InvoicesSlice

export { actions, reducer }
