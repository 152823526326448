import * as React from 'react'
import { useSelector } from 'react-redux'
import { authSelectors } from '../../../../state/modules/auth'
import { FormikProps, Form, withFormik } from 'formik'
import { first, omit, tail } from 'lodash'
import { Box, CheckBox } from 'grommet'
import classNames from 'classnames'
import * as yup from 'yup'
import {
  ITransaction,
  ICreateTransaction,
} from '../../../../state/modules/transactions/interfaces'
import { Text, Button, Loader, CurrencyInput } from '../..'
import { colors } from '../../../styles/variables'
import styled from 'styled-components'
import { addTrailingZero, convertToCents } from '../../../../utils/currency'

interface IRefundFormProps {
  transaction: ITransaction
  className?: string
  isApplyingRefund: boolean
  formatCurrency(value: number | string, currencies: string): string
  getCurrencySymbol(currencyName: string): string
  onSubmit(params: ICreateTransaction): void
}

interface IFormValues {
  total: string
  content: string
  channels: number
  bookings: any[]
  currencies: string
  psp: string
  transaction_types: string
  payment_methods: string
  linked_id: number
  total_remaining: number
}

const validationSchema = yup.object().shape({
  total: yup
    .number()
    .required('Please add a refund amount!')
    // need a normal function to maintain lexical scope for acccess to parent object.
    // tslint:disable-next-line:only-arrow-functions
    .test('is-valid', 'Cannot exceed refundable amount', function (val) {
      if (val && convertToCents(val) > this.parent.total_remaining) {
        return this.createError({
          path: this.path,
          message: 'Cannot exceed refundable amount',
        })
      }
      return true
    }),
})

const RefundForm = (props: IRefundFormProps & FormikProps<IFormValues>) => {
  const {
    setFieldValue,
    values,
    errors,
    transaction,
    isApplyingRefund,
    formatCurrency,
    getCurrencySymbol,
  } = props
  const classes = classNames(props.className, {
    'is-submitting': isApplyingRefund,
  })

  const isAdmin = useSelector(authSelectors.isAdmin)
  const handleCurrencyChange = (e: any) =>
    setFieldValue('total', e.target.value)

  const [checked, setChecked] = React.useState(false)

  const handleManualChange = (e: any) => {
    setChecked(e.target.checked)
    if (!checked) {
      setFieldValue('transaction_types', 'manual_refund')
    } else {
      setFieldValue('transaction_types', 'refund')
    }
  }

  return (
    <div className={classes}>
      {isApplyingRefund && <Loader text="Apply refund..." />}
      <Form className="form">
        <Text tag="h3" color={colors.blue.dark}>
          Refund
        </Text>
        <p>
          Refundable:{' '}
          {formatCurrency(transaction.total_remaining, transaction.currencies)}
        </p>
        <CurrencyInput
          prefix={getCurrencySymbol(transaction.currencies)}
          name="total"
          onChange={handleCurrencyChange}
          className="field"
          label="Refund"
          value={values.total}
          error={errors.total}
        />
        {isAdmin ? (
          <>
            <CheckBox
              checked={checked}
              label="Manual"
              onChange={handleManualChange}
              reverse={true}
            />
            {checked ? (
              <Box pad="small" width="medium">
                <Text
                  id="isManualCheckedNotice"
                  tag="span"
                  color={colors.red}
                  size="small"
                >
                  You should not process manual refunds without first refunding
                  the customer from the payment gateway.
                </Text>
              </Box>
            ) : null}
          </>
        ) : null}
        <footer>
          <Button
            styleType="secondary"
            label="Apply"
            size="small"
            type="submit"
          />
        </footer>
      </Form>
    </div>
  )
}

const StyledRefundForm = styled(RefundForm)`
  margin: 10px;
  &.is-submitting {
    form {
      display: none;
    }
  }
  h3 {
    margin: 0 0 10px 0;
    border-bottom: 1px solid ${colors.grey.lighter};
  }
  .form span {
    display: inline-block;
    margin-right: 18px;
    overflow: auto;
    white-space: normal;
  }
  .field {
    margin-bottom: 10px;
    position: relative;
  }
  .field label {
    margin-right: 30px;
  }
  #isManualCheckedNotice {
    margin-left: 18%;
  }
  footer {
    border-top: 1px solid ${colors.grey.lighter};
    text-align: right;
    margin-top: 10px;
    padding-top: 10px;
  }
  footer button {
    font-size: 1rem;
    padding: 6px 8px;
  }
  .field .error {
    display: block;
    position: absolute;
    right: 0;
    top: 30px;
    bottom: 30px;
    font-size: 12px;
  }
  #content::placeholder {
    font-size: 14px;
    font-weight: normal;
  }
`

export default withFormik<IRefundFormProps, IFormValues>({
  validationSchema,
  validateOnBlur: true,
  validateOnChange: true,
  mapPropsToValues: (props: IRefundFormProps) => {
    const { transaction } = props
    const { id, channels, bookings, currencies, psp, total_remaining } =
      transaction
    return {
      channels,
      bookings: bookings.map(b => omit(b, ['reference'])),
      currencies,
      psp,
      transaction_types: 'refund',
      payment_methods: 'credit-card',
      linked_id: id,
      total_remaining,
      total: addTrailingZero(total_remaining / 100),
      content: '',
    }
  },
  /**
   * Reset total to transaction.bookings[0].total if forex converted amount > transaction.bookings[0].total
   * @link https://app.clickup.com/t/2ffwjx
   */
  handleSubmit: (values: any, { props }) => {
    const { transaction } = props
    const totalInCents = convertToCents(values.total)
    const firstTransactionBooking = first(transaction.bookings)
    const tailBookings = tail(transaction.bookings)
    const convertedTotal = transaction.forex_rate
      ? convertToCents(values.total / parseFloat(transaction.forex_rate))
      : totalInCents
    const bookingTotal =
      transaction.forex_rate &&
      firstTransactionBooking &&
      convertedTotal > firstTransactionBooking.total
        ? firstTransactionBooking.total
        : convertedTotal

    const params = {
      ...values,
      total: totalInCents,
      bookings: [
        { ...firstTransactionBooking, total: bookingTotal },
        ...tailBookings,
      ],
    }
    props.onSubmit(params)
  },
})(StyledRefundForm)
