import { createSelector } from 'reselect'
import { get } from 'lodash'
import { NAMESPACE } from './constants'
import { IInvoicesState } from './interfaces'
import { sitesSelectors } from '../sites'

const getState = (state: any) => state

const getInvoicesState = (state: any): IInvoicesState => state[NAMESPACE]

/** @todo fix type */
// const getSlice = (slice: keyof IInvoicesState) => createSelector(getInvoicesState, (payouts) => get(payouts, [slice]));
// const getSlice = (slice: any) => createSelector(getInvoicesState, (payouts) => get(payouts, [slice]));

// export const getInvoicesSchema = getSlice('schema');
export const getInvoicesSchema = createSelector(
  getInvoicesState,
  invoicesState => invoicesState.schema,
)

// export const getInvoiceIds = getSlice('allIds');
export const getInvoiceIds = createSelector(
  [getInvoicesState, sitesSelectors.getCurrentSitePath],
  (invoicesState, currentSite) =>
    get(invoicesState, ['sites', currentSite, 'allIds'], []),
)

// export const getInvoicesById = getSlice('byId');
export const getInvoicesById = createSelector(
  [getInvoicesState, sitesSelectors.getCurrentSitePath],
  (invoicesState, currentSite) =>
    get(invoicesState, ['sites', currentSite, 'byId'], []),
)

// export const getRequestParams = getSlice('idsByParams');
export const getRequestParams = createSelector(
  [getInvoicesState, sitesSelectors.getCurrentSitePath],
  (invoicesState, currentSite) =>
    get(invoicesState, ['sites', currentSite, 'idsByParams'], []),
)

export const getInvoiceById = createSelector(
  getInvoicesById,
  invoices => (id: number) => get(invoices, [id]),
)

export const getInvoices = createSelector(
  [getInvoiceIds, getInvoicesById],
  (ids: number[], byId: any) => ids.map(id => byId[id]),
)

export const isLoading = createSelector(
  [getInvoicesState],
  invoicesState => invoicesState.isLoading,
)

export const isSingleLoading = createSelector(
  [getInvoicesState],
  invoicesState => invoicesState.isSingleLoading,
)

export const getPages = createSelector(
  [getState, getInvoicesState],
  (state, invoices) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(invoices, `sites[${sitePath}].pages`, {
      next: undefined,
      prev: undefined,
    })
  },
)
