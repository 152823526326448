import { createActions } from 'redux-actions'
import { IRequestSitesParams, IRequestSitesResponseObject } from './interfaces'
import { NAMESPACE } from './constants'

export const {
  [NAMESPACE]: {
    requestSitesSchema,
    requestSitesSchemaSuccess,
    requestSites,
    requestSitesSuccess,
    requestSite,
    requestSiteSuccess,
    requestCreateSite,
    requestCreateSiteSuccess,
    requestUpdateSite,
    requestUpdateSiteSuccess,
    updateSearchTerm,
    siteSelected,
  },
} = createActions({
  [`${NAMESPACE}/request_sites_schema`]: () => '',
  [`${NAMESPACE}/request_sites_schema_success`]: (payload: any) => payload,
  [`${NAMESPACE}/request_sites`]: (payload: IRequestSitesParams) => payload,
  [`${NAMESPACE}/request_sites_success`]: [
    (payload: IRequestSitesResponseObject[]) => payload,
    (payload: IRequestSitesResponseObject, meta: any) => meta,
  ],
  [`${NAMESPACE}/request_site`]: (payload: { id: number }) => payload,
  [`${NAMESPACE}/request_site_success`]: [
    (payload: IRequestSitesResponseObject[]) => payload,
    (payload: IRequestSitesResponseObject, meta: any) => meta,
  ],
  [`${NAMESPACE}/request_create_site`]: (payload: any) => payload,
  [`${NAMESPACE}/request_create_site_success`]: (
    payload: IRequestSitesResponseObject,
  ) => payload,
  [`${NAMESPACE}/request_update_site`]: (payload: any) => payload,
  [`${NAMESPACE}/request_update_site_success`]: (
    payload: IRequestSitesResponseObject,
  ) => payload,
  [`${NAMESPACE}/update_search_term`]: (payload: string) => payload,
  [`${NAMESPACE}/site_selected`]: (payload: IRequestSitesResponseObject) =>
    payload,
  //   [`${NAMESPACE}/site_selected_with_redirect`]: (payload: IRequestSitesResponseObject) => payload
})
