import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { map, uniq } from 'lodash'
import { NAMESPACE } from './constants'
import { IBatch, IBatchesState, IRequestBatchesParams } from './interfaces'

export const initialState: IBatchesState = {
  schema: {},
  allIds: [],
  byId: {},
  byCurrency: {},
  loading: false,
  isMarkAsReleasedLoading: false,
}

export const BatchesSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    requestBatchesSchema: state => {},
    requestBatchesSchemaSuccess: (state, action: PayloadAction<any>) => {
      state.schema = action.payload
    },
    requestBatches: (state, action: PayloadAction<IRequestBatchesParams>) => {
      state.loading = true
    },
    requestBatchesSuccess: {
      reducer: (state, action: PayloadAction<IBatch[]>) => {
        state.allIds = map(action.payload, ({ id }) => id)
        map(action.payload, payout => {
          state.byId[payout.id] = payout
        })
        state.loading = false
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },
    requestBatch: (state, action: PayloadAction<any>) => {},
    requestBatchSuccess: {
      reducer: (state, action: PayloadAction<IBatch>) => {
        state.allIds = uniq(state.allIds.concat([action.payload.id]))
        state.byId[action.payload.id] = action.payload
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },
    createBatch: (state, action: PayloadAction<{ payouts: number[] }>) => {},
    createBatchSuccess: {
      reducer: (state, action: PayloadAction<IBatch>) => {
        state.allIds.push(action.payload.id)
        state.byId[action.payload.id] = action.payload
        state.byCurrency[action.payload.currencies] = action.payload
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },
    markAsReleased: (
      state,
      action: PayloadAction<{ id: number; batch_number: string }>,
    ) => {
      state.isMarkAsReleasedLoading = true
    },
    markAsReleasedSuccess: {
      reducer: (state, action: PayloadAction<IBatch>) => {
        state.isMarkAsReleasedLoading = false
        state.allIds = uniq(state.allIds.concat([action.payload.id]))
        state.byId[action.payload.id] = action.payload
        state.byCurrency[action.payload.currencies] = action.payload
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },
  },
})

const { actions, reducer } = BatchesSlice

export { actions, reducer }
