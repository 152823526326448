import React, { Component } from 'react'
import ReactTable from 'react-table'
import classNames from 'classnames'
import 'react-table/react-table.css'
import Loader from '../../atoms/Loader/Loader'
import Pagination from './Pagination'
import './dataTable.scss'

export interface IDataTableProps {
  manual: boolean
  data: any
  columns: any
  loading: boolean
  pageSize: number
  noDataText?: string
  expandable?: Element
  SubComponent?: any
  fitContainer?: boolean
  pages?: number
  page?: number
  plain?: boolean
  onNext?(): void
  onPrevious?(): void
}

interface IDataTableState {
  tableHeight: string
}

class DataTable extends Component<IDataTableProps, IDataTableState> {
  public state = {
    tableHeight: 'auto',
  }

  private tableRef: HTMLDivElement | null = null

  public componentDidMount() {
    if (this.props.fitContainer) {
      this.updateTableHeight()
      window.addEventListener('resize', this.updateTableHeight)
    }
  }

  public componentWillUnmount() {
    if (this.props.fitContainer) {
      window.removeEventListener('resize', this.updateTableHeight)
    }
  }

  public getPagination = () => {
    const { page, pages, onNext, onPrevious } = this.props
    if (page && pages && onNext && onPrevious) {
      return (
        <Pagination
          page={page}
          pages={pages}
          onNext={onNext}
          onPrevious={onPrevious}
        />
      )
    }
  }

  public updateTableHeight = () => {
    if (this.tableRef) {
      const newTableHeight = Math.round(
        window.innerHeight - this.tableRef.getBoundingClientRect().top - 70,
      )
      this.setState({ tableHeight: `${newTableHeight}px` })
    }
  }

  public maybeRenderTable = () => {
    const {
      manual,
      data,
      columns,
      loading,
      noDataText,
      pageSize,
      SubComponent,
    } = this.props

    return loading ? (
      <Loader text="Loading..." />
    ) : (
      <ReactTable
        manual={manual}
        data={data}
        columns={columns}
        loading={loading}
        noDataText={noDataText}
        pageSize={pageSize}
        SubComponent={SubComponent}
        showPagination={false}
        style={{ height: this.state.tableHeight }}
      />
    )
  }

  public render(): JSX.Element {
    const classes = classNames('ReactTable-wrapper', {
      'plain-table': this.props.plain,
    })

    return (
      <div
        className={classes}
        ref={ref => {
          this.tableRef = ref
        }}
        style={{ height: this.state.tableHeight }}
      >
        {this.maybeRenderTable()}
        {this.getPagination()}
      </div>
    )
  }
}

export default DataTable
