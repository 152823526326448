import * as React from 'react'

interface ILogoutPageProps {
  logout(): void
}

export default class LogoutPage extends React.Component<ILogoutPageProps, {}> {
  public componentDidMount() {
    this.props.logout()
  }

  public render(): null {
    return null
  }
}
