import { format as datefnsFormat } from 'date-fns'

/**
 * Seconds since Epoch. JWT `exp` uses this format.
 */
export const getNumericDate = () => Math.floor(Date.now() / 1000)

/**
 * API date format `YYYY-MM-DD HH:SS:mm` isn't supported by `new Date`
 * @link http://ecma-international.org/ecma-262/5.1/#sec-15.9.1.15
 * @link https://stackoverflow.com/questions/13363673/javascript-date-is-invalid-on-ios/13363791#13363791
 */
export const formatDate = (date: string, format: string) =>
  date ? datefnsFormat(new Date(date.replace(' ', 'T')), format) : ''
