import { Box } from 'grommet'
import moment from 'moment'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce, usePrevious } from 'react-use'
import styled from 'styled-components'
import { Anchor, Button, DataTable } from '../../../'
import {
  allocationsActions,
  allocationsSelectors,
} from '../../../../../state/modules/allocations'
import { IAllocation } from '../../../../../state/modules/allocations/interfaces'
import { sitesSelectors } from '../../../../../state/modules/sites'
import { ITransaction } from '../../../../../state/modules/transactions/interfaces'
import { uiTransactionsSelectors } from '../../../../../state/modules/ui/transactions'
import { formatCurrency } from '../../../../../utils/currency'
import { redirect } from '../../../../../utils/router'
import { colors } from '../../../../styles/variables'
import CreateAllocationModal from '../CreateAllocationModal'

type Props = {
  className?: string
  transaction: ITransaction
  channelCount: number
  canUserCreateAllocation: boolean
  getTransaction(): void
}

type TRow = {
  id: number
  trust_id: string
  title: string
  channels_name: string
  currencies: string
  total: number
  color: 'red' | 'green'
  statement_date: string
}

type TData = TRow[]

const transformAllocations = (allocations: IAllocation[]): TData =>
  allocations.reduce<TData>((acc, allocation) => {
    if (allocation.reversed) {
      acc.push({
        id: allocation.id,
        trust_id: allocation.trust_id,
        title: `${allocation.title} Reversal`,
        channels_name: allocation.channels_name,
        currencies: allocation.currencies,
        total: allocation.total,
        color: 'green',
        statement_date: allocation.statement_date,
      })
    }
    acc.push({
      id: allocation.id,
      trust_id: allocation.trust_id,
      title: allocation.title,
      channels_name: allocation.channels_name,
      currencies: allocation.currencies,
      total: allocation.total,
      color: 'red',
      statement_date: allocation.statement_date,
    })
    return acc
  }, [])

const AllocationsAccordionPanel: FC<Props> = ({
  className,
  transaction,
  channelCount,
  canUserCreateAllocation,
  getTransaction,
}) => {
  const dispatch = useDispatch()
  const [isCreateAllocationModalOpen, setIsCreateAllocationModalOpen] =
    React.useState(false)
  const isAddingAllocation = useSelector(
    uiTransactionsSelectors.isAddingAllocation,
  )
  const isLoading = useSelector(allocationsSelectors.isLoading)
  const currentSitePath: string = useSelector(sitesSelectors.getCurrentSitePath)
  const transactionAllocations = useSelector(state =>
    allocationsSelectors.getAllocationsByTransactionId(state as never)(
      transaction.id,
    ),
  )
  const tableData: TData = transformAllocations(transactionAllocations)

  useEffectOnce(() => {
    dispatch(
      allocationsActions.requestAllocations({ transactions: transaction.id }),
    )
  })

  const wasAddingAllocation = usePrevious(isAddingAllocation)

  // Refetch transaction once allocation is added
  useEffect(() => {
    if (wasAddingAllocation && !isAddingAllocation) {
      dispatch(
        allocationsActions.requestAllocations({ transactions: transaction.id }),
      )
    }
  }, [isAddingAllocation, wasAddingAllocation, dispatch, transaction.id])

  const columns = [
    {
      Header: 'Trust ID',
      accessor: 'trust_id',
      style: {
        width: 80,
      },
    },
    {
      Header: 'Title',
      accessor: 'title',
      Cell: (row: any) => {
        const redirectTo = () =>
          redirect(`/${currentSitePath}/allocations/${row.original.id}`)
        return <Anchor onClick={redirectTo}>{row.original.title}</Anchor>
      },
    },
    {
      Header: 'Channel',
      accessor: 'channels_name',
    },
    {
      Header: 'Total',
      accessor: 'total',
      Cell: (row: any) => (
        <span
          style={{
            color: row.original.color === 'red' ? colors.red : colors.green,
          }}
        >
          {`${row.original.currencies} ${formatCurrency(
            row.original.total,
            true,
          )}`}
        </span>
      ),
    },
    {
      Header: 'Released',
      accessor: 'statement_date',
      Cell: (row: any) =>
        moment(row.original.statement_date).format('YYYY-MM-DD'),
    },
  ]

  return (
    <div className={className}>
      <DataTable
        manual={true}
        plain={true}
        columns={columns}
        data={tableData}
        pageSize={tableData.length}
        loading={isLoading}
      />
      <Box
        background="white"
        fill="horizontal"
        pad="medium"
        round="small"
        className="single-transaction-add-allocations"
      >
        {transaction.status === 'complete' &&
          ['purchase', 'capture', 'retained_purchase'].includes(
            transaction.transaction_types,
          ) &&
          channelCount >= 2 &&
          canUserCreateAllocation && ( // https://app.clickup.com/t/23zrp29?comment=662407386
            <Button
              styleType="secondary"
              size="small"
              label="Create Allocation"
              onClick={() => setIsCreateAllocationModalOpen(true)}
            />
          )}
      </Box>
      <CreateAllocationModal
        transaction={transaction}
        isOpen={isCreateAllocationModalOpen}
        onClose={() => setIsCreateAllocationModalOpen(false)}
        isAddingAllocation={isAddingAllocation}
      />
    </div>
  )
}

export default styled(AllocationsAccordionPanel)``
