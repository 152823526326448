import React, { ReactChild } from 'react'
import styled from 'styled-components'

type Props = {
  className?: string
  children: ReactChild
}

const DashboardPageLayout = ({ className, children }: Props) => {
  return <div className={className}>{children}</div>
}

export default styled(DashboardPageLayout)`
  display: flex;

  > div:not(last-child) {
    margin-right: 100px;
  }

  h2 {
    font-size: 22px;
    margin-bottom: 32px;
  }

  small {
    display: block;
    max-width: 170px;
    font-size: 11px;
    line-height: 13px;
    font-style: italic;
  }
`
