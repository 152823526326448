import { ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { getCurrentFilter, getCurrentChannel } from './selectors'
import * as actions from './actions'
import { perPage } from './constants'
import { statementActions } from '../../statements'
import { IRequestStatementsParams } from '../../statements/interfaces'

export const fetchDataOnPageChange = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.setCurrentPage),
    map(action => {
      const currentFilter = getCurrentFilter(state$.value)
      const currentChannel = getCurrentChannel(state$.value)
      let params: IRequestStatementsParams = {
        per_page: perPage,
        page: action.payload,
      }
      if (currentChannel) {
        params.channels = currentChannel
      }
      if (currentFilter) {
        params = {
          ...params,
          ...JSON.parse(currentFilter),
        }
      }
      return statementActions.requestStatements(params)
    }),
  )
