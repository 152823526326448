import { createSelector } from 'reselect'
import { get } from 'lodash'
import { globalSearchSelectors } from '../../globalsearch'
import { IGlobalSearchResult } from '../../globalsearch/interfaces'
import { currencySelectors } from '../../currency'

const getState = (state: any) => state

export const getGlobalSearchTableData = createSelector(
  getState,
  (state: any) => (page: number) => {
    const globalsearch =
      globalSearchSelectors.getGlobalSearchByPage(state)(page)

    return globalsearch.map((search: IGlobalSearchResult) => {
      const {
        id,
        trust_id,
        created,
        payee_surname,
        payee_email,
        bookings,
        total,
        currencies,
        last_four_digits,
        transaction_types,
        status,
        _links,
      } = search
      return {
        id,
        trust_id,
        created,
        payee_surname,
        payee_email,
        reference: get(bookings, '[0].reference', ''),
        transaction_types,
        total: currencySelectors.formatValueWithCurrency(state)(
          total,
          currencies,
        ),
        status,
        last_four_digits,
        _links: get(_links.self, '[0].href', ''),
      }
    })
  },
)
