import { get } from 'lodash'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { Button } from '../..'
import { bookingSelectors } from '../../../../state/modules/bookings'
import { channelSelectors } from '../../../../state/modules/channels'
import { sitesSelectors } from '../../../../state/modules/sites'
import { convertFromCents } from '../../../../utils/currency'
import timfinityTheme from '../../../styles/timfinityTheme'
import CreateProtectionOnlyTransactionModal from './CreateProtectionOnlyTransactionModal'

type Props = {
  bookingId: string
}

const CreateTransactionButton = ({ bookingId }: Props) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const getChannelById = useSelector(channelSelectors.getChannelById)

  const booking = useSelector(state =>
    bookingSelectors.getBookingById(state)(bookingId),
  )

  const isSiteProtectionOnly = useSelector(
    sitesSelectors.getCurrentSiteProtectionOnly,
  )

  const bookingChannel = getChannelById(booking?.channels)
  const bookingChannelForexFeed = bookingChannel?.forex_feed

  const status = booking?.status
  const date = booking?.date

  return (
    booking &&
    !['paid'].includes(status) &&
    date >= moment().format('YYYY-MM-DD') &&
    isSiteProtectionOnly && (
      <>
        <Button
          label="Create Transaction"
          style={{
            fontSize: get(timfinityTheme, 'global.font.size'),
            lineHeight: get(timfinityTheme, 'global.font.height'),
          }}
          styleType="primary"
          onClick={() => setIsModalOpen(true)}
        />
        <CreateProtectionOnlyTransactionModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          booking={booking}
          bookingChannel={bookingChannel}
          bookingChannelForexFeed={bookingChannelForexFeed}
          initialValues={{
            firstname: booking.firstname,
            surname: booking.surname,
            email: booking.email,
            currencies: booking.currencies,
            countries: booking.countries,
            total_unpaid: convertFromCents(booking.total_unpaid),
            psp: '',
            base_value: 0,
            // used for validation, values above change in form so need access to previous booking values
            _total_unpaid: convertFromCents(booking.total_unpaid),
            _currencies: booking.currencies,
          }}
        />
      </>
    )
  )
}

export default CreateTransactionButton
