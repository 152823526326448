import * as Sentry from '@sentry/browser'
import { Middleware, AnyAction } from 'redux'
import { authActions, authSelectors } from '../modules/auth'

const sentryContext: Middleware =
  (store: any) => (next: any) => (action: AnyAction) => {
    if (
      process.env?.SENTRY_ENV === 'production' &&
      action.type === authActions.requestTokenSuccess.toString()
    ) {
      const state = store.getState()
      const email = authSelectors.getUserEmail(state)
      const name = authSelectors.getUserName(state)

      if (email && name) {
        Sentry.configureScope(scope => {
          scope.setUser({ name, email })
        })
      }
    }
    return next(action)
  }

export default sentryContext
