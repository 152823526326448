import * as React from 'react'
import { Modal } from '../../..'
import {
  ITransaction,
  ICreateTransaction,
} from '../../../../../state/modules/transactions/interfaces'
import ApplyCaptureForm from './ApplyCaptureForm'

interface IApplyCaptureModalProps {
  isOpen: boolean
  transaction: ITransaction
  isApplyingCapture: boolean
  formatCurrency(value: number | string, currencies: string): string
  getCurrencySymbol(currencyName: string): string
  onClose(): void
  onSubmit(params: ICreateTransaction): void
}

const ApplyCaptureModal = (props: IApplyCaptureModalProps) => {
  const {
    isOpen,
    onClose,
    transaction,
    onSubmit,
    isApplyingCapture,
    formatCurrency,
    getCurrencySymbol,
  } = props

  const refundForm = () => (
    <ApplyCaptureForm
      transaction={transaction}
      onSubmit={onSubmit}
      isApplyingCapture={isApplyingCapture}
      formatCurrency={formatCurrency}
      getCurrencySymbol={getCurrencySymbol}
    />
  )

  return (
    <Modal
      isOpen={isOpen}
      showCloseIcon={true}
      onClose={onClose}
      InnerComponent={refundForm()}
    />
  )
}

export default ApplyCaptureModal
