import { useCallback, useEffect, useMemo } from 'react'
import { useQueryParam } from 'use-query-params'
import { difference, isEmpty } from 'lodash'
import { ObjectParam } from '../views/utils/queryParams'
import useFilter from './useFilter'
import moment from 'moment'

export default function useDateFilter(
  exclusions: string[] = [],
  dontUseDefaults: boolean = false,
) {
  const [dateFilters, setDateFilters] = useQueryParam('dateFilter', ObjectParam)

  const { filter } = useFilter()

  const isFilterExcluded = useMemo(
    () =>
      !isEmpty(filter) &&
      difference(Object.keys(filter || {}), exclusions).length === 0,
    [filter, exclusions],
  )

  /**
   * Proxy function to conditionally set date filters
   */
  const _setDateFilters = useCallback(
    (
      newValue: {
        [key: string]: string | number | undefined
      },
      updateType?: 'replace' | 'push' | 'replaceIn' | 'pushIn' | undefined,
    ) => {
      if (!isFilterExcluded) {
        setDateFilters(newValue, updateType)
      } else {
        setDateFilters({})
      }
    },
    [setDateFilters, isFilterExcluded],
  )

  // const [previousDateFilter, setPreviousDateFilter] = useState(dateFilters);
  const defaultDateFilters = useMemo(
    () => ({
      after: moment().subtract(2, 'weeks').format('YYYY-MM-DD 00:00:00'),
      before: moment().format('YYYY-MM-DD 23:59:59'),
    }),
    [],
  )

  useEffect(() => {
    if (isFilterExcluded || dontUseDefaults) {
      // setPreviousDateFilter(dateFilters);
      _setDateFilters({})
    } else {
      // previousDateFilter && _setDateFilters(previousDateFilter);
      if (!dateFilters) {
        _setDateFilters(defaultDateFilters)
      }
    }
  }, [
    filter,
    isFilterExcluded,
    _setDateFilters,
    defaultDateFilters,
    dateFilters,
    dontUseDefaults,
  ])

  return {
    dateFilters,
    setDateFilters: _setDateFilters,
    isFilterExcluded,
  }
}
