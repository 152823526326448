import { handleActions } from 'redux-actions'
import { AnyAction } from 'redux'
import { get as _get } from 'lodash'
import * as actions from './actions'
import model from './model'
import { IUITransactionsModel } from './interfaces'
import { transactionsActions } from '../../transactions'
import * as sitesActions from '../../sites/actions'
import { paymentsActions } from '../../payments'

export default handleActions(
  {
    [actions.clearServerErrors]: (state: IUITransactionsModel) => ({
      ...state,
      serverErrors: null,
    }),
    [actions.setCurrentPage]: (
      state: IUITransactionsModel,
      action: AnyAction,
    ) => ({
      ...state,
      currentPage: action.payload,
    }),
    [actions.setCurrentFilter]: (
      state: IUITransactionsModel,
      action: AnyAction,
    ) => ({
      ...state,
      currentFilter: action.payload,
    }),
    [transactionsActions.requestCreateTransaction]: (
      state: IUITransactionsModel,
      action: AnyAction,
    ) => {
      if (
        _get(action, 'payload.transaction_types', '') === 'refund' ||
        _get(action, 'payload.transaction_types', '') === 'manual_refund'
      ) {
        return {
          ...state,
          isApplyingRefund: true,
        }
      }
      if (_get(action, 'payload.transaction_types', '') === 'chargeback') {
        return {
          ...state,
          isApplyingChargeback: true,
        }
      }
      if (
        _get(action, 'payload.transaction_types', '') === 'chargeback-override'
      ) {
        return {
          ...state,
          isApplyingChargebackOverride: true,
        }
      }
      if (
        _get(action, 'payload.transaction_types', '') === 'chargeback-reversal'
      ) {
        return {
          ...state,
          isApplyingReversal: true,
        }
      }
      if (_get(action, 'payload.transaction_types', '') === 'capture') {
        return {
          ...state,
          isApplyingCapture: true,
        }
      }
      if (_get(action, 'payload.transaction_types', '') === 'void') {
        return {
          ...state,
          isApplyingVoid: true,
        }
      }
      return {
        ...state,
        isCreateTransactionSubmitting: true,
      }
    },
    [transactionsActions.requestCreateTransactionSuccess]: (
      state: IUITransactionsModel,
      action: AnyAction,
    ) => ({
      ...state,
      isApplyingRefund: false,
      isApplyingChargeback: false,
      isApplyingChargebackOverride: false,
      isApplyingReversal: false,
      isApplyingCapture: false,
      isApplyingVoid: false,
      isCreateTransactionSubmitting: false,
    }),
    [transactionsActions.requestUpdateTransaction]: (
      state: IUITransactionsModel,
      action: AnyAction,
    ) => ({
      ...state,
      isUpdatingTransaction: true,
      isApplyingChargeback:
        _get(action, 'payload[0].transaction_types', '') === 'chargeback',
    }),
    [transactionsActions.requestUpdateTransactionSuccess]: (
      state: IUITransactionsModel,
      action: AnyAction,
    ) => ({
      ...state,
      isUpdatingTransaction: false,
      isApplyingChargeback: false,
    }),
    [transactionsActions.resendEmailReceipt]: (
      state: IUITransactionsModel,
      action: AnyAction,
    ) => {
      return {
        ...state,
        isApplyingResend: true,
      }
    },
    [transactionsActions.resendEmailReceiptSuccess]: (
      state: IUITransactionsModel,
      action: AnyAction,
    ) => ({
      ...state,
      isApplyingResend: false,
    }),
    [transactionsActions.addAllocation]: (
      state: IUITransactionsModel,
      action: AnyAction,
    ) => {
      return {
        ...state,
        isAddingAllocation: true,
      }
    },
    [transactionsActions.addAllocationSuccess]: (
      state: IUITransactionsModel,
      action: AnyAction,
    ) => ({
      ...state,
      isAddingAllocation: false,
    }),
    [paymentsActions.deletePaymentSuccess]: (
      state: IUITransactionsModel,
      action: AnyAction,
    ) => ({
      ...state,
      isReversingPayment: false,
    }),
    http_error: (state: IUITransactionsModel, action: AnyAction) => {
      if (
        _get(action, 'meta.action', '') ===
        transactionsActions.requestCreateTransaction.toString()
      ) {
        return {
          ...state,
          serverErrors: action.payload.response,
          isApplyingRefund: false,
          isApplyingChargeback: false,
          isApplyingChargebackOverride: false,
          isApplyingReversal: false,
          isCreateTransactionSubmitting: false,
          isAddingAllocation: false,
        }
      } else if (
        _get(action, 'meta.action', '') ===
        transactionsActions.addAllocation.toString()
      ) {
        return {
          ...state,
          isAddingAllocation: false,
        }
      } else if (
        _get(action, 'meta.action', '') ===
        paymentsActions.deletePayment.toString()
      ) {
        return {
          ...state,
          isReversingPayment: false,
        }
      } else {
        return state
      }
    },
    [actions.setChannel]: (state: IUITransactionsModel, action: AnyAction) => {
      return {
        ...state,
        currentChannel: _get(action.payload, 'id', null),
      }
    },
    [sitesActions.siteSelected]: (state: IUITransactionsModel) => {
      return {
        ...state,
        currentFilter: '',
        currentChannel: null,
      }
    },
  },
  model,
)
