import { ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { Observable, of } from 'rxjs'
import { map, switchMap, mergeMap, mapTo, pluck } from 'rxjs/operators'
import { actions } from './state'
import { ENDPOINTS } from './constants'
import { get, catchRestError, optionsRequest } from '../../utils/http'
import RestfulResult from '../../utils/RestfulResult'
import { injectCurrentSitePath } from '../../utils/general'
import { IRequestInvoicesParams } from './interfaces'

export const requestInvoicesSchemaEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestInvoicesSchema),
    pluck('payload'),
    switchMap((payload: null) =>
      of(payload).pipe(
        optionsRequest(
          injectCurrentSitePath(ENDPOINTS.BASE, state$.value),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(mapTo(actions.requestInvoicesSchemaSuccess(p))),
          ),
        ),
        catchRestError(actions.requestInvoicesSchema.toString()),
      ),
    ),
  )

export const requestInvoicesEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestInvoices),
    pluck('payload'),
    switchMap((payload: IRequestInvoicesParams) =>
      of(payload).pipe(
        map(params => ({ params })),
        get(injectCurrentSitePath(ENDPOINTS.BASE, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestInvoicesSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestInvoices.toString()),
      ),
    ),
  )

export const requestInvoiceEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestInvoice),
    pluck('payload'),
    switchMap((payload: { id: number }) =>
      of(payload).pipe(
        map(urlReplacements => ({ urlReplacements })),
        get(
          injectCurrentSitePath(
            `${ENDPOINTS.ENTITY}?csv_url=true`,
            state$.value,
          ),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestInvoiceSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestInvoice.toString()),
      ),
    ),
  )
