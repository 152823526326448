import { handleActions } from 'redux-actions'
import { AnyAction } from 'redux'
import * as actions from './actions'
import model from './model'
import { IUIStatementsModel } from './interfaces'
import { statementActions } from '../../statements'

export default handleActions(
  {
    [actions.clearServerErrors]: (state: IUIStatementsModel) => ({
      ...state,
      serverErrors: null,
    }),
    [actions.setCurrentPage]: (
      state: IUIStatementsModel,
      action: AnyAction,
    ) => ({
      ...state,
      currentPage: action.payload,
    }),
    [actions.setCurrentFilter]: (
      state: IUIStatementsModel,
      action: AnyAction,
    ) => ({
      ...state,
      currentFilter: action.payload,
    }),
    [actions.setChannel]: (
      state: IUIStatementsModel = model,
      action: AnyAction,
    ) => ({
      ...state,
      channel: action.payload,
    }),
    [actions.setFilter]: (
      state: IUIStatementsModel = model,
      action: AnyAction,
    ) => ({
      ...state,
      filter: action.payload,
    }),
    [statementActions.updateStatement]: (state: IUIStatementsModel) => ({
      ...state,
      isUpdatingStatus: true,
    }),
    [statementActions.updateStatementSuccess]: (state: IUIStatementsModel) => ({
      ...state,
      isUpdatingStatus: false,
    }),
  },
  model,
)
