import * as React from 'react'
import { Grommet, RadioButtonGroup, RadioButtonGroupProps } from 'grommet'
import styled from 'styled-components'
import timfinityTheme from '../../../styles/timfinityTheme'

interface IRadioGroupProps extends RadioButtonGroupProps {
  className?: string
  inline?: boolean
  name: string
}

const RadioGroup = (props: IRadioGroupProps): JSX.Element => {
  return (
    <Grommet theme={timfinityTheme} className={props.className}>
      <RadioButtonGroup className="radio-group" fill="horizontal" {...props} />
    </Grommet>
  )
}

const StyledRadioGroup = styled(RadioGroup)`
  ${(props: IRadioGroupProps) => `
        ${
          props.inline &&
          `
            display: flex;
            flex-direction: row;
            label {
                margin-right: 20px;
            }
        `
        }
    `}
`

export default StyledRadioGroup
