import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { get } from 'lodash'
import { Action } from 'redux'

export const liftPayload = () => (source$: Observable<Action>) =>
  source$.pipe(map((action: any) => action.payload))

export const liftPayloadAndUrlReplacements =
  () => (source$: Observable<Action>) =>
    source$.pipe(
      map((action: any) => [
        action.payload,
        get(action, 'meta.urlReplacements', {}),
      ]),
    )

/**
 * Takes last segment after splitting by '-'
 * @link https://app.asana.com/0/1199083011771581/1199079241403940
 */
export const formatTrustId = () => (source$: Observable<any>) =>
  source$.pipe(
    map((params: any) => {
      let newParams = { ...params }

      if (params.include) {
        newParams = {
          ...newParams,
          include:
            typeof params.include === 'number'
              ? params.include
              : params.include.split('-').pop(),
        }
      }

      if (params.transactions) {
        newParams = {
          ...newParams,
          transactions:
            typeof params.transactions === 'number'
              ? params.transactions
              : params.transactions.split('-').pop(),
        }
      }

      return newParams
    }),
  )
