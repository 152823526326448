import { first } from 'lodash'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useEffectOnce } from 'react-use'
import useFilter from '../../../../hooks/useFilter'
import usePagination from '../../../../hooks/usePagination'
import { globalSearchActions } from '../../../../state/modules/globalsearch'

const GlobalSearchPageService = () => {
  const dispatch = useDispatch()

  const defaultPagination = {
    per_page: 20,
    page: 1,
  }

  const { filter } = useFilter()

  const { pagination, setPagination } = usePagination()

  useEffectOnce(() => {
    // Set default pagination params if they don't exist in URL
    if (!pagination) {
      setPagination(defaultPagination)
    }
  })

  const maybeHandleTrustIdFilter = useCallback(
    (filter: { [key: string]: string }) => {
      const filterKeys = Object.keys(filter)
      const filterKey = first(filterKeys)

      if (filterKey !== 'id') {
        return filter
      }

      let trustId: string | undefined = filter[filterKey] || ''

      const valSegments: string[] = trustId.split('-')
      if (valSegments.length > 0) {
        trustId = valSegments.pop()
      }

      return {
        id: trustId,
      }
    },
    [],
  )

  useEffect(() => {
    if (!filter) {
      return
    }
    const params = {
      ...maybeHandleTrustIdFilter(filter),
      ...pagination,
    }

    dispatch(globalSearchActions.requestGlobalSearch(params))
  }, [dispatch, filter, maybeHandleTrustIdFilter, pagination])

  return null
}

export default GlobalSearchPageService
