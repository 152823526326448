import { Observable, of } from 'rxjs'
import { switchMap, map, catchError } from 'rxjs/operators'
import { ajax, AjaxResponse } from 'rxjs/ajax'
import { createAction } from 'redux-actions'
import * as HTTPStatus from 'http-status-codes'
import queryString from 'query-string'
import RestfulResult from './RestfulResult'
import { getAuthHeaders } from '../../utils/auth'

export const ajaxSettings = {
  crossDomain: true,
  responseType: 'json',
}

export const successCodes: number[] = [
  HTTPStatus.OK,
  HTTPStatus.CREATED,
  HTTPStatus.ACCEPTED,
  HTTPStatus.IM_A_TEAPOT,
]

export const getHeaders = (authorized: boolean, refresh: boolean = false) => {
  const baseHeaders = {
    'Content-Type': 'application/json',
  }
  if (refresh || authorized) {
    return { ...baseHeaders, ...getAuthHeaders(refresh) }
  } else {
    return baseHeaders
  }
}

const injectUrlReplacements = (endpoint: string, urlReplacements: any) => {
  let newEndpoint = endpoint
  Object.keys(urlReplacements).forEach((key: string) => {
    newEndpoint = newEndpoint.replace(`:${key}`, urlReplacements[key])
  })
  return newEndpoint
}

export const getResponseHeaders = (response: AjaxResponse) =>
  response.xhr
    .getAllResponseHeaders()
    .split(/\n/g)
    .reduce((acc: any, header) => {
      const headerSegments = header.split(': ')
      acc[headerSegments[0]] = headerSegments[1]
      return acc
    }, {})

export const parseLinkHeader = (
  link: string,
): {
  next: string | undefined
  prev: string | undefined
} => {
  const links = link.split(',')
  const parsedLinks = links
    .filter(l => !!l)
    .map(l => {
      const parts = l.split(';')
      const link = parts[0]
        ?.replace(/"/g, '')
        .replace('<', '')
        .replace('>', '')
        .trim()
      const type = parts[1]?.replace(/"/g, '').replace('rel=', '').trim()
      return { link, type }
    })
  return {
    next: parsedLinks.find(pl => pl.type === 'next')?.link,
    prev: parsedLinks.find(pl => pl.type === 'prev')?.link,
  }
}

export const optionsRequest =
  (endpoint: string, authorized?: boolean) => (stream$: Observable<any>) =>
    stream$.pipe(
      switchMap(({ params }: { params: { [key: string]: string } }) => {
        let url = endpoint
        const method = 'options'
        const headers = getHeaders(!!authorized)
        if (params) {
          url = `${endpoint}?${queryString.stringify(params)}`
        }
        return ajax({ url, method, headers, ...ajaxSettings }).pipe(
          map(
            (response: AjaxResponse) =>
              new RestfulResult(response, {
                responseHeaders: getResponseHeaders(response),
                params,
              }),
          ),
        )
      }),
    )

export const post =
  (endpoint: string, authorized?: boolean, extendedHeaders = {}) =>
  (stream$: Observable<any>) =>
    stream$.pipe(
      switchMap(({ body, urlReplacements = {} }) => {
        const url = urlReplacements
          ? injectUrlReplacements(endpoint, urlReplacements)
          : endpoint
        const method = 'post'
        const headers = {
          ...getHeaders(!!authorized),
          ...extendedHeaders,
        }
        return ajax({ url, method, headers, body, ...ajaxSettings }).pipe(
          map(
            (response: AjaxResponse) =>
              new RestfulResult(response, {
                responseHeaders: getResponseHeaders(response),
              }),
          ),
        )
      }),
    )

/**
 * @todo revisit this, temporary workaround due to time constraints.
 */
export const refreshToken = (endpoint: string) => (stream$: Observable<any>) =>
  stream$.pipe(
    switchMap(() => {
      const url = endpoint
      const method = 'post'
      const headers = getHeaders(true, true)
      return ajax({ url, method, headers, ...ajaxSettings }).pipe(
        map(
          (response: AjaxResponse) =>
            new RestfulResult(response, {
              responseHeaders: getResponseHeaders(response),
            }),
        ),
      )
    }),
  )

export const putRequest =
  (endpoint: string, authorized?: boolean) => (stream$: Observable<any>) =>
    stream$.pipe(
      switchMap(({ body, urlReplacements = {}, params }) => {
        let url
        if (params) {
          url = `${injectUrlReplacements(
            endpoint,
            urlReplacements,
          )}?${queryString.stringify(params)}`
        } else {
          url = urlReplacements
            ? injectUrlReplacements(endpoint, urlReplacements)
            : endpoint
        }
        const method = 'put'
        const headers = getHeaders(!!authorized)
        return ajax({ url, method, headers, body, ...ajaxSettings }).pipe(
          map(
            (response: AjaxResponse) =>
              new RestfulResult(response, {
                responseHeaders: getResponseHeaders(response),
              }),
          ),
        )
      }),
    )

export const deleteRequest =
  (endpoint: string, authorized?: boolean) => (stream$: Observable<any>) =>
    stream$.pipe(
      switchMap(({ body = null, urlReplacements = {} }) => {
        const url = urlReplacements
          ? injectUrlReplacements(endpoint, urlReplacements)
          : endpoint
        const method = 'delete'
        const headers = getHeaders(!!authorized)
        return ajax({ url, method, headers, body, ...ajaxSettings }).pipe(
          map(
            (response: AjaxResponse) =>
              new RestfulResult(response, {
                responseHeaders: getResponseHeaders(response),
              }),
          ),
        )
      }),
    )

export const get =
  (endpoint: string, authorized?: boolean) => (stream$: Observable<any>) =>
    stream$.pipe(
      switchMap(({ urlReplacements, params, urlReplacementsAndParams }) => {
        let url
        let updatedEnpoint
        if (urlReplacementsAndParams) {
          const { id, channel_secret } = urlReplacementsAndParams
          updatedEnpoint = id
            ? injectUrlReplacements(endpoint, { id })
            : endpoint
          url = `${updatedEnpoint}?${queryString.stringify({ channel_secret })}`
        } else {
          updatedEnpoint = urlReplacements
            ? injectUrlReplacements(endpoint, urlReplacements)
            : endpoint
          url = `${updatedEnpoint}?${queryString.stringify(params, {
            arrayFormat: 'comma',
          })}`
        }
        const method = 'get'
        const headers = getHeaders(!!authorized)
        return ajax({ url, method, headers, ...ajaxSettings }).pipe(
          map(
            (response: AjaxResponse) =>
              new RestfulResult(response, {
                responseHeaders: getResponseHeaders(response),
                params,
              }),
          ),
        )
      }),
    )

export const httpError = createAction(
  'http_error',
  (payload: any) => payload,
  (payload: any, meta: any) => meta,
)

export const catchRestError = (action: string) =>
  catchError((err: any) => {
    if (err === null) {
      throw new Error(
        'Caught null, this could mean we failed to decode the response from the server',
      )
    }

    if (err === undefined) {
      throw new Error('Caught undefined')
    }

    const { status } = err
    if (status === undefined || status === null) {
      throw err
    }

    return of(httpError(err, { action }))
  })
