import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DataTable, Anchor, StatusText } from '../..'
import useFilter from '../../../../hooks/useFilter'
import usePagination from '../../../../hooks/usePagination'
import { globalSearchSelectors } from '../../../../state/modules/globalsearch'
import { sitesActions, sitesSelectors } from '../../../../state/modules/sites'
import { uiGlobalSearchSelectors } from '../../../../state/modules/ui'
import { formatDate } from '../../../../utils/date'
import { redirect } from '../../../../utils/router'

const Content = (): JSX.Element => {
  const dispatch = useDispatch()
  const { setCurrentPage, getCurrentPage } = usePagination()
  const pages: TPages = useSelector(globalSearchSelectors.getPages)
  const { filter } = useFilter()
  const currentPage: number = parseInt(getCurrentPage())
  const globalSearchData: any = useSelector(state =>
    uiGlobalSearchSelectors.getGlobalSearchTableData(state)(currentPage),
  )
  const data = filter ? globalSearchData : []
  const isLoading = useSelector(globalSearchSelectors.isLoading)
  const getSiteByPath = useSelector(sitesSelectors.getSiteByPath)

  const handleNext = () => setCurrentPage(currentPage + 1)

  const handlePrevious = () => setCurrentPage(currentPage - 1)

  const handleRowClick = (globalsearch: any) => {
    let link = globalsearch.original._links
    const linkBits = link.split('/')
    const site = linkBits[3]
    const id = 'transactions/' + linkBits[8]
    const siteObj = getSiteByPath(site)

    dispatch(sitesActions.siteSelected(siteObj))
    redirect(`${site}/${id}`)
  }

  const columns = [
    {
      Header: 'Trust ID',
      accessor: 'trust_id',
      Cell: (row: any) => {
        return <Anchor onClick={() => handleRowClick(row)}>{row.value}</Anchor>
      },
    },
    {
      Header: 'Created',
      accessor: 'created',
      Cell: (row: any) => formatDate(row.original.created, 'dd LLL yyyy HH:mm'),
    },
    {
      Header: 'Surname',
      accessor: 'payee_surname',
    },
    {
      Header: 'Email',
      accessor: 'payee_email',
    },
    {
      Header: 'Reference',
      accessor: 'reference',
    },
    {
      Header: 'Type',
      accessor: 'transaction_types',
    },
    {
      Header: 'Total',
      accessor: 'total',
      Cell: (row: any) => {
        return (
          <StatusText value={row.original.total} status={row.original.status} />
        )
      },
    },
    {
      Header: 'Last Four',
      accessor: 'last_four_digits',
    },
  ]

  return (
    <DataTable
      manual={true}
      fitContainer={true}
      data={data}
      columns={columns}
      loading={isLoading}
      noDataText="No results"
      pageSize={data.length}
      pages={pages.next ? currentPage + 1 : currentPage}
      page={currentPage}
      onNext={handleNext}
      onPrevious={handlePrevious}
    />
  )
}

export default Content
