import * as React from 'react'
import cx from 'classnames'
import Cleave from 'cleave.js/react'
import styled from 'styled-components'
import { colors } from '../../../styles/variables'

interface ICurrencyInputProps {
  prefix: string
  name: string
  id?: string
  label?: string
  value?: any
  className?: string
  error?: string
  disabled?: boolean
  onChange(e: any): void
  onFocus?(): void
}

class CurrencyInput extends React.Component<ICurrencyInputProps, {}> {
  private options = {
    numeral: true,
    numeralDecimalMark: '.',
    numeralIntegerScale: 0,
    numeralDecimalScale: 2,
    numeralPositiveOnly: true,
    stripLeadingZeroes: true,
    delimiter: ' ',
  }

  public ref: any

  public showTwoDecimals = (val: string) => {
    const valueSplit = val.split('.')
    if (val) {
      if (valueSplit.length > 1) {
        const decimal = valueSplit[1]
        if (decimal.length < 2) {
          this.ref.setRawValue(`${valueSplit[0]}.${decimal}0`)
        }
      } else {
        this.ref.setRawValue(`${val}.00`)
      }
    }
  }

  public componentDidMount() {
    this.showTwoDecimals(this.props.value.toString())
  }

  public componentDidUpdate(prevProps: ICurrencyInputProps) {
    /**
     * only update decimals when value changes if field is disabled.
     * otherwise this is called whenever the field is typed in, which
     * results in decimals being update on keystrokes,
     */
    if (prevProps.value !== this.props.value && this.props.disabled) {
      const stringVal = this.props.value.toString()
      this.showTwoDecimals(stringVal)
    }
  }

  public handleChange = (e: any) => {
    this.props.onChange({
      ...e,
      target: {
        ...e.target,
        value: e.target.rawValue,
      },
    })
  }

  public handleBlur = (e: any) => {
    const stringVal = e.target.rawValue
    this.showTwoDecimals(stringVal)
  }

  public handleFocus = () => {
    const { onFocus } = this.props
    if (onFocus) {
      onFocus()
    }
  }

  public getError = () => {
    const { error } = this.props
    return error && <span className="error">{error}</span>
  }

  public render(): JSX.Element {
    const { label, prefix, onChange, ...otherProps } = this.props
    return (
      <div className={cx(this.props.className)}>
        <div
          className={cx('currency-input-container', { 'has-prefix': prefix })}
        >
          {label && <label>{label}</label>}
          {prefix && <span>{prefix}</span>}
          <Cleave
            ref={ref => (this.ref = ref)}
            options={this.options}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            {...otherProps}
          />
        </div>
        {this.getError()}
      </div>
    )
  }
}

const StyledCurrencyInput = styled(CurrencyInput)`
  ${(props: ICurrencyInputProps) => {
    let extraStyles = ''

    if (props.error) {
      extraStyles += `
        input {
            border-color: ${colors.red} !important;
        }
        span:first-of-type {
          border-color: ${colors.red} !important;
        }
      `
    }

    return `.currency-input-container {
      display: flex;
      align-items: center;

      label {
        margin-right: 5px;
      }

      span:first-of-type {
        display: none;
        padding: 4px;
        border-style: solid;
        border-width: 1px 1px 1px 1px;
        border-color: ${colors.blue.dark};
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      input {
        border-style: solid;
        border-width: 1px;
        border-color: ${colors.blue.dark};
        border-radius: 4px;
        width: 100%;
        line-height: 20px;
        padding: 4px;

        &:focus {
          outline: none;
        }

        &[disabled] {
          background: ${colors.grey.lighter};
        }
      }

      &.has-prefix {
        span:first-of-type {
          display: block;
        }
        input {
          border-width: 1px 1px 1px 0;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }

    &.field {
      .currency-input-container {
        margin-bottom: 10px;

        label {
          font-size: 15px;
        }
        span:first-of-type {
          padding: 11px;
        }
        input {
          padding: 11px;
          font-size: 1rem;
        }
        &.has-prefix input {
          width: 77%;
          margin-bottom: 0;
        }
      }
    }

    .error {
      color: ${colors.red};
    }

    ${extraStyles}
  `
  }}
`

export default StyledCurrencyInput
