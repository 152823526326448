import { useCallback, useEffect } from 'react'

export function useDebouncedEffect(effect: any, delay: number, deps: string[]) {
  const callback = useCallback(effect, deps)

  useEffect(() => {
    const handler = setTimeout(() => {
      callback()
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [callback, delay])
}
