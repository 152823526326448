import * as React from 'react'
import { connect } from 'react-redux'
import { Dispatch, AnyAction } from 'redux'
import {
  uiTransactionsActions,
  uiTransactionsSelectors,
} from '../../../../../state/modules/ui/transactions'
import { DataTable, Anchor, StatusText } from '../../..'
import { sitesSelectors } from '../../../../../state/modules/sites'
import { redirect } from '../../../../../utils/router'

interface ITransactionAdjustmentsPanelProps {
  transactionId: number
}

interface ITransactionAdjustmentsPanelContainerProps {
  // data?: Array<{
  //     title: string;
  //     reference: string;
  //     total: number;
  // } | undefined>;
  data: any[]
  currentSitePath: string
  fetchTransactionsAdjustments(): void
  redirect(path: string): void
}

class TransactionAdjustmentsPanel extends React.PureComponent<
  ITransactionAdjustmentsPanelProps &
    ITransactionAdjustmentsPanelContainerProps,
  {}
> {
  public componentDidMount() {
    this.props.fetchTransactionsAdjustments()
  }

  public getColumns = () => [
    {
      Header: 'Trust ID',
      accessor: 'trust_id',
      Cell: (row: any) => {
        const redirectTo = () => {
          this.props.redirect(
            `/${this.props.currentSitePath}/transactions/${row.original.id}`,
          )
        }
        return <Anchor onClick={redirectTo}>{row.original.trust_id}</Anchor>
      },
    },
    {
      Header: 'Created',
      accessor: 'created',
    },
    {
      Header: 'Payee',
      accessor: 'payee_name',
      Cell: (row: any) => {
        return (
          <div>
            {row.original.payee_name} {row.original.payee_surname}
          </div>
        )
      },
    },
    {
      Header: 'Type',
      accessor: 'transaction_types',
    },
    {
      Header: 'Total',
      accessor: 'total',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: (row: any) => {
        return (
          <StatusText
            value={row.original.status}
            status={row.original.status}
          />
        )
      },
    },
  ]

  public render(): JSX.Element {
    const { data } = this.props
    return (
      <div className="single-transaction-table">
        <DataTable
          manual={true}
          plain={true}
          columns={this.getColumns()}
          data={data}
          pageSize={data.length}
          loading={false}
        />
      </div>
    )
  }
}

const mapStateToProps = (
  state: any,
  ownProps: ITransactionAdjustmentsPanelProps,
) => ({
  data: uiTransactionsSelectors.getTransactionAdjustmentsTableData(state)(
    ownProps.transactionId,
  ),
  currentSitePath: sitesSelectors.getCurrentSitePath(state),
})

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
  ownProps: ITransactionAdjustmentsPanelProps,
) => ({
  fetchTransactionsAdjustments: () => {
    dispatch(
      uiTransactionsActions.fetchTransactionAdjustments(ownProps.transactionId),
    )
  },
  redirect: (path: string) => redirect(path),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransactionAdjustmentsPanel)
