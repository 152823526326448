import { get } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { Button } from '../..'
import { authSelectors } from '../../../../state/modules/auth'
import { bookingSelectors } from '../../../../state/modules/bookings'
import { channelSelectors } from '../../../../state/modules/channels'
import { sitesSelectors } from '../../../../state/modules/sites'
import { convertFromCents } from '../../../../utils/currency'
import timfinityTheme from '../../../styles/timfinityTheme'
import PayViaBankTransferModal from './PayViaBankTransferModal'

type Props = {
  bookingId: string
}

const PayViaBankTransferButton = ({ bookingId }: Props) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const booking = useSelector(state =>
    bookingSelectors.getBookingById(state)(bookingId),
  )

  const bookingChannel = useSelector(state =>
    channelSelectors.getChannelById(state)(booking?.channels),
  )

  const channelAccountMode = bookingChannel?.account_mode

  const isAdmin = useSelector(authSelectors.isAdmin)

  const isSiteProtectionOnly = useSelector(
    sitesSelectors.getCurrentSiteProtectionOnly,
  )

  const status = booking?.status

  if (
    !booking ||
    !isAdmin ||
    !bookingChannel ||
    ['locked', 'paid', 'authorized'].includes(status) ||
    channelAccountMode === 'protection-only' ||
    isSiteProtectionOnly
  ) {
    return null
  }

  return (
    <>
      <Button
        label="Pay via Bank Transfer"
        style={{
          fontSize: get(timfinityTheme, 'global.font.size'),
          lineHeight: get(timfinityTheme, 'global.font.height'),
        }}
        styleType="primary"
        onClick={() => setIsModalOpen(true)}
      />
      <PayViaBankTransferModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        booking={booking}
        bookingChannel={bookingChannel}
        bookingChannelForexFeed={bookingChannel?.forex_feed}
        initialValues={{
          firstname: booking.firstname,
          surname: booking.surname,
          email: booking.email,
          currencies: booking.currencies,
          countries: booking.countries.split(',')[0],
          total_unpaid: convertFromCents(booking.total_unpaid),
          reference: booking.reference || '',
          base_value: 0,
          // used for validation, values above change in form so need access to previous booking values
          _total_unpaid: convertFromCents(booking.total_unpaid),
          _currencies: booking.currencies,
        }}
      />
    </>
  )
}

export default PayViaBankTransferButton
