import { createActions } from 'redux-actions'
import { NAMESPACE } from './constants'

export const {
  ui: {
    notifications: { addNotification, removeNotification },
  },
} = createActions({
  [`${NAMESPACE}/add_notification`]: (payload: any) => payload,
  [`${NAMESPACE}/remove_notification`]: (payload: any) => payload,
})
