import { ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { Observable, of } from 'rxjs'
import { map, switchMap, mergeMap, mapTo } from 'rxjs/operators'
import * as actions from './actions'
import { ENDPOINTS } from './constants'
import {
  get,
  optionsRequest,
  catchRestError,
  putRequest,
} from '../../utils/http'
import {
  liftPayload,
  liftPayloadAndUrlReplacements,
} from '../../utils/rx-operators'
import RestfulResult from '../../utils/RestfulResult'
import { injectCurrentSitePath } from '../../utils/general'
import { IRequestStatementsParams } from './interfaces'

/**
 * Get Channels Schema when `actions.requestStatementsSchema` action is dispatched
 * @param action$ Action observable
 * @param state$ State observable
 */
export const requestStatementsSchema = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestStatementsSchema),
    liftPayload(),
    switchMap((payload: IRequestStatementsParams) =>
      of(payload).pipe(
        map(params => ({ params })),
        optionsRequest(
          injectCurrentSitePath(ENDPOINTS.BASE, state$.value),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(
                actions.requestStatementsSchemaSuccess(
                  p,
                  response.getContext(),
                ),
              ),
            ),
          ),
        ),
        catchRestError(actions.requestStatementsSchema.toString()),
      ),
    ),
  )

/**
 * Get channels when `actions.requestStatements` action is dispatched.
 * @param action$ Action observable
 * @param state$ State obserable
 */
export const requestStatementsEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestStatements),
    liftPayload(),
    switchMap((payload: IRequestStatementsParams) =>
      of(payload).pipe(
        map(params => ({ params })),
        get(injectCurrentSitePath(ENDPOINTS.BASE, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestStatementsSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestStatements.toString()),
      ),
    ),
  )

/**
 * Get a channel when `actions.requestStatement` action is dispatched.
 * @param action$ Action observable
 * @param state$ State observable
 */
export const requestStatementEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestStatement),
    liftPayload(),
    switchMap((payload: any) =>
      of(payload).pipe(
        map(urlReplacements => ({ urlReplacements })),
        get(injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestStatementSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestStatement.toString()),
      ),
    ),
  )

/**
 * Update a channel when `actions.updateStatement` action is dispatched.
 * @param action$ Action observable
 * @param state$ State observable
 */
export const updateStatementEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.updateStatement),
    liftPayloadAndUrlReplacements(),
    map(([body, urlReplacements]) => ({ body, urlReplacements })),
    switchMap((payload: any) =>
      of(payload).pipe(
        putRequest(injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.updateStatementSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.updateStatement.toString()),
      ),
    ),
  )

/**
 * Get statement csv when `actions.requestStatement` action is dispatched.
 * @param action$ Action observable
 * @param state$ State obserable
 */
export const requestStatementCsvEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestStatementSuccess),
    liftPayload(),
    switchMap((payload: any) =>
      of(payload).pipe(
        map(urlReplacements => ({ urlReplacements })),
        get(
          injectCurrentSitePath(
            `${ENDPOINTS.ENTITY}?csv_url=true`,
            state$.value,
          ),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(
                actions.requestStatementCsvSuccess(p, response.getContext()),
              ),
            ),
          ),
        ),
        catchRestError(actions.requestStatement.toString()),
      ),
    ),
  )
