import { createSelector } from 'reselect'
import { get, find, memoize } from 'lodash'
import { NAMESPACE } from './constants'
import { IStatementsModel, IStatementResponse } from './interfaces'
import { sitesSelectors } from '../sites'

const getState = (state: any) => state

const getStatementsState = (state: any): IStatementsModel => state[NAMESPACE]

export const getStatementIds = createSelector(
  [getState, getStatementsState],
  (state, statements) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(statements, `sites[${sitePath}].allIds`, [])
  },
)

export const getStatements = createSelector(
  [getState, getStatementsState],
  (state, statements) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(statements, `sites[${sitePath}].byId`, {})
  },
)

export const getStatementFilters = createSelector(
  [getState, getStatementsState],
  (state, statements) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(statements, `sites[${sitePath}].byFilters`, {})
  },
)

export const getStatementsByFilter = createSelector(
  [getState, getStatementsState],
  (state, statements) => (filter: string) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    const statementsByFilter = get(
      statements,
      `sites[${sitePath}].byFilters[${filter}]`,
      [],
    )
    return statementsByFilter.map((statementId: number) =>
      getStatementById(state)(statementId),
    )
  },
)

export const getStatementsByPage = createSelector(
  [getState, getStatementsState],
  (state, statements) => (page: number | string) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    const statementsByPage = get(
      statements,
      `sites[${sitePath}].byPage[${page}]`,
      [],
    )
    return statementsByPage.map((statementId: number) =>
      getStatementById(state)(statementId),
    )
  },
)

export const getStatementsSchema = createSelector(
  getStatementsState,
  statements => statements.schema,
)

const getStatementByField = createSelector(
  getStatements,
  statements => (field: any, value: any) =>
    find(
      statements,
      (statement: IStatementResponse) => statement[field] === value,
    ),
)

export const getStatementById = createSelector(
  getStatements,
  (statements: any) => (id: number | string) => statements[id],
)

export const getStatementByName = createSelector(
  getState,
  s => (name: string) => getStatementByField(s)('name', name),
)

export const isLoading = createSelector(
  [getState, getStatementsState],
  (state, statements) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(statements, `sites[${sitePath}].isLoading`, false)
  },
)

export const getPages = createSelector(
  [getState, getStatementsState],
  (state, statements) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(statements, `sites[${sitePath}].pages`, false)
  },
)

export const getStatementChangeLog = createSelector(
  getState,
  state => (id: string | number) => {
    const statement = getStatementById(state)(id)
    return get(statement, '_changelog', []).map((changelog: any) => ({
      user: changelog.user,
      timestamp: changelog.timestamp,
      changes: changelog.changes,
    }))
  },
)

export const getPropertiesFromSchema = createSelector(
  getStatementsSchema,
  memoize(schema => get(schema, 'schema.properties', {})),
)

export const getChannels = createSelector(
  [getState, getStatementsState],
  (state, channels) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(channels, `sites[${sitePath}].byId`, {})
  },
)
