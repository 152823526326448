import * as Sentry from '@sentry/browser'
import { Middleware, AnyAction } from 'redux'
import { get } from 'lodash'
import { httpError } from '../utils/http'

const sentryHttpErrors: Middleware =
  (store: any) => (next: any) => (action: AnyAction) => {
    if (
      process.env?.SENTRY_ENV === 'production' &&
      action.type === httpError.toString()
    ) {
      const code: string = get(action, 'payload.response.code', 'tmt_error')
      const status = get(action, 'payload.response.data.status', code)
      if (code !== 'auth_expired') {
        const event = {
          message: `${status} HTTP error`,
          level: Sentry.Severity.Error,
          environment: 'production',
          extra: action,
        }
        Sentry.captureEvent(event)
      }
    }
    return next(action)
  }

export default sentryHttpErrors
