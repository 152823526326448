import * as React from 'react'
import { Grommet, TextArea as GrommetTextAreaInput, Box } from 'grommet'
import styled from 'styled-components'
import timfinityTheme from '../../../styles/timfinityTheme'
import { colors } from '../../../styles/variables'
import Button from '../../atoms/Button/Button'

export interface ITextAreaInputProps {
  name: string
  id?: string
  type?: string
  placeholder?: string
  disabled?: boolean
  error?: string
  className?: string
  value?: string
  label?: string
  plain?: boolean
  searchFilterTheme?: object
  viewOnly?: boolean
  copyToClipboard?: boolean
  isOnEditScreen?: boolean
  onChange?(e: any): void
}

export interface ITextAreaInputState {
  copyButtonText: string
}

class TextAreaInput extends React.Component<
  ITextAreaInputProps,
  ITextAreaInputState
> {
  public state: ITextAreaInputState = {
    copyButtonText: 'Copy',
  }

  public getLabel = () => {
    const { id, label } = this.props
    return label && <label htmlFor={id}>{label}</label>
  }

  public getError = () => {
    const { error } = this.props
    return error && <span className="error">{error}</span>
  }

  public copyToClipboardButton = () => {
    const { copyToClipboard, isOnEditScreen, value = '' } = this.props

    if (copyToClipboard && !isOnEditScreen) {
      // https://stackoverflow.com/questions/52923771/react-copy-component-state-value-to-clipboard-without-dummy-element
      // tried using refs, but could not get it to work within current implementation
      const copyToClipboardHandler = (e: any) => {
        e.preventDefault()
        const dummy = document.createElement('input')
        document.body.appendChild(dummy)
        dummy.setAttribute('value', value)
        dummy.select()
        document.execCommand('copy')
        document.body.removeChild(dummy)
        this.setState({ copyButtonText: 'Copied!' })
        setTimeout(() => this.setState({ copyButtonText: 'Copy' }), 2000)
      }

      return (
        <div style={{ marginLeft: '10px' }}>
          <Button
            styleType="plain"
            size="small"
            label={this.state.copyButtonText}
            onClick={copyToClipboardHandler}
          />
        </div>
      )
    }
    return null
  }

  public render(): JSX.Element {
    const {
      name,
      id,
      type,
      onChange,
      value,
      placeholder = '',
      disabled = false,
      className = '',
      plain,
      searchFilterTheme,
    } = this.props

    const inputProps: ITextAreaInputProps = {
      name,
      id,
      type,
      onChange,
      placeholder,
      value,
      disabled,
      className,
    }

    const theme = searchFilterTheme
      ? { ...timfinityTheme, ...searchFilterTheme }
      : timfinityTheme

    return (
      <div className={className}>
        <Grommet theme={theme}>
          <Box direction="row" align="center">
            {this.getLabel()}
            <div className="field-control">
              <GrommetTextAreaInput plain={plain} {...inputProps} />
              {this.copyToClipboardButton()}
            </div>
          </Box>
          <Box>{this.getError()}</Box>
        </Grommet>
      </div>
    )
  }
}

const StyledTextAreaInput = styled(TextAreaInput)`
  ${(props: ITextAreaInputProps) => {
    let extraStyles = ''
    if (props.error) {
      extraStyles += `
                textarea {
                    border-color: ${colors.red} !important;
                }
            `
    }

    if (props.disabled) {
      extraStyles += `
                textarea {
                    background: ${colors.grey.lighter};
                    opacity: 0.9;
                }
            `
    }

    if (props.viewOnly) {
      extraStyles += `
                textarea {
                    pointer-events: none;
                }
            `
    }

    return `
            label {
                margin-right: 20px;
            }
            .field-control {
                position: relative;
                width: 100%;
            }
            textarea {
                background: #fff;
                font-size: 1rem;
                border: 1px solid #063E5A;
                font-weight: 400;
			}
            .error {
                color: ${colors.red};
            }
            ${extraStyles}
        `
  }}
`

export default StyledTextAreaInput
