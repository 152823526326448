import * as React from 'react'
import { first, find } from 'lodash'
import { Moment } from 'moment'
import { Box, Grommet } from 'grommet'
import styled from 'styled-components'
import DateRangePicker, {
  IDatesAndITimes,
} from '../../molecules/DateRangePicker/DateRangePicker'
import Select from '../../molecules/Select/Select'
import timfinityTheme from '../../../styles/timfinityTheme'

interface IDateRangeFilterProps {
  className?: string
  filters: IFilter[]
  plain?: boolean
  disabled?: boolean
  startDate: Moment | null
  endDate: Moment | null
  startTime: Moment
  endTime: Moment
  onConfirm({
    startDate,
    endDate,
    startTime,
    endTime,
  }: {
    startDate: Moment | null
    endDate: Moment | null
    startTime: Moment | null
    endTime: Moment | null
    currentFilter: IFilter
  }): void
  onFilterChange?(value: string): void
}

interface IDateRangeFilterState {
  currentFilter: IFilter
}

export interface IFilter {
  label: string
  value: string
}

class DateRangeFilter extends React.Component<
  IDateRangeFilterProps,
  IDateRangeFilterState
> {
  public state = {
    currentFilter: {
      label: '',
      value: '',
    },
  }

  public componentDidMount() {
    const firstFilter = first(this.getFilterOptions())
    if (firstFilter) {
      const currentFilter: any = this.getFilterOptionsByLabel(firstFilter)
      this.setState({ currentFilter })
    }
  }

  public getFilterOptions = () => this.props.filters.map(filter => filter.label)

  public handleFilterChange = (val: any) => {
    this.setState({ currentFilter: val })
    const { onFilterChange } = this.props
    if (onFilterChange) {
      onFilterChange(val)
    }
  }

  public handleConfirm = (dates: IDatesAndITimes) => {
    const { startDate, endDate, startTime, endTime } = dates
    const { currentFilter } = this.state
    this.props.onConfirm({
      startDate,
      endDate,
      startTime,
      endTime,
      currentFilter,
    })
  }

  public getFilterOptionsByLabel = (val: string) => {
    return find(this.props.filters, (filter: IFilter) => filter.label === val)
  }

  public render(): JSX.Element {
    const { currentFilter } = this.state
    const { startDate, endDate, startTime, endTime, disabled } = this.props
    const { className } = this.props
    const options = this.getFilterOptions()
    return (
      <Grommet className={className} theme={timfinityTheme}>
        <Box direction="row" fill={true}>
          <Select
            options={options}
            onChange={this.handleFilterChange}
            value={currentFilter.label}
            getOptionByName={this.getFilterOptionsByLabel}
            disabled={options.length === 1 ? true : false}
          />
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            startTime={startTime}
            endTime={endTime}
            onConfirm={this.handleConfirm}
            disabled={disabled}
          />
        </Box>
      </Grommet>
    )
  }
}

const StyledDateRangeFilter = styled(DateRangeFilter)<IDateRangeFilterProps>`
  display: flex;
  max-width: 550px;
  box-sizing: border-box;
  white-space: nowrap;

  > div:first-of-type {
    button {
      > div {
        background-color: #f1f1f1;
        border-radius: 5px 0 0 5px;
        border: none;
      }
    }
  }

  [class*='DateRangePickerInput__withBorder'] {
    border-radius: 0 5px 5px 0;
    border: none;
  }

  .DateRangePicker {
    border-radius: 0 5px 5px 0;
  }

  [class*='StyledGrommet'] {
    border-radius: 5px 0 0 5px;
  }

  button {
    border: none;
  }

  border-radius: 5px;
  ${props => (props.plain ? 'border: none;' : 'border: 1px solid #063E5A;')}
  ${props =>
    props.disabled &&
    `
        cursor: not-allowed;
        opacity: .3;
    `}
`

export default StyledDateRangeFilter
