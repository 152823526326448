import * as React from 'react'
import { get } from 'lodash'
import { Grommet, Tabs as GTabs, Tab as GTab } from 'grommet'
import timfinityTheme from '../../../styles/timfinityTheme'

interface ITabsProps {
  className?: string
  tabs: Array<ITab>
  activeIndex?: number
  onActive?: (index: number) => void
}

interface ITab {
  title: string
  Component(): React.ReactNode
  hidden?: boolean
}

const Tabs = (props: ITabsProps): JSX.Element => {
  return (
    <Grommet theme={timfinityTheme} className={props.className}>
      <GTabs activeIndex={props.activeIndex} onActive={props.onActive}>
        {props.tabs
          .filter((tab: ITab) => !get(tab, 'hidden'))
          .map(({ title, Component }, i) => (
            <GTab key={i} title={title}>
              {Component()}
            </GTab>
          ))}
      </GTabs>
    </Grommet>
  )
}

export default Tabs
