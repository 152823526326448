import * as React from 'react'
import { Box, Image } from 'grommet'
import ReactTimeout, { ReactTimeoutProps } from 'react-timeout'
import styled from 'styled-components'
import loader from '../../../../assets/images/loader.svg'
import Text from '../Text/Text'

interface ILoaderProps {
  className?: string
  text?: string
  ms?: number
}

interface ILoaderState {
  didTimeout: boolean
}

class Loader extends React.Component<
  ILoaderProps & ReactTimeoutProps,
  ILoaderState
> {
  public state = {
    didTimeout: false,
  }

  public componentDidMount() {
    const { setTimeout = () => null, ms = 0 } = this.props
    setTimeout(this.handleTimeout, ms)
  }

  public handleTimeout = () => this.setState({ didTimeout: true })

  public render(): JSX.Element | null {
    const { text, className } = this.props
    return !this.state.didTimeout ? null : (
      <Box fill={true} align="center" justify="center" className={className}>
        {text && <Text color="#2ECC71">{text}</Text>}
        <Image src={loader} />
      </Box>
    )
  }
}

const StyledLoader = styled(Loader)`
  > img {
    animation: spin 1s linear infinite;
    margin-top: 20px;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export default ReactTimeout(StyledLoader)
