import * as React from 'react'
import { Grommet, Text as GrommetText } from 'grommet'
import styled from 'styled-components'
import timfinityTheme from '../../../styles/timfinityTheme'
import { PolymorphicType } from 'grommet/utils'

interface ITextProps {
  color: string
  tag?: PolymorphicType
  children: string | React.ReactNode
  className?: string
  id?: string
  size?: string
  style?: any
}

const Text = (props: ITextProps): JSX.Element => {
  const { className, children, ...otherProps } = props
  return (
    <Grommet theme={timfinityTheme} className={className}>
      <GrommetText {...otherProps}>{children}</GrommetText>
    </Grommet>
  )
}

export default styled(Text)`
  white-space: nowrap;
  h1 {
    font-size: 32px;
    margin-block-end: 0.6em;
    margin-block-start: 0em;
  }
  h2 {
    font-size: 26px;
  }
`
