import React, { useState } from 'react'
import classNames from 'classnames'
import AsyncSelect from '../../molecules/AsyncSelect/AsyncSelect'
import { useSelector } from 'react-redux'
import {
  sitesActions,
  sitesConstants,
  sitesSelectors,
} from '../../../../state/modules/sites'
import { useEffectOnce } from 'react-use'
import useFetchHelper from '../../molecules/AsyncSelect/useFetchHelper'
import styled from 'styled-components'
import { IUserSite } from '../../../../state/modules/users/interfaces'

interface IUserSitesSelectorProps {
  className?: string
  placeholder?: string
  onChange?(value: any): void
  value?: any
  disabled?: boolean
  id?: string
  closeMenuOnSelect?: boolean
  hideOnCount?: boolean
}
interface IOption {
  value: number
  label: string
  isFixed: boolean
}

const PER_PAGE = 20

const UserSitesSelector = ({
  className,
  placeholder = 'Select sites...',
  onChange,
  value,
  disabled,
  hideOnCount = true,
  ...props
}: IUserSitesSelectorProps) => {
  const { request } = useFetchHelper(
    sitesConstants.ENDPOINTS.BASE,
    sitesActions.requestSitesSuccess,
  )
  const [initialOptions, setInitialOptions] = useState([])
  const currentSite = useSelector(sitesSelectors.getCurrentSite)

  const transformOptionForSelect = (site: any, isFixed: boolean): IOption => {
    return {
      value: site.id,
      label: site.name,
      isFixed: isFixed,
    }
  }

  const transformOptionForOnChange = (site: any): any => {
    return {
      id: site.value,
      name: site.label,
      isFixed: site.isFixed,
    }
  }

  const getInitialOptions = async () => {
    return await request({ per_page: PER_PAGE, page: 1 })
  }

  useEffectOnce(() => {
    const setOptions = async () => {
      const { options } = await getInitialOptions()
      if (options) {
        setInitialOptions(options)
      }
    }
    setOptions()
  })

  const styles = {
    multiValue: (base: any, state: any) => {
      return state.data.isFixed ? { ...base, backgroundColor: '#9B9B9B' } : base
    },
    multiValueLabel: (base: any, state: any) => {
      return state.data.isFixed
        ? { ...base, fontWeight: 'nornal', color: 'white', paddingRight: 8 }
        : base
    },
    multiValueRemove: (base: any, state: any) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base
    },
  }

  const loadOptions = async (search: string, prevOptions: any) => {
    const params: any = {
      order_by: 'name',
      per_page: PER_PAGE,
      page: prevOptions.length / PER_PAGE + 1,
    }
    if (search) {
      params.search = search
    }
    const res = await request(params)
    return {
      ...res,
      options: res.options.map((v: any) => transformOptionForSelect(v, false)),
    }
  }

  const handleChange = (value: any, action: any) => {
    if (onChange) {
      if (value) {
        const sites = value.map((option: IOption) =>
          transformOptionForOnChange(option),
        )
        onChange(sites)
      } else {
        onChange([])
      }
    }
  }

  const currentValue = value
    ? value.map((val: IUserSite) => {
        const setIsFixed = currentSite!.id === val.id
        return transformOptionForSelect(val, setIsFixed)
      })
    : currentSite && transformOptionForSelect(currentSite, true)

  return !hideOnCount ? (
    <AsyncSelect
      className={classNames([className, 'sites-selector'])}
      isClearable={false}
      placeholder={placeholder}
      initialOptions={initialOptions}
      loadOptions={loadOptions}
      onChange={handleChange}
      value={currentValue}
      isDisabled={disabled}
      isMulti={true}
      styles={styles}
      {...props}
    />
  ) : null
}

export default styled(UserSitesSelector)`
  width: 300px;
  margin-right: 10px;
`
