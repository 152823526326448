import * as React from 'react'
import zxcvbn from 'zxcvbn'
import './PasswordStrengthIndicator.scss'

interface IPasswordStrengthIndicatorProps {
  password: string
}

class PasswordStrengthIndicator extends React.Component<
  IPasswordStrengthIndicatorProps,
  {}
> {
  public createPasswordLabel = (result: any) => {
    switch (result.score) {
      case 1:
        return 'Weak'
      case 2:
        return 'Medium'
      case 3:
        return 'Strong'
      default:
        return 'Weak'
    }
  }

  public render(): JSX.Element | null {
    const { password } = this.props
    const testedResult = zxcvbn(password)
    return (
      <div className="password-strength-meter">
        <label className="password-strength-meter--label">
          Password Strength
          <br />
          {password && (
            <span className="password-strength-meter--result">
              {this.createPasswordLabel(testedResult)}
            </span>
          )}
        </label>
        <progress
          className={`password-strength-meter--progress strength-${this.createPasswordLabel(
            testedResult,
          )}`}
          value={testedResult.score}
          max="3"
        />
      </div>
    )
  }
}

export default PasswordStrengthIndicator
