import { handleActions } from 'redux-actions'
import { AnyAction } from 'redux'
import * as actions from './actions'
import model from './model'
import { IUIUsersModel } from './interfaces'
import { usersActions } from '../../users'
import * as sitesActions from '../../sites/actions'

export default handleActions(
  {
    [actions.clearServerErrors]: (state: IUIUsersModel) => ({
      ...state,
      serverErrors: null,
    }),
    [actions.setCurrentPage]: (state: IUIUsersModel, action: AnyAction) => ({
      ...state,
      currentPage: action.payload,
    }),
    [sitesActions.siteSelected]: (state: IUIUsersModel) => {
      return {
        ...state,
        currentChannel: null,
      }
    },
    http_error: (state: IUIUsersModel, action: AnyAction) => {
      const { payload, meta } = action
      if (meta.action === usersActions.updateUser.toString()) {
        return {
          ...state,
          isSaving: false,
          serverErrors: payload.response,
        }
      }
      if (meta.action === usersActions.createUser.toString()) {
        return {
          ...state,
          isCreating: false,
          serverErrors: payload.response,
        }
      }
      if (meta.action === usersActions.deleteUser.toString()) {
        return {
          ...state,
          isDeleting: false,
          serverErrors: payload.response,
        }
      }
      return state
    },
    [usersActions.createUser]: (state: IUIUsersModel) => ({
      ...state,
      isCreating: true,
    }),
    [usersActions.createUserSuccess]: (state: IUIUsersModel) => ({
      ...state,
      isCreating: false,
    }),
    [usersActions.updateUser]: (state: IUIUsersModel) => ({
      ...state,
      isSaving: true,
    }),
    [usersActions.updateUserSuccess]: (state: IUIUsersModel) => ({
      ...state,
      isSaving: false,
    }),
    [usersActions.deleteUser]: (state: IUIUsersModel) => ({
      ...state,
      isDeleting: true,
    }),
    [usersActions.deleteUserSuccess]: (state: IUIUsersModel) => ({
      ...state,
      isDeleting: false,
    }),
  },
  model,
)
