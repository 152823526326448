import { createSelector } from 'reselect'
import { get } from 'lodash'
import { sitesSelectors } from '../../sites'

const getState = (state: any) => state

const getUIAppState = createSelector(getState, state =>
  get(state, 'ui.app', {}),
)

export const getLoginState = createSelector(getUIAppState, ui => ui.login)

export const isLoading = createSelector(
  [getState, getUIAppState],
  (state, ui) => {
    // previous site is null when the app is first loaded and then set once the main site is loaded
    // use this to determine if the app is loading and prevent a flash of the site selector or site name in header
    const previousSite = sitesSelectors.getPreviousSite(state)
    return ui.loading || previousSite === null
  },
)

export const isBooted = createSelector(getUIAppState, ui => ui.booted)

export const getForgotPasswordState = createSelector(
  getUIAppState,
  ui => ui.forgotpassword,
)

export const getResetPasswordState = createSelector(
  getUIAppState,
  ui => ui.resetpassword,
)

export const isLoggingIn = createSelector(getLoginState, ls => ls.loading)

export const isPasswordResetSent = createSelector(
  getForgotPasswordState,
  fs => fs.reset_link_sent,
)

export const isPasswordResetSuccess = createSelector(
  getResetPasswordState,
  rs => rs.reset_success,
)

export const loginErrors = createSelector(getLoginState, ls => ls.errors)

export const nonTestChannelsExist = createSelector(
  getUIAppState,
  ui => ui.nonTestChannelsExist,
)
