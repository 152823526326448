import { useDispatch } from 'react-redux'
import { useEffect, useMemo } from 'react'
import moment from 'moment'
import { uiStatementConstants } from '../../../../state/modules/ui/statements'
import { useEffectOnce } from 'react-use'
import usePagination from '../../../../hooks/usePagination'
import useDateFilter from '../../../../hooks/useDateFilter'
import useChannelFilter from '../../../../hooks/useChannelFilter'
import {
  statementInterfaces,
  statementActions,
} from '../../../../state/modules/statements'

const StatementsPageService = (): null => {
  const dispatch = useDispatch()

  const defaultPagination = {
    per_page: uiStatementConstants.perPage,
    page: 1,
  }

  const defaultDateFilters = useMemo(
    () => ({
      after: moment().subtract(2, 'weeks').format('YYYY-MM-DD 00:00:00'),
      before: moment().format('YYYY-MM-DD 23:59:59'),
    }),
    [],
  )

  const { pagination, setPagination } = usePagination()

  const { dateFilters, setDateFilters } = useDateFilter()

  const [currentChannel] = useChannelFilter()

  useEffectOnce(() => {
    // Set default pagination params if they don't exist in URL
    if (!pagination) {
      setPagination(defaultPagination)
    }

    // Set date filters if they don't exist in URL
    if (!dateFilters) {
      setDateFilters(defaultDateFilters)
    }
  })

  useEffect(() => {
    const params: statementInterfaces.IRequestStatementsParams = {
      ...pagination,
      ...dateFilters,
    }

    if (currentChannel) {
      params.channels = currentChannel
    }
    dispatch(statementActions.requestStatements(params))
  }, [dispatch, pagination, currentChannel, dateFilters])

  return null
}

export default StatementsPageService
