import React from 'react'
import {
  and,
  computeLabel,
  ControlProps,
  JsonSchema,
  RankedTester,
  rankWith,
  UISchemaElement,
  uiTypeIs,
} from '@jsonforms/core'
import cx from 'classnames'
import { withJsonFormsControlProps } from '@jsonforms/react'
import merge from 'lodash/merge'
import {
  VanillaRendererProps,
  withVanillaControlProps,
} from '@jsonforms/vanilla-renderers'
import styled from 'styled-components'
import { colors } from '../../../../views/styles/variables'
import { Grommet } from 'grommet'
import timfinityTheme from '../../../../views/styles/timfinityTheme'
import Select from 'react-select'
import { isArray } from 'lodash'

const CountriesControl = (
  props: ControlProps & VanillaRendererProps & { className?: string },
) => {
  const {
    config,
    data,
    className,
    classNames,
    id,
    enabled,
    uischema,
    schema,
    visible,
    required,
    label,
    path,
    handleChange,
    errors,
  } = props
  const [showErrors, setShowErrors] = React.useState(false)
  const appliedUiSchemaOptions = merge({}, config, uischema.options)
  const isValid = errors.length === 0 || !showErrors
  const isVisible = !!visible && (schema as any)?.visible !== false

  const divClassNames = [classNames?.validation]
    .concat(isValid ? classNames?.description : classNames?.validationError)
    .join(' ')

  const oneOf = schema?.oneOf as JsonSchema[]
  const arrayOneOf = oneOf?.find((option: any) => option.type === 'array')

  if (!arrayOneOf && !oneOf) {
    return null
  }

  const isMutli = (arrayOneOf?.items as any)?.oneOf?.length > 0

  const options = (
    ((arrayOneOf?.items as JsonSchema)?.oneOf as any[]) ||
    oneOf ||
    []
  ).map((option: any) => {
    return {
      value: option.const,
      label: option.title,
    }
  })

  const getOptionByValue = (value: string) => {
    return options.find(option => option.value === value)
  }

  const value = isArray(data)
    ? data.map(getOptionByValue)
    : getOptionByValue(data)

  const isFormFieldDisabled = () => {
    if (!enabled) {
      return true
    }
    const isReadOnly = (schema as any)?.readonly === true
    if (isReadOnly) {
      return true
    }
    return false
  }

  if (!isVisible) {
    return null
  }

  return (
    <Grommet theme={timfinityTheme}>
      <div
        className={cx(className, {
          'has-error': !isValid,
        })}
      >
        <label htmlFor={id}>
          {computeLabel(label, required || false, false)}
        </label>
        <div className="input-control-cell">
          <Select
            id={id}
            className={cx(['country-multi-selector'])}
            isClearable={true}
            onBlur={() => setShowErrors(true)}
            // placeholder={placeholder}
            closeMenuOnSelect={false}
            value={value}
            isMulti={isMutli}
            options={options}
            onChange={(value: any) => {
              handleChange(
                path,
                isArray(value)
                  ? value?.map((o: any) => o.value)
                  : value?.value || '',
              )
            }}
            isDisabled={isFormFieldDisabled()}
          />
          <div className={divClassNames}>
            {!isValid ? `${uischema.label} ${errors}` : null}
          </div>
        </div>
      </div>
    </Grommet>
  )
}

/**
 * Default tester for text-based/string controls.
 * @type {RankedTester}
 */
export const countriesControlTester: RankedTester = rankWith(
  4,
  and((uischema: UISchemaElement) => {
    return uischema?.options?.Component === 'CountriesControl'
  }),
)

const StyledCountriesControl = styled(CountriesControl)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  label {
    font-size: 15px;
    padding: 8px 0;
  }

  .input-control-cell {
    width: 100%;
  }

  .country-multi-selector {
    width: 100%;
    max-width: 676px;

    > div {
      width: 100%;
      border-radius: 4px;
      border-color: ${colors.blue.dark};
    }
  }

  &.has-error {
    label {
      color: ${colors.red};
    }

    .validation {
      color: ${colors.red};
      margin-top: 5px;
    }

    .country-multi-selector > div {
      border-color: ${colors.red};
    }

    input {
      border-color: ${colors.red} !important;
    }
  }
`

export default withVanillaControlProps(
  withJsonFormsControlProps(StyledCountriesControl),
)
