import { API_ADMIN_URL, API_SITE_URL } from '../../constants'
export const NAMESPACE = 'ledger'

const baseUrl = `${API_SITE_URL}/${NAMESPACE}`

export const ENDPOINTS = {
  BASE: baseUrl,
  ENTITY: `${baseUrl}/:id`,
  LEDGER_ADHOC: `${API_SITE_URL}/ledgeradhoc`,
  LEDGER_ADHOC_ENTITY: `${API_SITE_URL}/ledgeradhoc/:id`,
  ADJUST_LEDGER: `${API_SITE_URL}/ledgeradhoc`,
  IMPORT: `${API_ADMIN_URL}/ledgeradhoc/import`,
}
