import { createActions } from 'redux-actions'
import { NAMESPACE } from './constants'
import { IRequestBookingsParams, IBookingResponse } from './interfaces'

export const {
  [NAMESPACE]: {
    requestBookingsSchema,
    requestBookingsSchemaSuccess,
    requestBookings,
    requestBookingsSuccess,
    requestBooking,
    requestBookingSuccess,
    requestCreateBooking,
    requestCreateBookingSuccess,
    requestUpdateBooking,
    requestUpdateBookingSuccess,
    requestDeleteBooking,
    requestDeleteBookingSuccess,
  },
} = createActions({
  [`${NAMESPACE}/request_bookings_schema`]: () => '',
  [`${NAMESPACE}/request_bookings_schema_success`]: (payload: any) => payload,
  [`${NAMESPACE}/request_bookings`]: (payload: IRequestBookingsParams) =>
    payload,
  [`${NAMESPACE}/request_bookings_success`]: [
    (payload: IBookingResponse[]) => payload,
    (payload: any, meta: any) => meta,
  ],
  [`${NAMESPACE}/request_booking`]: (payload: any) => payload,
  [`${NAMESPACE}/request_booking_success`]: (payload: IBookingResponse) =>
    payload,
  [`${NAMESPACE}/request_create_booking`]: (payload: any) => payload,
  [`${NAMESPACE}/request_create_booking_success`]: (
    payload: IBookingResponse,
  ) => payload,
  [`${NAMESPACE}/request_update_booking`]: (payload: any) => payload,
  [`${NAMESPACE}/request_update_booking_success`]: (
    payload: IBookingResponse,
  ) => payload,
  [`${NAMESPACE}/request_delete_booking`]: (payload: any) => payload,
  [`${NAMESPACE}/request_delete_booking_success`]: (
    payload: IBookingResponse,
  ) => payload,
})
