import React from 'react'
import { Box, FileInput as GrommetFileInput } from 'grommet'
import styled from 'styled-components'
import { colors } from '../../../styles/variables'

export interface IFileInputProps {
  className?: string
  disabled?: boolean
  id?: string
  messages?: object
  multiple?: boolean
  fileList?: any[]
  name: string
  error?: string
  onChange(fileList: File[]): void
}

const Error = styled(
  ({ className, message }: { className?: string; message: string }) => (
    <Box className={className}>{message}</Box>
  ),
)`
  color: ${colors.red};
  padding: 10px 0;
`

const FileInput = (props: IFileInputProps): JSX.Element => {
  const { className = '', onChange, fileList, error, ...rest } = props

  const handleOnChange = (e: any) => {
    const fileList = e.target.files
    onChange(fileList)
  }

  return (
    <div className={className}>
      <GrommetFileInput
        {...rest}
        multiple={false}
        name="file"
        onChange={handleOnChange}
        value={fileList}
      />
      {error && <Error message={error} />}
    </div>
  )
}

const StyledFileInput = styled(FileInput)``

export default StyledFileInput
