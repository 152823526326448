import * as React from 'react'
import { match } from 'react-router'
import { Box } from 'grommet'
import { useSelector } from 'react-redux'
import { ArchiveTemplate, Text, Button } from '../..'
import Content from './Content'
import SearchFilters from './SearchFilters'
import { redirect } from '../../../../utils/router'
import styled from 'styled-components'
import { sitesSelectors } from '../../../../state/modules/sites'
import { channelSelectors } from '../../../../state/modules/channels'

interface IChannelsPageProps {
  className?: string
  match: match
}

const Header = (): JSX.Element => {
  const currentSitePath = useSelector(sitesSelectors.getCurrentSitePath)
  const channelsMethods = useSelector(channelSelectors.getChannelsSchemaMethods)
  const createNewChannelRedirect = React.useMemo(
    () => () => redirect(`/${currentSitePath}/channels/new`),
    [currentSitePath],
  )
  return (
    <>
      <Box justify="between" direction="row" className="page-intro">
        <Text tag="h1" color="white">
          Channels
        </Text>
        {channelsMethods.includes('POST' as never) && (
          <Button
            styleType="primary"
            label="+ Create Channel"
            onClick={createNewChannelRedirect}
          />
        )}
      </Box>
      <SearchFilters className="search-filters" />
    </>
  )
}

const ChannelsPage = (props: IChannelsPageProps): JSX.Element =>
  React.useMemo(
    () => (
      <div className={props.className}>
        <ArchiveTemplate
          Header={() => <Header />}
          Content={() => <Content />}
          displayChannelSelector={false}
        />
      </div>
    ),
    [props.className],
  )

ChannelsPage.whyDidYouRender = true

const StyledChannelsPage = styled(ChannelsPage)`
  width: 100%;
  overflow: scroll;

  .page-intro {
    align-items: center;
    margin-bottom: 20px;
  }

  .search-filters {
    margin-bottom: 10px;
  }

  .ReactTable {
    .rt-table {
      flex: auto;
    }
    .rt-thead.-header .rt-th {
      flex: auto;
    }
  }
`

export default StyledChannelsPage
