import { JsonSchema } from '@jsonforms/core'

/**
 * property `default` fields are not automatically added to the data object
 * that is not the behaviour outlined in the json schema spec
 * this function adds the default values to the data object
 */
export const mergeDataWithDefaultsFromSchema = (
  data: any,
  schema?: JsonSchema,
) => {
  if (schema === undefined) {
    return data
  }
  if (schema.properties === undefined) {
    return data
  }
  const newData = { ...data }
  Object.keys(schema.properties).forEach(key => {
    if (newData[key] === undefined && schema.properties) {
      newData[key] = schema.properties[key].default
    }
  })
  return newData
}

export const getEnumIfs = (schema: JsonSchema) => {
  const enumIfs: any = {}
  const properties = schema.properties
  if (!properties) return enumIfs

  Object.keys(properties).forEach(propKey => {
    const enumIf = (properties[propKey] as any).enumIf
    if (enumIf) {
      Object.keys(enumIf).forEach(enumIfKey => {
        enumIfs[enumIfKey] = (
          value: string,
          callback: (key: string, value: any) => void,
        ) => {
          callback(propKey, enumIf[enumIfKey][value])
        }
      })
    }
  })

  return enumIfs
}

export const generateDefaultIfChangeHandlers = (schema: JsonSchema) => {
  const changeHandlers: any = {}
  const properties = schema.properties
  if (!properties) return changeHandlers

  Object.keys(properties).forEach(propKey => {
    const defaultIf = (properties[propKey] as any).defaultIf
    if (defaultIf) {
      Object.keys(defaultIf).forEach(defaultIfKey => {
        changeHandlers[defaultIfKey] = (
          value: string,
          callback: (key: string, value: any) => void,
        ) => {
          callback(propKey, defaultIf[defaultIfKey][value])
        }
      })
    }
  })

  return changeHandlers
}

/**
 * Removes readonly fields from the payload
 */
export const buildRequestPayload = (body: any, schema: any) => {
  const payload: any = {}

  for (const key in body) {
    if (body.hasOwnProperty(key)) {
      const field = schema.schema.properties[key]
      if (field && !field.readonly) {
        payload[key] = body[key]
      }
    }
  }

  return payload
}
