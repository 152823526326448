import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from '../../..'
import { ITransaction } from '../../../../../state/modules/transactions/interfaces'
import { uiTransactionsSelectors } from '../../../../../state/modules/ui/transactions'
import AmendEmailForm from './AmendEmailForm'
import ApplyResendForm from './ApplyResendForm'

interface IApplyResendModalProps {
  isOpen: boolean
  transaction: ITransaction
  isApplyingResend: boolean
  onClose(): void
  onSubmit(endpoint: string): void
}

const ApplyResendModal = (props: IApplyResendModalProps) => {
  const { isOpen, onClose, transaction, onSubmit, isApplyingResend } = props

  const isAmendingEmail = useSelector(
    uiTransactionsSelectors.isUpdatingTransaction,
  )
  const [activeForm, setActiveForm] = React.useState<'resend' | 'amend'>(
    'resend',
  )
  const handleCancelAmend = useCallback(
    () => setActiveForm('resend'),
    [setActiveForm],
  )

  const _onClose = React.useCallback(() => {
    onClose()
    setActiveForm('resend')
  }, [onClose, setActiveForm])

  useEffect(() => {
    setActiveForm('resend')
  }, [transaction.payee_email, setActiveForm])

  const resendForm = () =>
    activeForm === 'resend' ? (
      <ApplyResendForm
        transaction={transaction}
        onSubmit={onSubmit}
        isApplyingResend={isApplyingResend}
        onAmendEmail={() => setActiveForm('amend')}
      />
    ) : (
      <AmendEmailForm
        transaction={transaction}
        isAmendingEmail={isAmendingEmail}
        onCancel={handleCancelAmend}
      />
    )

  return (
    <Modal
      isOpen={isOpen}
      showCloseIcon={true}
      onClose={_onClose}
      InnerComponent={resendForm()}
    />
  )
}

export default ApplyResendModal
