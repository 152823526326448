import { createActions } from 'redux-actions'
import { NAMESPACE } from './constants'
import { IRequestGlobalSearchParams, IGlobalSearchResult } from './interfaces'

export const {
  [NAMESPACE]: { requestGlobalSearch, requestGlobalSearchSuccess },
} = createActions({
  [`${NAMESPACE}/request_global_search`]: (
    payload: IRequestGlobalSearchParams,
  ) => payload,
  [`${NAMESPACE}/request_global_search_success`]: [
    (payload: IGlobalSearchResult[]) => payload,
    (payload: any, meta: any) => meta,
  ],
})
