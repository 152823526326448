import React, { FC, useEffect, useState } from 'react'
import { Box } from 'grommet'
import { Button, Text, TextInput } from '../../../../views/components'
import { colors } from '../../../../views/styles/variables'
import styled from 'styled-components'
import { useConfirmTwoFactorAuth } from '../../api'

type Props = {
  className?: string
  userId: string
  enabled: boolean
  onNext: () => void
}

const Step2: FC<Props> = ({ className, userId, onNext }) => {
  const [code, setCode] = useState('')
  const inputRef = React.useRef<any>(null)
  const { mutate, isLoading } = useConfirmTwoFactorAuth({
    userId,
    onSuccess: onNext,
  })

  const handleNext = () => {
    mutate({ code })
  }

  const setRef = (ref: any) => {
    inputRef.current = ref
    inputRef.current?.focus && inputRef.current.focus()
  }

  return (
    <Box className={className}>
      <Text tag="h3" color={colors.blue.dark}>
        Verify Google Authenticator - <small>Step 2 of 3</small>
      </Text>

      <p>Enter a code from Google Authenticator to confirm.</p>

      <TextInput
        name="two-factor-code"
        label="6 digit code"
        placeholder="000000"
        type="number"
        value={code}
        onChange={e => setCode(e.target.value)}
        setRef={setRef}
      />

      <footer>
        <Button
          disabled={isLoading}
          styleType="secondary"
          label={isLoading ? 'Loading...' : 'Next'}
          size="small"
          type="button"
          onClick={handleNext}
        />
      </footer>
    </Box>
  )
}

export default styled(Step2)`
  label {
    min-width: 110px;
  }
`
