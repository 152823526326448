import Ajv from 'ajv'
import React from 'react'
import { Modal } from '../..'
import ConfirmationModal from '../../organisms/ConfirmationModal/ConfirmationModal'
import useBookingForm from './useBookingForm'

type Props = {
  bookingId: string
  ajv: Ajv
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const BookingDeleteConfirmationModal = ({
  bookingId,
  ajv,
  isOpen,
  setIsOpen,
}: Props) => {
  const { deleteBooking } = useBookingForm({ bookingId, ajv })
  return (
    <Modal
      isOpen={isOpen}
      showCloseIcon={false}
      InnerComponent={
        <ConfirmationModal
          isOpen={isOpen}
          showCloseIcon={false}
          onClose={() => setIsOpen(false)}
          title={'Delete Booking'}
          onConfirm={deleteBooking}
        >
          <p>Are you sure you want to delete this booking?</p>
        </ConfirmationModal>
      }
      onClose={() => setIsOpen(false)}
    />
  )
}

export default BookingDeleteConfirmationModal
