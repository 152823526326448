import { JsonForms } from '@jsonforms/react'
import Ajv from 'ajv'
import React from 'react'
import styled from 'styled-components'
import { cells, renderers } from '../../../../modules/jsonForms/renderers'
import SettingsPageFormLoadingModal from './SettingsPageFormLoadingModal'
import useSettingsPageForm from './useSettingsPageForm'

type Props = {
  className?: string
  currentSitePath: string
  ajv: Ajv
}

const SettingsPageForm = ({ className, currentSitePath, ajv }: Props) => {
  const {
    schema,
    uiSchema,
    data,
    setData,
    isEditing,
    setIsEditing,
    handleChange,
    additionalErrors,
    getLoadingState,
  } = useSettingsPageForm({
    currentSitePath,
    ajv,
  })

  return (
    <div className={className}>
      <JsonForms
        readonly={!isEditing}
        schema={schema}
        uischema={uiSchema}
        data={data}
        renderers={renderers}
        cells={cells}
        ajv={ajv}
        onChange={handleChange}
        additionalErrors={additionalErrors}
      />

      <SettingsPageFormLoadingModal loadingState={getLoadingState()} />
    </div>
  )
}

const StyledSettingsPageForm = styled(SettingsPageForm)`
  label {
    min-width: 300px;
    width: 300px;
  }

  .tab-panel > .field {
    margin-bottom: 20px;
    label + div {
      width: auto;
      min-width: 400px;
    }
    label + button {
      min-width: 400px;
    }
    .error {
      margin-top: 5px;
      margin-left: 320px;
    }
  }
`

export default StyledSettingsPageForm
