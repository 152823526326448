import NumeralFormatter from 'cleave.js/src/shortcuts/NumeralFormatter'

const decimalMark = '.'
const integerScale = 0
const decimalScale = 2
const thousandsGroupStyle = 'thousand'
const positiveOnly = false
const stripLeadingZeros = true
const delimeter = ' '

const numeralFormatter = new NumeralFormatter(
  decimalMark,
  integerScale,
  decimalScale,
  thousandsGroupStyle,
  positiveOnly,
  stripLeadingZeros,
  delimeter,
)

export const formatCurrency = (
  value: string | number,
  isCents = false,
): string => {
  let val = isCents ? parseFloat(value as string) / 100 : value

  if (typeof val === 'number') {
    val = val.toString()
  }

  if (val.includes('.')) {
    const parts = val.split('.')
    if (parts[1].length === 1) {
      val = `${parts[0]}.${parts[1]}0`
    }
  }

  return numeralFormatter.format(val)
}

/**
 * Re-add stripped trailing 0.
 * @todo clean this up!
 */
export const addTrailingZero = (value: string | number) => {
  let strValue = typeof value !== 'string' ? value.toString() : value

  if (strValue.includes('.')) {
    const parts = strValue.split('.')
    if (parts[1].length === 1) {
      strValue = `${parts[0]}.${parts[1]}0`
    }
  }

  return strValue
}

/**
 * Round numbers down
 *
 * @link https://stackoverflow.com/questions/35821815/javascript-rounding-down-in-5-cases/35827227#35827227
 * @param num Number to round down - akin to PHP round with `PHP_ROUND_HALF_DOWN`
 */
export function roundHalfDown(num: number) {
  return -Math.round(-num)
}

/**
 * Convert currency amount to cents, rounding half down from .5
 */
export const convertToCents = (amount: string | number) => {
  const castAmount = typeof amount === 'string' ? parseFloat(amount) : amount

  return roundHalfDown(castAmount * 100)
}

export const convertFromCents = (amount: string | number) => {
  const castAmount = typeof amount === 'string' ? parseInt(amount) : amount
  return castAmount / 100
}
