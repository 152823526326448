import * as React from 'react'
import { Modal } from '../../..'
import {
  ITransaction,
  ICreateTransaction,
} from '../../../../../state/modules/transactions/interfaces'
import ChargebackReversalForm from './ChargebackReversalForm'

interface IChargebackReversalModalProps {
  isOpen: boolean
  transaction: ITransaction
  isApplyingChargeback: boolean
  onClose(): void
  formatCurrency(value: number | string, currencies: string): string
  getCurrencySymbol(currencyName: string): string
  onSubmit(params: ICreateTransaction): void
}

const ChargebackReversalModal = (props: IChargebackReversalModalProps) => {
  const {
    isOpen,
    onClose,
    transaction,
    onSubmit,
    isApplyingChargeback,
    formatCurrency,
    getCurrencySymbol,
  } = props

  const chargebackReversalForm = () => (
    <ChargebackReversalForm
      transaction={transaction}
      onSubmit={onSubmit}
      isApplyingChargeback={isApplyingChargeback}
      formatCurrency={formatCurrency}
      getCurrencySymbol={getCurrencySymbol}
    />
  )

  return (
    <Modal
      isOpen={isOpen}
      showCloseIcon={true}
      onClose={onClose}
      InnerComponent={chargebackReversalForm()}
    />
  )
}

export default ChargebackReversalModal
