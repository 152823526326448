import { ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { actions } from './state'
import { ENDPOINTS } from './constants'
import { map, mapTo, mergeMap, pluck, switchMap } from 'rxjs/operators'
import { injectCurrentSitePath } from '../../utils/general'
import RestfulResult from '../../utils/RestfulResult'
import { Observable, of } from 'rxjs'
import {
  ICreateSupplier,
  IReassignSupplier,
  IRequestSuppliersParams,
  ISupplier,
} from './interfaces'
import {
  catchRestError,
  deleteRequest,
  get,
  optionsRequest,
  post,
  putRequest,
} from '../../utils/http'

export const requestSuppliersSchemaEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestSuppliersSchema),
    switchMap(action =>
      of(action).pipe(
        optionsRequest(
          injectCurrentSitePath(ENDPOINTS.BASE, state$.value),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(
                actions.requestSuppliersSchemaSuccess(p, response.getContext()),
              ),
            ),
          ),
        ),
        catchRestError(actions.requestSuppliersSchema.toString()),
      ),
    ),
  )

export const requestSuppliersEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestSuppliers),
    pluck('payload'),
    switchMap((payload: IRequestSuppliersParams) =>
      of(payload).pipe(
        map(params => ({ params })),
        get(injectCurrentSitePath(ENDPOINTS.BASE, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestSuppliersSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestSuppliers.toString()),
      ),
    ),
  )

export const requestSupplierEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestSupplier),
    pluck('payload'),
    switchMap((payload: string) =>
      of(payload).pipe(
        map(urlReplacements => ({ urlReplacements })),
        get(injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestSupplierSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestSuppliers.toString()),
      ),
    ),
  )

export const createSupplierEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.createSupplier),
    pluck('payload'),
    switchMap((payload: ICreateSupplier) =>
      of(payload).pipe(
        map(body => ({ body })),
        post(injectCurrentSitePath(ENDPOINTS.BASE, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(mapTo(actions.createSupplierSuccess(p))),
          ),
        ),
        catchRestError(actions.createSupplier.toString()),
      ),
    ),
  )

export const updateSupplierEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.updateSupplier),
    pluck('payload'),
    switchMap((payload: ISupplier) =>
      of(payload).pipe(
        map(body => ({ body, urlReplacements: { id: body.id } })),
        putRequest(injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(mapTo(actions.updateSupplierSuccess(p))),
          ),
        ),
        catchRestError(actions.updateSupplier.toString()),
      ),
    ),
  )

export const deleteSupplier = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.deleteSupplier),
    pluck('payload'),
    switchMap((payload: IReassignSupplier) =>
      of(payload).pipe(
        map(body => ({ body, urlReplacements: { id: body.id } })),
        deleteRequest(
          injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(mapTo(actions.deleteSupplierSuccess(p))),
          ),
        ),
        catchRestError(actions.deleteSupplier.toString()),
      ),
    ),
  )
