import { ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { map as _map, get } from 'lodash'
import { Observable } from 'rxjs'
import { map, filter } from 'rxjs/operators'
import { httpError } from '../../../utils/http'
import * as actions from './actions'
import { transactionsActions } from '../../transactions'
import { bookingActions } from '../../bookings'
import { channelActions } from '../../channels'
import { statementActions } from '../../statements'
import * as sitesActions from '../../sites/actions'
import { usersActions } from '../../users'
import { authActions } from '../../auth'
import { ledgerActions } from '../../ledger'
import { paymentsActions } from '../../payments'
import { commentActions } from '../../comments'
import { supplierActions } from '../../suppliers'
import { allocationsActions } from '../../allocations'

export const refundNotifications = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(transactionsActions.requestCreateTransactionSuccess),
    filter((action: AnyAction) => {
      const hasRefundFailed =
        action.type ===
          transactionsActions.requestCreateTransactionSuccess.toString() &&
        action.payload.status !== 'complete'
      return hasRefundFailed
    }),
    map((action: AnyAction) => {
      // const object = action.payload.response.data.params;
      // const message = _map(object, (param: string) => param).join('<br />');
      const message = 'Bank Rejected Request!'
      return message
    }),
    map((message: string) => {
      return actions.addNotification({ message, type: 'error' })
    }),
  )

export const userDeleteNotifications = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(httpError),
    filter((action: AnyAction) => {
      const isDeleteUser =
        action.meta.action === usersActions.deleteUser.toString()
      return isDeleteUser
    }),
    map((action: AnyAction) => {
      const message = get(action, 'payload.response.message', {
        general: 'Unknown Error',
      })
      return message
    }),
    map((message: string) => {
      return actions.addNotification({ message, type: 'error' })
    }),
  )

const handledErrorActions = [
  transactionsActions.requestCreateTransaction.toString(),
  transactionsActions.addAllocation.toString(),
  transactionsActions.requestUpdateTransaction.toString(),
  channelActions.createChannel.toString(),
  channelActions.updateChannel.toString(),
  channelActions.deleteChannel.toString(),
  bookingActions.requestCreateBooking.toString(),
  bookingActions.requestUpdateBooking.toString(),
  bookingActions.requestDeleteBooking.toString(),
  sitesActions.requestCreateSite.toString(),
  sitesActions.requestUpdateSite.toString(),
  usersActions.createUser.toString(),
  authActions.requestPasswordReset.toString(),
  ledgerActions.adjustLedger.toString(),
  paymentsActions.deletePayment.toString(),
  commentActions.addComment.toString(),
  supplierActions.createSupplier.toString(),
  supplierActions.updateSupplier.toString(),
  supplierActions.deleteSupplier.toString(),
  allocationsActions.updateAllocation.toString(),
  allocationsActions.reverseAllocation.toString(),
  usersActions.updateUser.toString(),
]

export const setHttpErrorNotifications = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(httpError),
    filter((action: AnyAction) => {
      return handledErrorActions.includes(action.meta.action)
    }),
    filter((action: AnyAction) => action.response !== null),
    map((action: AnyAction) => {
      const errorMessage = get(action, 'payload.response.message')
      const responseStatus = get(action, 'payload.response.data.status')
      const object = get(action, 'payload.response.data.params', {
        general: `Error: ${responseStatus}`,
      })
      const message = _map(object, (param: string) => param).join('<br />')
      return errorMessage ? `${errorMessage} <br /> ${message}` : message
    }),
    map((message: string) => {
      return actions.addNotification({ message, type: 'error' })
    }),
  )

const ignoredErrorActions = [authActions.requestToken.toString()]

const ignoredResponseCodes = ['auth_expired']

export const setUnknownHttpErrorNotifications = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(httpError),
    filter((action: AnyAction) => {
      if (ignoredErrorActions.includes(action.meta.action)) {
        return false
      }

      if (ignoredResponseCodes.includes(action.payload?.response?.code)) {
        return false
      }

      return !handledErrorActions.includes(action.meta.action)
    }),
    map((action: AnyAction) => {
      const errorMessage = get(action, 'payload.response.message')
      const responseStatus = get(action, 'payload.response.data.status')
      const object = get(action, 'payload.response.data.params', {
        general: `Error: ${responseStatus}`,
      })
      const message = _map(object, (param: string) => param).join('<br />')
      return errorMessage ? `${errorMessage} <br /> ${message}` : message
    }),
    map((message: string) => {
      return actions.addNotification({ message, type: 'error' })
    }),
  )

const successNotifications = [
  {
    action: bookingActions.requestCreateBookingSuccess,
    message: 'Booking created',
  },
  {
    action: bookingActions.requestUpdateBookingSuccess,
    message: 'Booking updated',
  },
  {
    action: bookingActions.requestDeleteBookingSuccess,
    message: 'Booking deleted',
  },
  {
    action: channelActions.createChannelSuccess,
    message: 'Channel created',
  },
  {
    action: channelActions.updateChannelSuccess,
    message: 'Channel updated',
  },
  {
    action: channelActions.deleteChannelSuccess,
    message: 'Channel deleted',
  },
  {
    action: statementActions.updateStatementSuccess,
    message: 'Statement updated',
  },
  {
    action: transactionsActions.requestCreateTransactionSuccess,
    message: 'Transaction created',
  },
  {
    action: transactionsActions.resendEmailReceiptSuccess,
    message: 'Email Receipt sent',
  },
  {
    action: sitesActions.requestCreateSiteSuccess,
    message: 'Site created',
  },
  {
    action: sitesActions.requestUpdateSiteSuccess,
    message: 'Site updated',
  },
  {
    action: usersActions.createUserSuccess,
    message: 'User created',
  },
  {
    action: usersActions.updateUserSuccess,
    message: 'User updated',
  },
  {
    action: usersActions.deleteUserSuccess,
    message: 'User deleted',
  },
  {
    action: authActions.requestPasswordResetSuccess,
    message: 'Password reset successfully',
  },
  {
    action: ledgerActions.adjustLedgerSuccess,
    message: 'Ledger adjusted',
  },
  {
    action: paymentsActions.deletePaymentSuccess,
    message: 'Allocation successfully reversed.',
  },
  {
    action: supplierActions.createSupplierSuccess,
    message: 'Supplier created',
  },
  {
    action: supplierActions.updateSupplierSuccess,
    message: 'Supplier updated',
  },
  {
    action: supplierActions.deleteSupplierSuccess,
    message: 'Supplier deleted',
  },
]

export const setHttpSuccessNotifications = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(...successNotifications.map(sn => sn.action)),
    filter((action: AnyAction) => {
      const hasRefundFailed =
        action.type ===
          transactionsActions.requestCreateTransactionSuccess.toString() &&
        action.payload.status !== 'complete'
      return !hasRefundFailed
    }),
    map((action: AnyAction) => {
      const successNotification = successNotifications.find(
        sn => sn.action.toString() === action.type,
      )
      if (successNotification) {
        return actions.addNotification({
          message: successNotification.message,
          type: 'success',
        })
      }
    }),
  )
