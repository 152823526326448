import { Middleware } from 'redux'
import { get } from 'lodash'
import { sitesSelectors } from '../modules/sites'

const currentSiteMiddleware: Middleware = store => next => action => {
  const state = store.getState()
  const currentSite = sitesSelectors.getCurrentSitePath(state)
  const meta = get(action, 'meta', {})
  action.meta = { ...meta, currentSite }
  return next(action)
}

export default currentSiteMiddleware
