import * as React from 'react'
import { IStatementResponse } from '../../../../state/modules/statements/interfaces'
import { Text, Accordion, Button, Comments } from '../..'
import { Box } from 'grommet'
import { AppBar } from '../../../containers'
import SummaryHeader from './SummaryHeader'
import Table from '../../organisms/Table/Table'
import styled from 'styled-components'
import BookingTransactions from './BookingTransactions'
import { get } from 'lodash'
import { Helmet } from 'react-helmet'
import { redirect } from '../../../../utils/router'
import {
  CommentType,
  IAddCommentRequest,
  IComment,
} from '../../../../state/modules/comments/interfaces'
import { IFormValues as ICommentFormValues } from '../../organisms/Comments/CommentsForm'
import { formatInTimeZone } from 'date-fns-tz'

interface ISingleStatementPageProps {
  statement?: IStatementResponse
  statementId: string
  currentSiteName: string
  currentSitePath: string
  match: any
  className?: string
  summaryTableData: any[] /** @todo fix type */
  creditsTableData: any[]
  debitsTableData: any[]
  isAdmin: boolean
  canUserSignOffPayout: boolean
  internalComments: IComment[]
  currentUserId: number
  formatCurrency(amount: number): string
  getStatement(): void
  redirect(path: string): void
  getComments(id: string, type: CommentType): void
  addComment(id: string, body: IAddCommentRequest): void
  updateComment(
    statementId: string,
    commentId: string,
    description: string,
  ): void
  deleteComment(statementId: string, commentId: string): void
}

class SingleStatementPage extends React.Component<ISingleStatementPageProps> {
  public componentDidMount() {
    const { statementId, getStatement, getComments } = this.props
    getStatement()
    getComments(statementId, CommentType.Internal)
  }

  public getStatusButton = () => {
    const { statement } = this.props
    const status = statement ? statement.status : null
    const buttonStyle = () => {
      switch (status) {
        case 'pending-approval':
          return 'secondary'
        case 'pending':
          return 'warning'
        case 'publish':
        default:
          return 'primary'
      }
    }
    return statement && <Button styleType={buttonStyle()} label={status} />
  }

  public getExportButton = () => {
    const { statement } = this.props
    const csv = get(statement, '_links.self[0].csv', false)
    return (
      csv && (
        <Button
          styleType="secondary"
          label="Export"
          target="_blank"
          href={csv}
        />
      )
    )
  }

  public getTransactionsPaymentsButtons = () => {
    const { statement, currentSitePath } = this.props
    return (
      statement &&
      statement.total !== 0 && (
        <>
          <Button
            className="clickable-button"
            styleType="primary"
            label="Transactions"
            onClick={() => {
              redirect(
                `/${currentSitePath}/transactions?channel=${statement.channels}&dateFilter=statement_date_before-${statement.date_range.end}~statement_date_after-${statement.date_range.start}`,
              )
            }}
          />
          <Button
            className="clickable-button"
            styleType="primary"
            label="Payments"
            onClick={() =>
              redirect(
                `/${currentSitePath}/payments?channel=${statement.channels}&dateFilter=statement_date_before-${statement.date_range.end}~statement_date_after-${statement.date_range.start}`,
              )
            }
          />
        </>
      )
    )
  }

  public getTitle = () => {
    const { statement } = this.props
    return !statement ? null : (
      <div className="page-title">
        <Text tag="h3" color="#fff">
          {statement.title}
        </Text>
        <div className="action-buttons">
          {this.getStatusButton()}
          {this.getExportButton()}
          {this.getTransactionsPaymentsButtons()}
        </div>
      </div>
    )
  }

  public handleAddComment = (
    type: CommentType,
    values: { comment: string },
  ) => {
    this.props.addComment(this.props.statementId, {
      type,
      description: values.comment,
    })
  }

  public handleCommentUpdated = (id: number, values: ICommentFormValues) => {
    this.props.updateComment(
      this.props.statementId,
      id.toString(),
      values.comment,
    )
  }

  public handleCommentDeleted = (id: number) => {
    this.props.deleteComment(this.props.statementId, id.toString())
  }

  public transformCommentsForComment = (comments: IComment[]) => {
    return comments.map((comment: IComment) => ({
      id: comment.id,
      time: formatInTimeZone(
        new Date(comment.created_at),
        'GMT',
        'yyyy-LL-dd HH:mm:ss',
      ),
      user: comment.user ? comment.user.name : '',
      comment: comment.description,
      canEdit: comment.user && this.props.currentUserId === comment.user.id,
    }))
  }

  public getAccordions = () => {
    const {
      statement,
      currentSitePath,
      redirect,
      formatCurrency,
      internalComments,
      isAdmin,
      canUserSignOffPayout,
    } = this.props
    const panels = []
    if (statement && statement.booking_transactions.length !== 0) {
      panels.push({
        title: 'Booking Transactions',
        Content: (
          <BookingTransactions
            statement={statement}
            currentSitePath={currentSitePath}
            redirect={redirect}
            formatCurrency={formatCurrency}
          />
        ),
        removePadding: true,
      })
    }
    if (isAdmin || canUserSignOffPayout) {
      panels.push({
        title: 'Private Comments',
        Content: (
          <>
            <Comments
              className="private-comments-list"
              comments={this.transformCommentsForComment(internalComments)}
              commentType={CommentType.Internal}
              onNewCommentAdded={values =>
                this.handleAddComment(CommentType.Internal, values)
              }
              onCommentUpdated={this.handleCommentUpdated}
              onCommentDeleted={this.handleCommentDeleted}
            />
          </>
        ),
      })
    }
    return panels
  }

  public render(): JSX.Element | null {
    const {
      statement,
      currentSiteName,
      className,
      summaryTableData,
      creditsTableData,
      debitsTableData,
    } = this.props
    return !statement ? null : (
      <Box
        fill={true}
        pad="medium"
        overflow={{ vertical: 'scroll' }}
        className={className}
      >
        <Helmet>
          <title>{`Trust My Travel | Statements | ${statement?.title}`}</title>
        </Helmet>
        <AppBar>
          <Text tag="h1" color="white">
            {currentSiteName}
          </Text>
        </AppBar>
        {this.getTitle()}
        <div className="box">
          <SummaryHeader statement={statement} />
          <Text tag="h3" color="#000">
            Summary
          </Text>
          <Table
            data={summaryTableData}
            striped={false}
            bolded={true}
            total={true}
          />
          <Text tag="h3" color="#000">
            Credits
          </Text>
          <Table
            data={creditsTableData}
            striped={false}
            subheader={true}
            total={true}
          />
          <Text tag="h3" color="#000">
            Debits
          </Text>
          <Table
            data={debitsTableData}
            striped={false}
            subheader={true}
            total={true}
          />
        </div>
        <div>
          <Accordion accordions={this.getAccordions()} />
        </div>
      </Box>
    )
  }
}

const StyledSingleStatementPage = styled(SingleStatementPage)`
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  overflow: scroll;

  .page-title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    h3 {
      margin: 0;
    }
    button {
      margin-left: 40px;
    }
    div:last-child {
      margin-left: auto;
    }
  }

  .action-buttons {
    display: flex;
    button {
      margin-left: 10px;
      cursor: default;
      &.clickable-button {
        cursor: pointer;
      }
    }
    a {
      margin-left: 10px;
    }
  }

  .box {
    border-radius: 12px;
    background: #fff;
    padding: 24px;
    margin-bottom: 16px;
  }

  .modal-actions {
    margin-left: 10px;
  }
`

export default StyledSingleStatementPage
