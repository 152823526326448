import { IUsersModel } from './interfaces'

const usersModel: IUsersModel = {
  admin_users: {
    isLoading: false,
    pages: {
      next: undefined,
      prev: undefined,
    },
    byId: {},
    allIds: [],
    byPage: {},
  },
  sites: {},
  schema: {},
}

export default usersModel
