import * as React from 'react'
import { Box } from 'grommet'
import { useQueryParam, StringParam } from 'use-query-params'
import styled from 'styled-components'
import { useEffectOnce } from 'react-use'
import { useSelector } from 'react-redux'
import { authSelectors } from '../../../../state/modules/auth'
import { redirect } from '../../../../utils/router'
import { AppBar } from '../../../containers'
import { colors } from '../../../styles/variables'
import { Text, Select } from '../../'
import SearchFilters from './SearchFilters'
import Content from './Content'

interface IPayoutsPageProps {
  className?: string
}

const PayoutsPage = (props: IPayoutsPageProps): JSX.Element => {
  const isAdmin = useSelector(authSelectors.isAdmin)
  useEffectOnce(() => {
    if (!isAdmin) {
      redirect('/')
    }
  })

  /** @todo refactor to custom hook with TS interface for `PayoutStatus` */
  const [status = 'pending', setStatus] = useQueryParam('status', StringParam)

  const statusSelectTheme = {
    select: {
      background:
        status === 'pending'
          ? colors.grey.light
          : status === 'exported'
          ? colors.amber
          : colors.green,
    },
  }

  return React.useMemo(
    () => (
      <div className={props.className}>
        <Box fill={true} pad="medium" overflow={{ vertical: 'scroll' }}>
          <AppBar />
          <Box pad={{ vertical: 'medium' }} flex="grow">
            <Box justify="between" direction="row" className="page-title">
              <Text tag="h1" color="white">
                Payouts
              </Text>
              <Select
                className="status-selector"
                plain={true}
                options={['pending', 'exported', 'released']}
                onChange={setStatus}
                value={status}
                dropHeight="medium"
                theme={statusSelectTheme}
              />
            </Box>
            <Box>
              <SearchFilters className="search-filters" />
            </Box>
            <Box fill="vertical">
              <Content />
            </Box>
          </Box>
        </Box>
      </div>
    ),
    [props.className, setStatus, status, statusSelectTheme],
  )
}

// PayoutsPage.whyDidYouRender = true;

const StyledPayoutsPage = styled(PayoutsPage)`
  width: 100%;
  overflow: scroll;

  .page-title {
    margin-top: 10px;
    margin-bottom: 10px;
    align-items: center;

    h1 {
      margin-bottom: 0;
    }
  }

  .status-selector button {
    border-radius: 4px;
    border: none;
    margin-bottom: 8px;
    > div {
      border-radius: 4px;
    }
  }

  .search-filters {
    margin-bottom: 20px;
  }

  .status-text {
    cursor: default;
  }
`

export default StyledPayoutsPage
