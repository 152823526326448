import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, RouteComponentProps } from 'react-router-dom'
import { authSelectors } from '../../../state/modules/auth'

interface IPrivateRouteProps {
  isAuthenticated?: boolean
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>
  exact: boolean
  path: string
  props: any
}

const PrivateRoute = (props: IPrivateRouteProps) => {
  const { component: Component, isAuthenticated, ...rest } = props
  const renderRoute = (properties: any) => {
    return isAuthenticated ? (
      <Component {...properties} />
    ) : (
      <Redirect
        to={{ pathname: '/login', state: { from: properties.location } }}
      />
    )
  }

  return <Route {...rest} render={renderRoute} />
}

const mapStateToProps = (state: any) => ({
  isAuthenticated: authSelectors.isAuthenticated(state),
})

export default connect(mapStateToProps, null)(PrivateRoute)
