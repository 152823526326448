import { createSelector } from 'reselect'
import { get, map, memoize } from 'lodash'
import { currencySelectors } from '../../currency'
import { statementSelectors } from '../../statements'
import { IStatementResponse } from '../../statements/interfaces'
import { IUIStatementsModel } from '../statements/interfaces'
import { stringUtils } from '../../../../views/utils'

const getState = (state: any) => state

const getUIState = (state: any): IUIStatementsModel => state.ui

const getUIStatementsState = (state: any) => state.ui.statements

const getStatementsState = createSelector(
  getUIState,
  (ui: any) => ui.statements,
)

export const isUpdatingStatus = createSelector(
  getStatementsState,
  statement => statement.isUpdatingStatus,
)

export const getCurrentPage = createSelector(
  getUIStatementsState,
  uiStatementsState => get(uiStatementsState, 'currentPage', 1),
)

export const getCurrentFilter = createSelector(
  getUIStatementsState,
  uiStatementsState => get(uiStatementsState, 'currentFilter', ''),
)

export const getCurrentChannel = createSelector(
  getUIStatementsState,
  uiStatementsState => get(uiStatementsState, 'currentChannel', null),
)

export const getStatementsTableData = createSelector(getState, (state: any) =>
  memoize((page: number) => {
    const statements = statementSelectors.getStatementsByPage(state)(page)
    return statements.map((transaction: IStatementResponse) => {
      const { id, statement_date, title, total, currencies, status } =
        transaction
      return {
        id,
        statement_date,
        title,
        total: currencySelectors.formatValueWithCurrency(state)(
          total,
          currencies,
        ),
        status,
      }
    })
  }),
)

export const getChannelsForSelectDropdown = createSelector(
  statementSelectors.getChannels,
  (channels: any) => map(channels, c => c.name),
)

export const getStatementSummaryTableData = createSelector(
  getState,
  (state: any) => (statementId: number | string) => {
    const statement: IStatementResponse =
      statementSelectors.getStatementById(state)(statementId)
    if (!statement) {
      return []
    }
    if (statement.release_fee > 0) {
      return [
        {
          name: 'Total Credit',
          value: currencySelectors.formatValueWithCurrency(state)(
            statement.credits.total,
            statement.currencies,
          ),
        },
        {
          name: 'Total Debit',
          value: currencySelectors.formatValueWithCurrency(state)(
            statement.debits.total,
            statement.currencies,
          ),
        },
        {
          name: 'Trust Release Fee',
          value: currencySelectors.formatValueWithCurrency(state)(
            statement.release_fee,
            statement.currencies,
          ),
        },
        {
          name: 'Total',
          value: currencySelectors.formatValueWithCurrency(state)(
            statement.total,
            statement.currencies,
          ),
        },
      ]
    }
    return [
      {
        name: 'Total Credit',
        value: currencySelectors.formatValueWithCurrency(state)(
          statement.credits.total,
          statement.currencies,
        ),
      },
      {
        name: 'Total Debit',
        value: currencySelectors.formatValueWithCurrency(state)(
          statement.debits.total,
          statement.currencies,
        ),
      },
      {
        name: 'Total',
        value: currencySelectors.formatValueWithCurrency(state)(
          statement.total,
          statement.currencies,
        ),
      },
    ]
  },
)

export const getCreditsTableData = createSelector(
  getState,
  (state: any) => (statementId: number | string) => {
    const statement: IStatementResponse =
      statementSelectors.getStatementById(state)(statementId)
    if (!statement) {
      return []
    }
    return [
      {
        name: 'Item',
        value: 'Total',
      },
      ...map(statement.credits, (credit, key) => ({
        name: stringUtils.titleCase(key),
        credit,
        value: currencySelectors.formatValueWithCurrency(state)(
          credit,
          statement.currencies,
        ),
      })).filter((val: any) => {
        if (val.name === 'Carried Over To Next Statement' && val.credit === 0) {
          return false
        }
        return true
      }),
    ]
  },
)

export const getDebitsTableData = createSelector(
  getState,
  (state: any) => (statementId: number | string) => {
    const statement: IStatementResponse =
      statementSelectors.getStatementById(state)(statementId)
    if (!statement) {
      return []
    }
    return [
      {
        name: 'Item',
        value: 'Total',
      },
      ...map(statement.debits, (debit, key) => ({
        name: stringUtils.titleCase(key),
        debit,
        value: currencySelectors.formatValueWithCurrency(state)(
          debit,
          statement.currencies,
        ),
      })).filter((val: any) => {
        if (val.name === 'Owing From Previous Statement' && val.debit === 0) {
          return false
        }
        return true
      }),
    ]
  },
)

export const getServerErrors = createSelector(
  getUIStatementsState,
  uiChannels => get(uiChannels, 'serverErrors', null),
)
