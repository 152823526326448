import { handleActions } from 'redux-actions'
import { get } from 'lodash'
import * as actions from './actions'
import model from './model'
import { AnyAction } from 'redux'
import { IUiAppModel } from './interfaces'
import { authActions } from '../../auth'

export default handleActions(
  {
    [actions.init]: (state: IUiAppModel = model, action: AnyAction) => ({
      ...state,
      loading: true,
    }),
    [actions.booted]: (state: IUiAppModel = model, action: AnyAction) => ({
      ...state,
      booted: true,
      loading: false,
    }),
    [actions.setLoginLoadingState]: (
      state: IUiAppModel = model,
      action: AnyAction,
    ) => ({
      ...state,
      login: { ...state.login, loading: action.payload },
    }),
    [actions.setLoginErrors]: (
      state: IUiAppModel = model,
      action: AnyAction,
    ) => ({
      ...state,
      login: {
        ...state.login,
        errors: action.payload,
        loading: false,
      },
    }),
    [authActions.requestTokenSuccess]: (
      state: IUiAppModel = model,
      action: AnyAction,
    ) => ({
      ...state,
      login: {
        loading: false,
      },
    }),
    [actions.setPasswordResetSent]: (
      state: IUiAppModel = model,
      action: AnyAction,
    ) => ({
      ...state,
      forgotpassword: {
        ...state.forgotpassword,
        reset_link_sent: action.payload,
      },
    }),
    [actions.setPasswordResetSuccess]: (
      state: IUiAppModel = model,
      action: AnyAction,
    ) => ({
      ...state,
      resetpassword: { ...state.resetpassword, reset_success: action.payload },
    }),
    [actions.setNonTestChannelsExist]: (
      state: IUiAppModel = model,
      action: AnyAction,
    ) => ({
      ...state,
      nonTestChannelsExist: action.payload,
    }),
    http_error: (state: IUiAppModel = model, action: AnyAction) => {
      const httpAction = get(action, 'meta.action', false)
      return httpAction === authActions.requestToken.toString()
        ? {
            ...state,
            login: {
              ...state.login,
              loading: false,
              errors: action.payload.response,
            },
          }
        : state
    },
  },
  model,
)
