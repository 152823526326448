import { createSelector } from 'reselect'
import { get, memoize } from 'lodash'
import { paymentsSelectors } from '../../payments'
import { IPayment } from '../../payments/interfaces'

const getState = (state: any) => state

const getUIPaymentsState = (state: any) => state.ui.payments

export const getCurrentPage = createSelector(
  getUIPaymentsState,
  uiPaymentsState => get(uiPaymentsState, 'currentPage', 1),
)

export const getCurrentFilter = createSelector(
  getUIPaymentsState,
  uiPaymentsState => get(uiPaymentsState, 'currentFilter', ''),
)

export const getCurrentChannel = createSelector(
  getUIPaymentsState,
  uiPaymentsState => get(uiPaymentsState, 'currentChannel', null),
)

export const getPaymentsTableData = createSelector(getState, (state: any) =>
  memoize((page: number) => {
    const payments = paymentsSelectors.getPaymentsByPage(state)(page)
    return payments.map((payment: IPayment) => {
      const {
        trust_id,
        created,
        title,
        content,
        currencies,
        payment_types,
        total,
        channels,
        transaction_id,
        statement_batches,
        payment_classifications,
        statement_date,
      } = payment
      return {
        trust_id,
        created,
        title,
        content,
        currencies,
        payment_types,
        total,
        channels,
        transaction_id,
        statement_batches,
        payment_classifications,
        statement_date,
      }
    })
  }),
)

export const getPaymentsTableDataByFilter = createSelector(
  getState,
  state => (filter: string) =>
    paymentsSelectors.getPaymentsByFilter(state)(filter),
)
