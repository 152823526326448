import React, { FC } from 'react'
import { Box } from 'grommet'
import { Button, Text } from '../../../../views/components'
import { colors } from '../../../../views/styles/variables'
import { useGetRecoveryCodes } from '../../api'

type Props = {
  className?: string
  userId: string
  enabled: boolean
  onConfirm: () => void
}

const Step3: FC<Props> = ({ className, userId, enabled, onConfirm }) => {
  const { data } = useGetRecoveryCodes({ userId, enabled })
  return (
    <Box className={className}>
      <Text tag="h3" color={colors.blue.dark}>
        Backup Verification Codes - <small>Step 3 of 3</small>
      </Text>
      <p>
        With 2FA enabled for your account, you'll need these backup codes if you
        ever lose your device. Without your device or a backup code, you'll have
        to contact support to recover your account.
      </p>
      <div>
        {data?.map((code, index) => (
          <Text key={index} tag="span" color={colors.blue.dark}>
            {code}
          </Text>
        ))}
      </div>
      <footer>
        <Button
          styleType="primary"
          label="Got it"
          size="small"
          type="button"
          onClick={onConfirm}
        />
      </footer>
    </Box>
  )
}

export default Step3
