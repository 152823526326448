import { ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { Observable } from 'rxjs'
import { map, ignoreElements, pluck, mergeMap } from 'rxjs/operators'
import { getCurrentChannel } from './selectors'
import * as actions from './actions'
import { perPage } from './constants'
import { usersActions } from '../../users'
import { IUIUsersModel } from './interfaces'
import { sitesSelectors } from '../../sites'
import { redirect } from '../../../../utils/router'
import { IUserChannel } from '../../users/interfaces'
import { get } from 'lodash'
import { channelActions } from '../../channels'

export const fetchDataOnPageChange = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.setCurrentPage),
    map(action => {
      const currentChannel = getCurrentChannel(state$.value)
      let params: IUIUsersModel = { per_page: perPage, page: action.payload }
      if (currentChannel) {
        params.channels = currentChannel
      }
      return usersActions.requestUsers(params)
    }),
  )

export const handleUserCreationSuccess = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(usersActions.createUserSuccess),
    map(action => {
      const path = sitesSelectors.getCurrentSitePath(state$.value)
      redirect(`/${path}/users`)
    }),
    ignoreElements(),
  )

export const fetchUserChannelsWhenUserIsFetched = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(usersActions.requestUserSuccess),
    pluck('payload'),
    mergeMap(payload => {
      const channels: IUserChannel[] = get(payload, ['channels'], [])
      const params = { include: channels.map(({ id }) => id).join(',') }
      return channels.length > 0 ? [channelActions.requestChannels(params)] : []
    }),
  )
