import decode from 'jwt-decode'
import { getItem, setItem, removeItem } from './localStorage'
import { getNumericDate } from './date'

const TOKEN = '__TOKEN__'
const REFRESH_TOKEN = '__REFRESH_TOKEN__'
const USER_META = '__USER_META__'
const LAST_REQUEST = '__LAST_REQUEST__'

export const deleteTokens = () => () => {
  removeItem(TOKEN)
  removeItem(REFRESH_TOKEN)
  removeItem(USER_META)
}

export const getToken = (): string | false => getItem(TOKEN)

export const getRefreshToken = () => getItem(REFRESH_TOKEN)

export const getUserMeta = () => getItem(USER_META)

export const getDecodedToken = (refreshToken = false): any | false => {
  const token = refreshToken ? getRefreshToken() : getToken()
  return token && decode(token)
}

export const getAuthHeaders = (refresh: boolean = false) => {
  const token = refresh ? getRefreshToken() : getToken()
  return { Authorization: `Bearer ${token}` }
}

export const setLastRequestTimestamp = () =>
  setItem(LAST_REQUEST, JSON.stringify(getNumericDate()))

export const getLastRequestTimestamp = () => {
  const lastRequest = getItem(LAST_REQUEST)
  if (!lastRequest) {
    return false
  }
  return parseInt(lastRequest, 0)
}

export const setTokenFromResponse = () => (res: any) => {
  const { token, refresh_token } = res
  if (token !== undefined) {
    setItem(TOKEN, token)
    setItem(USER_META, res)
  }
  if (refresh_token) {
    setItem(REFRESH_TOKEN, refresh_token)
  }
}

export const isTokenExpired = (decodedToken?: any) => {
  if (!decodedToken) {
    return true
  }
  const { exp } = decodedToken
  // JWT exp is a NumericDate (Seconds since Epoch, not milliseconds)
  const now = getNumericDate()
  return exp && exp < now
}

export const isAuthenticated = (): boolean => {
  const token = getDecodedToken()
  const expired = isTokenExpired(token)
  return !expired
}
