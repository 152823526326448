import { createActions } from 'redux-actions'
import { NAMESPACE } from './constants'
import {
  IRequestTransactionsParams,
  ITransaction,
  IRequestTransactionsMeta,
  IAddAllocation,
} from './interfaces'

export const {
  [NAMESPACE]: {
    requestTransactionsSchema,
    requestTransactionsSchemaSuccess,
    requestTransactions,
    requestTransactionsSuccess,
    requestTransactionsWithPayments,
    requestTransactionsWithPaymentsSuccess,
    requestTransaction,
    requestTransactionSuccess,
    requestCreateTransaction,
    requestCreateTransactionSuccess,
    requestUpdateTransaction,
    requestUpdateTransactionSuccess,
    resendEmailReceipt,
    resendEmailReceiptSuccess,
    addAllocation,
    addAllocationSuccess,
  },
} = createActions({
  [`${NAMESPACE}/request_transactions_schema`]: () => '',
  [`${NAMESPACE}/request_transactions_schema_success`]: (payload: any) =>
    payload,
  [`${NAMESPACE}/request_transactions`]: [
    (payload: IRequestTransactionsParams) => payload,
    (payload: IRequestTransactionsParams, meta: IRequestTransactionsMeta) =>
      meta,
  ],
  [`${NAMESPACE}/request_transactions_success`]: [
    (payload: ITransaction[]) => payload,
    (payload: any, meta: any) => meta,
  ],
  [`${NAMESPACE}/request_transactions_with_payments`]: (
    payload: IRequestTransactionsParams,
  ) => payload,
  [`${NAMESPACE}/request_transactions_with_payments_success`]: [
    (payload: ITransaction[]) => payload,
    (payload: any, meta: any) => meta,
  ],
  [`${NAMESPACE}/request_transaction`]: (payload: any) => payload,
  [`${NAMESPACE}/request_transaction_success`]: (payload: ITransaction) =>
    payload,
  [`${NAMESPACE}/request_create_transaction`]: (payload: any) => payload,
  [`${NAMESPACE}/request_create_transaction_success`]: (
    payload: ITransaction,
  ) => payload,
  [`${NAMESPACE}/request_update_transaction`]: (payload: any) => payload,
  [`${NAMESPACE}/request_update_transaction_success`]: (
    payload: ITransaction,
  ) => payload,
  [`${NAMESPACE}/resend_email_receipt`]: (payload: any) => payload,
  [`${NAMESPACE}/resend_email_receipt_success`]: (payload: ITransaction) =>
    payload,
  [`${NAMESPACE}/add_allocation`]: (payload: {
    urlReplacements: { id: string }
    body: IAddAllocation
  }) => payload,
  [`${NAMESPACE}/add_allocation_success`]: (payload: ITransaction) => payload,
})
