import * as React from 'react'
import timfinityTheme from '../../../styles/timfinityTheme'
import { Grommet } from 'grommet/components/Grommet'
import { Box } from 'grommet/components/Box'
import { ITextInputProps } from '../TextInput/TextInput'
import Popup from '../../molecules/Popup/Popup'
import Button from '../../atoms/Button/Button'
import TextInput from '../../molecules/TextInput/TextInput'

interface IButtonFieldProps {
  text: string
  isOnEditScreen: boolean
  copyToClipboard?: boolean
  viewChanged: boolean
  isAdmin: boolean
  value: any
  okOnClickHandler(payload: any): void
  viewOnClickHandler(payload: any): void
  deleteOnClickHandler?(): void
}

interface IButtonFieldState {
  showFieldButton: boolean
  showField: boolean
  showPopup: boolean
  viewButtonClicked: boolean
  value: any
}

const initialState: IButtonFieldState = {
  showFieldButton: true,
  showField: false,
  showPopup: false,
  viewButtonClicked: false,
  value: '',
}
class ButtonField extends React.Component<
  IButtonFieldProps & ITextInputProps,
  IButtonFieldState
> {
  constructor(props: IButtonFieldProps & ITextInputProps) {
    super(props)
    this.state = {
      ...initialState,
    }
  }

  public displayPopup = () => {
    const { text, className, okOnClickHandler } = this.props
    return this.state.showPopup ? (
      <Popup
        className={className}
        text={text}
        okOnClickHandler={payload => {
          okOnClickHandler(payload) // passing handler from parent to child so function can be called at child level while maintaining relevant context (i.e. this is the function received from props)
          this.setState({
            showFieldButton: !this.state.showFieldButton,
            showField: !this.state.showField,
            showPopup: !this.state.showPopup,
          })
        }}
        cancelOnClickHandler={() =>
          this.setState({
            showFieldButton: !this.state.showFieldButton,
            showField: !this.state.showField,
            showPopup: !this.state.showPopup,
          })
        }
      />
    ) : null
  }

  public renderFieldButton = () => {
    const { className, label, value, viewOnClickHandler } = this.props
    return this.state.showFieldButton ? (
      <Grommet theme={timfinityTheme}>
        <Box direction="row" align="center">
          {label && <label style={{ marginRight: '20px' }}>{label}</label>}
          <div style={{ minWidth: '400px' }}>
            <Button
              className={className}
              styleType="secondary"
              label="View"
              size="small"
              onClick={payload => {
                this.setState({ viewButtonClicked: true })
                // if there is a value, render right away, otherwise fire onClickHandler and handle render in componentDidUpdate
                if (value) {
                  this.setState({
                    value,
                    showFieldButton: !this.state.showFieldButton,
                    showField: !this.state.showField,
                    viewButtonClicked: !this.state.viewButtonClicked,
                  })
                } else {
                  viewOnClickHandler(payload)
                }
              }}
            />
          </div>
        </Box>
      </Grommet>
    ) : null
  }

  public displayDeleteButton = () => {
    return this.props.isOnEditScreen ? (
      <Grommet theme={timfinityTheme}>
        <Box direction="row" align="center">
          <label style={{ marginRight: '20px' }}>&nbsp;</label>
          <div style={{ minWidth: '400px' }}>
            <Button
              styleType="danger"
              label="Delete"
              size="small"
              onClick={() =>
                this.setState({ showPopup: !this.state.showPopup })
              }
            />
          </div>
        </Box>
      </Grommet>
    ) : null
  }

  public renderField = () => {
    return this.state.showField ? (
      <>
        <TextInput {...this.props} value={this.state.value} />
        {this.displayDeleteButton()}
        {this.displayPopup()}
      </>
    ) : null
  }

  public resetView = () => {
    this.setState({
      ...initialState,
    })
  }

  public componentDidUpdate(prevProps: IButtonFieldProps) {
    if (prevProps.viewChanged !== this.props.viewChanged) {
      this.resetView()
    }
    // this guarantees that field does not render before value is set once view button is clicked
    if (
      this.props.value &&
      prevProps.value !== this.props.value &&
      this.state.viewButtonClicked === true
    ) {
      this.setState({
        value: this.props.value,
        showFieldButton: !this.state.showFieldButton,
        showField: !this.state.showField,
        viewButtonClicked: !this.state.viewButtonClicked,
      })
    }
  }

  public render() {
    return !this.props.isOnEditScreen ||
      (this.props.isOnEditScreen && !this.props.isAdmin) ? (
      <>
        {this.renderFieldButton()}
        {this.renderField()}
      </>
    ) : null
  }
}

export default ButtonField
