import { createActions } from 'redux-actions'
import { NAMESPACE } from './constants'

export const {
  ui: {
    payouts: { clearServerErrors, setCurrentPage },
  },
} = createActions({
  [`${NAMESPACE}/clear_server_errors`]: () => null,
  [`${NAMESPACE}/set_current_page`]: (page: number) => page,
})
