import Ajv from 'ajv'
import { Box } from 'grommet'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { usePrevious } from 'react-use'
import { Button } from '../..'
import { sitesSelectors } from '../../../../state/modules/sites'
import { uiBookingsSelectors } from '../../../../state/modules/ui'
import { redirect } from '../../../../utils/router'
import BookingDeleteConfirmationModal from './BookingDeleteConfirmationModal'
import useBookingForm from './useBookingForm'
import { bookingSelectors } from '../../../../state/modules/bookings'

type Props = {
  bookingId: string
  ajv: Ajv
}

const ViewButtons = ({ bookingId, ajv }: Props) => {
  const { isEditing, setIsEditing, data } = useBookingForm({
    bookingId,
    ajv,
  })
  const isDeletingBooking = useSelector(uiBookingsSelectors.isDeleting)
  const wasDeletingBooking = usePrevious(isDeletingBooking)

  const canDelete = isEmpty(data?.transaction_ids || [])
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false)

  useEffect(() => {
    if (wasDeletingBooking && !isDeletingBooking) {
      setIsDeleteConfirmationOpen(false)
    }
  }, [wasDeletingBooking, isDeletingBooking])

  const canUserCreateBookings = useSelector(state =>
    sitesSelectors.getCurrentSitePermission(state as never)('create_bookings'),
  )

  if (!canUserCreateBookings) {
    return null
  }

  const bookingSchemaMethods = useSelector(
    bookingSelectors.getBookingsSchemaMethods,
  )

  return (
    <Box direction="row" className="view-buttons">
      <BookingDeleteConfirmationModal
        bookingId={bookingId}
        ajv={ajv}
        isOpen={isDeleteConfirmationOpen}
        setIsOpen={setIsDeleteConfirmationOpen}
      />
      {bookingSchemaMethods?.includes('PUT' as never) && (
        <Button
          styleType="secondary"
          label="Edit"
          size="small"
          type="button"
          onClick={() => {
            setIsEditing(!isEditing)
          }}
        />
      )}

      {canDelete && bookingSchemaMethods?.includes('DELETE' as never) && (
        <Button
          styleType="danger"
          label="Delete"
          size="small"
          onClick={() => setIsDeleteConfirmationOpen(true)}
        />
      )}
    </Box>
  )
}

const CreateAndEditButtons = ({ bookingId, ajv }: Props) => {
  const {
    initialValues,
    isViewingBooking,
    createBooking,
    updateBooking,
    isEditing,
    isValid,
    setData,
    setIsEditing,
    currentSitePath,
  } = useBookingForm({ bookingId, ajv })

  const handleSave = useCallback(() => {
    if (isViewingBooking) {
      updateBooking()
    } else {
      createBooking()
    }
  }, [isViewingBooking, createBooking])

  const handleCancel = useCallback(() => {
    if (isEditing) {
      setIsEditing(false)
      setData(initialValues)
    } else {
      redirect(`/${currentSitePath}/bookings`)
    }
  }, [])

  return (
    <>
      <Button
        disabled={!isValid}
        styleType="primary"
        label="Save"
        size="small"
        type="submit"
        onClick={handleSave}
      />
      <Button
        styleType="danger"
        label="Cancel"
        size="small"
        onClick={handleCancel}
      />
    </>
  )
}

const BookingFormControls = ({ bookingId, ajv }: Props) => {
  const { isViewingBooking, isEditing } = useBookingForm({ bookingId, ajv })
  return (
    <Box direction="row" className="actions">
      {isViewingBooking && !isEditing ? (
        <ViewButtons bookingId={bookingId} ajv={ajv} />
      ) : (
        <CreateAndEditButtons bookingId={bookingId} ajv={ajv} />
      )}
    </Box>
  )
}

export default BookingFormControls
