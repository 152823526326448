import React from 'react'
import {
  and,
  Categorization,
  ControlElement,
  deriveLabelForUISchemaElement,
  isVisible,
  RankedTester,
  rankWith,
  StatePropsOfLayout,
  Tester,
  UISchemaElement,
  uiTypeIs,
} from '@jsonforms/core'
import {
  TranslateProps,
  useJsonForms,
  withJsonFormsLayoutProps,
  withTranslateProps,
} from '@jsonforms/react'
import { AjvProps, withAjvProps } from '@jsonforms/vanilla-renderers'
import { useMemo, useState } from 'react'
import { Tabs } from '../../../../views/components'
import { LayoutRenderer, LayoutRendererProps } from './layout'

export interface CategorizationLayoutRendererProps
  extends StatePropsOfLayout,
    AjvProps,
    TranslateProps {
  selected?: number
  ownState?: boolean
  data?: any
  onChange?(selected: number, prevSelected: number): void
}

export const CategorizationLayoutRenderer = (
  props: CategorizationLayoutRendererProps,
) => {
  const {
    data,
    path,
    renderers,
    cells,
    schema,
    uischema,
    visible,
    enabled,
    selected,
    onChange,
    ajv,
    t,
  } = props
  const ctx = useJsonForms()
  const errors = ctx?.core?.errors || []

  const categorization = uischema as Categorization
  const [activeCategory, setActiveCategory] = useState<number>(selected ?? 0)
  const findFieldByScope = (scope: string) => {
    const key = Object.keys(schema?.properties || {}).find(
      key => key === scope.split('/')[2],
    )
    if (!key) {
      return null
    }
    return schema.properties?.[key] || null
  }

  const categories = useMemo(
    () =>
      categorization.elements
        .filter(category => isVisible(category, data, path, ajv))
        .filter(category => {
          // TODO: remove this once 'visible' properties are removed from the schema
          const groupHasFields = (category.elements as UISchemaElement[]).some(
            el => {
              if (el.options?.visible === true) {
                return true
              }
              const field = findFieldByScope((el as ControlElement).scope)
              return field && (field as any)?.visible !== false
            },
          )
          return groupHasFields
        }),
    [categorization, data, ajv],
  )

  const categoriesWithErrors = useMemo(() => {
    return categories.filter(cat => {
      const errorsForCategory = errors.filter(error => {
        return (
          (cat.elements as UISchemaElement[]).find(e => {
            return error.schemaPath.includes((e as ControlElement).scope)
          }) !== undefined ||
          (error.keyword === 'required' &&
            (cat.elements as UISchemaElement[]).find(e => {
              const fieldName = (e as ControlElement).scope.split('/')[2]
              return error.params.missingProperty.includes(fieldName)
            }) !== undefined)
        )
      })
      return errorsForCategory.length > 0
    })
  }, [categories, errors])

  const safeCategory =
    activeCategory >= categorization.elements.length ? 0 : activeCategory

  const childProps: LayoutRendererProps = {
    elements: categories[safeCategory] ? categories[safeCategory].elements : [],
    schema,
    path,
    enabled,
    visible,
    renderers,
    cells,
  }
  const onTabChange = (index: number) => {
    setActiveCategory(index)
  }

  const tabLabels = useMemo(() => {
    return categories.map(e => deriveLabelForUISchemaElement(e, t))
  }, [categories, t])

  const tabs = categories.map((_, index: number) => ({
    title: tabLabels[index]
      ? `${tabLabels[index]}${
          categoriesWithErrors.includes(categories[index]) ? ' *' : ''
        }`
      : '',
    Component: () => {
      return (
        <div className="tab-panel">
          <LayoutRenderer {...childProps} />
        </div>
      )
    },
  }))

  return (
    <Tabs tabs={tabs} onActive={onTabChange} activeIndex={activeCategory} />
  )
}

export default withAjvProps(
  withTranslateProps(withJsonFormsLayoutProps(CategorizationLayoutRenderer)),
)

export const isSingleLevelCategorization: Tester = and(
  uiTypeIs('Categorization'),
  (uischema: UISchemaElement): boolean => {
    const categorization = uischema as Categorization
    const isAllElementsCategories = categorization.elements.reduce(
      (acc, e) => acc && e.type === 'Category',
      true,
    )

    return categorization.elements && isAllElementsCategories
  },
)

export const categorizationTester: RankedTester = rankWith(
  1,
  isSingleLevelCategorization,
)
