import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/browser'
import GA4React from 'ga-4-react'
import './index.css'
import * as serviceWorker from './serviceWorker'
import { isAuthenticated, getUserMeta } from './utils/auth'
import store from './state/store'
import { authActions } from './state/modules/auth'
import Routes from './views/Routes'
import { GA_ID } from './state/constants'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

if (isAuthenticated()) {
  store.dispatch(authActions.requestTokenSuccess(getUserMeta()))
}

if (typeof (window as any).Cypress !== 'undefined') {
  ;(window as any).store = store
}

if (
  process.env.REACT_APP_SENTRY_DSN &&
  process.env?.SENTRY_ENV === 'production'
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    maxBreadcrumbs: 50,
    debug: true,
    release: process.env.REACT_APP_TMT_VERSION, // this is dynamically set in the deploy script no required in environment variables
    environment: process.env.NODE_ENV,
    // beforeSend(event) {
    //     // Check if it is an exception, if so, show the report dialog
    //     if (event.exception) {
    //         Sentry.showReportDialog();
    //     }
    //     return event;
    // }
  })
}

/**
 * This breaks hot reload, so only add it when if needed for debugging
 */
// if (process.env.NODE_ENV === 'development') {
//     const whyDidYouRender = require('@welldone-software/why-did-you-render');
//     whyDidYouRender(React, {
//         trackAllPureComponents: false
//     });
// }

const AppRoot = () => {
  const queryClient = new QueryClient()
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Routes />
        {process.env.NODE_ENV === 'development' && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
      </QueryClientProvider>
    </Provider>
  )
}

const render = async () => {
  if (GA_ID && process.env.NODE_ENV === 'production') {
    const ga4react = new GA4React(GA_ID)
    await ga4react
      .initialize()
      .then((res: any) => {
        // console.log('Analytics Success.');
      })
      .catch((err: any) => {
        // console.log('Analytics Failure.');
      })
      .finally(() => {
        ReactDOM.render(<AppRoot />, document.getElementById('root'))
      })
  } else {
    ReactDOM.render(<AppRoot />, document.getElementById('root'))
  }
}

render()

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./views/Routes', async () => {
    await render()
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
