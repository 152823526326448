import * as React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { Box } from 'grommet'
import { get } from 'lodash'
import DateRangeFilter from '../../organisms/DateRangeFilter/DateRangeFilter'
import useDateFilter from '../../../../hooks/useDateFilter'
import usePagination from '../../../../hooks/usePagination'

interface IStatementsSearchFiltersProps {
  className?: string
}

const StatementsSearchFilters = (
  props: IStatementsSearchFiltersProps,
): JSX.Element => {
  const { setDateFilters, dateFilters } = useDateFilter()
  const { setCurrentPage } = usePagination()

  const getDateFilters = () => [
    {
      label: 'Statement Date',
      value: 'date',
    },
  ]

  const handleOnConfirm = (values: any) => {
    const { startDate, endDate, startTime, endTime } = values
    if (startDate && endDate && startTime && endTime) {
      setDateFilters({
        before: endDate.format('YYYY-MM-DD') + endTime.format(' H:mm:ss'),
        after: startDate.format('YYYY-MM-DD') + startTime.format(' H:mm:ss'),
      })
    } else if (startDate && endDate) {
      setDateFilters({
        before: endDate.format('YYYY-MM-DD 23:59:59'),
        after: startDate.format('YYYY-MM-DD 00:00:00'),
      })
    }

    if (!startDate && !endDate) {
      // resetDates();
    }
    setCurrentPage(1)
  }

  const startDate = get(dateFilters, 'after', get(dateFilters, 'after'))
  const endDate = get(dateFilters, 'before', get(dateFilters, 'before'))
  const startTime = get(dateFilters, 'after', get(dateFilters, 'after'))
  const endTime = get(dateFilters, 'before', get(dateFilters, 'before'))

  return (
    <Box
      direction="row"
      align="end"
      justify="between"
      className={props.className}
    >
      {startDate && endDate && (
        <DateRangeFilter
          plain={true}
          startDate={moment(startDate) || moment().subtract(2, 'weeks')}
          endDate={moment(endDate) || moment()}
          startTime={moment(startTime) || moment('00:00:00')}
          endTime={moment(endTime) || moment('23:59:59')}
          filters={getDateFilters()}
          onConfirm={handleOnConfirm}
        />
      )}
    </Box>
  )
}

export default styled(StatementsSearchFilters)`
  display: flex;
  min-height: 44px;
`
