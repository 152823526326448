import * as React from 'react'
import { Box } from 'grommet'
import { DataTable, Anchor, StatusText } from '../..'
import styled from 'styled-components'
import { IBookingTransactionTableData } from './interfaces'

interface ITransactionsTableProps {
  data: IBookingTransactionTableData[]
  className?: string
  redirectToTransaction(id: string | number): any
}

class TransactionsTable extends React.Component<ITransactionsTableProps, {}> {
  public getColumns = () => [
    {
      Header: 'Trust ID',
      accessor: 'trust_id',
      Cell: (row: any) => {
        const redirectTo = () =>
          this.props.redirectToTransaction(row.original.id)
        return <Anchor onClick={redirectTo}>{row.original.trust_id}</Anchor>
      },
    },
    {
      Header: 'Created',
      accessor: 'created',
    },
    {
      Header: 'Payee',
      accessor: 'payee',
      Cell: (row: any) => {
        return (
          <div>
            {row.original.payee_name} {row.original.payee_surname}
          </div>
        )
      },
    },
    {
      Header: 'Type',
      accessor: 'transaction_types',
    },
    {
      Header: 'Credit',
      accessor: 'credit',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: (row: any) => {
        return (
          <StatusText
            value={row.original.status}
            status={row.original.status}
          />
        )
      },
    },
  ]

  public render(): JSX.Element {
    const { data, className } = this.props
    return (
      <Box fill={true} className={className}>
        <DataTable
          manual={true}
          columns={this.getColumns()}
          data={data}
          loading={false}
          pageSize={data.length}
        />
      </Box>
    )
  }
}

const StyledTransactionsTable = styled(TransactionsTable)`
  min-height: 300px;
  table {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`

export default StyledTransactionsTable
