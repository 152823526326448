import * as React from 'react'
import { Modal } from '../../..'
import {
  ITransaction,
  ICreateTransaction,
} from '../../../../../state/modules/transactions/interfaces'
import ChargebackOverrideForm from './ChargebackOverrideForm'

interface IChargebackOverrideModalProps {
  isOpen: boolean
  transaction: ITransaction
  isApplyingChargebackOverride: boolean
  onClose(): void
  onSubmit(params: ICreateTransaction): void
}

const ChargebackReversalModal = (props: IChargebackOverrideModalProps) => {
  const {
    isOpen,
    onClose,
    transaction,
    onSubmit,
    isApplyingChargebackOverride,
  } = props

  const chargebackOverrideForm = () => (
    <ChargebackOverrideForm
      transaction={transaction}
      onSubmit={onSubmit}
      isApplyingChargebackOverride={isApplyingChargebackOverride}
    />
  )

  return (
    <Modal
      isOpen={isOpen}
      showCloseIcon={true}
      onClose={onClose}
      InnerComponent={chargebackOverrideForm()}
    />
  )
}

export default ChargebackReversalModal
