import * as React from 'react'
import { FormikProps, Form, withFormik } from 'formik'
import { first, omit, tail } from 'lodash'
import classNames from 'classnames'
import * as yup from 'yup'
import {
  ITransaction,
  ICreateTransaction,
} from '../../../../../state/modules/transactions/interfaces'
import { Text, Button, Loader, CurrencyInput } from '../../..'
import { colors } from '../../../../styles/variables'
import styled from 'styled-components'
import { addTrailingZero, convertToCents } from '../../../../../utils/currency'

interface IApplyVoidFormProps {
  transaction: ITransaction
  className?: string
  isApplyingVoid: boolean
  formatCurrency(value: number | string, currencies: string): string
  getCurrencySymbol(currencyName: string): string
  onSubmit(params: ICreateTransaction): void
}

interface IFormValues {
  total: string
  content: string
  channels: number
  bookings: any[]
  currencies: string
  psp: string
  transaction_types: string
  payment_methods: string
  linked_id: number
  total_remaining: number
}

const validationSchema = yup.object().shape({
  total: yup
    .number()
    .required('Please add a refund amount!')
    // need a normal function to maintain lexical scope for acccess to parent object.
    // tslint:disable-next-line:only-arrow-functions
    .test('is-valid', 'Cannot exceed refundable amount', function (val) {
      if (val && convertToCents(val) > this.parent.total_remaining) {
        return this.createError({
          path: this.path,
          message: 'Cannot exceed refundable amount',
        })
      }
      return true
    }),
})

const ApplyVoidForm = (
  props: IApplyVoidFormProps & FormikProps<IFormValues>,
) => {
  const {
    setFieldValue,
    values,
    errors,
    transaction,
    isApplyingVoid,
    formatCurrency,
    getCurrencySymbol,
  } = props
  const classes = classNames(props.className, {
    'is-submitting': isApplyingVoid,
  })

  const handleCurrencyChange = (e: any) =>
    setFieldValue('total', e.target.value)

  return (
    <div className={classes}>
      {isApplyingVoid && <Loader text="Applying void..." />}
      <Form className="form">
        <Text tag="h3" color={colors.blue.dark}>
          Void
        </Text>
        <p>
          Void:{' '}
          {formatCurrency(transaction.total_remaining, transaction.currencies)}
        </p>
        <CurrencyInput
          disabled={true}
          prefix={getCurrencySymbol(transaction.currencies)}
          name="total"
          onChange={handleCurrencyChange}
          className="field"
          label="Void"
          value={values.total}
          error={errors.total}
        />
        <footer>
          <Button
            styleType="secondary"
            label="Apply"
            size="small"
            type="submit"
          />
        </footer>
      </Form>
    </div>
  )
}

const StyledApplyVoidForm = styled(ApplyVoidForm)`
  margin: 10px;
  &.is-submitting {
    form {
      display: none;
    }
  }
  h3 {
    margin: 0 0 10px 0;
    border-bottom: 1px solid ${colors.grey.lighter};
  }
  .field {
    margin-bottom: 10px;
    position: relative;
  }
  .field label {
    margin-right: 30px;
  }
  footer {
    border-top: 1px solid ${colors.grey.lighter};
    text-align: right;
    margin-top: 10px;
    padding-top: 10px;
  }
  footer button {
    font-size: 1rem;
    padding: 6px 8px;
  }
  .field .error {
    display: block;
    position: absolute;
    right: 0;
    top: 30px;
    bottom: 30px;
    font-size: 12px;
  }
  #content::placeholder {
    font-size: 14px;
    font-weight: normal;
  }
`

export default withFormik<IApplyVoidFormProps, IFormValues>({
  validationSchema,
  validateOnBlur: true,
  validateOnChange: true,
  mapPropsToValues: (props: IApplyVoidFormProps) => {
    const { transaction } = props
    const { id, channels, bookings, currencies, psp, total_remaining } =
      transaction
    return {
      channels,
      bookings: bookings.map(b => omit(b, ['reference'])),
      currencies,
      psp,
      transaction_types: 'void',
      payment_methods: 'credit-card',
      linked_id: id,
      total_remaining,
      total: addTrailingZero(total_remaining / 100),
      content: '',
    }
  },
  /**
   * Reset total to transaction.bookings[0].total if forex converted amount > transaction.bookings[0].total
   * @link https://app.clickup.com/t/2ffwjx
   */
  handleSubmit: (values: any, { props }) => {
    const { transaction } = props
    const totalInCents = convertToCents(values.total)
    const firstTransactionBooking = first(transaction.bookings)
    const tailBookings = tail(transaction.bookings)
    const convertedTotal = transaction.forex_rate
      ? convertToCents(values.total / parseFloat(transaction.forex_rate))
      : totalInCents
    const bookingTotal =
      transaction.forex_rate &&
      firstTransactionBooking &&
      convertedTotal > firstTransactionBooking.total
        ? firstTransactionBooking.total
        : convertedTotal

    const params = {
      ...values,
      total: totalInCents,
      bookings: [
        { ...firstTransactionBooking, total: bookingTotal },
        ...tailBookings,
      ],
    }
    props.onSubmit(params)
  },
})(StyledApplyVoidForm)
