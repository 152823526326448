import * as React from 'react'
import { Box } from 'grommet'
import styled from 'styled-components'
import bg from '../../../../assets/images/bg-dark.svg'

interface IContentSectionProps {
  className: string
  children: any
}

const ContentSection = (props: any) => {
  const { className } = props
  return (
    <Box className={className} gridArea="main" justify="start" align="center">
      {props.children}
    </Box>
  )
}

const StyledContentSection = styled(ContentSection)`
  background-color: #063e5a;
  background-image: url(${bg});
  background-position: top right;
  background-repeat: no-repeat;
`

export default StyledContentSection
