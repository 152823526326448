import * as React from 'react'
import styled from 'styled-components'
import { Box } from 'grommet'
import { useSelector } from 'react-redux'
import { redirect } from '../../../../utils/router'
import { sitesSelectors } from '../../../../state/modules/sites'
import { ArchiveTemplate, Text, Button } from '../..'
import Content from './Content'
import SearchFilters from './SearchFilters'
import { bookingSelectors } from '../../../../state/modules/bookings'

interface IBookingsPageProps {
  className?: string
}

const Header = (): JSX.Element => {
  const bookingsSchemaMethods = useSelector(
    bookingSelectors.getBookingsSchemaMethods,
  )
  const currentSitePath = useSelector(sitesSelectors.getCurrentSitePath)
  const createBookingUrl = React.useMemo(
    () => () => redirect(`/${currentSitePath}/bookings/new`),
    [currentSitePath],
  )
  return (
    <>
      <Box justify="between" direction="row" className="page-intro">
        <Text tag="h1" color="white">
          Bookings
        </Text>
        {bookingsSchemaMethods.includes('POST' as never) && (
          <Button
            styleType="primary"
            label="+ Create Booking"
            onClick={createBookingUrl}
          />
        )}
      </Box>
      <SearchFilters className="search-filters" />
    </>
  )
}

const BookingsPage = (props: IBookingsPageProps): JSX.Element =>
  React.useMemo(
    () => (
      <div className={props.className}>
        <ArchiveTemplate
          verticalOverflow="scroll"
          Header={() => <Header />}
          Content={() => <Content />}
          displayChannelSelector={true}
        />
      </div>
    ),
    [props.className],
  )

const StyledBookingsPage = styled(BookingsPage)`
  width: 100%;
  overflow: scroll;

  .page-intro {
    align-items: center;
    margin-bottom: 20px;
  }

  .search-filters {
    margin-bottom: 10px;
  }
`

export default StyledBookingsPage
