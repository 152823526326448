import { ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { Observable } from 'rxjs'
import { map, mergeMap, pluck } from 'rxjs/operators'
import { getCurrentFilter, getCurrentChannel } from './selectors'
import * as actions from './actions'
import { perPage } from './constants'
import { transactionsActions, transactionsSelectors } from '../../transactions'
import { ITransaction } from '../../transactions/interfaces'
import { bookingActions } from '../../bookings'
import { paymentsActions } from '../../payments'
import { liftPayload } from '../../../utils/rx-operators'
import { get as _get } from 'lodash'
import { IUITransactionsModel } from './interfaces'
import { channelActions } from '../../channels'
import { commentActions } from '../../comments'
import { CommentEntity, CommentType } from '../../comments/interfaces'

export const fetchDataOnPageChange = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.setCurrentPage),
    map(action => {
      const currentFilter = getCurrentFilter(state$.value)
      const currentChannel = getCurrentChannel(state$.value)
      let params: IUITransactionsModel = {
        per_page: perPage,
        page: action.payload,
      }
      if (currentChannel) {
        params.channels = currentChannel
      }
      if (currentFilter) {
        params = {
          ...params,
          ...JSON.parse(currentFilter),
        }
      }
      return transactionsActions.requestTransactions(params)
    }),
  )

export const fetchTransactionBookings = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.fetchTransactionBookings),
    map(action => {
      const transaction: ITransaction =
        transactionsSelectors.getTransactionById(state$.value)(action.payload)
      return transaction.bookings.map(booking => booking.id)
    }),
    map(bookingIds =>
      bookingActions.requestBookings({ include: bookingIds.join(',') }),
    ),
  )

export const fetchTransactionAdjustments = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.fetchTransactionBookings),
    map(action => {
      const transaction: ITransaction =
        transactionsSelectors.getTransactionById(state$.value)(action.payload)
      return transaction.adjustments
    }),
    /** @todo fix this! Only return request if transaction has adjustments. Currently fetches default if empty. */
    map(adjustmentIds =>
      transactionsActions.requestTransactions({
        include: adjustmentIds.join(','),
      }),
    ),
  )

export const fetchTransactionPayments = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.fetchTransactionPayments),
    map(action => {
      const transaction: ITransaction =
        transactionsSelectors.getTransactionById(state$.value)(action.payload)
      return transaction.payment_ids
    }),
    /** @todo fix this! Only return request if transaction has adjustments. Currently fetches default if empty. */
    map(paymentIds =>
      paymentsActions.requestPayments({ include: paymentIds.join(',') }),
    ),
  )

export const fetchTransactionPaymentsOnAllocation = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(transactionsActions.addAllocationSuccess),
    map(action => {
      const transaction: ITransaction =
        transactionsSelectors.getTransactionById(state$.value)(
          action.payload.id,
        )
      return transaction.payment_ids
    }),
    map(paymentIds =>
      paymentsActions.requestPayments({ include: paymentIds.join(',') }),
    ),
  )

export const fetchTransactionsOnChannelChange = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.setChannel),
    liftPayload(),
    map(action => {
      const currentFilter = getCurrentFilter(state$.value)
      const channels = _get(action, 'id', null)
      let params: IUITransactionsModel = { per_page: perPage, page: 1 }
      if (channels) {
        params.channels = channels
      }
      if (currentFilter) {
        params = {
          ...params,
          ...JSON.parse(currentFilter),
        }
      }
      return transactionsActions.requestTransactions(params)
    }),
  )

export const fetchTransactionData = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(transactionsActions.requestTransactionSuccess),
    liftPayload(),
    mergeMap(payload => [
      channelActions.requestChannel({ id: payload.channels }),
      paymentsActions.requestPayments({
        include: payload.payment_ids.join(','),
      }),
    ]),
  )

export const refreshChangelogOnTransactionUpdate = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(
      transactionsActions.requestUpdateTransactionSuccess,
      transactionsActions.addAllocationSuccess,
    ),
    pluck('payload'),
    map(payload =>
      commentActions.requestComments(
        { type: CommentType.Readonly },
        {
          entity: CommentEntity.Transactions,
          entityId: payload.id,
          urlReplacements: { id: payload.id },
        },
      ),
    ),
  )
