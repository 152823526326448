import { IAuthModel } from './interfaces'

const authModel: IAuthModel = {
  id: null,
  name: '',
  token: '',
  refresh_token: '',
  default_site: null,
  site_count: 0,
  type: '',
  user_email: '',
  user_nicename: '',
  user_display_name: '',
  username: '',
  usertype: null,
  can_sign_off_payout: false,
  isAuthenticated: false,
  isRefreshingToken: false,
}

export default authModel
