import React from 'react'
import { Box } from 'grommet'
import styled from 'styled-components'
import { useEffectOnce } from 'react-use'
import { useSelector } from 'react-redux'
import { authSelectors } from '../../../../state/modules/auth'
import { redirect } from '../../../../utils/router'
import { Text, Button } from '../../'
import Content from './Content'
import SearchFilters from './SearchFilters'
import { AppBar } from '../../../containers'
import { supplierSelectors } from '../../../../state/modules/suppliers'

interface ISuppliersPageProps {
  className?: string
}

const Header = (): JSX.Element => {
  return (
    <>
      <Box justify="between" direction="row" className="page-intro">
        <Text tag="h1" color="white">
          Suppliers
        </Text>
        <Button
          styleType="primary"
          label="+ Create Supplier"
          onClick={() => redirect('/suppliers/new')}
        />
      </Box>
      <SearchFilters className="search-filters" />
    </>
  )
}

const SuppliersPage = (props: ISuppliersPageProps): JSX.Element => {
  const isAdmin = useSelector(authSelectors.isAdmin)
  const supplierCategories = useSelector(
    supplierSelectors.getSupplierCategoriesForSelectFilter,
  )

  useEffectOnce(() => {
    if (!isAdmin) {
      redirect('/')
    }
  })

  return (
    <div className={props.className}>
      <Box fill={true} pad="medium" overflow={{ vertical: 'hidden' }}>
        <AppBar />
        <Box pad={{ vertical: 'medium' }} flex="grow">
          <Box height={{ min: 'auto' }}>
            <Header />
          </Box>
          <Box fill="vertical">
            <Content supplierCategories={supplierCategories} />
          </Box>
        </Box>
      </Box>
    </div>
  )
}

const StyledSuppliersPage = styled(SuppliersPage)`
  width: 100%;
  overflow: scroll;

  .page-intro {
    align-items: center;
  }

  .search-filters {
    margin-bottom: 10px;
  }
`

export default StyledSuppliersPage
