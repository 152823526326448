import { PayloadAction } from '@reduxjs/toolkit'
import { BaseMeta } from '../../interfaces'

interface ICommentUser {
  id: number
  uuid: string
  name: string
  username: string
  tel: string
  email: string
  email_verified_at: null | string
  created_at: string
  updated_at: string
  legacy_id: null | number
  is_superadmin: number
  client_id: number
  usertype: string
}

export enum CommentEntity {
  Bookings = 'bookings',
  Transactions = 'transactions',
  Channels = 'channels',
  Statements = 'statements',
}
export interface IComment {
  id: number
  type: string
  user_id: number
  description: string
  created_at: string
  updated_at: string
  from_name: string
  user: ICommentUser
  /**
   * @todo consider renaming `entity` to `parent`. i.e. `comment_parent` and `parent_id`?
   */
  comment_entity: CommentEntity // used in redux state to separate comments
  entity_id: number // used in redux state to find comments by entity id - i.e. booking id
}

export interface ICommentsModel {
  sites: {
    [key: string]: {
      byId: {
        [key: number]: IComment
      }
      allIds: number[]
    }
  }
}

export enum CommentType {
  Internal = 'internal',
  External = 'external',
  Readonly = 'readonly',
}

export interface IRequestCommentsParams {
  type: CommentType
}

export interface IAddCommentRequest {
  type: CommentType
  description: string
}

export type IDeleteCommentSuccess = {
  deleted: boolean
}

/**
 * Actions
 */

// Update Comment
export type UpdateCommentRequestPayload = {
  urlReplacements: { id: string; commentId: string }
  body: { description: string }
}
export interface UpdateCommentRequestMeta extends BaseMeta {
  entity: CommentEntity
}
export type UpdateCommentRequestAction = PayloadAction<
  UpdateCommentRequestPayload,
  string,
  UpdateCommentRequestMeta
>
export interface UpdateCommentSuccessMeta extends BaseMeta {
  entity: CommentEntity
  entityId: number
}
export type UpdateCommentSuccessAction = PayloadAction<
  IComment,
  string,
  UpdateCommentSuccessMeta
>

// Delete Comment
export type DeleteCommentRequestPayload = { id: string; commentId: string }
export interface DeleteCommentRequestMeta extends BaseMeta {
  entity: CommentEntity
}
export type DeleteCommentRequestAction = PayloadAction<
  DeleteCommentRequestPayload,
  string,
  DeleteCommentRequestMeta
>
export interface DeleteCommentSuccessPayload extends IDeleteCommentSuccess {
  deleted: boolean
  id: string
  commentId: string
}
export interface DeleteCommentSuccessMeta extends BaseMeta {}
export type DeleteCommentSuccessAction = PayloadAction<
  DeleteCommentSuccessPayload,
  string,
  DeleteCommentSuccessMeta
>
