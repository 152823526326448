import * as React from 'react'
import { Box, Text } from 'grommet'
import { Button } from '../..'
import styled from 'styled-components'

export interface IForgotPasswordSuccessFormProps {
  className?: string
  redirect(path: string): void
}

class ForgotPasswordSuccessForm extends React.Component<
  IForgotPasswordSuccessFormProps,
  {}
> {
  public render() {
    const { className, redirect } = this.props
    const redirectTo = () => redirect('/login')
    return (
      <Box
        fill={true}
        align="center"
        justify="center"
        pad="large"
        className={className}
      >
        <Box width="medium">
          <Text tag="h1" color="green">
            Success!
          </Text>
          <p>You will receive an email with the link to reset your password</p>
          <div className="login-button">
            <Button
              onClick={redirectTo}
              alignSelf="center"
              styleType="gradient"
              size="large"
              type="submit"
              label="Go to login"
            />
          </div>
        </Box>
      </Box>
    )
  }
}

const StyledForgotPasswordSuccessForm = styled(ForgotPasswordSuccessForm)`
  h1,
  p {
    text-align: center;
  }
  h1 {
    color: #67be5a;
    font-size: 28px;
    text-transform: uppercase;
  }
  .login-button {
    button {
      padding: 17px 80px;
    }
  }
`

export default StyledForgotPasswordSuccessForm
