import * as React from 'react'
import { Box } from 'grommet'
import Button from '../../atoms/Button/Button'
import Icon from '../../atoms/Icon/Icon'
import styled from 'styled-components'
import { colors } from '../../../styles/variables'
import { prev, next } from '../../../../assets/icons'
interface IPaginationProps {
  page: number
  pages: number
  className?: string
  onNext(): void
  onPrevious(): void
}

class Pagination extends React.Component<IPaginationProps, {}> {
  public render(): JSX.Element | null {
    const { page, pages, className, onNext, onPrevious } = this.props
    return pages <= 1 ? null : (
      <Box direction="row" className={className}>
        <Button
          className={`${className}__prev`}
          disabled={page <= 1}
          onClick={onPrevious}
          styleType="plain"
          icon={<Icon svg={prev} />}
          label="Previous"
        />
        <Button
          className={`${className}__next`}
          disabled={page >= pages}
          onClick={onNext}
          styleType="plain"
          icon={<Icon svg={next} />}
          label="Next"
        />
      </Box>
    )
  }
}

const StyledPagination = styled(Pagination)`
  background: white;
  color: ${colors.blue.dark};
  padding: 10px;
  justify-content: space-between;

  button {
    color: ${colors.blue.dark};
    box-shadow: none;
    background: white;
    padding: 2px;

    div {
      align-items: flex-start;
    }
  }

  &__next {
    div {
      display: flex;
      flex-direction: row-reverse;
    }
  }
`

export default StyledPagination
