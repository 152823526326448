import * as React from 'react'
import { Grommet, Layer, Image } from 'grommet'
import timfinityTheme from '../../../styles/timfinityTheme'
import { close } from '../../../../assets/icons/index'
import Button from '../../atoms/Button/Button'

export interface IModalProps {
  isOpen: boolean
  showCloseIcon: boolean
  InnerComponent: JSX.Element
  className?: string
  onClose?(): void
  disableOutsideClick?: boolean
}

class Modal extends React.Component<IModalProps, {}> {
  public render(): JSX.Element | null {
    const {
      isOpen,
      showCloseIcon,
      onClose,
      disableOutsideClick,
      InnerComponent,
      className,
    } = this.props

    const CloseButton = () => (
      <div className="close-button">
        <Button
          styleType="plain"
          icon={<Image src={close} />}
          onClick={this.props.onClose}
        />
      </div>
    )

    return !isOpen ? null : (
      <Grommet theme={timfinityTheme} className={className}>
        <Layer
          modal={true}
          position="center"
          onClickOutside={() => !disableOutsideClick && onClose && onClose()}
          onEsc={onClose}
        >
          {showCloseIcon && <CloseButton />}
          {InnerComponent}
        </Layer>
      </Grommet>
    )
  }
}

export default Modal
