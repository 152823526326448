import { createSelector } from 'reselect'
import { IPspsState } from './interfaces'
import { NAMESPACE } from './constants'
import { get } from 'lodash'

const getPspsState = (state: any): IPspsState => state[NAMESPACE]

export const getPspIds = createSelector(getPspsState, p => p.allIds)

export const getPsps = createSelector(getPspsState, p => p.byId)

export const getPspById = createSelector(
  getPsps,
  psps => (id: string) => get(psps, [id]),
)
