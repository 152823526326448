import * as React from 'react'
import { Grommet, Grid, Box } from 'grommet'
import { grommet } from 'grommet/themes'
import Logo from './Logo'
import SideNav from './SideNav'
import ContentSection from './ContentSection'
import NotificationsContainer from '../../../containers/NotificationsContainer'
export interface IAuthenticatedLayoutProps {
  match?: any
  currentSitePath: string
  children: any
}

class AuthenticatedLayout extends React.Component<
  IAuthenticatedLayoutProps,
  {}
> {
  public gridAreas = [
    { name: 'sidebar', start: [0, 0], end: [0, 1] },
    { name: 'main', start: [1, 1], end: [1, 1] },
  ]

  public render() {
    const {
      match: { url },
      currentSitePath,
    } = this.props
    return (
      <Grommet full={true} theme={grommet}>
        <Grid
          fill={true}
          rows={['auto', 'flex']}
          columns={['auto', 'flex']}
          areas={this.gridAreas}
        >
          <Box
            gridArea="sidebar"
            background="light"
            width="small"
            alignContent="center"
            justify="start"
          >
            <Logo />
            <SideNav currentPath={url} currentSitePath={currentSitePath} />
          </Box>
          <ContentSection>
            <NotificationsContainer />
            {this.props.children}
          </ContentSection>
        </Grid>
      </Grommet>
    )
  }
}

export default AuthenticatedLayout
