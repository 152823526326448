import React, { useState } from 'react'
import { Box } from 'grommet'
import { Button, DataTable, Modal } from '../../'
import CommentsForm, { CommentAction, IFormValues } from './CommentsForm'
import { authSelectors } from '../../../../state/modules/auth'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { buttonLink } from '../../../styles/buttonStyles'
import { CommentType } from '../../../../state/modules/comments/interfaces'

interface ICommentsProps {
  className?: string
  comments: Array<IComment>
  commentType: CommentType
  onNewCommentAdded(comment: IFormValues): void
  onCommentUpdated(id: number, values: IFormValues): void
  onCommentDeleted(commentId: number): void
}

interface IComment {
  id: number
  time: string
  user: string
  comment: string
  canEdit: boolean
}

interface ICommentModalState {
  action: CommentAction
  commentId?: number
  isOpen: boolean
}
const commentModalInitialState = { action: CommentAction.New, isOpen: false }

const Comments = (props: ICommentsProps) => {
  const [commentModal, setCommentModal] = useState<ICommentModalState>(
    commentModalInitialState,
  )

  const onClose = () => setCommentModal(commentModalInitialState)

  const onOpenNewCommentModal = () =>
    setCommentModal({ action: CommentAction.New, isOpen: true })

  const onOpenEditCommentModal = (commentId: number) =>
    setCommentModal({ action: CommentAction.Edit, isOpen: true, commentId })

  const {
    className,
    comments,
    commentType,
    onNewCommentAdded,
    onCommentUpdated,
    onCommentDeleted,
  } = props

  const isAdmin = useSelector(authSelectors.isAdmin)

  const getColumns = () => [
    {
      Header: 'Time',
      accessor: 'time',
      minWidth: 30,
    },
    {
      Header: 'User',
      accessor: 'user',
      minWidth: 30,
    },
    {
      Header: 'Comment',
      accessor: 'comment',
      style: {
        whiteSpace: 'break-spaces',
      },
    },
  ]

  const handleNewCommentAdded = (values: IFormValues) => {
    onNewCommentAdded(values)
    onClose()
  }

  const handleCommentUpdate = (values: IFormValues) => {
    const id = commentModal?.commentId
    if (id) {
      onCommentUpdated(id, values)
    }
    onClose()
  }

  const handleCommentDelete = () => {
    onCommentDeleted(commentModal?.commentId || 0)
    onClose()
  }

  const translateCommentTypeText = (commentType: CommentType) => {
    switch (commentType) {
      case CommentType.External:
        return 'Public'
      case CommentType.Internal:
        return 'Private'
      default:
        return 'Read Only'
    }
  }

  const addTitle = `Add ${translateCommentTypeText(commentType)} Comment`

  const newCommentForm = (
    <Box width="large">
      <CommentsForm
        className="new-comment-form"
        commentAction={CommentAction.New}
        modalTitle={addTitle}
        buttonText={addTitle}
        onSubmit={handleNewCommentAdded}
      />
    </Box>
  )

  const editTitle = `Update ${translateCommentTypeText(commentType)} Comment`
  const deleteTitle = `Delete ${translateCommentTypeText(commentType)} Comment`

  const editCommentForm = (
    <Box width="large">
      <CommentsForm
        className="edit-comment-form"
        commentAction={CommentAction.Edit}
        modalTitle={editTitle}
        buttonText={editTitle}
        deleteButtonText={deleteTitle}
        initialValues={{
          comment:
            comments.find(comment => comment.id === commentModal?.commentId)
              ?.comment ?? '',
        }}
        onDelete={handleCommentDelete}
        onSubmit={handleCommentUpdate}
      />
    </Box>
  )

  const commentsData = comments.map((comment: IComment) => ({
    ...comment,
    user: comment.canEdit ? (
      <button onClick={() => onOpenEditCommentModal(comment.id)}>
        {comment.user}
      </button>
    ) : (
      comment.user
    ),
  }))

  return (
    <div className={className}>
      <DataTable
        manual={true}
        plain={true}
        columns={getColumns()}
        data={commentsData}
        pageSize={comments.length}
        loading={false}
      />
      {isAdmin && (
        <Box
          background="white"
          fill="horizontal"
          pad="medium"
          round="small"
          className="add-comments"
        >
          <Button
            styleType="secondary"
            size="small"
            label={addTitle}
            onClick={onOpenNewCommentModal}
          />
        </Box>
      )}
      <Modal
        isOpen={
          commentModal.action === CommentAction.New && commentModal.isOpen
        }
        showCloseIcon={true}
        onClose={onClose}
        InnerComponent={newCommentForm}
      />
      <Modal
        isOpen={
          commentModal.action === CommentAction.Edit && commentModal.isOpen
        }
        showCloseIcon={true}
        onClose={onClose}
        InnerComponent={editCommentForm}
      />
    </div>
  )
}

export default styled(Comments)`
  .ReactTable .rt-tbody .rt-tr,
  .ReactTable-striped .rt-tbody .rt-tr {
    align-items: flex-start;
  }

  .ReactTable .rt-tbody .rt-td {
    align-self: start;
  }

  .rt-tbody button {
    ${buttonLink}
    font-size: 14px;
  }
`
