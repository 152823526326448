import React, { FC } from 'react'
import styled from 'styled-components'
import { colors } from '../../../styles/variables'

type Props = {
  className?: string
  intent: 'success' | 'warning' | 'danger'
  children: React.ReactNode
}

const Alert: FC<Props> = ({ className, children }) => {
  return <div className={className}>{children}</div>
}

export default styled(Alert)`
  border-width: 1px;
  border-style: solid;
  margin: 10px 0;
  padding: 20px;

  ${({ intent }) => {
    switch (intent) {
      case 'success':
        return `
          border-color: #155724;
          background-color: #dff0d8;
          color: #155724;
        `
      case 'warning':
        return `
          border-color: ${colors.amberDark};
          background-color: ${colors.amberLight};
          color: ${colors.amberDark};
        `
      case 'danger':
        return `
          border-color: #721c24;
          background-color: #f5c6cb;
          color: #721c24;
        `
    }
  }}
`
