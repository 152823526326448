import * as React from 'react'
import { IStatementResponse } from '../../../../state/modules/statements/interfaces'
import { Grid } from 'grommet'
import styled from 'styled-components'
import { colors } from '../../../styles/variables'
import { formatDate } from '../../../../utils/date'

interface ISummaryHeaderProps {
  statement: IStatementResponse
  className?: string
}

const getDateRange = (statement: IStatementResponse) => {
  return `${formatDate(
    statement.date_range.start,
    'yyyy-LL-dd',
  )} - ${formatDate(statement.date_range.end, 'yyyy-LL-dd')}`
}

const SummaryHeader = (props: ISummaryHeaderProps) => {
  const { statement } = props
  return !statement ? null : (
    <Grid
      columns={{ count: 2, size: 'auto' }}
      gap="small"
      className={props.className}
    >
      <div>
        Date of Statement:{' '}
        {formatDate(statement.statement_date, 'EEE dd LLL yyyy')} <br />
        Date Range: {getDateRange(statement)} <br />
        Currency: {statement.currencies}
      </div>
      <div>
        Member ID: {statement.member_id} <br />
        Statement ID: {statement.trust_id}
      </div>
    </Grid>
  )
}

const StyledSummaryHeader = styled(SummaryHeader)`
  font-size: 0.9rem;
  > div:first-child {
    border-right: 1px solid ${colors.grey.light};
  }
`

export default StyledSummaryHeader
