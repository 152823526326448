import { IUiAppModel } from './interfaces'

const uiAppModel: IUiAppModel = {
  loading: false,
  booted: false,
  login: {
    loading: false,
    errors: false,
  },
  forgotpassword: {
    reset_link_sent: false,
  },
  resetpassword: {
    reset_success: false,
  },
  nonTestChannelsExist: false,
}

export default uiAppModel
