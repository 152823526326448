import React, { FC } from 'react'
import { Modal } from '../../views/components'
import CreateApiTokenForm from './CreateApiTokenForm'

type Props = {
  className?: string
  isOpen: boolean
  onClose: () => void
}

const CreateApiTokenModal: FC<Props> = ({ className, isOpen, onClose }) => {
  return (
    <Modal
      className={className}
      isOpen={isOpen}
      showCloseIcon={true}
      onClose={onClose}
      InnerComponent={<CreateApiTokenForm />}
      disableOutsideClick={true}
    />
  )
}

export default CreateApiTokenModal
