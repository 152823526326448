import React, { Component } from 'react'
import { Grommet, Select as GrommetSelect, Box, SelectProps } from 'grommet'
import { debounce, get } from 'lodash'
import timfinityTheme from '../../../styles/timfinityTheme'
import { colors } from '../../../styles/variables'
import styled from 'styled-components'

export interface ISelectProps extends SelectProps {
  filters?: any
  options: any[]
  placeholder?: string
  className?: string
  value?: string
  searchFilterTheme?: object
  plain?: boolean
  label?: string
  disabled?: boolean
  error?: string
  multiple?: boolean
  theme?: object
  onSearch?(term: string): void
  onOpen?(args: any): void
  onClose?(rgs: any): void
  loadInitialData?(): void
  onChange?(option: any): void
  getOptionByName?(name: string): void
  onMore?(): void
}

class Select extends Component<ISelectProps, {}> {
  public state = {
    value: '',
  }

  public intersectionObserver: IntersectionObserver | null = null

  public intersectionTarget: Element | null = null

  public componentDidMount() {
    const { loadInitialData, value } = this.props
    if (typeof loadInitialData === 'function') {
      loadInitialData()
    }
    if (value) {
      this.setState({ value })
    }
  }

  public componentDidUpdate(prevProps: ISelectProps) {
    const { value, options } = this.props

    if (value && prevProps.value !== value) {
      this.setState({ value })
    }

    // Re initialise intersection observer when more channels are loaded
    if (prevProps.options.length < options.length) {
      this.initIntersectionObserver()
    }
  }

  public onChange = (e: any) => {
    const { option } = e
    const { onChange, getOptionByName } = this.props
    this.setState({ value: option?.value || option })
    if (typeof onChange === 'function') {
      if (getOptionByName) {
        onChange(getOptionByName(option))
      } else {
        onChange(option)
      }
    }
  }

  public getLabel = () => {
    const { label } = this.props
    return label && <label>{label}</label>
  }

  public getError = () => {
    const { error } = this.props
    return error && <span className="error">{error}</span>
  }

  public initIntersectionObserver = () => {
    const { onMore } = this.props
    const options = {
      root: document.querySelector('[class*="SelectContainer__OptionsBox"]'),
    }
    this.intersectionObserver = new IntersectionObserver(entries => {
      if (get(entries, [0, 'isIntersecting'])) {
        if (typeof onMore === 'function') {
          onMore()
        }
      }
    }, options)
    this.intersectionTarget = document.querySelector(
      '[class*="SelectContainer__OptionsBox"] > div:nth-last-child(2)',
    )
    if (this.intersectionTarget) {
      this.intersectionObserver.observe(this.intersectionTarget)
    }
  }

  public handleOpen = (args: any) => {
    const { onOpen } = this.props
    if (typeof onOpen === 'function') {
      onOpen(args)
    }
    this.initIntersectionObserver()
  }

  public handleClose = (args: any) => {
    const { onClose } = this.props
    if (typeof onClose === 'function') {
      onClose(args)
    }
    if (this.intersectionObserver && this.intersectionTarget) {
      this.intersectionObserver.unobserve(this.intersectionTarget)
    }
  }

  public render() {
    const {
      options,
      onSearch,
      placeholder,
      className,
      disabled,
      multiple = false,
      theme,
      ...rest
    } = this.props
    const { value } = this.state
    const placeholderText = placeholder || 'Select'

    return (
      <Grommet theme={{ ...timfinityTheme, ...theme }} className={className}>
        <Box direction="row" align="center">
          {this.getLabel()}
          <GrommetSelect
            {...rest}
            placeholder={placeholderText}
            value={value}
            options={options}
            onChange={this.onChange}
            onClose={this.handleClose}
            onSearch={onSearch}
            dropHeight={get(this.props, 'dropHeight', 'small')}
            disabled={disabled}
            multiple={multiple}
            /** @hack debounce to prevent multiple consecutive calls */
            onOpen={debounce(this.handleOpen, 300)}
          />
        </Box>
        <Box>{this.getError()}</Box>
      </Grommet>
    )
  }
}

const StyledSelect = styled(Select)`
  ${(props: ISelectProps) => `
        box-sizing: border-box;
        height: 100%;
        border-radius: 5px;
        label {
            margin-right: 20px;
        }
        span {
            font-size: 1.1rem;
            line-height: 1.1rem;
        }
        input::placeholder, input[value] {
            font-family: Lato;
            font-style: italic;
            font-weight: normal;
            padding-left: 5px;
            line-height: 1.1rem;
            font-size: 1.1rem;
            margin-left: 5px;
            color: ${props.error ? colors.red : 'inherit'};
            opacity: 1; /* Firefox */
        }
        button {
            border: 1px solid ${props.error ? colors.red : colors.blue.dark};
            &[disabled] {
                opacity: 1;
                &, input {
                    cursor: not-allowed;
                }
                > div {
                    background: ${colors.grey.lighter};
                }
            }
        }
        .error {
            color: ${colors.red};
            font-size: 15px;
        }
        ${
          props.options.length === 1
            ? `
            [class*=StyledIcon] {
                display: none;
            }`
            : ''
        }
    `}
`

export default StyledSelect
