import { handleActions, combineActions } from 'redux-actions'
import { get, uniq, omit } from 'lodash'
import { AnyAction } from 'redux'
import * as actions from './actions'
import model from './model'
import { IBookingsModel, IBookingResponse } from './interfaces'
import { sitesSelectors } from '../sites'
import { parseLinkHeader } from '../../utils/http'

/** @todo move to util functions */
const getCurrentSitePathState = (state: any, currentSitePath: string) =>
  get(state, `sites[${currentSitePath}]`, {})
const getAllIds = (state: any, currentSitePath: string) =>
  get(state, `sites[${currentSitePath}].allIds`, [])
const getById = (state: any, currentSitePath: string) =>
  get(state, `sites[${currentSitePath}].byId`, {})
const getFiltersKey = (meta: any) =>
  JSON.stringify(omit(meta.params, ['per_page', 'page']))
const getByFilters = (state: any, currentSitePath: string) =>
  get(state, `sites[${currentSitePath}].byFilters`, {})
const getByPage = (state: any, currentSitePath: string) =>
  get(state, `sites[${currentSitePath}].byPage`, {})

export default function createBookingsReducer(rootState: any) {
  const currentSitePath = sitesSelectors.getCurrentSitePath(rootState)

  return handleActions(
    {
      [actions.requestBookingsSchemaSuccess]: (
        state: IBookingsModel,
        action: AnyAction,
      ) => ({
        ...state,
        schema: action.payload,
      }),
      [actions.requestBookings]: (state: IBookingsModel) => {
        return {
          ...state,
          sites: {
            ...state.sites,
            [currentSitePath]: {
              ...getCurrentSitePathState(state, currentSitePath),
              isLoading: true,
            },
          },
        }
      },
      [actions.requestBookingsSuccess]: (
        state: IBookingsModel,
        action: AnyAction,
      ) => {
        const { payload, meta } = action
        return {
          ...state,
          sites: {
            ...state.sites,
            [currentSitePath]: {
              ...getCurrentSitePathState(state, currentSitePath),
              isLoading: false,
              pages: parseLinkHeader(meta.responseHeaders?.link || ''),
              byId: {
                ...getById(state, currentSitePath),
                ...payload.reduce(
                  (bookings: IBookingResponse[], booking: IBookingResponse) => {
                    bookings[booking.id] = booking
                    return bookings
                  },
                  {},
                ),
              },
              allIds: uniq([
                ...getAllIds(state, currentSitePath),
                ...payload.map((booking: IBookingResponse) => booking.id),
              ]),
              byPage: {
                ...getByPage(state, currentSitePath),
                [meta.params.page]: payload.map(
                  (channel: IBookingResponse) => channel.id,
                ),
              },
              byFilters: {
                ...getByFilters(state, currentSitePath),
                [getFiltersKey(meta)]: payload.map(
                  (booking: IBookingResponse) => booking.id,
                ),
              },
            },
          },
        }
      },
      [combineActions(
        actions.requestBookingSuccess,
        actions.requestCreateBookingSuccess,
        actions.requestUpdateBookingSuccess,
      )]: (state: IBookingsModel, action: AnyAction) => {
        const { payload } = action
        return {
          ...state,
          sites: {
            ...state.sites,
            [currentSitePath]: {
              ...getCurrentSitePathState(state, currentSitePath),
              byId: {
                ...getById(state, currentSitePath),
                [payload.id]: payload,
              },
              allIds: uniq([...getAllIds(state, currentSitePath), payload.id]),
            },
          },
        }
      },
    },
    model,
  )
}
