import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NAMESPACE } from './constants'
import { IRequestPspsParams, IPspResponse, IPspsState } from './interfaces'

export const initialState: IPspsState = {
  schema: {},
  allIds: [],
  byId: {},
  loading: false,
}

export const pspsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    requestPsps: (state, action: PayloadAction<IRequestPspsParams>) => {},
    requestPspsSuccess: {
      prepare: (payload, meta) => ({ payload, meta }),
      reducer: (state, action: PayloadAction<IPspResponse[]>) => {
        state.allIds = action.payload.map(s => s.id)
        action.payload.forEach(s => {
          state.byId[s.id] = s
        })
      },
    },
  },
})

const { actions, reducer } = pspsSlice

export { actions, reducer }
