import { useDispatch, useSelector } from 'react-redux'
import { useAxios } from '../../utils/axios'
import { sitesSelectors } from '../../state/modules/sites'
import { EndpointKeys, Endpoints, ENDPOINTS } from './constants'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  ConfirmResponse,
  DisableResponse,
  EnableResponse,
  QRCodeResponse,
  RecoveryCodesResponse,
  SecretKeyResponse,
} from './types'
import { uiNotificationActions } from '../../state/modules/ui'
import { usersActions } from '../../state/modules/users'

/**
 * TODO: handle expired tokens
 */

// TODO: move into more generic hook.
const useTwoFactorAuthApi = ({ userId }: { userId: string }) => {
  const currentSitePath = useSelector(sitesSelectors.getCurrentSitePath)
  const endpoints = Object.keys(ENDPOINTS).reduce<Endpoints>(
    (acc, key) => {
      acc[key as EndpointKeys] = (ENDPOINTS as any)[key]
        .replace(':sitePath', currentSitePath)
        .replace(':id', userId.toString())
      return acc
    },
    { ...ENDPOINTS },
  )

  return {
    endpoints,
  }
}

export const useEnableTwoFactorAuth = ({
  userId,
  onSuccess,
  onError,
}: {
  userId: string
  onSuccess: (data: any) => void
  onError?: (error: any) => void
}) => {
  const dispatch = useDispatch()
  const { endpoints } = useTwoFactorAuthApi({ userId })
  const axios = useAxios()
  // const queryClient = useQueryClient()

  const queryData = useMutation<EnableResponse, any, any>({
    mutationFn: async () => {
      const { data } = await axios.post<EnableResponse>(endpoints.ENABLE, null)
      return data
    },
    onSuccess: data => {
      onSuccess(data)
      // queryClient.invalidateQueries({
      //   queryKey: ['apiTokens'],
      // })
    },
    // TODO: move this to a global queryCache error handler
    onError: error => {
      const { response } = error

      if (onError) {
        onError(error)
      }

      dispatch(
        uiNotificationActions.addNotification({
          message:
            'Enabling two factor authentication failed. Please try again.',
          type: 'error',
        }),
      )
    },
  })

  return queryData
}

export const useDisableTwoFactorAuth = ({
  userId,
  onSuccess,
}: {
  userId: string
  onSuccess: (data: any) => void
}) => {
  const dispatch = useDispatch()
  const { endpoints } = useTwoFactorAuthApi({ userId })
  const axios = useAxios()
  // const queryClient = useQueryClient()

  const queryData = useMutation<DisableResponse, any, any>({
    mutationFn: async () => {
      const { data } = await axios.delete<DisableResponse>(endpoints.DISABLE)
      return data
    },
    onSuccess: data => {
      dispatch(usersActions.requestUserSuccess(data.user))
      onSuccess(data)
      // queryClient.invalidateQueries({
      //   queryKey: ['apiTokens'],
      // })
    },
    // TODO: move this to a global queryCache error handler
    onError: ({ response }) => {
      dispatch(
        uiNotificationActions.addNotification({
          message:
            'Disabling two factor authentication failed. Please try again.',
          type: 'error',
        }),
      )
    },
  })

  return queryData
}

export const useGetQRCode = ({
  userId,
  enabled,
}: {
  userId: string
  enabled: boolean
}) => {
  const { endpoints } = useTwoFactorAuthApi({ userId })
  const axios = useAxios()

  return useQuery<QRCodeResponse>(
    ['twoFactorAuth.qrCode', userId],
    async () => {
      const data = await axios.get<QRCodeResponse>(endpoints.QR_CODE)
      return data.data
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    },
  )
}

export const useConfirmTwoFactorAuth = ({
  userId,
  onSuccess,
}: {
  userId: string
  onSuccess: (data: any) => void
}) => {
  const dispatch = useDispatch()
  const { endpoints } = useTwoFactorAuthApi({ userId })
  const axios = useAxios()
  // const queryClient = useQueryClient()

  const queryData = useMutation<ConfirmResponse, any, any>({
    mutationFn: async ({ code }) => {
      const { data } = await axios.post<ConfirmResponse>(endpoints.CONFIRM, {
        code,
      })
      return data
    },
    onSuccess: data => {
      dispatch(usersActions.requestUserSuccess(data.user))
      onSuccess(data)
      // queryClient.invalidateQueries({
      //   queryKey: ['apiTokens'],
      // })
    },
    // TODO: move this to a global queryCache error handler
    onError: ({ response }) => {
      dispatch(
        uiNotificationActions.addNotification({
          message: 'Two factor authentication failed. Please try again.',
          type: 'error',
        }),
      )
    },
  })

  return queryData
}

export const useGetSecret = ({
  userId,
  enabled,
}: {
  userId: string
  enabled: boolean
}) => {
  const { endpoints } = useTwoFactorAuthApi({ userId })
  const axios = useAxios()

  return useQuery<SecretKeyResponse>(
    ['twoFactorAuth.getSecret', userId],
    async () => {
      const data = await axios.get<SecretKeyResponse>(endpoints.SECRET_KEY)
      return data.data
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    },
  )
}

export const useGetRecoveryCodes = ({
  userId,
  enabled,
}: {
  userId: string
  enabled: boolean
}) => {
  const { endpoints } = useTwoFactorAuthApi({ userId })
  const axios = useAxios()

  return useQuery<RecoveryCodesResponse>(
    ['twoFactorAuth.getRecoveryCodes', userId],
    async () => {
      const data = await axios.get<RecoveryCodesResponse>(
        endpoints.RECOVERY_CODES,
      )
      return data.data
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    },
  )
}
