import * as React from 'react'
import { Box } from 'grommet'
import { DataTable } from '../..'
import styled from 'styled-components'
import { IBookingPaymentsTableData } from './interfaces'

interface IPaymentsTableProps {
  data: IBookingPaymentsTableData[]
  className?: string
}

class PaymentsTable extends React.Component<IPaymentsTableProps, {}> {
  public getColumns = () => [
    {
      Header: 'Title',
      accessor: 'title',
      Cell: (row: any) => <strong>{row.original.title}</strong>,
    },
    {
      Header: 'Credit',
      accessor: 'credit',
    },
    {
      Header: 'Debit',
      accessor: 'debit',
    },
  ]

  public render(): JSX.Element {
    const { data, className } = this.props
    return (
      <Box fill={true} className={className}>
        <DataTable
          manual={true}
          columns={this.getColumns()}
          data={data}
          loading={false}
          pageSize={data.length}
        />
      </Box>
    )
  }
}

const StyledPaymentsTable = styled(PaymentsTable)`
  min-height: 300px;
  table {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`

export default StyledPaymentsTable
