import axios from 'axios'
import { getToken } from './auth'

const axiosInstance = axios.create({
  timeout: 2000,
})

// TODO: check this doesn't create too many instances
export const useAxios = () => {
  const authToken = getToken()
  return axios.create({
    timeout: 2000,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
}

export default axiosInstance
