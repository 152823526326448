import React from 'react'
import { RankedTester, Test, UISchemaElement } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { withVanillaControlProps } from '@jsonforms/vanilla-renderers'
import ApiTokens from '../../../apiTokens/ApiTokens'

const ApiTokensControl = () => {
  return <ApiTokens />
}

const { rankWith } = Test

/**
 * Alternative tester for an array that also checks whether the 'table'
 * option is set.
 * @type {RankedTester}
 */
export const apiTokensControlTester: RankedTester = rankWith(
  4,
  (uischema: UISchemaElement) => {
    return uischema?.options?.Component === 'ApiTokensControl'
  },
)

export default withVanillaControlProps(
  withJsonFormsControlProps(ApiTokensControl),
)
