import * as React from 'react'
import Ajv from 'ajv'
import addFormats from 'ajv-formats'
import styled from 'styled-components'
import { ArchiveTemplate } from '../..'
import SettingsPageHeader from './SettingsPageHeader'
import SettingsPageForm from './SettingsPageForm'
import { useSelector } from 'react-redux'
import { sitesSelectors } from '../../../../state/modules/sites'

type Props = {
  className?: string
  currentSitePath: string
}

const ajv = new Ajv({
  allErrors: true,
  verbose: false,
  strict: false,
  validateSchema: false,
  inlineRefs: false,
  code: {
    optimize: false,
  },
})
addFormats(ajv)

const SettingsPage = ({ className }: Props) => {
  const currentSitePath = useSelector(sitesSelectors.getCurrentSitePath)

  return React.useMemo(
    () => (
      <div className={className}>
        <ArchiveTemplate
          verticalOverflow="scroll"
          Header={() => (
            <SettingsPageHeader ajv={ajv} currentSitePath={currentSitePath} />
          )}
          Content={() => (
            <SettingsPageForm ajv={ajv} currentSitePath={currentSitePath} />
          )}
          displayChannelSelector={false}
        />
      </div>
    ),
    [currentSitePath],
  )
}

const StyledSettingsPage = styled(SettingsPage)`
  width: 100%;
  overflow: scroll;

  .section-header {
    margin-bottom: 10px;
    h2 {
      margin-right: 20px;
    }

    .actions button {
      font-size: inherit;
      line-height: inherit;
      padding: 10px 18px;
      margin-left: 10px;
    }
  }
`

export default StyledSettingsPage
