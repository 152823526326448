import Ajv from 'ajv'
import React, { useEffect } from 'react'
import { Text } from '../..'
import styled from 'styled-components'

import { Box } from 'grommet'
import addFormats from 'ajv-formats'
import { JsonForms } from '@jsonforms/react'
import useBookingForm from './useBookingForm'

import BookingFormControls from './BookingFormControls'
import { useEffectOnce } from 'react-use'
import BookingFormStatus from './BookingFormStatus'
import PaymentRequestButton from './PaymentRequestButton'
import CreateTransactionButton from './CreateTransactionButton'
import PayViaBankTransferButton from './PayViaBankTransferButton'
import LoadingModal from './LoadingModal'
import { cells, renderers } from '../../../../modules/jsonForms/renderers'

type Props = {
  className?: string
  bookingId: string
  token: string
}

const ajv = new Ajv({
  allErrors: true,
  verbose: false,
  strict: false,
  validateSchema: false,
  inlineRefs: false,
  code: {
    optimize: false,
  },
})
addFormats(ajv)

const BookingForm: React.FC<Props> = ({ className, bookingId, token }) => {
  const {
    schema,
    uiSchema,
    booking,
    data,
    setData,
    setInitialValues,
    isEditing,
    setIsEditing,
    additionalErrors,
    getLoadingState,
    handleChange,
  } = useBookingForm({ bookingId, ajv })

  useEffectOnce(() => {
    if (bookingId === 'new') {
      setIsEditing(true)
    } else {
      setIsEditing(false)
    }
    return () => {
      // reset form data on unmount
      setData(null)
    }
  })

  // Reset form data when booking changes
  useEffect(() => {
    const bookingData = setInitialValues(booking)
    setData(bookingData)
  }, [booking])

  return (
    <div className={className}>
      <LoadingModal state={getLoadingState()} />
      <Box
        direction="row"
        align="center"
        justify="between"
        wrap={true}
        className="section-header"
      >
        <Box direction="row">
          <Text tag="h2" color="white">
            {bookingId === 'new' ? 'Create Booking' : data?.title}
          </Text>
          <BookingFormStatus bookingId={bookingId} />
          <BookingFormControls bookingId={bookingId} ajv={ajv} />
        </Box>
        <Box direction="row">
          <PaymentRequestButton bookingId={bookingId} token={token} />
          <PayViaBankTransferButton bookingId={bookingId} />
          <CreateTransactionButton bookingId={bookingId} />
        </Box>
      </Box>

      <Box direction="column" className="form">
        <JsonForms
          readonly={!isEditing}
          schema={schema}
          uischema={uiSchema}
          data={data}
          renderers={renderers}
          cells={cells}
          ajv={ajv}
          onChange={handleChange}
          additionalErrors={additionalErrors}
        />
      </Box>
    </div>
  )
}

export default styled(BookingForm)`
  .section-header {
    margin-bottom: 20px;

    h2 {
      margin-right: 20px;
    }
    .status-button {
      cursor: default;
    }

    .actions {
      display: flex;
      align-items: center;
    }

    .actions button {
      font-size: inherit;
      line-height: inherit;
      padding: 10px 18px;
    }
  }

  .group-layout {
    border: none;
    margin-bottom: 20px;
    padding: 0;
    legend {
      font-weight: bold;
      padding: 0;
      margin-bottom: 20px;
    }
  }

  .form {
    margin-bottom: 20px;
    border-radius: 12px;
    padding: 24px;
    background: #fff;

    label {
      width: 300px;
    }

    .input-description {
      display: none;
    }
  }
`
