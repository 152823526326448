import { first, get } from 'lodash'
import * as React from 'react'
import { useEffectOnce } from 'react-use'
import styled from 'styled-components'
import { SearchFilter } from '../..'
import useFilter from '../../../../hooks/useFilter'
import usePagination from '../../../../hooks/usePagination'
import { TextFilter } from '../../organisms/SearchFilter/types'
import search from '../../../../assets/icons/search.svg'

interface IGlobalSearchFiltersProps {
  className?: string
}

const GlobalSearchFilters: React.FC<IGlobalSearchFiltersProps> = ({
  className,
}) => {
  const { setCurrentPage } = usePagination()
  const {
    filter,
    activeFilter,
    setActiveFilter,
    activeFilterValue,
    setActiveFilterValue,
  } = useFilter()

  const filters = [
    {
      value: 'id',
      label: 'Trust ID',
      type: 'text' as TextFilter,
    },
    {
      value: 'payee_surname',
      label: 'Payee Surname',
      type: 'text' as TextFilter,
    },
    {
      value: 'payee_email',
      label: 'Payee Email',
      type: 'text' as TextFilter,
    },
    {
      value: 'reference',
      label: 'Reference',
      type: 'text' as TextFilter,
    },
    {
      value: 'last_four_digits',
      label: 'Credit Card Last 4 Digits',
      type: 'text' as TextFilter,
    },
    {
      value: 'arn_number',
      label: 'ARN Number',
      type: 'text' as TextFilter,
    },
  ]

  const firstFilter = first(filters)
  const _activeFilter = activeFilter || get(firstFilter, 'value', '')

  /**
   * Set default active filter and value
   * If filter in query params, set that.
   * Else set first filter in array above as active filter
   */
  useEffectOnce(() => {
    const firstFilterKey =
      (filter && first(Object.keys(filter))) || get(filters, [0, 'value'], '')
    const firstFilterValue = (filter && first(Object.values(filter))) || ''

    if (!activeFilter) {
      setActiveFilter(firstFilterKey)
    }
    if (!activeFilterValue) {
      setActiveFilterValue(firstFilterValue)
    }
  })

  /**
   * Wrap `setActiveFilterValue` so we can reset pagination as well.
   */
  const _setActiveFilterValue = React.useCallback(
    (val: any) => {
      setActiveFilterValue(val)
      setCurrentPage(1)
    },
    [setActiveFilterValue, setCurrentPage],
  )

  return (
    <div className={className}>
      <SearchFilter
        filters={filters}
        activeFilter={_activeFilter}
        setFilter={setActiveFilter}
        activeFilterValue={activeFilterValue}
        setActiveFilterValue={_setActiveFilterValue}
      />
    </div>
  )
}

export default styled(GlobalSearchFilters)`
  background-color: #fff;
  padding: 1rem;
  border-radius: 15px;
  display: flex;
  min-height: 44px;
  justify-content: space-between;

  > div {
    width: 100%;
    max-width: 100%;
  }

  > div > div:first-of-type {
    button {
      min-width: 260px;
      > div {
        background-color: #f1f1f1;
      }
    }
  }

  > div > div:last-of-type {
    position: relative;
    width: 100%;
    &:after {
      content: '';
      background: url(${search}) no-repeat;
      background-size: 95%;
      display: block;
      position: absolute;
      top: 6px;
      right: 3px;
      width: 30px;
      height: 30px;
      margin: 0 5px 0 10px;
    }
    input {
      width: 100%;
    }
  }

  input,
  button {
    border-radius: 0;
  }

  button {
    border: none;
  }
`
