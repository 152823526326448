import * as React from 'react'
import { colors } from '../../../styles/variables'
import Text from '../../atoms/Text/Text'
import Modal, { IModalProps } from '../../molecules/Modal/Modal'
import Button from '../../atoms/Button/Button'
import styled from 'styled-components'
import { Box } from 'grommet'
import { Loader } from '../..'

export interface IConfirmationModalProps
  extends Omit<IModalProps, 'InnerComponent'> {
  onConfirm(): void
  title: string
  children: React.ReactNode
  isLoading?: boolean
  loadingText?: string
}

const ConfirmationModal = (props: IConfirmationModalProps) => {
  const {
    onConfirm,
    title,
    children,
    className,
    isLoading,
    loadingText,
    ...modalProps
  } = props
  const confirmationBody = (
    <Box className={className}>
      {isLoading ? (
        <Loader text={loadingText} />
      ) : (
        <>
          <Text tag="h3" color={colors.blue.dark}>
            {title}
          </Text>
          {children}
          <footer>
            <Button
              styleType="danger"
              label="Confirm"
              size="small"
              type="button"
              onClick={onConfirm}
            />
            <Button
              styleType="plain"
              label="Cancel"
              size="small"
              type="button"
              onClick={modalProps.onClose}
            />
          </footer>
        </>
      )}
    </Box>
  )

  return <Modal {...modalProps} InnerComponent={confirmationBody} />
}

export default styled(ConfirmationModal)`
  min-width: 400px;
  padding: 16px;

  h3 {
    margin: 0 0 10px 0;
    border-bottom: 1px solid ${colors.grey.lighter};
  }

  footer {
    display: flex;
    border-top: 1px solid ${colors.grey.lighter};
    margin-top: 10px;
    padding-top: 10px;

    footer button {
      font-size: 1rem;
      padding: 6px 8px;
    }

    button:first-child {
      margin-right: 10px;
    }
  }
`
