import { createSlice, createSelector } from '@reduxjs/toolkit'
import { httpError } from '../../../utils/http'
import { supplierActions } from '../../suppliers'

type TUISuppliers = {
  isLoading: boolean
}

const uiSuppliersState = createSlice({
  name: 'uiSuppliers',
  initialState: {
    isLoading: false,
  } as TUISuppliers,
  reducers: {},
  extraReducers: {
    [supplierActions.requestSupplier.toString()]: state => {
      state.isLoading = true
    },
    [supplierActions.requestSupplierSuccess.toString()]: state => {
      state.isLoading = false
    },
    [supplierActions.createSupplier.toString()]: state => {
      state.isLoading = true
    },
    [supplierActions.createSupplierSuccess.toString()]: state => {
      state.isLoading = false
    },
    [supplierActions.updateSupplier.toString()]: state => {
      state.isLoading = true
    },
    [supplierActions.updateSupplierSuccess.toString()]: state => {
      state.isLoading = false
    },
    [supplierActions.deleteSupplier.toString()]: state => {
      state.isLoading = true
    },
    [supplierActions.deleteSupplierSuccess.toString()]: state => {
      state.isLoading = false
    },
    [httpError]: (state, { payload, meta }) => {
      if (
        meta.action === supplierActions.createSupplier.toString() ||
        meta.action === supplierActions.updateSupplier.toString() ||
        meta.action === supplierActions.deleteSupplier.toString()
      ) {
        state.isLoading = false
      }
    },
  },
})

const getUiSuppliersState = (state: any) => state.ui.suppliers

export const uiSuppliersSelectors = {
  isLoading: createSelector(getUiSuppliersState, ss => ss.isLoading),
}

const { actions: uiSuppliersActions, reducer: uiSuppliersReducer } =
  uiSuppliersState

export { uiSuppliersActions, uiSuppliersReducer }
