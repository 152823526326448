import { handleActions } from 'redux-actions'
import { get } from 'lodash'
import model from './model'
import { IUISitesModel } from './interfaces'
import * as sitesActions from '../../sites/actions'
import { AnyAction } from 'redux'

export default handleActions(
  {
    [sitesActions.requestCreateSite]: (state: IUISitesModel) => ({
      ...state,
      isCreating: true,
    }),
    [sitesActions.requestCreateSiteSuccess]: (state: IUISitesModel) => ({
      ...state,
      isCreating: false,
    }),
    [sitesActions.requestUpdateSite]: (state: IUISitesModel) => ({
      ...state,
      isSaving: true,
    }),
    [sitesActions.requestUpdateSiteSuccess]: (state: IUISitesModel) => ({
      ...state,
      isSaving: false,
    }),
    http_error: (state: IUISitesModel, action: AnyAction) => {
      const httpAction = get(action, 'meta.action', false)

      if (httpAction === sitesActions.requestCreateSite.toString()) {
        return {
          ...state,
          isCreating: false,
        }
      }

      if (httpAction === sitesActions.requestUpdateSite.toString()) {
        return {
          ...state,
          isSaving: false,
        }
      }

      return state
    },
  },
  model,
)
