import * as React from 'react'
import { Box, Text } from 'grommet'

interface IPanelHeaderProps {
  title: string
  active: boolean
}

export default (props: IPanelHeaderProps): JSX.Element => (
  <Box direction="row" align="center" pad="medium" gap="small">
    <strong>
      <Text>{props.title}</Text>
    </strong>
    <Text color="brand">{props.active ? '-' : '+'}</Text>
  </Box>
)
