import { API_SITE_URL } from '../../constants'

export const NAMESPACE = 'statements'

const baseUrl = `${API_SITE_URL}/${NAMESPACE}`

export const ENDPOINTS = {
  BASE: baseUrl,
  ENTITY: `${baseUrl}/:id`,
}
