import React from 'react'
import icons from './dashboard-icons.svg'

export type TDashboardIcon =
  | 'release'
  | 'statement'
  | 'documentation'
  | 'member-hub'
  | 'owing'
  | 'invoice'
  | 'calendar-cash'
  | 'transaction'

type Props = {
  icon: TDashboardIcon
}

const DashboardIcon = ({ icon }: Props) => {
  return (
    <svg width={24} height={24}>
      <use href={`${icons}#${icon}`} />
    </svg>
  )
}

export default DashboardIcon
