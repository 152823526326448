import {
  JsonFormsCellRendererRegistryEntry,
  JsonFormsRendererRegistryEntry,
  JsonSchema,
  OwnPropsOfRenderer,
  UISchemaElement,
} from '@jsonforms/core'
import { JsonFormsDispatch } from '@jsonforms/react'
import { isEmpty } from 'lodash'
import React from 'react'

export const renderLayoutElements = (
  elements: UISchemaElement[],
  schema: JsonSchema,
  path: string,
  enabled: boolean,
  renderers?: JsonFormsRendererRegistryEntry[],
  cells?: JsonFormsCellRendererRegistryEntry[],
) => {
  return elements.map((child, index) => {
    return (
      <JsonFormsDispatch
        key={`${path}-${index}`}
        uischema={child}
        schema={schema}
        path={path}
        enabled={enabled}
        renderers={renderers}
        cells={cells}
      />
    )
  })
}

export interface LayoutRendererProps extends OwnPropsOfRenderer {
  elements: UISchemaElement[]
}

const LayoutRendererComponent = ({
  visible,
  elements,
  schema,
  path,
  enabled,
  renderers,
  cells,
}: LayoutRendererProps) => {
  if (isEmpty(elements)) {
    return null
  } else {
    return (
      <div style={{ display: visible ? 'block' : 'none' }}>
        {renderLayoutElements(
          elements,
          schema as JsonSchema,
          path || '',
          !!enabled,
          renderers,
          cells,
        )}
      </div>
    )
  }
}
export const LayoutRenderer = React.memo(LayoutRendererComponent)
