import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  EnumCellProps,
  isEnumControl,
  RankedTester,
  rankWith,
} from '@jsonforms/core'
import {
  TranslateProps,
  useJsonForms,
  withJsonFormsCellProps,
  withJsonFormsEnumCellProps,
  withTranslateProps,
} from '@jsonforms/react'
import {
  i18nDefaults,
  VanillaRendererProps,
  withVanillaCellProps,
  withVanillaEnumCellProps,
} from '@jsonforms/vanilla-renderers'
import { Select } from '../../../../views/components'
import { titleCase } from '../../../../views/utils/string'
import styled from 'styled-components'
import classNames from 'classnames'
import { generateDefaultIfChangeHandlers, getEnumIfs } from '../../utils'
import { useAtom } from 'jotai'
import { enumsAtom } from '../../atoms'
import { useEffectOnce } from 'react-use'

type Option = {
  value: string
  label: string
}

export const EnumCell = (
  props: EnumCellProps & VanillaRendererProps & TranslateProps,
) => {
  const {
    data,
    className,
    id,
    enabled,
    schema,
    uischema,
    rootSchema,
    path,
    handleChange,
    // t,
  } = props

  // const noneOptionLabel = useMemo(
  //   () => t('enum.none', i18nDefaults['enum.none'], { schema, uischema, path }),
  //   [t, schema, uischema, path],
  // )

  // Set initial enums based on enum ifs
  useEffectOnce(() => {
    Object.keys(enumIfs).forEach(key => {
      enumIfs[key]?.(
        rootSchema?.properties?.[key]?.default,
        (key: string, _enums: any) => {
          setEnums({
            ...enums,
            [key]: _enums,
          })
        },
      )
    })
  })

  const [enums, setEnums] = useAtom(enumsAtom)
  const [searchTerm, setSearchTerm] = useState('')

  const getOptions = useCallback(() => {
    if (enums[path]) {
      return enums[path]
        .map((option: string) =>
          transformOptionForSelect(
            option,
            // TODO: remove this hack. The schema should control formatting.
            path !== 'currencies' && !path.includes('currency'),
          ),
        )
        .filter((option: Option) =>
          option.label.toLowerCase().includes(searchTerm.toLowerCase()),
        )
    }

    return (
      schema?.enum?.map((option: string) =>
        transformOptionForSelect(
          option,
          // TODO: remove this hack. The schema should control formatting.
          path !== 'currencies' && !path.includes('currency'),
        ),
      ) || []
    ).filter((option: Option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase()),
    )
  }, [enums, searchTerm])

  const isFormFieldDisabled = () => {
    if (!enabled) {
      return true
    }
    const isReadOnly = (schema as any)?.readonly === true
    if (isReadOnly) {
      return true
    }
    return false
  }

  const enumIfs = getEnumIfs(rootSchema)
  const defaultIfChangeHandlers = generateDefaultIfChangeHandlers(rootSchema)

  const _handleChange = (option: { value: any }) => {
    handleChange(path, option.value)

    enumIfs?.[path]?.(option.value, (key: string, _enums: any) => {
      setEnums({
        ...enums,
        [key]: _enums,
      })
    })

    if (defaultIfChangeHandlers?.[path]) {
      defaultIfChangeHandlers[path](option.value, handleChange)
    }
  }

  return (
    <Select
      name={id}
      id={id}
      className={classNames(className, 'input-control')}
      disabled={isFormFieldDisabled()}
      value={data || ''}
      onChange={_handleChange}
      options={getOptions()}
      labelKey="label"
      valueKey={{ key: 'value', reduce: true }}
      onSearch={setSearchTerm}
    />
  )
}

export const transformOptionForSelect = (
  option: string,
  formatTitleCase: boolean,
) => {
  return {
    value: option,
    label: formatTitleCase
      ? option.includes('-')
        ? titleCase(option.split('-').join(' '))
        : titleCase(option)
      : option,
  }
}

/**
 * Default tester for enum controls.
 * @type {RankedTester}
 */
export const enumCellTester: RankedTester = rankWith(3, isEnumControl)

const StyledEnumCell = styled(EnumCell)``

export default withJsonFormsEnumCellProps(
  withTranslateProps(withVanillaEnumCellProps(StyledEnumCell)),
)
