import * as React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { SingleTemplate } from '../..'
import UserFormContainer from './UserFormContainer'

interface ISingleUsersPageProps {
  className: string
  user: any /** @todo update type */
  id: string
  getUser(): void
  isAdmin: boolean
  getInitialChannels(): void
}

class SingleUsersPage extends React.Component<ISingleUsersPageProps, {}> {
  public componentDidMount() {
    const { id, getUser, getInitialChannels } = this.props
    // fetch initial channels so channel picker display logic works as expected
    getInitialChannels()
    if (id !== 'new') {
      getUser()
    }
  }

  public render(): JSX.Element | null {
    const { user, id, className, isAdmin } = this.props
    const profileHeader = id === 'new' ? 'Create Profile' : 'User Profile'
    return isAdmin ? (
      <SingleTemplate currentSiteName={profileHeader}>
        <Helmet>
          <title>{`Trust My Travel | Users | ${user?.name}`}</title>
        </Helmet>
        <UserFormContainer className={className} userId={id} user={user} />
      </SingleTemplate>
    ) : null
  }
}

const StyledSingleUsersPage = styled(SingleUsersPage)`
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  overflow: scroll;
  min-height: 100vh;

  .page-title {
    button {
      margin-left: 10px;
    }
  }
`

export default StyledSingleUsersPage
