import { createSelector } from 'reselect'
import { get, isEmpty, memoize } from 'lodash'
import { ITransaction } from '../../transactions/interfaces'
import { transactionsSelectors } from '../../transactions'
import { bookingSelectors } from '../../bookings'
import { IPayment } from '../../payments/interfaces'
import { paymentsSelectors } from '../../payments'
import { currencySelectors } from '../../currency'
import { formatDate } from '../../../../utils/date'
import { renderAuthorName } from '../../../utils/authorname'
import { authSelectors } from '../../auth'

const getState = (state: any) => state

// const getUIState = (state: any): any => state[NAMESPACE];

const getUITransactionsState = (state: any) => state.ui.transactions

export const getCurrentPage = createSelector(
  getUITransactionsState,
  uiTransactionsState => get(uiTransactionsState, 'currentPage', 1),
)

export const getCurrentFilter = createSelector(
  getUITransactionsState,
  uiTransactionsState => get(uiTransactionsState, 'currentFilter', ''),
)

export const getCurrentChannel = createSelector(
  getUITransactionsState,
  uiTransactionsState => get(uiTransactionsState, 'currentChannel', null),
)

export const isApplyingRefund = createSelector(
  getUITransactionsState,
  uiTransactionsState => get(uiTransactionsState, 'isApplyingRefund', ''),
)

export const isUpdatingTransaction = createSelector(
  getUITransactionsState,
  uiTransactionsState => get(uiTransactionsState, 'isUpdatingTransaction', ''),
)

export const isApplyingChargeback = createSelector(
  getUITransactionsState,
  uiTransactionsState => get(uiTransactionsState, 'isApplyingChargeback', ''),
)

export const isApplyingChargebackOverride = createSelector(
  getUITransactionsState,
  uiTransactionsState =>
    get(uiTransactionsState, 'isApplyingChargebackOverride', ''),
)

export const isApplyingReversal = createSelector(
  getUITransactionsState,
  uiTransactionsState => get(uiTransactionsState, 'isApplyingReversal', ''),
)

export const isApplyingCapture = createSelector(
  getUITransactionsState,
  uiTransactionsState => get(uiTransactionsState, 'isApplyingCapture', ''),
)

export const isApplyingVoid = createSelector(
  getUITransactionsState,
  uiTransactionsState => get(uiTransactionsState, 'isApplyingVoid', ''),
)

export const isCreateTransactionSubmitting = createSelector(
  getUITransactionsState,
  uiTransactionsState =>
    get(uiTransactionsState, 'isCreateTransactionSubmitting', ''),
)

export const isApplyingResend = createSelector(
  getUITransactionsState,
  uiTransactionsState => get(uiTransactionsState, 'isApplyingResend', ''),
)

export const isAddingAllocation = createSelector(
  getUITransactionsState,
  uiTransactionsState => get(uiTransactionsState, 'isAddingAllocation', ''),
)

export const getTransactionsTableData = createSelector(getState, (state: any) =>
  memoize((page: number) => {
    const transactions =
      transactionsSelectors.getTransactionsByPage(state)(page)
    return transactions.map((transaction: ITransaction) => {
      const {
        id,
        trust_id,
        created,
        payee_surname,
        payee_email,
        bookings,
        transaction_types,
        card_types,
        total,
        currencies,
        last_four_digits,
        status,
      } = transaction
      return {
        id,
        trust_id,
        created,
        payee_surname,
        payee_email,
        reference: get(bookings, '[0].reference', ''),
        transaction_types,
        card_types,
        total: currencySelectors.formatValueWithCurrency(state)(
          total,
          currencies,
        ),
        last_four_digits,
        status,
      }
    })
  }),
)

export const getTransactionTableData = createSelector(
  getState,
  (state: any) => (transactionId: number) => {
    const isAdmin = authSelectors.isAdmin(state)
    const transaction: ITransaction =
      transactionsSelectors.getTransactionById(state)(transactionId)
    if (!transaction) {
      return [[]]
    }
    return [
      [
        {
          name: 'Trust ID',
          value: transaction.trust_id,
          isVisible: true,
        },
        {
          name: 'Channel',
          value: transaction.channels,
          isVisible: true,
        },
        {
          name: 'Forex Rate',
          value: transaction.forex_rate,
          isVisible: transaction.transaction_types !== 'chargeback',
        },
        {
          name: 'ARN',
          value: transaction.arn_number,
          modal: isAdmin && 'chargeback',
          isVisible: transaction.transaction_types === 'chargeback',
        },
        {
          name: 'Created',
          value: formatDate(transaction.created, 'EEE dd LLL yyyy @ HH:mm:ss'),
          isVisible: true,
        },
        {
          name: 'Created By',
          value: renderAuthorName(transaction.authorname),
          isVisible: true,
        },
      ].filter(val => val.isVisible),
      [
        {
          name: 'Type',
          value: transaction.transaction_types,
          isVisible: transaction.transaction_types !== 'chargeback',
        },
        {
          name: 'Amount',
          value: currencySelectors.formatValueWithCurrency(state)(
            transaction.total,
            transaction.currencies,
          ),
          isVisible: true,
        },
        {
          name: 'Reason',
          value: transaction.reason_code,
          modal: isAdmin && 'chargeback',
          isVisible: transaction.transaction_types === 'chargeback',
        },
        {
          name: 'Date of Chargeback',
          value:
            transaction.chargeback_date &&
            formatDate(transaction.chargeback_date, 'yyyy-LL-dd'),
          modal: isAdmin && 'chargeback',
          isVisible: transaction.transaction_types === 'chargeback',
        },
        {
          name: 'Challenge By',
          value:
            transaction.challenge_date &&
            formatDate(transaction.challenge_date, 'yyyy-LL-dd'),
          modal: isAdmin && 'chargeback',
          isVisible: transaction.transaction_types === 'chargeback',
        },
        {
          name: 'Remaining',
          value: currencySelectors.formatValueWithCurrency(state)(
            transaction.total_remaining,
            transaction.currencies,
          ),
          isVisible: transaction.transaction_types !== 'chargeback',
        },
        {
          name: 'Modified',
          value: formatDate(transaction.modified, 'EEE dd LLL yyyy @ HH:mm:ss'),
          isVisible: true,
        },
        {
          name: 'Released Date',
          value:
            transaction.statement_date &&
            formatDate(transaction.statement_date, 'EEE dd LLL yyyy'),
          isVisible: transaction.transaction_types !== 'chargeback',
        },
      ].filter(val => val.isVisible),
    ]
  },
)

export const getTransactionPayeeTableData = createSelector(
  getState,
  (state: any) => (transactionId: number) => {
    const transaction: ITransaction =
      transactionsSelectors.getTransactionById(state)(transactionId)
    if (!transaction) {
      return {
        details: [],
        method: [],
      }
    }
    return {
      details: [
        {
          name: 'Name',
          value: transaction.payee_name + ' ' + transaction.payee_surname,
        },
        {
          name: 'Email',
          value: transaction.payee_email,
        },
        {
          name: 'IP',
          value: transaction.ip_address,
        },
      ],
      method: [
        {
          name: 'Card Type',
          value: transaction.card_types,
        },
        {
          name: 'BIN',
          value: transaction.bin_number,
        },
        {
          name: 'Last 4',
          value: transaction.last_four_digits,
        },
        {
          name: 'Country',
          value: !isEmpty(transaction.issuer_countries)
            ? transaction.issuer_countries
            : transaction.countries,
        },
      ],
    }
  },
)

export const getTransactionPayeeTableDataDetails = createSelector(
  getState,
  (state: any) => (transactionId: number) => {
    const transaction: ITransaction =
      transactionsSelectors.getTransactionById(state)(transactionId)
    if (!transaction) {
      return []
    }
    return [
      {
        name: 'Name',
        value: transaction.payee_name + ' ' + transaction.payee_surname,
      },
      {
        name: 'IP',
        value: transaction.ip_address,
      },
    ]
  },
)

export const getTransactionPSPTableData = createSelector(
  getState,
  (state: any) => (transactionId: number) => {
    const transaction: ITransaction =
      transactionsSelectors.getTransactionById(state)(transactionId)
    if (!transaction) {
      return []
    }
    return [
      {
        name: 'Provider',
        value: !isEmpty(transaction.acquirer)
          ? transaction.psp + ': ' + transaction.acquirer
          : transaction.psp,
      },
      {
        name: 'Response',
        value: transaction.content,
      },
      {
        name: 'Auth Code',
        value: transaction.auth_code,
      },
      {
        name: '3D Secure',
        value: isEmpty(transaction['3ds_response']) ? 'No' : 'yes',
      },
      {
        name: 'Token',
        value: transaction.token,
      },
    ]
  },
)

export const getTransactionPSPTableDataMemberAdmin = createSelector(
  getState,
  (state: any) => (transactionId: number) => {
    const transaction: ITransaction =
      transactionsSelectors.getTransactionById(state)(transactionId)
    if (!transaction) {
      return []
    }
    return [
      {
        name: 'Provider',
        value: transaction.psp,
      },
      {
        name: 'Response',
        value: transaction.content,
      },
      {
        name: 'Auth Code',
        value: transaction.auth_code,
      },
      {
        name: '3D Secure',
        value: isEmpty(transaction['3ds_response']) ? 'No' : 'yes',
      },
    ]
  },
)

export const getTransactionBookingsTableData = createSelector(
  getState,
  (state: any) => (transactionId: number) => {
    const transaction: ITransaction =
      transactionsSelectors.getTransactionById(state)(transactionId)
    if (!transaction) {
      return []
    }
    const data = transaction.bookings
      .map(b => {
        const booking = bookingSelectors.getBookingById(state)(b.id)
        if (booking) {
          const { id, title, reference, total, currencies } = booking
          return {
            id,
            title,
            reference,
            total: currencySelectors.formatValueWithCurrency(state)(
              total,
              currencies,
            ),
          }
        } else {
          return undefined
        }
      })
      .filter(d => d !== undefined)
    return data
  },
)

export const getTransactionAdjustmentsTableData = createSelector(
  getState,
  (state: any) => (transactionId: number) => {
    const transaction: ITransaction =
      transactionsSelectors.getTransactionById(state)(transactionId)
    if (!transaction) {
      return []
    }
    const data = transaction.adjustments
      .map(adjustmentId => {
        const adjustedTransaction: ITransaction =
          transactionsSelectors.getTransactionById(state)(adjustmentId)
        if (adjustedTransaction) {
          const {
            id,
            trust_id,
            created,
            payee_name,
            payee_surname,
            transaction_types,
            total,
            currencies,
            status,
          } = adjustedTransaction
          return {
            id,
            trust_id,
            created,
            payee_name,
            payee_surname,
            transaction_types,
            total: currencySelectors.formatValueWithCurrency(state)(
              total,
              currencies,
            ),
            status,
          }
        } else {
          return undefined
        }
      })
      .filter(d => d !== undefined)
    return data
  },
)

export const getTransactionPaymentsTableData = createSelector(
  getState,
  (state: any) => (transactionId: number) => {
    const transaction: ITransaction =
      transactionsSelectors.getTransactionById(state)(transactionId)
    if (!transaction) {
      return []
    }
    const data = transaction.payment_ids
      .map(paymentId => paymentsSelectors.getPaymentById(state)(paymentId))
      .filter(
        (payment: IPayment) =>
          get(transaction, 'channels') === get(payment, 'channels'),
      )
      .map((payment: IPayment) => {
        if (payment) {
          const {
            id,
            title,
            total,
            payment_types,
            payment_classifications,
            currencies,
            statement_date,
            reversed,
          } = payment
          const formattedTotal = currencySelectors.formatValueWithCurrency(
            state,
          )(total, currencies)
          return {
            id,
            title,
            payment_types,
            payment_classifications,
            statement_date,
            reversed,
            total:
              payment_types === 'debit' ? `-${formattedTotal}` : formattedTotal,
          }
        } else {
          return undefined
        }
      })
      .filter(d => d !== undefined)
    return data
  },
)

export const getCurrenciesForSearchFilter = createSelector(
  transactionsSelectors.getCurrenciesFromSchema,
  (currencies: any) =>
    currencies.map((c: string) => ({
      value: c,
      label: c,
    })),
)

export const getTransactionTypesForSearchFilter = createSelector(
  transactionsSelectors.getTransactionTypesFromSchema,
  (transactionTypes: any) =>
    transactionTypes.map((c: string) => ({
      value: c,
      label: c,
    })),
)

export const getCardTypesForSearchFilter = createSelector(
  transactionsSelectors.getCardTypesFromSchema,
  (cardTypes: any) =>
    cardTypes.map((c: string) => ({
      value: c,
      label: c,
    })),
)

export const getStatusForSearchFilter = createSelector(
  transactionsSelectors.getStatusFromSchema,
  (status: any) =>
    status.map((c: string) => ({
      value: c,
      label: c,
    })),
)

export const getServerErrors = createSelector(
  getUITransactionsState,
  uiChannels => get(uiChannels, 'serverErrors', null),
)
