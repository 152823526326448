import * as React from 'react'
import { Box } from 'grommet'
import { AppBar } from '../../containers'
import { Text } from '..'
import styled from 'styled-components'

interface ISingleTemplateProps {
  currentSiteName?: string
  className?: string
  children: any
}

class SingleTemplate extends React.PureComponent<ISingleTemplateProps, {}> {
  public getComponent = (
    Component: JSX.Element | undefined,
    heading?: string,
  ) =>
    Component && (
      <>
        {heading && (
          <Text tag="h3" color="#fff">
            {heading}
          </Text>
        )}
        <Box
          background="white"
          fill="horizontal"
          pad="medium"
          round="small"
          className="box"
        >
          {Component}
        </Box>
      </>
    )

  public render(): JSX.Element | null {
    const { currentSiteName, children, className } = this.props
    return (
      <Box
        fill={true}
        pad="medium"
        overflow={{ vertical: 'scroll' }}
        className={className}
      >
        <AppBar>
          {currentSiteName && (
            <Text tag="h1" color="white">
              {currentSiteName}
            </Text>
          )}
        </AppBar>
        <Box>{children}</Box>
      </Box>
    )
  }
}

const StyledSingleTemplate = styled(SingleTemplate)`
  h1 {
    margin: 0;
  }
  .box {
    margin-bottom: 20px;
    min-height: 80vh;
  }

  .section-header {
    margin-bottom: 20px;
    button {
      margin-left: 10px;
    }
  }
`

export default StyledSingleTemplate
