import * as React from 'react'
import { DataTable } from '../..'

interface IChargesTableProps {
  isAdmin: boolean
  isEditing: boolean
  columns: Array<{
    accessor: string
    Header: string
    Cell?(row: any): JSX.Element
  }>
  data: any[]
}

class ChargesTable extends React.Component<IChargesTableProps, {}> {
  public shouldComponentUpdate(nextProps: IChargesTableProps) {
    if (nextProps.isEditing !== this.props.isEditing) {
      return true
    }
    return false
  }

  public render(): JSX.Element | null {
    const { isAdmin, columns, data } = this.props
    return !isAdmin ? null : (
      <>
        <DataTable
          manual={true}
          columns={columns}
          data={data}
          loading={false}
          pageSize={data.length}
        />
      </>
    )
  }
}

export default ChargesTable
