import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IAllocation,
  IAllocationsState,
  IRequestAllocationsParams,
  IUpdateAllocationPayload,
} from './interfaces'
import { NAMESPACE } from './constants'
import { uniq } from 'lodash'
import { parseLinkHeader } from '../../utils/http'

const initialState: IAllocationsState = {
  schema: {},
  byId: {},
  allIds: [],
  byChannelId: {},
  pages: {
    next: undefined,
    prev: undefined,
  },
  byPage: {},
  loading: false,
}

const allocationsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    requestAllocationsSchema: () => {},
    requestAllocationsSchemaSuccess: (
      state,
      action: PayloadAction<IAllocation[]>,
    ) => {
      state.schema = action.payload
    },
    requestAllocations: (
      state,
      action: PayloadAction<IRequestAllocationsParams>,
    ) => {
      state.loading = true
    },
    requestAllocationsSuccess: {
      prepare: (payload, meta) => ({ payload, meta }),
      reducer: (state, action: PayloadAction<IAllocation[], string, any>) => {
        state.loading = false
        state.pages = parseLinkHeader(action.meta.responseHeaders?.link || '')
        state.allIds = action.payload.map(allocation => allocation.id)
        state.byId = action.payload.reduce(
          (acc, a) => ({ ...acc, [a.id]: a }),
          {},
        )
      },
    },
    requestAllocation: (state, action: PayloadAction<{ id: number }>) => {
      state.loading = true
    },
    requestAllocationSuccess: (
      state,
      { payload }: PayloadAction<IAllocation>,
    ) => {
      state.loading = false
      state.allIds = uniq([...state.allIds, payload.id])
      state.byId[payload.id] = payload
    },
    updateAllocation: (
      state,
      { payload }: PayloadAction<IUpdateAllocationPayload>,
    ) => {
      state.loading = true
    },
    updateAllocationSuccess: (
      state,
      { payload }: PayloadAction<IAllocation>,
    ) => {
      state.loading = false
      state.byId[payload.id] = payload
    },
    reverseAllocation: (state, { payload }: PayloadAction<{ id: number }>) => {
      state.loading = true
    },
    reverseAllocationSuccess: (
      state,
      { payload }: PayloadAction<IAllocation>,
    ) => {
      state.loading = false
      state.byId[payload.id] = payload
    },
  },
  extraReducers: {
    http_error: (
      state: any,
      action: PayloadAction<any, string, { action: string }>,
    ) => {
      if (
        [
          'allocations/reverseAllocation',
          'allocations/updateAllocation',
        ].includes(action.meta.action)
      ) {
        state.loading = false
      }
    },
  },
})

const { reducer, actions } = allocationsSlice

export { reducer, actions, initialState }
