import React from 'react'
import { DataTable, Anchor } from '../..'
import { redirect } from '../../../../utils/router'
import SuppliersPageService from './SuppliersPageService'
import countries from '../../../../constants/countries'
import { useSelector } from 'react-redux'
import { supplierSelectors } from '../../../../state/modules/suppliers'
import styled from 'styled-components'
import { colors } from '../../../styles/variables'
import usePagination from '../../../../hooks/usePagination'

interface IContentProps {
  supplierCategories: any
}

const getCountyByValue = (value: string) =>
  countries.find((c: any) => c.value === value)

const Content = (props: IContentProps): JSX.Element => {
  const { setCurrentPage, getCurrentPage } = usePagination()
  const currentPage: number = parseInt(getCurrentPage())
  const pages: TPages = useSelector(supplierSelectors.getPages)
  const supplierData = useSelector(supplierSelectors.getSuppliersOrderedbyId)
  const isSuppliersLoading = useSelector(supplierSelectors.isLoading)

  const handleNext = () => setCurrentPage(currentPage + 1)

  const handlePrevious = () => setCurrentPage(currentPage - 1)

  const getCategoryByValue = (value: string) =>
    props.supplierCategories.find((c: any) => c.value === value)

  const columns = [
    {
      Header: 'Code',
      accessor: 'id',
      width: Math.round(window.innerWidth * 0.08),
    },
    {
      Header: 'Category',
      accessor: 'category',
      Cell: (row: any) => {
        return getCategoryByValue(row.original.category)?.label
      },
      width: Math.round(window.innerWidth * 0.08),
    },
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (row: any) => {
        const redirectTo = () => redirect(`/suppliers/${row.original.id}`)
        return <Anchor onClick={redirectTo}>{row.value}</Anchor>
      },
    },
    {
      Header: 'URL',
      accessor: 'url',
      Cell: (row: any) => {
        return (
          <a
            style={{
              color: colors.blue.medium,
              fontWeight: '600',
              textDecoration: 'none',
            }}
            href={row.original.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {row.value}
          </a>
        )
      },
    },
    {
      Header: 'Country',
      accessor: 'country',
      Cell: (row: any) => {
        return getCountyByValue(row.original.country)?.label
      },
    },
  ]

  return (
    <>
      <SuppliersPageService />
      <DataTable
        manual={true}
        fitContainer={true}
        data={supplierData}
        columns={columns}
        loading={isSuppliersLoading}
        pageSize={supplierData.length}
        pages={pages.next ? currentPage + 1 : currentPage}
        page={currentPage}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />
    </>
  )
}

const StyledContent = styled(Content)`
  .external-link {
    color: ${colors.blue.medium};
  }
`

export default StyledContent
