import { ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { Observable, of } from 'rxjs'
import { map, switchMap, mergeMap, mapTo, pluck } from 'rxjs/operators'
import { actions } from './state'
import { ENDPOINTS } from './constants'
import {
  get,
  catchRestError,
  optionsRequest,
  putRequest,
} from '../../utils/http'
import RestfulResult from '../../utils/RestfulResult'
import { IPayout, IRequestPayoutsParams } from './interfaces'
import { injectCurrentSitePath } from '../../utils/general'

export const requestPayoutsSchemaEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestPayoutsSchema),
    pluck('payload'),
    switchMap((payload: null) =>
      of(payload).pipe(
        optionsRequest(
          injectCurrentSitePath(ENDPOINTS.BASE, state$.value),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(mapTo(actions.requestPayoutsSchemaSuccess(p))),
          ),
        ),
        catchRestError(actions.requestPayoutsSchema.toString()),
      ),
    ),
  )

export const requestPayoutsEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestPayouts),
    pluck('payload'),
    switchMap((payload: IRequestPayoutsParams) =>
      of(payload).pipe(
        map(params => ({ params })),
        get(injectCurrentSitePath(ENDPOINTS.BASE, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestPayoutsSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestPayouts.toString()),
      ),
    ),
  )

export const requestPayoutEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestPayout),
    pluck('payload'),
    switchMap((payload: any) =>
      of(payload).pipe(
        map(urlReplacements => ({ urlReplacements })),
        get(injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestPayoutSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestPayout.toString()),
      ),
    ),
  )

export const updatePayoutEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.updatePayout),
    pluck('payload'),
    switchMap((payload: IPayout) =>
      of(payload).pipe(
        putRequest(injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.updatePayoutSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.updatePayout.toString()),
      ),
    ),
  )
