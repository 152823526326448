import { createSelector } from 'reselect'
import { get, uniqBy } from 'lodash'

const getState = (state: any) => state

const getUINotifications = createSelector(getState, state =>
  get(state, 'ui.notifications', []),
)

export const getNotifications = createSelector(
  getUINotifications,
  ({ notifications }) => {
    const uniqNotifications: any[] = uniqBy(notifications, 'message')
    return uniqNotifications
  },
)

export const getErrorNotificaitons = createSelector(
  getUINotifications,
  ({ notifications }) =>
    notifications.filter((notification: any) => notification.type === 'error'),
)
