import { handleActions } from 'redux-actions'
import { get, uniq } from 'lodash'
import { AnyAction } from 'redux'
import * as actions from './actions'
import model from './model'
import { IUsersModel, IUser } from './interfaces'
import { sitesSelectors } from '../sites'
import { mapUsersToIds } from './utils'
import { parseLinkHeader } from '../../utils/http'

/** @todo move to util functions */
const getCurrentSitePathState = (state: any, currentSitePath: string) =>
  get(state, `sites[${currentSitePath}]`, {})
const getAllIds = (state: any, currentSitePath: string) =>
  get(state, `sites[${currentSitePath}].allIds`, [])
const getById = (state: any, currentSitePath: string) =>
  get(state, `sites[${currentSitePath}].byId`, {})
/** @todo review stripping certain params from key, breaking dashboard so removed `per_page` for now. */
const getByPage = (state: any, currentSitePath: string) =>
  get(state, `sites[${currentSitePath}].byPage`, {})

export default function createUsersReducer(rootState: any) {
  const currentSitePath = sitesSelectors.getCurrentSitePath(rootState)

  return handleActions(
    {
      [actions.requestUsersSchemaSuccess]: (
        state: IUsersModel,
        action: AnyAction,
      ) => ({
        ...state,
        schema: action.payload,
      }),
      [actions.requestUsers]: (state: IUsersModel, action: AnyAction) => {
        return {
          ...state,
          sites: {
            ...state.sites,
            [currentSitePath]: {
              ...getCurrentSitePathState(state, currentSitePath),
              isLoading: true,
            },
          },
        }
      },
      [actions.requestUsersSuccess]: (
        state: IUsersModel,
        action: AnyAction,
      ) => {
        const { payload, meta } = action

        return {
          ...state,
          sites: {
            ...state.sites,
            [currentSitePath]: {
              ...getCurrentSitePathState(state, currentSitePath),
              isLoading: false,
              pages: parseLinkHeader(meta.responseHeaders?.link || ''),
              byId: {
                ...getById(state, currentSitePath),
                ...payload.reduce((users: IUser[], user: IUser) => {
                  users[user.id] = user
                  return users
                }, {}),
              },
              allIds: uniq([
                ...getAllIds(state, currentSitePath),
                ...mapUsersToIds(payload),
              ]),
              byPage: {
                ...getByPage(state, currentSitePath),
                [meta.params.page]: payload.map((user: IUser) => user.id),
              },
            },
          },
        }
      },
      [actions.requestUserSuccess]: (state: IUsersModel, action: AnyAction) => {
        const { payload } = action
        return {
          ...state,
          sites: {
            ...state.sites,
            [currentSitePath]: {
              ...getCurrentSitePathState(state, currentSitePath),
              byId: {
                ...getById(state, currentSitePath),
                [payload.id]: payload,
              },
              allIds: uniq([...getAllIds(state, currentSitePath), payload.id]),
            },
          },
        }
      },
      [actions.requestAdminUsers]: (state: IUsersModel, action: AnyAction) => {
        return {
          ...state,
          admin_users: {
            ...state.admin_users,
            isLoading: true,
          },
        }
      },
      [actions.requestAdminUsersSuccess]: (
        state: IUsersModel,
        action: AnyAction,
      ) => {
        const { payload, meta } = action
        return {
          ...state,
          admin_users: {
            isLoading: false,
            pages: parseLinkHeader(meta.responseHeaders?.link || ''),
            byId: {
              ...state.admin_users.byId,
              ...payload.reduce((users: IUser[], user: IUser) => {
                users[user.id] = user
                return users
              }, {}),
            },
            allIds: payload.map((user: IUser) => user.id),
            // allIds: uniq([
            //     ...state.admin_users.allIds,
            //     ...payload.map((user: IUser) => user.id)
            // ]),
            byPage: {
              ...state.admin_users.byPage,
              [meta.params.page]: payload.map((user: IUser) => user.id),
            },
          },
        }
      },
      [actions.updateUserSuccess]: (state: IUsersModel, action: AnyAction) => {
        const { payload } = action
        return {
          ...state,
          sites: {
            [currentSitePath]: {
              ...getCurrentSitePathState(state, currentSitePath),
              byId: {
                ...getById(state, currentSitePath),
                [payload.id]: payload,
              },
              allIds: [...getAllIds(state, currentSitePath), payload.id],
            },
          },
        }
      },
    },
    model,
  )
}
