import { ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { actions } from './state'
import { ENDPOINTS } from './constants'
import { map, mapTo, mergeMap, pluck, switchMap } from 'rxjs/operators'
import { injectCurrentSitePath } from '../../utils/general'
import RestfulResult from '../../utils/RestfulResult'
import { Observable, of } from 'rxjs'
import { IRequestPspsParams } from './interfaces'
import { catchRestError, get } from '../../utils/http'

export const requestPspsEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestPsps),
    pluck('payload'),
    switchMap((payload: IRequestPspsParams) =>
      of(payload).pipe(
        map(params => ({ params })),
        get(injectCurrentSitePath(ENDPOINTS.BASE, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestPspsSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestPsps.toString()),
      ),
    ),
  )
