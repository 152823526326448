import { TMT_URL } from '../../constants'

export const NAMESPACE = 'auth'

export const ENDPOINTS = {
  TOKEN_REQUEST: `${TMT_URL}/wp/wp-json/jwt-auth/v1/token`,
  REFRESH_TOKEN: `${TMT_URL}/wp/wp-json/jwt-auth/v1/token/refresh`,
  FORGOT_PASSWORD_REQUEST: `${TMT_URL}/wp/wp-json/jwt-auth/v1/password/forgot`,
  RESET_PASSWORD_REQUEST: `${TMT_URL}/wp/wp-json/jwt-auth/v1/password/reset`,
}
