import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styles/variables'
import Button from '../../atoms/Button/Button'

export interface INotificationProps {
  id: string
  className?: string
  message: string
  type: 'error' | 'success'
  onClose(id: string): void
}

const Notification = (props: INotificationProps) => {
  const handleClose = () => props.onClose(props.id)

  return (
    <div className={props.className}>
      <div
        className="notification-text"
        dangerouslySetInnerHTML={{ __html: props.message }}
      />
      <Button icon={<i>x</i>} onClick={handleClose} styleType="plain" />
    </div>
  )
}

const StyledNotification = styled(Notification)`
  ${(props: INotificationProps) => `
	background: ${props.type === 'error' ? colors.red : colors.green};
	padding: 10px 3rem;
	color: white;
	display: flex;
	flex-direction: row;
	box-sizing: border-box;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	.notification-text {
	    margin: 0 auto;
	    padding: 0 25px;
	    font-weight: 600;
	    text-align: center;
        }
	button {
	    position: absolute;
	    top: 10px;
	    right: 24px;
	    background: #fff;
	    display: block;
	    width: 15px;
	    height: 15px;
	    border-radius: 100%;
	    padding: 12px;
	    i {
		font-size: 15px;
		font-style: normal;
		font-weight: bold;
		color: ${props.type === 'error' ? colors.red : colors.green};
		margin-left: -5px;
	    }
	}
    `}
`

export default StyledNotification
