import { createActions } from 'redux-actions'
import { NAMESPACE } from './constants'

export const {
  ui: {
    globalsearch: { clearServerErrors },
  },
} = createActions({
  [`${NAMESPACE}/clear_server_errors`]: () => null,
})
