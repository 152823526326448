import { handleActions, combineActions } from 'redux-actions'
import { get, omit, uniq } from 'lodash'
import { AnyAction } from 'redux'
import * as actions from './actions'
import model from './model'
import { ITransactionsModel, ITransaction } from './interfaces'
import { sitesSelectors } from '../sites'
import { mapTransactionsToIds } from './utils'
import { parseLinkHeader } from '../../utils/http'

/** @todo move to util functions */
const getCurrentSitePathState = (state: any, currentSitePath: string) =>
  get(state, `sites[${currentSitePath}]`, {})
const getAllIds = (state: any, currentSitePath: string) =>
  get(state, `sites[${currentSitePath}].allIds`, [])
const getById = (state: any, currentSitePath: string) =>
  get(state, `sites[${currentSitePath}].byId`, {})
/** @todo review stripping certain params from key, breaking dashboard so removed `per_page` for now. */
const getFiltersKey = (meta: any) => JSON.stringify(omit(meta.params, ['page']))
const getByFilters = (state: any, currentSitePath: string) =>
  get(state, `sites[${currentSitePath}].byFilters`, {})
const getByPage = (state: any, currentSitePath: string) =>
  get(state, `sites[${currentSitePath}].byPage`, {})

export default function createTransactionsReducer(rootState: any) {
  const currentSitePath = sitesSelectors.getCurrentSitePath(rootState)

  return handleActions(
    {
      // [sitesActions.siteSelected]: () => model, // Reset state when site is changed.
      [actions.requestTransactionsSchemaSuccess]: (
        state: ITransactionsModel,
        action: AnyAction,
      ) => ({
        ...state,
        schema: action.payload,
      }),
      [combineActions(
        actions.requestTransactions,
        actions.requestTransactionsWithPayments,
      )]: (state: ITransactionsModel, action: AnyAction) => {
        return {
          ...state,
          sites: {
            ...state.sites,
            [currentSitePath]: {
              ...getCurrentSitePathState(state, currentSitePath),
              isLoading: true,
            },
          },
        }
      },
      [combineActions(
        actions.requestTransactionsSuccess,
        actions.requestTransactionsWithPaymentsSuccess,
      )]: (state: ITransactionsModel, action: AnyAction) => {
        const { payload, meta } = action
        const byFilters = getByFilters(state, currentSitePath)
        const idsInFilter = get(byFilters, [getFiltersKey(meta)], [])

        return {
          ...state,
          sites: {
            ...state.sites,
            [currentSitePath]: {
              ...getCurrentSitePathState(state, currentSitePath),
              isLoading: false,
              pages: parseLinkHeader(meta.responseHeaders?.link || ''),
              byId: {
                ...getById(state, currentSitePath),
                ...payload.reduce(
                  (transactions: ITransaction[], transaction: ITransaction) => {
                    transactions[transaction.id] = transaction
                    return transactions
                  },
                  {},
                ),
              },
              allIds: uniq([
                ...getAllIds(state, currentSitePath),
                ...mapTransactionsToIds(payload),
              ]),
              byPage: {
                ...getByPage(state, currentSitePath),
                [meta.params.page]: payload.map(
                  (transaction: ITransaction) => transaction.id,
                ),
              },
              byFilters: {
                ...getByFilters(state, currentSitePath),
                [getFiltersKey(meta)]: uniq([
                  ...idsInFilter,
                  ...mapTransactionsToIds(payload),
                ]),
              },
            },
          },
        }
      },
      [combineActions(
        actions.requestTransactionSuccess,
        actions.requestCreateTransactionSuccess,
        actions.addAllocation,
        actions.addAllocationSuccess,
        actions.requestUpdateTransactionSuccess,
      )]: (state: ITransactionsModel, action: AnyAction) => {
        const { payload } = action
        return {
          ...state,
          sites: {
            ...state.sites,
            [currentSitePath]: {
              ...getCurrentSitePathState(state, currentSitePath),
              byId: {
                ...getById(state, currentSitePath),
                [payload.id]: payload,
              },
              allIds: uniq([...getAllIds(state, currentSitePath), payload.id]),
            },
          },
        }
      },
    },
    model,
  )
}
