import React, { useState } from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import {
  sitesConstants,
  sitesSelectors,
  sitesActions,
} from '../../../../state/modules/sites'
import { useEffectOnce } from 'react-use'
import { ISite } from '../../../../state/modules/sites/interfaces'
import useFetchHelper from '../../molecules/AsyncSelect/useFetchHelper'
import AsyncSelect from '../../molecules/AsyncSelect/AsyncSelect'
import useChannelFilter from '../../../../hooks/useChannelFilter'

interface IAdminSiteSelectorProps {
  className?: string
}

interface IOption {
  value: string | number
  label: string
}

const transformSiteToOption = (site: ISite): IOption => {
  return {
    value: site.id,
    label: site.name,
  }
}

const AdminSiteSelector = (props: IAdminSiteSelectorProps) => {
  const dispatch = useDispatch()
  const { request } = useFetchHelper(
    sitesConstants.ENDPOINTS.BASE,
    sitesActions.requestSitesSuccess,
  )
  const [initialOptions, setInitialOptions] = useState([])

  const [, setChannel] = useChannelFilter()

  const currentSite = useSelector(sitesSelectors.getCurrentSite)
  const getSiteById = useSelector(sitesSelectors.getSiteById)

  const getInitialOptions = async () => {
    return await request({ per_page: 10, page: 1 })
  }

  useEffectOnce(() => {
    const setOptions = async () => {
      const { options } = await getInitialOptions()
      if (options) {
        setInitialOptions(options.map(transformSiteToOption))
      }
    }
    setOptions()
  })

  const loadOptions = async (search: string, prevOptions: any) => {
    const params: any = { per_page: 10, page: prevOptions.length / 10 + 1 }
    if (search) {
      params.search = search
    }
    const res = await request(params)
    return {
      ...res,
      options: res.options.map(transformSiteToOption),
    }
  }

  const handleChange = (option: any) => {
    const selectedSite = getSiteById(option.value)
    if (selectedSite) {
      setChannel(undefined) // remove channel ID from URL when site is changed
      dispatch(sitesActions.siteSelected(selectedSite))
    }
  }

  return (
    <AsyncSelect
      className={classNames([props.className, 'site-selector'])}
      initialOptions={initialOptions}
      loadOptions={loadOptions}
      onChange={handleChange}
      value={currentSite && transformSiteToOption(currentSite as any)}
    />
  )
}

const StyledSiteSelector = styled(AdminSiteSelector)`
  width: 300px;
  margin-right: 10px;
`

export default StyledSiteSelector
