import React, { Component } from 'react'
import countries, { ICountry } from '../../../../constants/countries'
import Select from '../../../components/molecules/Select/Select'

interface ICountrySelectorProps {
  className?: string
  disabled?: boolean
  error?: string
  value?: string
  onSelect(country: ICountry): void
  onSearch?(term: string): void
  loadInitialData?(): void
  onChange?(option: any): void
  getOptionByName?(name: string): void
}

interface ICountrySelectorState {
  countries: ICountry[]
}

class CountrySelector extends Component<
  ICountrySelectorProps,
  ICountrySelectorState
> {
  public state = {
    countries: [],
  }

  public componentDidMount() {
    this.setState({ countries })
  }

  public handleSearch = (query: string) => {
    this.setState({
      countries: this.state.countries.filter((country: ICountry) =>
        country.label.toLowerCase().includes(query.toLowerCase()),
      ),
    })
  }

  public handleClose = () => {
    this.setState({ countries })
  }

  public render() {
    const { className, onSelect, value, disabled } = this.props
    return (
      <Select
        className={className}
        options={this.state.countries.map((country: ICountry) => country.label)}
        onSearch={this.handleSearch}
        onChange={onSelect}
        onClose={this.handleClose}
        getOptionByName={val =>
          countries.find(country => country.label === val)
        }
        value={value}
        disabled={disabled}
      />
    )
  }
}

export default CountrySelector
