import React, { useCallback } from 'react'
import {
  CellProps,
  isBooleanControl,
  RankedTester,
  rankWith,
} from '@jsonforms/core'
import { withJsonFormsCellProps } from '@jsonforms/react'
import {
  VanillaRendererProps,
  withVanillaBooleanCellProps,
} from '@jsonforms/vanilla-renderers'
import { RadioGroup } from '../../../../views/components'
import styled from 'styled-components'

const BooleanCell = (props: CellProps & VanillaRendererProps) => {
  const { data, className, id, enabled, schema, path, handleChange } = props

  const isFormFieldDisabled = () => {
    if (!enabled) {
      return true
    }
    const isReadOnly = (schema as any)?.readonly === true
    if (isReadOnly) {
      return true
    }
    return false
  }

  const _handleChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(path, ev.target.value === 'true' ? true : false)
    },
    [handleChange, path],
  )

  return (
    <RadioGroup
      name={id}
      value={data || false}
      onChange={_handleChange}
      className={className}
      disabled={isFormFieldDisabled()}
      options={[
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ]}
    />
  )
}

const StyledBooleanCell = styled(BooleanCell)`
  flex-direction: row;
  label {
    width: fit-content !important;
    min-width: fit-content !important;
    margin-right: 20px;
  }
`

/**
 * Default tester for boolean controls.
 * @type {RankedTester}
 */
export const booleanCellTester: RankedTester = rankWith(3, isBooleanControl)

export default withJsonFormsCellProps(
  withVanillaBooleanCellProps(StyledBooleanCell),
)
