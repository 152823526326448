import * as React from 'react'
import { Box } from 'grommet'
import { AppBar } from '../../containers'
import SiteSelector from '../stateful/SiteSelectors/SiteSelector'
import ChannelSelector from '../stateful/ChannelSelector/ChannelSelector'

interface IArchiveTemplateProps {
  verticalOverflow?: 'scroll' | 'hidden'
  displayChannelSelector?: boolean
  Header(): JSX.Element
  Content(): JSX.Element
  CustomChannelSelector?: React.ReactChild
}

const ArchiveTemplate = (props: IArchiveTemplateProps): JSX.Element => {
  const {
    Header,
    Content,
    verticalOverflow,
    displayChannelSelector,
    CustomChannelSelector,
  } = props
  const vOverflow = verticalOverflow || 'hidden'

  /** @todo use matchPath instead */
  const { pathname } = window.location
  const isClearable = !pathname.includes('ledger')

  return (
    <Box fill={true} pad="medium" overflow={{ vertical: vOverflow }}>
      <AppBar>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SiteSelector className="site-selector" />
          {CustomChannelSelector
            ? CustomChannelSelector
            : displayChannelSelector && (
                <ChannelSelector isClearable={isClearable} />
              )}
        </div>
      </AppBar>
      <Box pad={{ vertical: 'medium' }} flex="grow">
        <Box height={{ min: 'auto' }}>
          <Header />
        </Box>
        <Box fill="vertical">
          <Content />
        </Box>
      </Box>
    </Box>
  )
}

export default ArchiveTemplate
