import * as React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Box } from 'grommet'
import queryString from 'query-string'
import { NavAnchor } from '../..'
import useChannelFilter from '../../../../hooks/useChannelFilter'
import { authSelectors } from '../../../../state/modules/auth'
import { sitesSelectors } from '../../../../state/modules/sites'

interface ISideNavProps {
  currentPath: string
  currentSitePath: string
  currentChannel?: number
  className?: string
}

interface INavItem {
  path: string
  label: string
  appendChannel: boolean
  isVisible: boolean
}

interface INavItemProps {
  navItem: INavItem
  currentPath: string
}

const NavItem = (props: INavItemProps) => {
  const {
    currentPath,
    navItem: { path, label },
  } = props
  const currentPathSplit = currentPath.split('/')
  const classes = path.includes(currentPathSplit[2]) ? 'active' : ''
  return (
    <li className={classes}>
      <NavAnchor path={path} label={label} />
    </li>
  )
}

const SideNav = (props: ISideNavProps): JSX.Element => {
  const { className, currentPath, currentSitePath } = props
  const [currentChannel] = useChannelFilter()
  const isAdmin = useSelector(authSelectors.isAdmin)
  const canViewSettings = useSelector(state =>
    sitesSelectors.getCurrentSitePermission(state as never)('view_settings'),
  )
  const currentSite = useSelector(sitesSelectors.getCurrentSite)
  const isProtectionOnly = currentSite?.protection_only

  const navItems: INavItem[] = [
    {
      path: `/${currentSitePath}/dashboard`,
      label: 'Dashboard',
      appendChannel: true,
      isVisible: true,
    },
    {
      path: `/${currentSitePath}/channels`,
      label: 'Channels',
      appendChannel: false,
      isVisible: true,
    },
    {
      path: `/${currentSitePath}/bookings`,
      label: 'Bookings',
      appendChannel: true,
      isVisible: true,
    },
    {
      path: `/${currentSitePath}/transactions`,
      label: 'Transactions',
      appendChannel: true,
      isVisible: true,
    },
    {
      path: `/${currentSitePath}/allocations`,
      label: 'Allocations',
      appendChannel: true,
      isVisible: canViewSettings,
    },
    {
      path: `/${currentSitePath}/payments`,
      label: 'Credits & Debits',
      appendChannel: true,
      isVisible: true,
    },
    {
      path: `/${currentSitePath}/statements`,
      label: 'Statements',
      appendChannel: true,
      isVisible: !isProtectionOnly,
    },
    {
      path: `/${currentSitePath}/ledger`,
      label: 'Ledger',
      appendChannel: true,
      isVisible: true,
    },
  ]
    .filter(item => item.isVisible)
    .map((navItem: INavItem) => {
      if (
        currentChannel !== undefined &&
        navItem.appendChannel &&
        (isAdmin || (!isAdmin && navItem.label === 'Dashboard'))
      ) {
        navItem.path = `${navItem.path}?${queryString.stringify({
          channel: currentChannel,
        })}`
      }
      return navItem
    })

  return (
    <Box tag="ul" className={className}>
      {navItems.map((navItem, i) => (
        <NavItem key={i} navItem={navItem} currentPath={currentPath} />
      ))}
    </Box>
  )
}

const StyledSideNav = styled(SideNav)`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    font-size: 16px;
    &.active {
      background: #67be5a;
      a {
        color: white;
      }
    }
  }
  a {
    color: #696969;
    margin: 0;
    display: block;
    padding: 9px 18px;
    text-decoration: none;

    &:hover {
      color: #4a4a4a;
      text-decoration: none;
    }
  }
`

export default StyledSideNav
