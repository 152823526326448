import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useEffectOnce } from 'react-use'
import { get } from 'lodash'
import {
  channelInterfaces,
  channelActions,
} from '../../../../state/modules/channels'
import { uiChannelsConstants } from '../../../../state/modules/ui/channels'
import useFilter from '../../../../hooks/useFilter'
import usePagination from '../../../../hooks/usePagination'
import useChannelFilter from '../../../../hooks/useChannelFilter'

const ChannelsPageService = (): null => {
  const dispatch = useDispatch()

  const defaultPagination = {
    per_page: uiChannelsConstants.perPage,
    page: 1,
  }

  const { filter } = useFilter()

  const { pagination, setPagination } = usePagination()

  /** Fetch channel from query params in case page has reloaded and no channels in state yet */
  const [currentChannel] = useChannelFilter()
  useEffect(() => {
    if (currentChannel) {
      setPagination(defaultPagination)
      dispatch(channelActions.requestChannel({ id: currentChannel }))
    }
    // eslint-disable-next-line
  }, [currentChannel, dispatch, setPagination])

  useEffectOnce(() => {
    // Set default pagination params if they don't exist in URL
    if (!pagination) {
      setPagination(defaultPagination)
    }
  })

  useEffect(() => {
    const params: channelInterfaces.IRequestChannelsParams =
      filter && get(Object.values(filter), [0]) === 'all'
        ? {
            ...pagination,
          }
        : {
            ...filter,
            ...pagination,
          }

    dispatch(channelActions.requestChannels(params))
  }, [dispatch, filter, pagination])

  return null
}

export default ChannelsPageService
