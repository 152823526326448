import { ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { Observable, of } from 'rxjs'
import { map, switchMap, tap, mergeMap, mapTo } from 'rxjs/operators'
import { setTokenFromResponse, deleteTokens } from '../../../utils/auth'
import * as actions from './actions'
import { ENDPOINTS } from './constants'
import { post, catchRestError, refreshToken } from '../../utils/http'
import { liftPayload } from '../../utils/rx-operators'
import {
  ITokenRequest,
  IPasswordForgotRequest,
  IPasswordResetRequest,
} from './interfaces'
import RestfulResult from '../../utils/RestfulResult'

export const loginEpic = (action$: Observable<AnyAction>, state$: any): any =>
  action$.pipe(
    ofType(actions.requestToken),
    liftPayload(),
    switchMap((payload: ITokenRequest) =>
      of(payload).pipe(
        map(body => ({ body })),
        post(ENDPOINTS.TOKEN_REQUEST, false, {
          'x-origin': 'dashboard',
        }),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              tap(setTokenFromResponse()),
              mapTo(actions.requestTokenSuccess(p)),
            ),
          ),
        ),
        catchRestError(actions.requestToken.toString()),
      ),
    ),
  )

export const refreshTokenEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestTokenRefresh),
    switchMap((payload: any) =>
      of(payload).pipe(
        refreshToken(ENDPOINTS.REFRESH_TOKEN),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              tap(setTokenFromResponse()),
              mapTo(actions.requestTokenRefreshSuccess(p)),
            ),
          ),
        ),
        catchRestError(actions.requestTokenRefresh.toString()),
      ),
    ),
  )

export const logoutEpic = (action$: Observable<AnyAction>, state$: any): any =>
  action$.pipe(
    ofType(actions.requestLogout),
    tap(deleteTokens()),
    mapTo(actions.loggedOut()),
  )

export const forgotPasswordEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestPasswordForgot),
    liftPayload(),
    switchMap((payload: IPasswordForgotRequest) =>
      of(payload).pipe(
        map(body => ({ body })),
        post(ENDPOINTS.FORGOT_PASSWORD_REQUEST),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(mapTo(actions.requestPasswordForgotSuccess(p))),
          ),
        ),
        catchRestError(actions.requestPasswordForgot.toString()),
      ),
    ),
  )

export const resetPasswordEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestPasswordReset),
    liftPayload(),
    switchMap((payload: IPasswordResetRequest) =>
      of(payload).pipe(
        map(body => ({ body })),
        post(ENDPOINTS.RESET_PASSWORD_REQUEST),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(mapTo(actions.requestPasswordResetSuccess(p))),
          ),
        ),
        catchRestError(actions.requestPasswordReset.toString()),
      ),
    ),
  )
