import {
  ArrayControl,
  arrayControlTester,
  GroupLayout,
  groupTester,
  HorizontalLayout,
  horizontalLayoutTester,
  LabelRenderer,
  labelRendererTester,
  OneOfRadioGroupControl,
  oneOfRadioGroupControlTester,
  RadioGroupControl,
  radioGroupControlTester,
  vanillaCells,
  VerticalLayout,
  verticalLayoutTester,
} from '@jsonforms/vanilla-renderers'
import TextCell, { textCellTester } from './cells/TextCell'
import DateCell, { dateCellTester } from './cells/DateCell'
import InputControl, { inputControlTester } from './controls/InputControl'
import DateTimeCell, { dateTimeCellTester } from './cells/DateTimeCell'
import SuppliersArrayControl, {
  suppliersArrayControlTester,
} from './complex/SuppliersArrayControl'
import ChannelCell, { channelCellTester } from './cells/ChannelCell'
import IntegerCell, { integerCellTester } from './cells/IntegerCell'
import OneOfEnumCell, { oneOfEnumCellTester } from './cells/OneOfEnumCell'
import CurrencyCell, { currencyCellTester } from './cells/CurrencyCell'
import ArrayOneOfEnumCell, {
  arrayOneOfEnumCellTester,
} from './cells/ArrayOneOfEnumCell'
import CountriesControl, {
  countriesControlTester,
} from './complex/CountriesControl'
import CategorizationLayout, {
  categorizationTester,
} from './layouts/CategorizationLayout'
import EnumCell, { enumCellTester } from './cells/EnumCell'
import ChannelChargesTable, {
  channelChargesTableTester,
} from './complex/ChannelChargesTable'
import EmailArrayControl, {
  emailsArrayControlTester,
} from './complex/EmailArrayControl'
import ChannelSecretControl, {
  channelSecretControlTester,
} from './complex/ChannelSecretControl'
import BooleanCell, { booleanCellTester } from './cells/BooleanCell'
import ApiTokensControl, {
  apiTokensControlTester,
} from './complex/ApiTokensControl'
import NumberCell, { numberCellTester } from './cells/NumberCell'
import TextAreaCell, { textAreaCellTester } from './cells/TextAreaCell'

export const cells = [
  ...vanillaCells,
  {
    tester: booleanCellTester,
    cell: BooleanCell,
  },
  {
    tester: textCellTester,
    cell: TextCell,
  },
  {
    tester: textAreaCellTester,
    cell: TextAreaCell,
  },
  {
    tester: integerCellTester,
    cell: IntegerCell,
  },
  {
    tester: numberCellTester,
    cell: NumberCell,
  },
  {
    tester: enumCellTester,
    cell: EnumCell,
  },
  {
    tester: oneOfEnumCellTester,
    cell: OneOfEnumCell,
  },
  {
    tester: arrayOneOfEnumCellTester,
    cell: ArrayOneOfEnumCell,
  },
  {
    tester: dateCellTester,
    cell: DateCell,
  },
  {
    tester: dateTimeCellTester,
    cell: DateTimeCell,
  },
  {
    tester: channelCellTester,
    cell: ChannelCell,
  },
  {
    tester: currencyCellTester,
    cell: CurrencyCell,
  },
]

export const renderers = [
  {
    tester: inputControlTester,
    renderer: InputControl,
  },
  { tester: suppliersArrayControlTester, renderer: SuppliersArrayControl },
  { tester: emailsArrayControlTester, renderer: EmailArrayControl },
  { tester: countriesControlTester, renderer: CountriesControl },
  { tester: channelChargesTableTester, renderer: ChannelChargesTable },
  { tester: channelSecretControlTester, renderer: ChannelSecretControl },
  { tester: apiTokensControlTester, renderer: ApiTokensControl },

  // Vanilla renderers
  { tester: radioGroupControlTester, renderer: RadioGroupControl },
  { tester: oneOfRadioGroupControlTester, renderer: OneOfRadioGroupControl },
  { tester: arrayControlTester, renderer: ArrayControl },
  { tester: labelRendererTester, renderer: LabelRenderer },
  // { tester: categorizationTester, renderer: Categorization },
  // leave this out because we aren't using the mutli-row table array control at the moment.
  // we may overwrite this in the future to exclude `oneOf` controls
  // { tester: tableArrayControlTester, renderer: TableArrayControl },
  { tester: groupTester, renderer: GroupLayout },
  // Layouts
  { tester: verticalLayoutTester, renderer: VerticalLayout },
  { tester: horizontalLayoutTester, renderer: HorizontalLayout },
  {
    tester: categorizationTester,
    renderer: CategorizationLayout,
  },
]
