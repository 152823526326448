import * as React from 'react'
import { Grommet, Box, Image, Grid } from 'grommet'
import { grommet } from 'grommet/themes'
import logo from '../../../assets/images/logo-tmt-systems-unauthenticated.svg'
import bg from '../../../assets/images/bg.svg'
import { Header } from '..'
import NotificationsContainer from '../../containers/NotificationsContainer'

export interface IUnauthenticatedLayoutProps {
  children: any
}

export default class UnauthenticatedLayout extends React.PureComponent<
  IUnauthenticatedLayoutProps,
  any
> {
  public gridAreas = [
    { name: 'header', start: [1, 0], end: [1, 0] },
    { name: 'main', start: [1, 1], end: [1, 1] },
  ]

  public render() {
    return (
      <Grommet
        full={true}
        theme={grommet}
        style={{ background: `url(${bg}) no-repeat top right` }}
      >
        <NotificationsContainer />
        <Grid
          fill={true}
          rows={['auto', 'flex']}
          columns={['auto', 'flex']}
          areas={this.gridAreas}
        >
          <Header background="light">
            <Image src={logo} />
          </Header>
          <Box gridArea="main" justify="center" align="center">
            {this.props.children}
          </Box>
        </Grid>
      </Grommet>
    )
  }
}
