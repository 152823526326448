import * as React from 'react'
import { Grommet, TextInput as GrommetTextInput, Box } from 'grommet'
import styled from 'styled-components'
import timfinityTheme from '../../../styles/timfinityTheme'
import { colors } from '../../../styles/variables'
import Button from '../../atoms/Button/Button'

export interface ITextInputProps {
  name: string
  id?: string
  type?: string
  placeholder?: string
  disabled?: boolean
  error?: string
  className?: string
  value?: string
  label?: string | React.ReactNode
  plain?: boolean
  searchFilterTheme?: object
  viewOnly?: boolean
  copyToClipboard?: boolean
  isOnEditScreen?: boolean
  step?: string
  field?: any // formik field props
  form?: any // formik form props
  setRef?(ref: any): void
  onChange?(e: any): void
  onBlur?(e: any): void
  onFocus?(e: any): void
}

export interface ITextInputState {
  copyButtonText: string
  inputRef?: any
}

class TextInput extends React.Component<ITextInputProps, ITextInputState> {
  public state: ITextInputState = {
    copyButtonText: 'Copy',
  }

  public getLabel = () => {
    const { id, label } = this.props
    return label && <label htmlFor={id}>{label}</label>
  }

  /**
   * Grommet doesn't support class components with refs.
   * This is a workaround
   * @link https://github.com/grommet/grommet/issues/4092#issuecomment-713674912
   */
  public setInputRef = (ref: any) => {
    const { inputRef } = this.state
    if (!inputRef) {
      this.setState({ inputRef: ref }, () => {
        // this.state.inputRef.focus()
        if (this.props.setRef) {
          this.props.setRef(this.state.inputRef)
        }
      })
    }
  }

  public getError = () => {
    const { error } = this.props
    return error && <span className="error">{error}</span>
  }

  public copyToClipboardButton = () => {
    const { copyToClipboard, isOnEditScreen, value = '' } = this.props

    if (copyToClipboard && !isOnEditScreen) {
      // https://stackoverflow.com/questions/52923771/react-copy-component-state-value-to-clipboard-without-dummy-element
      // tried using refs, but could not get it to work within current implementation
      const copyToClipboardHandler = (e: any) => {
        e.preventDefault()
        const dummy = document.createElement('input')
        document.body.appendChild(dummy)
        dummy.setAttribute('value', value)
        dummy.select()
        document.execCommand('copy')
        document.body.removeChild(dummy)
        this.setState({ copyButtonText: 'Copied!' })
        setTimeout(() => this.setState({ copyButtonText: 'Copy' }), 2000)
      }

      return (
        <div style={{ marginLeft: '10px' }}>
          <Button
            styleType="plain"
            size="small"
            label={this.state.copyButtonText}
            onClick={copyToClipboardHandler}
          />
        </div>
      )
    }

    return null
  }

  public render(): JSX.Element {
    const {
      name,
      id,
      type,
      onChange,
      onBlur,
      onFocus,
      value,
      placeholder = '',
      disabled = false,
      className = '',
      plain,
      searchFilterTheme,
      /**
       * remove form props see
       * @link https://formik.org/docs/api/field#component
       * @link https://www.karltarvas.com/formik-custom-input-not-submitting-on-enter
       */
      form,
      field,
      ...rest
    } = this.props

    const inputProps: ITextInputProps = {
      name,
      id,
      type,
      onChange,
      onBlur,
      onFocus,
      placeholder,
      value,
      disabled,
      className,
    }

    const theme = searchFilterTheme
      ? { ...timfinityTheme, ...searchFilterTheme }
      : timfinityTheme

    const input = (
      <GrommetTextInput
        plain={plain}
        {...inputProps}
        {...field}
        {...rest}
        ref={ref => this.setInputRef(ref)}
      />
    )

    return (
      <div className={className}>
        <Grommet theme={theme}>
          <Box direction="row" align="center">
            {this.getLabel()}
            {this.copyToClipboardButton() ? (
              <div className="text-input-container">
                {input}
                {this.copyToClipboardButton()}
              </div>
            ) : (
              <>
                {input}
                {this.copyToClipboardButton()}
              </>
            )}
          </Box>
          <Box>{this.getError()}</Box>
        </Grommet>
      </div>
    )
  }
}

const StyledTextInput = styled(TextInput)`
  ${(props: ITextInputProps) => {
    let extraStyles = ''
    if (props.error) {
      extraStyles += `
                input {
                    border-color: ${colors.red} !important;
                }
            `
    }

    if (props.disabled) {
      extraStyles += `
                input {
                    background: ${colors.grey.lighter};
                    opacity: 0.9;
                }
            `
    }

    if (props.viewOnly) {
      extraStyles += `
                input {
                    pointer-events: none;
                }
            `
    }

    return `
            label {
                margin-right: 20px;
            }
            input {
                background: #fff;
                font-size: 1rem;
			}
            .error {
                color: ${colors.red};
            }
            ${extraStyles}
        `
  }}
`

export default StyledTextInput
