import * as React from 'react'
import { Image } from 'grommet'

interface IIconProps {
  className?: string
  svg: string
}

const Icon = (props: IIconProps) => {
  const { className, svg } = props
  return (
    <div className={className}>
      <Image src={svg} />
    </div>
  )
}

export default Icon
