import React from 'react'
import {
  and,
  ControlProps,
  RankedTester,
  rankWith,
  UISchemaElement,
} from '@jsonforms/core'
import { withJsonFormsControlProps, useJsonForms } from '@jsonforms/react'
import {
  VanillaRendererProps,
  withVanillaControlProps,
} from '@jsonforms/vanilla-renderers'
import ChargesTable from '../../../../views/components/pages/SingleChannelPage/ChargesTable'
import { useSelector } from 'react-redux'
import { authSelectors } from '../../../../state/modules/auth'
import ChargesInputField from '../../../../views/components/pages/SingleChannelPage/ChargesInputField'
import { get, map, upperFirst } from 'lodash'
import { useAtomValue } from 'jotai'
import {
  editingAtom,
  formDataAtom,
} from '../../../../views/components/pages/SingleChannelPage/channelFormAtoms'

const ChannelChargesTable = (
  props: ControlProps & VanillaRendererProps & { className?: string },
) => {
  const {
    config,
    data,
    className,
    classNames,
    id,
    enabled,
    uischema,
    schema,
    visible,
    required,
    label,
    path,
    handleChange,
    errors,
  } = props

  const { core } = useJsonForms()
  const chargesErrors = core?.errors?.filter(e => e.instancePath.includes(path))

  const isAdmin = useSelector(authSelectors.isAdmin)
  const isEditing = useAtomValue(editingAtom)
  const channelFormData = useAtomValue(formDataAtom)
  const getIsEditing = () => isEditing
  const channelCurrency = channelFormData?.currencies

  const chargesTableData = map(schema.properties, (p: any, key: any) => {
    return {
      label: upperFirst(key.replace(/_/g, ' ')),
      name: key,
      operator: p.type === 'number' ? 'Percent' : 'Flat',
      contentType: p.contentType,
      hiddenIn: get(p, 'hiddenIn', []),
      value: data[key],
    }
  }).filter((p: any) => !p.hiddenIn.includes(channelFormData?.account_mode))

  const isFormFieldDisabled = (field?: string) => {
    if (field) {
      const isReadOnly = get(schema, [field, 'readonly'], false)
      if (isReadOnly) {
        return true
      }
    }
    return !isEditing
  }

  const columns = [
    {
      accessor: 'label',
      Header: 'Label',
    },
    {
      accessor: 'operator',
      Header: 'Operator',
    },
    {
      accessor: 'value',
      Header: 'Value',
      Cell: (row: any) => {
        const key = `_tmt_charges[${row.original.name}]`
        const showCurrency = row.original.contentType === 'cents' ? true : false
        const shouldDisallowDecimals =
          !showCurrency && row.original.operator === 'Flat'
        const value = row.original.value
        const error = chargesErrors?.find(
          e => e.instancePath.split('/').pop() === row.original.name,
        )?.message

        return (
          <ChargesInputField
            getIsEditing={getIsEditing}
            disallowDecimals={shouldDisallowDecimals}
            className={className}
            name={key}
            value={value}
            currency={showCurrency ? channelCurrency : ''}
            disabled={isFormFieldDisabled(key)}
            onChange={e => {
              const val = !isNaN(parseFloat(e.target.value))
                ? parseFloat(e.target.value)
                : 0
              handleChange(`${path}.${row.original.name}`, val)
            }}
            errorCheck={() => false} // TODO: fix this
            error={error}
          />
        )
      },
    },
  ]

  return (
    <ChargesTable
      isAdmin={isAdmin}
      isEditing={isEditing}
      columns={columns}
      data={chargesTableData}
    />
  )
}

export const channelChargesTableTester: RankedTester = rankWith(
  4,
  and((uischema: UISchemaElement) => {
    return uischema?.options?.Component === 'ChannelChargesTable'
  }),
)

export default withVanillaControlProps(
  withJsonFormsControlProps(ChannelChargesTable, true),
)
