import { connect } from 'react-redux'
import { AppBar } from '../components'
// import { profile, logout, settings, search, accounts, analytics } from '../../assets/icons';
import { redirect } from '../../utils/router'
import { sitesSelectors } from '../../state/modules/sites'
import { authSelectors } from '../../state/modules/auth'
import { IAppBarIcon } from '../components/organisms/AppBar/AppBar'
import { uiAppSelectors } from '../../state/modules/ui'

const getTitleTooltip = (title: string) => ({
  content: title,
  plain: true,
})

const mapStateToProps = (state: any): { icons: IAppBarIcon[] } => {
  const currentSitePath = sitesSelectors.getCurrentSitePath(state as never)
  const currentSite = sitesSelectors.getCurrentSite(state as never)
  const isProtectionOnly = currentSite?.protection_only
  const isAdmin = authSelectors.isAdmin(state)
  const isMemberAdmin = authSelectors.isMemberAdmin(state)
  const canUserViewSettings = sitesSelectors.getCurrentSitePermission(
    state as never,
  )('view_settings')
  return {
    icons: [
      {
        title: getTitleTooltip('Search'),
        icon: 'search',
        path: '/search',
        currentPath: window.location.pathname,
        visible: isAdmin,
      },
      {
        title: getTitleTooltip('Accounts'),
        icon: 'accounts',
        path: '/payouts',
        currentPath: window.location.pathname,
        visible: isAdmin,
      },
      {
        title: getTitleTooltip('Profile'),
        icon: 'profile',
        path: `/${currentSitePath}/users`,
        currentPath: window.location.pathname,
        visible: isAdmin,
      },
      {
        title: getTitleTooltip('Uploads'),
        icon: 'uploads',
        path: `/${currentSitePath}/uploads`,
        currentPath: window.location.pathname,
        visible: isAdmin || (isMemberAdmin && isProtectionOnly),
      },
      {
        title: getTitleTooltip('Suppliers'),
        icon: 'suppliers',
        path: '/suppliers',
        currentPath: window.location.pathname,
        visible: isAdmin,
      },
      {
        title: getTitleTooltip('Analytics'),
        icon: 'analytics',
        path: `/${currentSitePath}/analytics`,
        currentPath: window.location.pathname,
        visible: uiAppSelectors.nonTestChannelsExist(state),
      },
      {
        title: getTitleTooltip('Settings'),
        icon: 'settings',
        path: `/${currentSitePath}/settings`,
        currentPath: window.location.pathname,
        visible: canUserViewSettings,
      },
      {
        title: getTitleTooltip('Logout'),
        icon: 'logout',
        path: '/logout',
        currentPath: window.location.pathname,
        visible: true,
      },
    ],
  }
}

const mapDispatchToProps = () => ({
  redirect: (path: string) => redirect(path),
})

export default connect(mapStateToProps, mapDispatchToProps)(AppBar)
