import { createSelector } from 'reselect'
import { get, find, memoize, max, sortBy } from 'lodash'
import { NAMESPACE } from './constants'
import { IChannelsModel, IChannelResponse } from './interfaces'
import { sitesSelectors } from '../sites'

const getState = (state: any) => state

const getChannelsState = (state: any): IChannelsModel => state[NAMESPACE]

export const getChannelIds = createSelector(
  [getState, getChannelsState],
  (state, channels) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(channels, `sites[${sitePath}].allIds`, [])
  },
)

export const getChannels = createSelector(
  [getState, getChannelsState],
  memoize((state: any, channels: any) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(channels, `sites[${sitePath}].byId`, {})
  }),
)

export const getChannelsInOrder = createSelector(
  [getState, getChannelsState],
  memoize((state: any, channels: any) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    const allIds = get(channels, `sites[${sitePath}].allIds`, [])
    return allIds.map((id: any) =>
      get(channels, `sites[${sitePath}].byId[${id}]`, {}),
    )
  }),
)

export const getChannelsOrderedByName = createSelector([getState], state => {
  return sortBy(getChannels(state), ['name'])
})

export const getChannelFilters = createSelector(
  [getState, getChannelsState],
  (state, channels) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(channels, `sites[${sitePath}].byFilters`, {})
  },
)

export const getChannelsByFilter = createSelector(
  [getState, getChannelsState],
  (state, channels) => (filter: string) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    const channelsByFilter = get(
      channels,
      `sites[${sitePath}].byFilters[${filter}]`,
      [],
    )
    return channelsByFilter.map((channelId: number) =>
      getChannelById(state)(channelId),
    )
  },
)

export const getChannelsByPage = createSelector(
  [getState, getChannelsState],
  (state, channels) => (page: number | string) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    const channelsByPage = get(
      channels,
      `sites[${sitePath}].byPage[${page}]`,
      [],
    )
    return channelsByPage.map((channelId: number) =>
      getChannelById(state)(channelId),
    )
  },
)

export const getLastFetchedPageNumber = createSelector(
  [getState, getChannelsState],
  (state, channels) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    const pageNumbers = Object.keys(
      get(channels, `sites[${sitePath}].byPage`, {}),
    )
    return max(pageNumbers)
  },
)

export const getChannelsSchema = createSelector(
  getChannelsState,
  channels => channels.schema,
)

export const getChannelsSchemaMethods = createSelector(
  getChannelsSchema,
  schema => get(schema, 'methods', []),
)

const getChannelByField = createSelector(
  getChannels,
  channels => (field: any, value: any) =>
    find(channels, (channel: IChannelResponse) => channel[field] === value),
)

export const getChannelById = createSelector(
  getChannels,
  (channels: any) => (id: number | string) => channels[id],
)

export const getChannelByName = createSelector(
  getState,
  s => (name: string) => getChannelByField(s)('name', name),
)

export const getChannelFieldById = createSelector(
  getState,
  s => (id: number, field: string) => {
    const channel = getChannelById(s)(id)
    return get(channel, [field])
  },
)

export const isLoading = createSelector(
  [getState, getChannelsState],
  (state, channels) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(channels, `sites[${sitePath}].isLoading`, false)
  },
)

export const getPages = createSelector(
  [getState, getChannelsState],
  (state, channels) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(channels, `sites[${sitePath}].pages`, false)
  },
)

export const getTotalInTrust = createSelector(
  [getState, getChannelsState],
  (state, channels) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(channels, `sites[${sitePath}].totalInTrust`, false)
  },
)

export const getTotalInTrustByChannelId = createSelector(
  [getState],
  state => (channelId: number) => {
    const totalInTrust = getTotalInTrust(state)
    return get(totalInTrust, [channelId])
  },
)

export const getChannelChangeLog = createSelector(
  getState,
  state => (id: string | number) => {
    const channel = getChannelById(state)(id)
    return get(channel, '_changelog', []).map((changelog: any) => ({
      user: changelog.user,
      timestamp: changelog.timestamp,
      changes: changelog.changes,
    }))
  },
)

export const getPropertiesFromSchema = createSelector(
  getChannelsSchema,
  memoize(schema => get(schema, 'schema.properties', {})),
)

export const getUiSchema = createSelector(
  getChannelsSchema,
  memoize(schema => get(schema, 'schema.ui', {})),
)

export const getCurrenciesFromSchema = createSelector(
  getChannelsSchema,
  memoize(schema => get(schema, 'schema.properties.currencies.enum', [])),
)

export const getChargesPropertiesFromSchema = createSelector(
  getChannelsSchema,
  schema => get(schema, 'schema.properties._tmt_charges.properties', []),
)

export const getAccountModesFromSchema = createSelector(
  getChannelsSchema,
  schema => get(schema, 'schema.properties.account_mode.enum', []),
)

export const getDefaultAccountModeFromSchema = createSelector(
  getChannelsSchema,
  schema => get(schema, 'schema.properties.account_mode.default', []),
)

export const getAccountTypesFromSchema = createSelector(
  getChannelsSchema,
  schema => get(schema, 'schema.properties.account_type.enum', []),
)

export const getProtectionTypesFromSchema = createSelector(
  getChannelsSchema,
  schema => get(schema, 'schema.properties.protection_type.enum', []),
)

export const getStatementPeriodsFromSchema = createSelector(
  getChannelsSchema,
  schema => get(schema, 'schema.properties.statement_period.enum', []),
)

export const getQuoteCodesFromSchema = createSelector(
  getChannelsSchema,
  schema => get(schema, 'schema.properties.quote_code.enum', []),
)

export const getSettlementCurrenciesFromSchema = createSelector(
  getChannelsSchema,
  schema => get(schema, 'schema.properties.settlement_currency.enum', ''),
)

export const getStatusOptionsFromSchema = createSelector(
  getChannelsSchema,
  schema => get(schema, 'schema.properties.channel_status.enum', []),
)

export const getStatusOptionsByAccountMode = createSelector(
  getChannelsSchema,
  schema => (accountMode: string) =>
    get(
      schema,
      `schema.properties.channel_status.enumIf.account_mode[${accountMode}]`,
      [],
    ),
)

export const getStatusDefaultByAccountMode = createSelector(
  getChannelsSchema,
  schema => (accountMode: string) =>
    get(
      schema,
      `schema.properties.channel_status.defaultIf.account_mode[${accountMode}]`,
      '',
    ),
)
