import { createSelector } from 'reselect'
import { get } from 'lodash'

const getUISitesState = (state: any) => state.ui.sites

export const isSaving = createSelector(getUISitesState, uiSites =>
  get(uiSites, 'isSaving', null),
)

export const isCreating = createSelector(getUISitesState, uiSites =>
  get(uiSites, 'isCreating', null),
)
