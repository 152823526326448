import * as React from 'react'
import { Box, FormField } from 'grommet'
import { FormikProps, Form, Field, Formik } from 'formik'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import { Button, TextInput, PasswordStrengthIndicator } from '../..'
import Anchor from '../../atoms/Anchor/Anchor'
import styled from 'styled-components'

interface IFormValues {
  email: string
  password: string
  password_confirmation: string
}

export interface IResetPasswordFormProps {
  resetpassword: (
    email: string,
    password: string,
    password_confirmation: string,
    token: string,
  ) => void
  className?: string
}

export interface IResetPasswordFormState {
  copyPasswordText: string
}

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email is required.'),
  password: yup
    .string()
    .matches(/^(?=.{6,})/, 'The password must be at least 6 characters')
    .required('Password is required.'),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Password confirmation is required.'),
})

const ResetPasswordForm = (props: IResetPasswordFormProps) => {
  const [copyPasswordText, setCopyPasswordText] = React.useState(
    'Generate strong password',
  )
  const { className } = props

  const initialValues: IFormValues = {
    email: '',
    password: '',
    password_confirmation: '',
  }

  const randomPassword = () => {
    let i,
      n = 10,
      password = '',
      possible =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    for (i = 0; i < n; ++i) {
      password += possible.charAt(Math.floor(Math.random() * possible.length))
    }
    return password
  }

  const copyToClipboardHandler = (e: any) => {
    e.preventDefault()
    const dummy = document.createElement('input')
    document.body.appendChild(dummy)
    dummy.setAttribute('value', randomPassword())
    dummy.select()
    document.execCommand('copy')
    document.body.removeChild(dummy)
    setCopyPasswordText('Password Copied!')
    setTimeout(() => setCopyPasswordText('Generate strong password'), 2000)
  }

  const handleSubmit = (values: IFormValues) => {
    const token = window.location.search.split('=')
    props.resetpassword(
      values.email,
      values.password,
      values.password_confirmation,
      token[1],
    )
  }

  return (
    <Box
      fill={true}
      align="center"
      justify="center"
      pad="large"
      className={className}
    >
      <Box width="medium">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({
            handleChange,
            setFieldValue,
            values,
            errors,
            touched,
          }: FormikProps<IFormValues>) => {
            return (
              <Form>
                <FormField>
                  <Field
                    component={TextInput}
                    id="email"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    value={values.email}
                    error={touched.email && errors.email}
                  />
                </FormField>
                <FormField>
                  <Field
                    component={TextInput}
                    id="password"
                    name="password"
                    type="password"
                    placeholder="New Password"
                    onChange={handleChange}
                    value={values.password}
                    error={touched.password && errors.password}
                  />
                </FormField>
                <FormField>
                  <Field
                    component={TextInput}
                    id="password_confirmation"
                    name="password_confirmation"
                    type="password"
                    placeholder="Confirm Password"
                    onChange={handleChange}
                    value={values.password_confirmation}
                    error={
                      touched.password_confirmation &&
                      errors.password_confirmation
                    }
                  />
                </FormField>
                <PasswordStrengthIndicator password={values.password} />
                <Anchor
                  className="generate-password"
                  onClick={copyToClipboardHandler}
                >
                  {copyPasswordText}
                </Anchor>
                <div className="reset-password-button">
                  <Button
                    alignSelf="center"
                    styleType="gradient"
                    size="large"
                    type="submit"
                    label="Reset"
                  />
                </div>
                <div className="login">
                  Go to <Link to="/login">login</Link>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </Box>
  )
}

const StyledResetPasswordForm = styled(ResetPasswordForm)`
  input {
    border: none;
    &:focus {
      box-shadow: none;
    }
  }
  .generate-password {
    display: block;
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    text-decoration: underline;
    margin-top: 5px;
  }
  .login {
    margin-top: 30px;
    text-align: center;
    font-size: 1rem;
    a {
      color: #2980b9;
    }
  }
`

export default StyledResetPasswordForm
