import { ISitesModel } from './interfaces'

const sitesModel: ISitesModel = {
  schema: {},
  byId: {},
  allIds: [],
  bySearchTerms: {},
  currentSearchTerm: '',
  currentSite: null,
  previousSite: null,
}

export default sitesModel
