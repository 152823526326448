import { ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import * as actions from './actions'
import { perPage } from './constants'
import { IUIPayoutsModel } from './interfaces'
import { get as _get } from 'lodash'
import { payoutActions } from '../../payouts'

export const fetchDataOnPageChange = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.setCurrentPage),
    map(action => {
      let params: IUIPayoutsModel = { per_page: perPage, page: action.payload }
      return payoutActions.requestPayouts(params)
    }),
  )
