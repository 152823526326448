import { formatCurrency } from '../../../../utils/currency'
import { IChannelMetrics, IChannelMetricsFormatted } from './interfaces'

export const transformMetrics = (
  metrics: IChannelMetrics,
): IChannelMetricsFormatted => ({
  ...metrics,
  unreported_transactions: formatCurrency(
    metrics.unreported_transactions,
    true,
  ),
  pending_statements: formatCurrency(metrics.pending_statements, true),
  ledger: formatCurrency(metrics.ledger, true),
  reserve: formatCurrency(metrics.reserve, true),
  last_payout: formatCurrency(metrics.last_payout, true),
  last_invoice: formatCurrency(metrics.last_invoice, true),
  invoice_owing: formatCurrency(metrics.invoice_owing, true),
  weekly_transactions: formatCurrency(metrics.weekly_transactions, true),
  monthly_transactions: formatCurrency(metrics.monthly_transactions, true),
  weekly_bookings: formatCurrency(metrics.weekly_bookings, true),
  monthly_bookings: formatCurrency(metrics.monthly_bookings, true),
})
