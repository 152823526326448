import { createActions } from 'redux-actions'
import { NAMESPACE } from './constants'

export const {
  ui: {
    app: {
      init,
      booted,
      setLoginLoadingState,
      setLoginErrors,
      setPasswordResetSent,
      setPasswordResetSuccess,
      setNonTestChannelsExist,
    },
  },
} = createActions({
  [`${NAMESPACE}/init`]: (payload: any) => payload,
  [`${NAMESPACE}/booted`]: (payload: any) => payload,
  [`${NAMESPACE}/set_login_loading_state`]: (payload: any) => payload,
  [`${NAMESPACE}/set_login_errors`]: (payload: any) => payload,
  [`${NAMESPACE}/set_password_reset_sent`]: (payload: any) => payload,
  [`${NAMESPACE}/set_password_reset_success`]: (payload: any) => payload,
  [`${NAMESPACE}/set_non_test_channels_exist`]: (payload: boolean) => payload,
})
