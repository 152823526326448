import React from 'react'
import ConfirmationModal, {
  IConfirmationModalProps,
} from '../../../../views/components/organisms/ConfirmationModal/ConfirmationModal'
import { useDisableTwoFactorAuth } from '../../api'
import { useDispatch } from 'react-redux'
import { usersActions } from '../../../../state/modules/users'

interface Props
  extends Omit<IConfirmationModalProps, 'children' | 'title' | 'onConfirm'> {
  userId: string
}

const DisableTwoFactorAuthModal = ({ userId, ...modalProps }: Props) => {
  const { mutate, isLoading } = useDisableTwoFactorAuth({
    userId,
    onSuccess: res => {
      if (modalProps.onClose) {
        modalProps.onClose()
      }
    },
  })

  const handleConfirm = React.useCallback(() => {
    mutate(null)
  }, [mutate])

  return (
    <ConfirmationModal
      {...modalProps}
      isLoading={isLoading}
      loadingText="Disabling two factor authentication..."
      title="Disable Two Factor Authentication"
      onConfirm={handleConfirm}
    >
      <p>Are you sure you want to disable two factor authentication?</p>
    </ConfirmationModal>
  )
}

export default DisableTwoFactorAuthModal
