import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { get } from 'lodash'
import useFilter from '../../../../hooks/useFilter'
import usePagination from '../../../../hooks/usePagination'
import {
  supplierInterfaces,
  supplierActions,
} from '../../../../state/modules/suppliers'

const SuppliersPageService = () => {
  const dispatch = useDispatch()

  const { filter } = useFilter()
  const { pagination, setPagination } = usePagination()

  const defaultPagination = {
    per_page: 20,
    page: 1,
  }

  useEffectOnce(() => {
    if (!pagination) {
      setPagination(defaultPagination)
    }
  })

  useEffect(() => {
    const params: supplierInterfaces.IRequestSuppliersParams =
      filter && get(Object.values(filter), [0]) === 'all'
        ? {
            ...pagination,
          }
        : {
            ...pagination,
            ...filter,
          }

    dispatch(supplierActions.requestSuppliers(params))
  }, [dispatch, filter, pagination])

  return null
}

export default SuppliersPageService
