import * as React from 'react'
import { FormikProps, Form, withFormik } from 'formik'
import classNames from 'classnames'
import { ITransaction } from '../../../../../state/modules/transactions/interfaces'
import { Text, Button, Loader } from '../../..'
import { colors } from '../../../../styles/variables'
import styled from 'styled-components'

interface IApplyResendFormProps {
  transaction: ITransaction
  className?: string
  isApplyingResend: boolean
  onAmendEmail(): void
  onSubmit(endpoint: string): void
}

interface IFormValues {
  endpoint: string
}

const ApplyResendForm = (
  props: IApplyResendFormProps & FormikProps<IFormValues>,
) => {
  const { transaction, isApplyingResend, onAmendEmail } = props
  const classes = classNames(props.className, {
    'is-submitting': isApplyingResend,
  })

  return (
    <div className={classes}>
      {isApplyingResend && <Loader text="Resending receipt..." />}
      <Form id="apply-resend-form" className="form">
        <Text tag="h3" color={colors.blue.dark}>
          Email Receipt
        </Text>
        <p>Resend email receipt to {transaction.payee_email}</p>
        <footer>
          <Button
            styleType="warning"
            label="Amend Email"
            size="small"
            type="button"
            onClick={onAmendEmail}
          />
          <Button
            styleType="secondary"
            label="Send"
            size="small"
            type="submit"
          />
        </footer>
      </Form>
    </div>
  )
}

const StyledApplyResendForm = styled(ApplyResendForm)`
  margin: 10px;
  &.is-submitting {
    form {
      display: none;
    }
  }
  h3 {
    margin: 0 0 10px 0;
    border-bottom: 1px solid ${colors.grey.lighter};
  }
  .field {
    margin-bottom: 10px;
    position: relative;
  }
  .field label {
    margin-right: 30px;
  }
  footer {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid ${colors.grey.lighter};
    margin-top: 10px;
    padding-top: 10px;
  }
  footer button {
    font-size: 1rem;
    padding: 6px 8px;
    margin-left: 10px;
  }
  .field .error {
    display: block;
    position: absolute;
    right: 0;
    top: 30px;
    bottom: 30px;
    font-size: 12px;
  }
  #content::placeholder {
    font-size: 14px;
    font-weight: normal;
  }
`

export default withFormik<IApplyResendFormProps, IFormValues>({
  handleSubmit: ({ endpoint }, { props }) => {
    const { transaction } = props
    const resend = transaction._links.self[0].resend
    props.onSubmit(resend)
  },
})(StyledApplyResendForm)
