import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useEffectOnce } from 'react-use'
import AsyncSelect from '../molecules/AsyncSelect/AsyncSelect'
import useFetchHelper from '../molecules/AsyncSelect/useFetchHelper'
import { ISupplier } from '../../../state/modules/suppliers/interfaces'
import {
  supplierActions,
  supplierConstants,
  supplierSelectors,
} from '../../../state/modules/suppliers'

interface ISupplierSelectorProps {
  className?: string
  isClearable?: boolean
  placeholder?: string
  onChange?(value: string | string[]): void
  value?: string | string[]
  disabled?: boolean
  id?: string
  isMulti?: boolean
  closeMenuOnSelect?: boolean
}
interface IOption {
  value: string | number
  label: string
}

const PER_PAGE = 20

const SupplierSelector = ({
  className,
  isClearable,
  placeholder = 'Select supplier...',
  onChange,
  value,
  disabled,
  isMulti = false,
  ...props
}: ISupplierSelectorProps) => {
  const dispatch = useDispatch()
  const { request } = useFetchHelper(
    supplierConstants.ENDPOINTS.BASE,
    supplierActions.requestSuppliersSuccess,
  )
  const [initialOptions, setInitialOptions] = useState([])
  const getSupplierById = useSelector(supplierSelectors.getSupplierById)

  const getInitialOptions = async () => {
    return await request({ per_page: PER_PAGE, page: 1 })
  }

  const transformOption = (supplier: ISupplier): IOption => {
    return (
      supplier && {
        value: supplier.id,
        label: supplier.name,
      }
    )
  }

  useEffectOnce(() => {
    const setOptions = async () => {
      const { options } = await getInitialOptions()
      setInitialOptions(
        options.map((supplier: ISupplier) => transformOption(supplier)),
      )
    }
    setOptions()
  })

  useEffect(() => {
    if (isMulti && Array.isArray(value)) {
      value.length > 0 &&
        dispatch(supplierActions.requestSuppliers({ include: value.join(',') }))
    }
  }, [dispatch, value, isMulti])

  const loadOptions = async (name: string, prevOptions: any) => {
    const params: any = {
      order_by: 'name',
      per_page: PER_PAGE,
      page: prevOptions.length / PER_PAGE + 1,
    }
    if (name) {
      params.name = name
    }
    const res = await request(params)
    return {
      ...res,
      options: res.options.map((supplier: ISupplier) =>
        transformOption(supplier),
      ),
    }
  }

  const handleChange = (value: any, action: any) => {
    if (onChange) {
      if (isMulti) {
        if (value) {
          const suppliers = value.map((v: IOption) => v.value)
          onChange(suppliers)
        } else {
          onChange([])
        }
      } else {
        onChange(value.value)
      }
    } else if (!value) {
      // setSupplierId(undefined);
    } else {
      // const supplier: ISupplier = getSupplierById(value.value);
      // supplier && setSupplierId(supplier.id);
    }
  }

  const currentValue = isMulti
    ? value &&
      Array.isArray(value) &&
      value.map((val: string) => transformOption(getSupplierById(val)))
    : value && transformOption(getSupplierById(value as string))

  return (
    <AsyncSelect
      className={classNames([className, 'supplier-selector'])}
      isClearable={isClearable}
      placeholder={placeholder}
      initialOptions={initialOptions}
      loadOptions={loadOptions}
      onChange={handleChange}
      value={currentValue}
      isDisabled={disabled}
      isMulti={isMulti}
      {...props}
    />
  )
}

export default styled(SupplierSelector)`
  width: 300px;
  margin-right: 10px;
`
