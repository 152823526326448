import { createSelector } from 'reselect'
import { get, flatMap, memoize } from 'lodash'
import moment from 'moment'
import { bookingSelectors } from '../../bookings'
import {
  transactionsSelectors,
  transactionsInterfaces,
} from '../../transactions'
import { IBookingResponse } from '../../bookings/interfaces'
import { currencySelectors } from '../../currency'
import { formatDate } from '../../../../utils/date'
import { history } from '../../../../utils/router'
import { ITransaction } from '../../transactions/interfaces'
import { paymentsSelectors } from '../../payments'
import { convertFromCents } from '../../../../utils/currency'
import { IPayment } from '../../payments/interfaces'
import countries from '../../../../constants/countries'

const getState = (state: any) => state

// const getUIState = (state: any): any => state[NAMESPACE];

const getUIBookingsState = (state: any) => state.ui.bookings

export const getCurrentPage = createSelector(
  getUIBookingsState,
  uiBookingsState => get(uiBookingsState, 'currentPage', 1),
)

export const getCurrentFilter = createSelector(
  getUIBookingsState,
  uiBookingsState => get(uiBookingsState, 'currentFilter', ''),
)

export const getCurrentChannel = createSelector(
  getUIBookingsState,
  uiBookingsState => get(uiBookingsState, 'currentChannel', null),
)

export const getBookingsTableData = createSelector(getState, (state: any) =>
  memoize((page: number) => {
    const bookings = bookingSelectors.getBookingsByPage(state)(page)
    return bookings.map((booking: IBookingResponse) => {
      const {
        id,
        trust_id,
        created,
        surname,
        email,
        reference,
        date: complete,
        currencies,
        total,
        total_unpaid,
        status,
      } = booking
      return {
        id,
        trust_id,
        created,
        surname,
        email,
        reference,
        complete,
        total: currencySelectors.formatValueWithCurrency(state)(
          total,
          currencies,
        ),
        total_unpaid: currencySelectors.formatValueWithCurrency(state)(
          total_unpaid,
          currencies,
        ),
        status,
      }
    })
  }),
)

export const getBookingsTableDataByFilter = createSelector(
  getState,
  state => (filter: string) =>
    bookingSelectors
      .getBookingsByFilter(state)(filter)
      .map((booking: IBookingResponse) => {
        const {
          id,
          trust_id,
          created,
          surname,
          email,
          reference,
          date: complete,
          total,
          total_unpaid,
          suppliers,
        } = booking
        return {
          id,
          trust_id,
          created,
          surname,
          email,
          reference,
          complete,
          total,
          total_unpaid,
          suppliers,
        }
      }),
)

export const isFetchingBooking = createSelector(
  getUIBookingsState,
  uiBookingState => uiBookingState.isFetchingBooking,
)

export const isCreatingBooking = createSelector(
  getUIBookingsState,
  uiBookingState => uiBookingState.isCreatingBooking,
)

export const isViewingBooking = createSelector(getState, state => {
  const {
    location: { pathname },
  } = history
  return pathname.includes('/bookings/') && pathname.split('/').pop() !== 'new'
})

export const isSaving = createSelector(
  getUIBookingsState,
  uiBookingState => uiBookingState.isSaving,
)

export const isDeleting = createSelector(
  getUIBookingsState,
  uiBookingState => uiBookingState.isDeleting,
)

export const getLastCreatedBookingId = createSelector(
  getUIBookingsState,
  uiBookingState => uiBookingState.lastCreatedBookingId,
)

const getBookingTransactions = createSelector(
  getState,
  state => (bookingId: number) => {
    const transactionIds =
      bookingSelectors.getBookingTransactionIds(state)(bookingId)

    if (transactionIds.length === 0) {
      return []
    }

    return transactionIds
      .map((id: number) => transactionsSelectors.getTransactionById(state)(id))
      .filter((transaction: any) => transaction !== null)
  },
)

export const getBookingTransactionsTableData = createSelector(
  getState,
  state => (bookingId: number) => {
    const transactions = getBookingTransactions(state)(bookingId)

    return transactions.map(
      (transaction: transactionsInterfaces.ITransaction) => {
        const {
          id,
          trust_id,
          created,
          payee_name,
          payee_surname,
          transaction_types,
          currencies,
          total,
          status,
        } = transaction

        return {
          id,
          trust_id,
          created: formatDate(created, 'dd LLL yyyy HH:mm'),
          payee_name,
          payee_surname,
          transaction_types,
          credit: currencySelectors.formatValueWithCurrency(state)(
            total,
            currencies,
          ),
          status,
        }
      },
    )
  },
)

export const getBookingPaymentsTableData = createSelector(
  getState,
  state => (bookingId: number) => {
    const booking = bookingSelectors.getBookingById(state)(bookingId)
    const bookingChannelId = get(booking, 'channels')
    const paymentIds: number[] = flatMap(
      getBookingTransactions(state)(bookingId).map(
        (transaction: ITransaction) => transaction.payment_ids,
      ),
    )

    const payments: IPayment[] = paymentIds.map(id =>
      paymentsSelectors.getPaymentById(state)(id),
    )

    const paymentsData = payments
      .filter(
        (payment: IPayment) => bookingChannelId === get(payment, 'channels'),
      )
      .map((payment: IPayment) => {
        if (!payment) {
          return {
            id: 0,
            title: 'Loading...',
            credit: false,
            debit: false,
          }
        }

        const { id, title, total, payment_types, currencies } = payment
        const formattedTotal = currencySelectors.formatValueWithCurrency(state)(
          total,
          currencies,
        )

        return {
          id,
          title: title,
          debit: payment_types === 'debit' && total ? formattedTotal : false,
          credit: payment_types === 'credit' && total ? formattedTotal : false,
        }
      })

    return paymentsData.filter(d => d !== undefined)
  },
)
