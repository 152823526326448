import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import Select from 'react-select'
import styled from 'styled-components'
import countries, { ICountry } from '../../../../constants/countries'

interface ICountryMultiSelectorProps {
  className?: string
  isClearable?: boolean
  placeholder?: string
  onChange?(value: string | ICountry[]): void
  value?: any /** @todo */
  disabled?: boolean
  id?: string
}

const CountryMultiSelector = ({
  className,
  isClearable,
  placeholder = 'Select countries...',
  onChange,
  value,
  disabled,
  ...props
}: ICountryMultiSelectorProps) => {
  const [selectedCountries, setSelectedCountries] = useState([])

  useEffect(() => {
    if (value) {
      setSelectedCountries(value)
    }
  }, [value])

  const handleChange = (value: any, action: any) => {
    if (onChange) {
      if (value) {
        const countries = value.map((option: ICountry) => option)
        onChange(countries)
      } else {
        onChange([])
      }
    } else if (!value) {
      console.log('No countries selected')
    }
  }

  return (
    <Select
      className={classNames([className, 'country-multi-selector'])}
      isClearable={isClearable}
      placeholder={placeholder}
      closeMenuOnSelect={false}
      value={selectedCountries}
      isMulti={true}
      options={countries}
      onChange={handleChange}
      isDisabled={disabled}
      {...props}
    />
  )
}

export default styled(CountryMultiSelector)`
  width: inherit;
`
