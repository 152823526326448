import { useDispatch, useStore } from 'react-redux'
import { ActionCreator, AnyAction } from 'redux'
import { of } from 'rxjs'
import { get as _get } from 'lodash'
import { map, mergeMap, mapTo, tap } from 'rxjs/operators'
import { injectCurrentSitePath } from '../../../../state/utils/general'
import { catchRestError, get } from '../../../../state/utils/http'
import RestfulResult from '../../../../state/utils/RestfulResult'

/**
 * Hook that fetches data using RXJS and dispatches action for Redux compatibility
 */
export default function useFetchHelper(
  endpoint: string,
  action: ActionCreator<AnyAction>,
) {
  const store = useStore()
  const state = store.getState()
  const dispatch = useDispatch()

  return {
    request: (params: any = {}) =>
      of(params)
        .pipe(
          map(params => ({ params })),
          get(injectCurrentSitePath(endpoint, state), true),
          mergeMap((response: RestfulResult) =>
            response.mapOrFail((p: any) =>
              of(p).pipe(
                tap(p => dispatch(action(p, response.getContext()))),
                mapTo({ response: p, context: response.getContext() }),
              ),
            ),
          ),
          catchRestError(action.toString()),
          map(({ response, context }) => {
            return {
              options: response,
              hasMore: _get(response, 'length', 0) === params.per_page,
            }
          }),
        )
        .toPromise(),
  }
}
