import * as React from 'react'
import styled from 'styled-components'
import { get, first } from 'lodash'
import { useEffectOnce } from 'react-use'
import { useSelector } from 'react-redux'
import SearchFilter from '../../organisms/SearchFilter/SearchFilter'
import { TextFilter, SelectFilter } from '../../organisms/SearchFilter/types'
import useFilter from '../../../../hooks/useFilter'
import { uiChannelsSelectors } from '../../../../state/modules/ui'
import { sitesSelectors } from '../../../../state/modules/sites'
import usePagination from '../../../../hooks/usePagination'

interface IChannelSearchFiltersProps {
  className?: string
}

const ChannelSearchFilters = (
  props: IChannelSearchFiltersProps,
): JSX.Element => {
  const { setCurrentPage } = usePagination()
  const {
    filter,
    activeFilter,
    setActiveFilter,
    activeFilterValue,
    setActiveFilterValue,
  } = useFilter()

  const currencies: any = useSelector(
    uiChannelsSelectors.getCurrenciesForSearchFilter,
  )
  const accountModes: any = useSelector(
    uiChannelsSelectors.getAccountModesForSearchFilter,
  )
  const statementPeriods: any = useSelector(
    uiChannelsSelectors.getStatementPeriodsForSearchFilter,
  )
  const accountTypes: any = useSelector(
    uiChannelsSelectors.getAccountTypesForSearchFilter,
  )
  const currentSite = useSelector(sitesSelectors.getCurrentSite)
  const isProtectionOnly = currentSite?.protection_only

  /**
   * We don't need this because we're overwriting filters in `handleFilterChange`
   */
  // const handleParamsResetOnFilterOptionChange = () => {
  // };

  const filters = [
    {
      value: 'include',
      label: 'Channel ID',
      type: 'text' as TextFilter,
      onChange: setActiveFilterValue,
    },
    {
      value: 'name',
      label: 'Channel Name',
      type: 'text' as TextFilter,
      onChange: setActiveFilterValue,
    },
    {
      value: 'external_id',
      label: 'External ID',
      type: 'text' as TextFilter,
      onChange: setActiveFilterValue,
    },
    {
      value: 'currencies',
      label: 'Currency',
      type: 'select' as SelectFilter,
      options: [{ value: 'all', label: 'All currencies' }, ...currencies],
      onChange: setActiveFilterValue,
    },
    {
      value: 'account_mode',
      label: 'Account Mode',
      type: 'select' as SelectFilter,
      onChange: setActiveFilterValue,
      options: [{ value: 'all', label: 'All account modes' }, ...accountModes],
    },
  ]

  if (!isProtectionOnly) {
    filters.push(
      {
        value: 'statement_period',
        label: 'Statement Period',
        type: 'select' as SelectFilter,
        onChange: setActiveFilterValue,
        options: [
          { value: 'all', label: 'All statement periods' },
          ...statementPeriods,
        ],
      },
      {
        value: 'account_type',
        label: 'Account Type',
        type: 'select' as SelectFilter,
        onChange: setActiveFilterValue,
        options: [
          { value: 'all', label: 'All account types' },
          ...accountTypes,
        ],
      },
    )
  }

  const firstFilter = first(filters)
  const _activeFilter = activeFilter || get(firstFilter, 'value', '')

  /**
   * Set default active filter and value
   * If filter in query params, set that.
   * Else set first filter in array above as active filter
   */
  useEffectOnce(() => {
    const firstFilterKey =
      (filter && first(Object.keys(filter))) || get(filters, [0, 'value'], '')
    const firstFilterValue = (filter && first(Object.values(filter))) || ''
    setActiveFilter(firstFilterKey)
    setActiveFilterValue(firstFilterValue)
  })

  /**
   * Wrap `setActiveFilterValue` so we can reset pagination as well.
   */
  const _setActiveFilterValue = (val: any) => {
    setActiveFilterValue(val)
    setCurrentPage(1)
  }

  return (
    <div className={props.className}>
      <SearchFilter
        filters={filters}
        activeFilter={_activeFilter}
        setFilter={setActiveFilter}
        activeFilterValue={activeFilterValue}
        setActiveFilterValue={_setActiveFilterValue}
        // paramsResetOnFilterOptionChange={paramsResetOnFilterOptionChange}
      />
    </div>
  )
}

export default styled(ChannelSearchFilters)`
  display: flex;
  min-height: 44px;
`
