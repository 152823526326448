import * as React from 'react'
import { FormikProps, Form, FastField, withFormik } from 'formik'
import { omit } from 'lodash'
import classNames from 'classnames'
import * as yup from 'yup'
import {
  ITransaction,
  ICreateTransaction,
} from '../../../../../state/modules/transactions/interfaces'
import { Text, Button, Loader } from '../../..'
import { TextArea } from 'grommet'
import { colors } from '../../../../styles/variables'
import styled from 'styled-components'
import { addTrailingZero, convertToCents } from '../../../../../utils/currency'

interface IChargebackOverrideFormProps {
  transaction: ITransaction
  className?: string
  isApplyingChargebackOverride: boolean
  onSubmit(params: ICreateTransaction): void
}

interface IFormValues {
  content: string
}

const validationSchema = yup.object().shape({
  content: yup.string().required('Please give a reason for the override.'),
})

const ChargebackOverrideForm = (
  props: IChargebackOverrideFormProps & FormikProps<IFormValues>,
) => {
  const {
    handleChange,
    values,
    touched,
    errors,
    isApplyingChargebackOverride,
  } = props
  const classes = classNames(props.className, {
    'is-submitting': isApplyingChargebackOverride,
  })

  return (
    <div className={classes}>
      {isApplyingChargebackOverride && <Loader text="Applying override..." />}
      <Form className="form">
        <Text tag="h3" color={colors.blue.dark}>
          Chargeback Override
        </Text>
        <FastField
          component={TextArea}
          id="content"
          name="content"
          onChange={handleChange}
          label="Reason for override"
          value={values.content}
          error={touched.content && errors.content}
          className="field"
          placeholder="Reason for override"
        />
        <footer>
          <Button
            styleType="secondary"
            label="Override"
            size="small"
            type="submit"
          />
        </footer>
      </Form>
    </div>
  )
}

const StyledChargebackOverrideForm = styled(ChargebackOverrideForm)`
  margin: 10px;
  &.is-submitting {
    form {
      display: none;
    }
  }
  h3 {
    margin: 0 0 10px 0;
    border-bottom: 1px solid ${colors.grey.lighter};
  }
  .field {
    margin-bottom: 10px;
  }
  .field label {
    margin-right: 20px;
  }
  footer {
    border-top: 1px solid ${colors.grey.lighter};
    text-align: right;
    margin-top: 10px;
    padding-top: 10px;
  }
  footer button {
    font-size: 1rem;
    padding: 6px 8px;
  }
  .field .error {
    display: block;
    position: absolute;
    right: 10px;
    top: 150px;
    bottom: 30px;
    font-size: 12px;
  }
  #content::placeholder {
    font-size: 14px;
    font-weight: normal;
  }
`

export default withFormik<IChargebackOverrideFormProps, IFormValues>({
  validationSchema,
  validateOnBlur: true,
  validateOnChange: true,
  mapPropsToValues: (props: IChargebackOverrideFormProps) => {
    const { transaction } = props
    const { id, channels, bookings, currencies, total } = transaction
    return {
      channels,
      bookings: bookings.map(b => omit(b, ['reference'])),
      currencies,
      transaction_types: 'chargeback-override',
      linked_id: id,
      total: addTrailingZero(total / 100),
      content: '',
    }
  },
  handleSubmit: (values: any, { props }) => {
    const { transaction } = props
    const total = convertToCents(values.total)
    const params = {
      ...values,
      total,
      bookings: values.bookings.map((booking: any) => ({
        ...booking,
        total: transaction.forex_rate
          ? convertToCents(values.total / parseFloat(transaction.forex_rate))
          : total,
      })),
    }
    props.onSubmit(params)
  },
})(StyledChargebackOverrideForm)
