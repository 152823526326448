import { encodeObject, decodeObject } from 'use-query-params'

/**
 * Custom serialization for object query params
 * @link https://github.com/pbeshai/serialize-query-params
 */
export const ObjectParam = {
  encode: (obj: {
    [key: string]: string | number | undefined
  }): string | undefined => {
    return encodeObject(obj, '-', '~')
  },
  decode: (
    input: string | string[] | null | undefined,
  ): { [key: string]: string } | undefined => {
    /** consider removing JSON.stringify once re-renderes are ironed out. */
    // return JSON.stringify(decodeObject(input, '-', '~'));
    return decodeObject(input, '-', '~')
  },
}
