import { connect } from 'react-redux'
import { Dispatch, AnyAction } from 'redux'
import { Notifications } from '../components'
import {
  uiNotificationSelectors,
  uiNotificationActions,
} from '../../state/modules/ui/notifications'

const mapStateToProps = (state: any, ownProps: any) => ({
  notifications: uiNotificationSelectors.getNotifications(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onClose: (id: string) =>
    dispatch(uiNotificationActions.removeNotification(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
