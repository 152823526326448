import React from 'react'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import classNames from 'classnames'
import { FormField } from 'grommet'
import { useDispatch } from 'react-redux'
import { ITransaction } from '../../../../../state/modules/transactions/interfaces'
import { Text, Button, Loader, TextInput } from '../../..'
import { colors } from '../../../../styles/variables'
import styled from 'styled-components'
import { transactionsActions } from '../../../../../state/modules/transactions'

interface IAmendEmailFormProps {
  transaction: ITransaction
  className?: string
  isAmendingEmail: boolean
  onCancel(): void
}

const validationSchema = yup.object({
  payee_email: yup
    .string()
    .email('Invalid email address')
    .required('Email is required.'),
})

const AmendEmailForm = (props: IAmendEmailFormProps) => {
  const { transaction, isAmendingEmail, onCancel } = props
  const dispatch = useDispatch()
  const classes = classNames(props.className, {
    'is-submitting': isAmendingEmail,
  })

  return (
    <div className={classes}>
      <Formik
        initialValues={{ payee_email: transaction.payee_email }}
        validationSchema={validationSchema}
        onSubmit={values => {
          dispatch(
            transactionsActions.requestUpdateTransaction([
              values,
              { id: transaction.id },
            ]),
          )
        }}
      >
        {({ handleChange, values, touched, errors }) => {
          return (
            <>
              {isAmendingEmail && <Loader text="Amending email..." />}
              <Form id="amend-email-form" className="form">
                <Text tag="h3" color={colors.blue.dark}>
                  Email Receipt
                </Text>
                <FormField className="field" error={errors.payee_email}>
                  <TextInput
                    plain
                    name={'payee_email'}
                    value={values.payee_email}
                    onChange={handleChange}
                  />
                </FormField>
                <footer>
                  <Button
                    styleType="secondary"
                    label="Save"
                    size="small"
                    type="submit"
                  />
                  <Button
                    styleType="danger"
                    label="Cancel"
                    size="small"
                    type="button"
                    onClick={onCancel}
                  />
                </footer>
              </Form>
            </>
          )
        }}
      </Formik>
    </div>
  )
}

const StyledAmendEmailForm = styled(AmendEmailForm)`
  margin: 10px;
  &.is-submitting {
    form {
      display: none;
    }
  }
  h3 {
    margin: 0 0 10px 0;
    border-bottom: 1px solid ${colors.grey.lighter};
  }
  .field {
    margin-bottom: 10px;
    position: relative;
  }
  .field label {
    margin-right: 30px;
  }
  footer {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid ${colors.grey.lighter};
    margin-top: 10px;
    padding-top: 10px;
  }
  footer button {
    font-size: 1rem;
    padding: 6px 8px;
    margin-left: 10px;
  }
  .field span {
    font-size: 12px;
  }
  #content::placeholder {
    font-size: 14px;
    font-weight: normal;
  }
`

export default StyledAmendEmailForm
