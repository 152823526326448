import decode from 'jwt-decode'
import { createSelector } from 'reselect'
import { get } from 'lodash'
import { NAMESPACE } from './constants'
import { IAuthModel, UserType } from './interfaces'
// import { IUser } from '../users/interfaces';

const getState = (state: any) => state

export const getAuthState = (state: any): IAuthModel => state[NAMESPACE]

export const isAuthenticated = createSelector(
  getAuthState,
  (authState: IAuthModel) => get(authState, 'isAuthenticated', false),
)

export const getToken = createSelector(
  getAuthState,
  (authState: IAuthModel) => authState.token,
)

export const getUserId = createSelector(getState, (state: any): number => {
  const token = getToken(state)
  const decodedToken = decode(token)
  return get(decodedToken, 'sub', 0)
})

export const getUserType = createSelector(
  getAuthState,
  (authState: IAuthModel): UserType => authState.usertype,
)

export const getUserEmail = createSelector(
  getAuthState,
  (authState: IAuthModel) => authState.user_email,
)

export const getUserName = createSelector(
  getAuthState,
  (authState: IAuthModel) => authState.user_nicename,
)

export const getUserUsername = createSelector(
  getAuthState,
  (authState: IAuthModel) => authState.username,
)

export const getSiteCount = createSelector(
  getAuthState,
  (authState: IAuthModel) => authState.site_count,
)

export const isAdmin = createSelector(
  getState,
  (s: any) => getUserType(s) === 'administrator',
)

export const isMemberAdmin = createSelector(
  getState,
  (s: any) => getUserType(s) === 'member_admin',
)

export const canUserSignOffPayout = createSelector(
  getState,
  (s: any) => getAuthState(s).can_sign_off_payout,
)
