import * as React from 'react'
import styled from 'styled-components'
import { Box } from 'grommet'
import { ArchiveTemplate, Text } from '../..'
import Content from './Content'
import SearchFilters from './SearchFilters'

interface IStatementsPageProps {
  className?: string
}

const Header = (): JSX.Element => (
  <Box justify="between" direction="row" className="page-title">
    <Text tag="h1" color="white">
      Statements
    </Text>
    <SearchFilters className="search-filters" />
  </Box>
)

const StatementsPage = (props: IStatementsPageProps): JSX.Element =>
  React.useMemo(
    () => (
      <div className={props.className}>
        <ArchiveTemplate
          verticalOverflow="scroll"
          Header={() => <Header />}
          Content={() => <Content />}
          displayChannelSelector={true}
        />
      </div>
    ),
    [props.className],
  )

const StyledStatementsPage = styled(StatementsPage)`
  width: 100%;
  overflow: scroll;

  .page-title {
    margin-bottom: 5px;
    align-items: center;
  }

  .search-filters {
    margin-bottom: 10px;
  }
`

export default StyledStatementsPage
