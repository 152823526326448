import { Epic, ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { Observable, of } from 'rxjs'
import { map, switchMap, mergeMap, mapTo } from 'rxjs/operators'
// import { IAddCommentRequest, IRequestCommentsParams } from './interfaces';
import { injectCurrentSitePath } from '../../utils/general'
import {
  catchRestError,
  deleteRequest,
  get,
  post,
  putRequest,
} from '../../utils/http'
import RestfulResult from '../../utils/RestfulResult'
import { commentActions } from './state'
import { getCommentsEndpoint, getCommentsEntityEndpoint } from './constants'
import {
  DeleteCommentRequestAction,
  DeleteCommentSuccessPayload,
  IComment,
  UpdateCommentRequestAction,
} from './interfaces'

export const commentsRequestEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(commentActions.requestComments),
    mergeMap(action =>
      of(action.payload).pipe(
        map(params => ({
          params,
          urlReplacements: action.meta.urlReplacements,
        })),
        get(
          injectCurrentSitePath(
            getCommentsEndpoint(action.meta.entity),
            state$.value,
          ),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(
                commentActions.requestCommentsSuccess(p, {
                  ...response.getContext(),
                  entity: action.meta.entity,
                  entityId: parseInt(action.meta.entityId),
                }),
              ),
            ),
          ),
        ),
        catchRestError(commentActions.requestComments.toString()),
      ),
    ),
  )

export const addCommentEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(commentActions.addComment),
    switchMap(action =>
      of(action).pipe(
        map(({ payload, meta }) => ({
          body: payload,
          urlReplacements: meta.urlReplacements,
        })),
        post(
          injectCurrentSitePath(
            getCommentsEndpoint(action.meta.entity),
            state$.value,
          ),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(
                commentActions.addCommentSuccess(p, {
                  ...response.getContext(),
                  entity: action.meta.entity,
                  entityId: parseInt(action.meta.entityId),
                }),
              ),
            ),
          ),
        ),
        catchRestError(commentActions.addComment.toString()),
      ),
    ),
  )

export const updateCommentEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<UpdateCommentRequestAction>(
      commentActions.updateCommentRequest.toString(),
    ),
    switchMap(action =>
      of(action).pipe(
        map(({ payload: { body, urlReplacements } }) => ({
          body,
          urlReplacements,
        })),
        putRequest(
          injectCurrentSitePath(
            getCommentsEntityEndpoint(action.meta.entity),
            state$.value,
          ),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: IComment) =>
            of(p).pipe(
              mapTo(
                commentActions.updateCommentSuccess(p, {
                  ...response.getContext(),
                  entity: action.meta.entity,
                  entityId: parseInt(action.payload.urlReplacements.id),
                }),
              ),
            ),
          ),
        ),
        catchRestError(commentActions.updateCommentRequest.toString()),
      ),
    ),
  )

export const deleteCommentEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<DeleteCommentRequestAction>(
      commentActions.deleteCommentRequest.toString(),
    ),
    switchMap(action =>
      of(action).pipe(
        map(({ payload }) => ({ urlReplacements: payload })),
        deleteRequest(
          injectCurrentSitePath(
            getCommentsEntityEndpoint(action.meta.entity),
            state$.value,
          ),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: DeleteCommentSuccessPayload) =>
            of(p).pipe(
              mapTo(
                commentActions.deleteCommentSuccess(
                  {
                    ...p,
                    id: action.payload.id,
                    commentId: action.payload.commentId,
                  },
                  { ...response.getContext() },
                ),
              ),
            ),
          ),
        ),
        catchRestError(commentActions.deleteCommentRequest.toString()),
      ),
    ),
  )
