import * as React from 'react'
import PaymentsTable from './PaymentsTable'
import { IBookingPaymentsTableData } from './interfaces'

interface IPaymentsPanelProps {
  data: IBookingPaymentsTableData[]
}

class PaymentsPanel extends React.PureComponent<IPaymentsPanelProps, {}> {
  public render(): JSX.Element {
    return <PaymentsTable data={this.props.data} />
  }
}

export default PaymentsPanel
