import { createActions } from 'redux-actions'
import { NAMESPACE } from './constants'
import {
  IRequestStatementsParams,
  IStatementResponse,
  IUpdateStatement,
} from './interfaces'

export const {
  [NAMESPACE]: {
    requestStatementsSchema,
    requestStatementsSchemaSuccess,
    requestStatements,
    requestStatementsSuccess,
    requestStatement,
    requestStatementSuccess,
    updateStatement,
    updateStatementSuccess,
    requestStatementCsv,
    requestStatementCsvSuccess,
  },
} = createActions({
  [`${NAMESPACE}/request_statements_schema`]: () => '',
  [`${NAMESPACE}/request_statements_schema_success`]: (payload: any) => payload,
  [`${NAMESPACE}/request_statements`]: (payload: IRequestStatementsParams) =>
    payload,
  [`${NAMESPACE}/request_statements_success`]: [
    (payload: IStatementResponse[]) => payload,
    (payload: any, meta: any) => meta,
  ],
  [`${NAMESPACE}/request_statement`]: (payload: any) => payload,
  [`${NAMESPACE}/request_statement_success`]: (payload: IStatementResponse) =>
    payload,
  [`${NAMESPACE}/update_statement`]: [
    (payload: IUpdateStatement) => payload,
    (payload: any, meta: any) => meta,
  ],
  [`${NAMESPACE}/update_statement_success`]: (payload: IStatementResponse) =>
    payload,
  [`${NAMESPACE}/request_statement_csv`]: (payload: IStatementResponse) =>
    payload,
  [`${NAMESPACE}/request_statement_csv_success`]: (
    payload: IStatementResponse,
  ) => payload,
})
