import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { LocationState } from 'history'
import { get } from 'lodash'
import { ResetPasswordForm } from '..'

export interface IResetPasswordPageProps {
  isPasswordResetSuccess: boolean
  location: LocationState
  resetpassword: (
    email: string,
    password: string,
    password_confirmation: string,
    token: string,
  ) => void
}

export default class IResetPasswordPage extends React.PureComponent<
  IResetPasswordPageProps,
  {}
> {
  public render() {
    const { isPasswordResetSuccess, location, resetpassword } = this.props
    let from = get(location, 'state.from', '/login')

    return isPasswordResetSuccess ? (
      <Redirect to={from} />
    ) : (
      <ResetPasswordForm resetpassword={resetpassword} />
    )
  }
}
