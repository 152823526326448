import * as React from 'react'
import styled from 'styled-components'
import GlobalSearchTemplate from '../../templates/GlobalSearchTemplate'
import Content from './Content'
import GlobalSearchPageService from './GlobalSearchPageService'
import SearchFilters from './SearchFilters'
interface IGlobalSearchPageProps {
  className?: string
}

const Header = () => <SearchFilters className="search-filters" />

const GlobalSearchPage = (props: IGlobalSearchPageProps): JSX.Element => {
  const { className } = props

  return (
    <div className={className}>
      <GlobalSearchPageService />
      <GlobalSearchTemplate Header={Header} Content={Content} />
    </div>
  )
}

const StyledGlobalSearchPage = styled(GlobalSearchPage)`
  width: 100%;
  overflow: scroll;

  .page-title {
    margin-top: 10px;
    margin-bottom: 10px;
    align-items: center;
  }

  .search-filters {
    margin-bottom: 20px;
  }

  .status-text {
    cursor: default;
  }
`

export default StyledGlobalSearchPage
