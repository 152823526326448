import { createSelector } from 'reselect'
import { get } from 'lodash'

import { NAMESPACE } from './constants'
import { ILedger, ILedgerModel } from './interfaces'

const getState = (state: any) => state

const getLedgerState = (state: any): ILedgerModel => state[NAMESPACE]

export const getLedgerSchema = createSelector(getLedgerState, state =>
  get(state, 'schema', {}),
)

export const getLedgerSchemaMethods = createSelector(getLedgerSchema, schema =>
  get(schema, 'methods', []),
)

export const getLedgerAdhocSchema = createSelector(getLedgerState, state =>
  get(state, 'ledgeradhocSchema', {}),
)

export const getLedgerAdhocSchemaMethods = createSelector(
  getLedgerAdhocSchema,
  schema => get(schema, 'methods', []),
)

export const getLedgerIds = createSelector(getLedgerState, state =>
  get(state, 'allIds', []),
)

export const getLedger = createSelector(getLedgerState, state =>
  get(state, 'byId', {}),
)

export const getByPage = createSelector(getLedgerState, state =>
  get(state, 'byPage', {}),
)

export const getPages = createSelector(getLedgerState, state =>
  get(state, ['pages'], {
    next: undefined,
    prev: undefined,
  }),
)

export const getAggregates = createSelector(getLedgerState, state =>
  get(state, ['aggregates'], { owing: 0, history: 0 }),
)

export const getEntriesByPage = createSelector(
  [getLedger, getByPage],
  (byId, byPage) => (page: number) =>
    get(byPage, [page], []).map((id: number) => get(byId, [id])),
)

export const getLedgerEntryById = createSelector(
  getLedgerState,
  state => (id: number) => get(state, `byId[${id}]`),
)

export const getEntriesByChannelId = createSelector(
  [getState, getLedgerState],
  (state, ledgerState) => (channelId: number) => {
    const channelIds = ledgerState.byChannelId[channelId] || []
    return channelIds.map(id => getLedgerEntryById(state)(id))
  },
)

export const getEntriesByStatus = createSelector(
  [getState, getLedgerState],
  (state, ledgerState) => (status: string) =>
    ledgerState.allIds
      .map(id => getLedgerEntryById(state)(id))
      .filter((entry: ILedger) => entry.status === status),
)

export const getEntriesByChannelIdAndStatus = createSelector(
  [getState],
  state => (channelId: number, status: string) =>
    getEntriesByChannelId(state)(channelId).filter(
      (entry: ILedger) => entry.status === status,
    ),
)

export const isLoading = createSelector(
  [getLedgerState],
  ledgerState => (actionType: string) =>
    get(ledgerState, `loading.${actionType}`, false),
)
