import { createActions } from 'redux-actions'
import { NAMESPACE } from './constants'
import { ICurrency } from './interfaces'

export const {
  [NAMESPACE]: { requestCurrencyData, requestCurrencyDataSuccess },
} = createActions({
  [`${NAMESPACE}/request_currency_data`]: () => null,
  [`${NAMESPACE}/request_currency_data_success`]: (payload: ICurrency[]) =>
    payload,
})
