import * as React from 'react'
import { Text } from 'grommet'
import { colors } from '../../../styles/variables'

interface IStatusTextProps {
  value: string
  status: string
  size?: string
}

class StatusText extends React.Component<IStatusTextProps, {}> {
  public render(): JSX.Element {
    const { value, status, size = 'small' } = this.props

    let color = colors.red

    switch (status) {
      case 'draft':
      case 'failed':
        color = colors.grey.dark
        break
      case 'unpaid':
        color = colors.blue.medium
        break
      case 'authorized':
      case 'expired':
        color = colors.purple
        break
      case 'complete':
      case 'paid':
        color = colors.green
        break
      case 'queued':
      case 'incomplete':
        color = colors.grey.light
        break
      case 'partial-payment':
      case 'pending':
        color = colors.amber
        break
    }

    return (
      <Text
        title={status}
        className="status-text"
        as="span"
        size={size}
        color={color}
      >
        {value}
      </Text>
    )
  }
}

export default StatusText
