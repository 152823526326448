import { ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { Observable, of } from 'rxjs'
import { map, switchMap, mergeMap, mapTo, pluck } from 'rxjs/operators'
import { actions } from './state'
import { ENDPOINTS } from './constants'
import {
  get,
  catchRestError,
  optionsRequest,
  post,
  putRequest,
} from '../../utils/http'
import RestfulResult from '../../utils/RestfulResult'
import { IRequestBatchesParams } from './interfaces'
import { injectCurrentSitePath } from '../../utils/general'

export const requestBatchesSchemaEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestBatchesSchema),
    pluck('payload'),
    switchMap((payload: null) =>
      of(payload).pipe(
        optionsRequest(
          injectCurrentSitePath(ENDPOINTS.BASE, state$.value),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(mapTo(actions.requestBatchesSchemaSuccess(p))),
          ),
        ),
        catchRestError(actions.requestBatchesSchema.toString()),
      ),
    ),
  )

export const requestBatchesEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestBatches),
    pluck('payload'),
    switchMap((payload: IRequestBatchesParams) =>
      of(payload).pipe(
        map(params => ({ params })),
        get(injectCurrentSitePath(ENDPOINTS.BASE, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestBatchesSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestBatches.toString()),
      ),
    ),
  )

export const requestBatchEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestBatch),
    pluck('payload'),
    switchMap((payload: any) =>
      of(payload).pipe(
        get(injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestBatchSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestBatch.toString()),
      ),
    ),
  )

export const createBatchEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.createBatch),
    pluck('payload'),
    switchMap((payload: { payouts: number[] }) =>
      of(payload).pipe(
        map(body => ({ body })),
        post(
          injectCurrentSitePath(ENDPOINTS.BASE + '?csv_url=true', state$.value),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.createBatchSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.createBatch.toString()),
      ),
    ),
  )

export const markAsReleasedEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.markAsReleased),
    pluck('payload'),
    switchMap((payload: { id: number; batch_number: string }) =>
      of(payload).pipe(
        map(body => ({ body, urlReplacements: { id: body.id } })),
        putRequest(injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.markAsReleasedSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.markAsReleased.toString()),
      ),
    ),
  )
