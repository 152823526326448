import React, { FC, useEffect } from 'react'
import { IRequestChannelsParams } from '../../../../state/modules/channels/interfaces'
import Text from '../../atoms/Text/Text'
import useChannelForm from './useChannelForm'
import { colors } from '../../../styles/variables'
import styled from 'styled-components'
import ChannelFormLoadingModal from './ChannelFormLoadingModal'
import ConfirmationModal from '../../organisms/ConfirmationModal/ConfirmationModal'
import { JsonForms } from '@jsonforms/react'
import Ajv from 'ajv'
import addFormats from 'ajv-formats'
import SingleChannelHeader from './SingleChannelHeader'
import { useEffectOnce } from 'react-use'
import { cells, renderers } from '../../../../modules/jsonForms/renderers'

type Props = {
  className?: string
  channelId: string
}

const ajv = new Ajv({
  allErrors: true,
  verbose: false,
  strict: false,
  validateSchema: false,
  inlineRefs: false,
  code: {
    optimize: false,
  },
})
addFormats(ajv)

const ChannelForm: FC<Props> = ({ className, channelId }) => {
  const {
    schema,
    uiSchema,
    data,
    setData,
    isEditing,
    setIsEditing,
    isCloseChannelModalActive,
    setIsCloseChannelModalActive,
    additionalErrors,
    getLoadingState,
    handleChange,
    closeChannel,
  } = useChannelForm({
    channelId,
    ajv,
  })

  useEffectOnce(() => {
    if (channelId === 'new') {
      setIsEditing(true)
    } else {
      setIsEditing(false)
    }
    return () => {
      // reset form data on unmount
      setData(null)
    }
  })

  return (
    <div className={className}>
      <SingleChannelHeader channelId={channelId} ajv={ajv} />
      <JsonForms
        readonly={!isEditing}
        schema={schema}
        uischema={uiSchema}
        data={data}
        renderers={renderers}
        cells={cells}
        ajv={ajv}
        onChange={handleChange}
        additionalErrors={additionalErrors}
      />

      <ChannelFormLoadingModal loadingState={getLoadingState()} />

      <ConfirmationModal
        isOpen={isCloseChannelModalActive}
        title="Close Channel"
        showCloseIcon={true}
        onConfirm={() => {
          setIsCloseChannelModalActive(false)
          closeChannel()
          // submitForm({
          //   ...formikRef?.current?.values,
          //   account_mode: 'closed',
          // })
        }}
        onClose={() => setIsCloseChannelModalActive(false)}
      >
        <Text tag="p" color="#000" style={{ whiteSpace: 'pre-wrap' }}>
          You are attempting to close a channel. A closed channel does not
          receive statements, and once closed, cannot be reopened again. This is
          permanent. Are you sure you wish to continue?
        </Text>
      </ConfirmationModal>
    </div>
  )
}

const StyledChannelForm = styled(ChannelForm)`
  label {
    min-width: 300px;
    width: 300px;
  }

  .currency-input-container label {
    margin-right: 20px;
  }

  .currency-input-container input {
    width: auto;
    min-width: 375px;
  }

  .multi-input {
    display: flex;

    .field {
      min-width: 400px;
    }

    .button-section {
      display: flex;
      div:nth-of-type(2) {
        margin-left: 20px;
        button {
          font-size: 24px;
        }
      }
    }
  }

  .tab-panel > .field {
    margin-bottom: 20px;
    label + div {
      width: auto;
      min-width: 400px;
    }
    label + button {
      min-width: 400px;
    }
    .error {
      margin-top: 5px;
      margin-left: 320px;
    }
  }

  button.field {
    margin-bottom: 20px;
  }

  .tab-panel > .multi-input {
    .error {
      margin-top: 5px;
      margin-left: 320px;
    }
  }

  .tab-panel > button {
    margin-bottom: 20px;
  }

  .radio-field {
    margin-bottom: 20px;

    label {
      width: auto;
      min-width: 60px;
    }
    > div > div > label {
      width: 430px;
    }
  }

  tbody {
    max-height: fit-content;
  }

  .channel-suppliers {
    width: 100%;
    max-width: 699px;
    > div {
      border-radius: 4px;
      border-color: ${colors.blue.dark};
    }
  }

  .trust-override-visible,
  .trust-override-hidden {
    transition: 0.3s all ease-in-out;
  }

  .trust-override-visible {
    display: block;
  }

  .trust-override-hidden {
    display: none;
  }

  .input-description {
    display: none;
  }
`

export default StyledChannelForm
