import { IUIStatementsModel } from './interfaces'
import moment from 'moment'

const uiStatementsModel: IUIStatementsModel = {
  channel: {},
  isUpdatingStatus: false,
  currentPage: 1,
  currentFilter: '',
  serverErrors: null,
}

export const defaultFilterParams = {
  per_page: 100,
  after: moment().subtract(2, 'weeks').format('YYYY-MM-DD 00:00:00'),
}

export default uiStatementsModel
