import { ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { Observable, of } from 'rxjs'
import { map, switchMap, mergeMap, mapTo, ignoreElements } from 'rxjs/operators'
import { get as _get } from 'lodash'
import { history, redirect } from '../../../utils/router'
import * as actions from './actions'
import { ENDPOINTS } from './constants'
import {
  get,
  catchRestError,
  post,
  putRequest,
  optionsRequest,
} from '../../utils/http'
import { liftPayload } from '../../utils/rx-operators'
import RestfulResult from '../../utils/RestfulResult'
import { IRequestSitesParams } from './interfaces'
import { sitesActions, sitesSelectors } from '.'
import { authActions, authSelectors } from '../auth'
import { injectCurrentSitePath } from '../../utils/general'

/**
 * Get Sites Schema when `actions.requestSitesSchema` action is dispatched
 * @param action$ Action observable
 * @param state$ State observable
 */
export const requestSitesSchema = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestSitesSchema),
    liftPayload(),
    switchMap((payload: IRequestSitesParams) =>
      of(payload).pipe(
        map(params => ({ params })),
        optionsRequest(
          injectCurrentSitePath(ENDPOINTS.BASE, state$.value),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(
                actions.requestSitesSchemaSuccess(p, response.getContext()),
              ),
            ),
          ),
        ),
        catchRestError(actions.requestSitesSchemaSuccess.toString()),
      ),
    ),
  )

export const requestSitesEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestSites),
    liftPayload(),
    mergeMap((payload: IRequestSitesParams) =>
      of(payload).pipe(
        map(params => ({ params })),
        get(
          authSelectors.isAdmin(state$.value)
            ? injectCurrentSitePath(ENDPOINTS.BASE, state$.value)
            : injectCurrentSitePath(ENDPOINTS.MEMBER_BASE, state$.value),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestSitesSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestSites.toString()),
      ),
    ),
  )

export const requestSiteEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestSite),
    liftPayload(),
    mergeMap((payload: { id: number }) =>
      of(payload).pipe(
        map(urlReplacements => ({ urlReplacements })),
        get(ENDPOINTS.ENTITY, true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestSiteSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestSite.toString()),
      ),
    ),
  )

export const requestCreateSiteEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestCreateSite),
    liftPayload(),
    switchMap((payload: any) =>
      of(payload).pipe(
        map(body => ({ body })),
        post(injectCurrentSitePath(ENDPOINTS.BASE, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestCreateSiteSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestCreateSite.toString()),
      ),
    ),
  )

export const requestUpdateSiteEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestUpdateSite),
    liftPayload(),
    switchMap((payload: any) =>
      of(payload).pipe(
        map(body => ({ body, urlReplacements: { id: body.id } })),
        putRequest(
          authSelectors.isAdmin(state$.value)
            ? injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value)
            : injectCurrentSitePath(ENDPOINTS.MEMBER_ENTITY, state$.value),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(actions.requestUpdateSiteSuccess(p, response.getContext())),
            ),
          ),
        ),
        catchRestError(actions.requestUpdateSite.toString()),
      ),
    ),
  )

export const redirectToSelectedSite = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.siteSelected),
    liftPayload(),
    map((payload: any) => {
      const previousSitePath =
        sitesSelectors.getPreviousSitePath(state$.value) ||
        sitesSelectors.getCurrentSitePath(state$.value)
      const {
        location: { pathname },
      } = history
      const path = pathname.replace(
        previousSitePath,
        payload.path.replace(/\//g, ''),
      )
      /** @todo (technical-debt): update this temporary fix with something more elegant */
      if (pathname === '/sites/new') {
        redirect(`/${previousSitePath}/settings`)
      } else {
        redirect(path + window.location.search)
      }
    }),
    ignoreElements(),
  )

export const requestSiteOnTokenSuccess = (
  action$: Observable<AnyAction>,
  state$: any,
  dependencies: any,
): any =>
  action$.pipe(
    ofType(authActions.requestTokenSuccess),
    liftPayload(),
    map((payload: any) => {
      const firstSite = _get(payload, 'default_site')
      const sitePathname = firstSite.path.replace(/\//g, '')
      return sitesActions.requestSites({ site: sitePathname })
    }),
  )
