import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ICommentsModel,
  IRequestCommentsParams,
  IComment,
  IAddCommentRequest,
  CommentEntity,
  UpdateCommentSuccessAction,
  UpdateCommentSuccessMeta,
  UpdateCommentRequestAction,
  UpdateCommentRequestPayload,
  UpdateCommentRequestMeta,
  DeleteCommentRequestPayload,
  DeleteCommentRequestMeta,
  DeleteCommentRequestAction,
  DeleteCommentSuccessPayload,
  DeleteCommentSuccessMeta,
  DeleteCommentSuccessAction,
} from './interfaces'

export const commentsInitialState: ICommentsModel = {
  sites: {},
}

export const commentsSlice = createSlice({
  name: 'comments',
  initialState: commentsInitialState,
  reducers: {
    requestComments: {
      prepare: (payload, meta) => ({ payload, meta }),
      reducer: (state, action: PayloadAction<IRequestCommentsParams>) => {},
    },
    requestCommentsSuccess: {
      prepare: (payload, meta) => ({ payload, meta }),
      reducer: (
        state,
        {
          payload,
          meta,
        }: PayloadAction<
          IComment[],
          string,
          { entity: CommentEntity; entityId: number; currentSite: string }
        >,
      ) => {
        /** @todo move this to an external reducer when a site is selected */
        if (!state.sites[meta?.currentSite]) {
          state.sites[meta?.currentSite] = {
            allIds: [],
            byId: {},
          }
        }
        state.sites[meta?.currentSite].allIds = payload.map(
          comment => comment.id,
        )
        payload.forEach(comment => {
          state.sites[meta?.currentSite].byId[comment.id] = {
            ...comment,
            comment_entity: meta.entity,
            entity_id: meta.entityId,
          }
        })
      },
    },
    addComment: {
      prepare: (payload, meta) => ({ payload, meta }),
      reducer: (state, action: PayloadAction<IAddCommentRequest>) => {},
    },
    addCommentSuccess: {
      prepare: (payload, meta) => ({ payload, meta }),
      reducer: (
        state,
        {
          payload,
          meta,
        }: PayloadAction<
          IComment,
          string,
          { entity: CommentEntity; entityId: number; currentSite: string }
        >,
      ) => {
        /** @todo move this to an external reducer when a site is selected */
        if (!state.sites[meta?.currentSite]) {
          state.sites[meta?.currentSite] = {
            allIds: [],
            byId: {},
          }
        }
        state.sites[meta?.currentSite].allIds = [
          ...state.sites[meta?.currentSite].allIds,
          payload.id,
        ]
        state.sites[meta?.currentSite].byId = {
          ...state.sites[meta?.currentSite].byId,
          [payload.id]: {
            ...payload,
            comment_entity: meta.entity,
            entity_id: meta.entityId,
          },
        }
      },
    },
    updateCommentRequest: {
      prepare: (
        payload: UpdateCommentRequestPayload,
        meta: UpdateCommentRequestMeta,
      ) => ({ payload, meta, error: null }),
      reducer: (state, action: UpdateCommentRequestAction) => {},
    },
    updateCommentSuccess: {
      prepare: (payload: IComment, meta: UpdateCommentSuccessMeta) => ({
        payload,
        meta,
        error: null,
      }),
      reducer: (state, { payload, meta }: UpdateCommentSuccessAction) => {
        if (meta?.currentSite) {
          state.sites[meta?.currentSite].byId[payload.id] = {
            ...payload,
            comment_entity: meta.entity,
            entity_id: meta.entityId,
          }
        }
      },
    },
    deleteCommentRequest: {
      prepare: (
        payload: DeleteCommentRequestPayload,
        meta: DeleteCommentRequestMeta,
      ) => ({ payload, meta, error: null }),
      reducer: (state, action: DeleteCommentRequestAction) => {},
    },
    deleteCommentSuccess: {
      prepare: (
        payload: DeleteCommentSuccessPayload,
        meta: DeleteCommentSuccessMeta,
      ) => ({ payload, meta, error: null }),
      reducer: (state, { payload, meta }: DeleteCommentSuccessAction) => {
        if (meta?.currentSite) {
          delete state.sites[meta.currentSite].byId[parseInt(payload.commentId)]
          state.sites[meta.currentSite].allIds.filter(
            id => id !== parseInt(payload.commentId),
          )
        }
      },
    },
  },
})

const { reducer: commentReducer, actions: commentActions } = commentsSlice

export { commentActions, commentReducer }
