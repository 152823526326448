import { createStore, applyMiddleware, compose } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { createBrowserHistory, History } from 'history'
import { rootReducer, rootEpic } from './root'
import sentryContextMiddleware from './middleware/sentryContext'
import sentryHttpErrorsMiddleware from './middleware/sentryHttpErrors'
import currentSiteMiddleware from './middleware/currentSiteMiddleware'
import * as authActions from './modules/auth/actions'
import * as channelsActions from './modules/channels/actions'
import * as sitesActions from './modules/sites/actions'
import * as usersActions from './modules/users'
import * as uiActions from './modules/ui/app/actions'
import { transactionsActions } from './modules/transactions'
import { paymentsActions } from './modules/payments'
import tokenExpiryMiddleware from './middleware/tokenExpiry/tokenExpiry'
import { currencyActions } from './modules/currency'
import { statementActions } from './modules/statements'
import { bookingActions } from './modules/bookings'
import { globalSearchActions } from './modules/globalsearch'

/**
 * redux-observable epic middleware
 */
const epicMiddleware = createEpicMiddleware()

// Create a history of your choosing (we're using a browser history in this case)
export const history: History = createBrowserHistory()

const actionCreators = {
  ...authActions,
  ...bookingActions,
  ...channelsActions,
  ...currencyActions,
  ...paymentsActions,
  ...globalSearchActions,
  ...sitesActions,
  ...statementActions,
  ...transactionsActions,
  ...usersActions,
  ...uiActions,
}

// const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__  || compose;
const composeEnhancers =
  typeof window === 'object' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionCreators, serialize...
        name: 'Timfinity Dashboard',
        actionCreators,
        // features: {
        //     pause: true, // start/pause recording of dispatched actions
        //     lock: true, // lock/unlock dispatching actions and side effects
        //     persist: true, // persist states on page reloading
        //     export: true, // export history of actions in a file
        //     import: 'custom', // import history of actions from a file
        //     jump: true, // jump back and forth (time travelling)
        //     skip: true, // skip (cancel) actions
        //     reorder: true, // drag and drop actions in the history list
        //     dispatch: true, // dispatch custom actions or action creators
        //     test: true // generate tests for the selected actions
        // },
      })
    : compose

const enhancer = composeEnhancers(
  applyMiddleware(
    currentSiteMiddleware,
    epicMiddleware,
    tokenExpiryMiddleware,
    sentryContextMiddleware,
    sentryHttpErrorsMiddleware,
  ),
)

function configureStore(initialState: any): object {
  const store = createStore(rootReducer, initialState, enhancer)

  epicMiddleware.run(rootEpic)

  // @todo fix, this isn't working @link https://github.com/reactjs/react-redux/releases/tag/v2.0.0
  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require('./root').rootReducer
      store.replaceReducer(nextRootReducer)
    })
  }
  return store
}

const reduxStore: any = configureStore(undefined)

export default reduxStore
