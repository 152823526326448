import { ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { Observable, of } from 'rxjs'
import { map, switchMap, mergeMap, mapTo } from 'rxjs/operators'
import * as actions from './actions'
import { ENDPOINTS } from './constants'
import {
  get as getRequest,
  catchRestError,
  post,
  optionsRequest,
  putRequest,
} from '../../utils/http'
import { formatTrustId, liftPayload } from '../../utils/rx-operators'
import RestfulResult from '../../utils/RestfulResult'
import { IAddAllocation, IRequestTransactionsParams } from './interfaces'
import { injectCurrentSitePath } from '../../utils/general'

/**
 * Get Bookings Schema when `actions.requestChannelsSchema` action is dispatched
 * @param action$ Action observable
 * @param state$ State observable
 */
export const requestTransactionsSchema = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestTransactionsSchema),
    liftPayload(),
    switchMap((payload: IRequestTransactionsParams) =>
      of(payload).pipe(
        map(params => ({ params })),
        optionsRequest(
          injectCurrentSitePath(ENDPOINTS.BASE, state$.value),
          true,
        ),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(
                actions.requestTransactionsSchemaSuccess(
                  p,
                  response.getContext(),
                ),
              ),
            ),
          ),
        ),
        catchRestError(actions.requestTransactionsSchema.toString()),
      ),
    ),
  )

export const requestTransactionsEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestTransactions),
    switchMap((action: any) =>
      of(action.payload).pipe(
        formatTrustId(),
        map(params => ({ params })),
        getRequest(injectCurrentSitePath(ENDPOINTS.BASE, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(
                actions.requestTransactionsSuccess(p, response.getContext()),
              ),
            ),
          ),
        ),
        catchRestError(actions.requestTransactions.toString()),
      ),
    ),
  )

export const requestTransactionsWithPaymentsEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestTransactionsWithPayments),
    liftPayload(),
    switchMap((payload: IRequestTransactionsParams) =>
      of(payload).pipe(
        map(params => ({ params })),
        getRequest(injectCurrentSitePath(ENDPOINTS.BASE, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(
                actions.requestTransactionsWithPaymentsSuccess(
                  p,
                  response.getContext(),
                ),
              ),
            ),
          ),
        ),
        catchRestError(actions.requestTransactionsWithPayments.toString()),
      ),
    ),
  )

export const requestTransactionEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestTransaction),
    liftPayload(),
    switchMap((payload: any) =>
      of(payload).pipe(
        map(urlReplacements => ({ urlReplacements })),
        getRequest(injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(
                actions.requestTransactionSuccess(p, response.getContext()),
              ),
            ),
          ),
        ),
        catchRestError(actions.requestTransaction.toString()),
      ),
    ),
  )

export const createTransactionEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestCreateTransaction),
    liftPayload(),
    switchMap((payload: any) =>
      of(payload).pipe(
        map(body => ({ body })),
        post(injectCurrentSitePath(ENDPOINTS.BASE, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(
                actions.requestCreateTransactionSuccess(
                  p,
                  response.getContext(),
                ),
              ),
            ),
          ),
        ),
        catchRestError(actions.requestCreateTransaction.toString()),
      ),
    ),
  )

export const addAllocationToTransaction = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.addAllocation),
    liftPayload(),
    switchMap(
      (payload: { urlReplacements: { id: string }; body: IAddAllocation }) =>
        of(payload).pipe(
          putRequest(
            injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value),
            true,
          ),
          mergeMap((response: RestfulResult) =>
            response.mapOrFail((p: any) =>
              of(p).pipe(
                mapTo(actions.addAllocationSuccess(p, response.getContext())),
              ),
            ),
          ),
          catchRestError(actions.addAllocation.toString()),
        ),
    ),
  )

export const updateTransactionEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.requestUpdateTransaction),
    liftPayload(),
    switchMap((payload: any) =>
      of(payload).pipe(
        map(([body, urlReplacements]) => ({ body, urlReplacements })),
        putRequest(injectCurrentSitePath(ENDPOINTS.ENTITY, state$.value), true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(
                actions.requestUpdateTransactionSuccess(
                  p,
                  response.getContext(),
                ),
              ),
            ),
          ),
        ),
        catchRestError(actions.requestUpdateTransaction.toString()),
      ),
    ),
  )

export const resendEmailReceiptEpic = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.resendEmailReceipt),
    liftPayload(),
    switchMap((payload: any) =>
      of(payload).pipe(
        getRequest(payload, true),
        mergeMap((response: RestfulResult) =>
          response.mapOrFail((p: any) =>
            of(p).pipe(
              mapTo(
                actions.resendEmailReceiptSuccess(p, response.getContext()),
              ),
            ),
          ),
        ),
        catchRestError(actions.resendEmailReceipt.toString()),
      ),
    ),
  )
