import * as React from 'react'
import { Formik, Form, FastField } from 'formik'
import * as yup from 'yup'
import { Text, Button } from '../../'
import { TextArea } from 'grommet'
import { colors } from '../../../styles/variables'
import styled from 'styled-components'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'

export interface IFormValues {
  comment: string
}
interface ICommentsFormProps {
  className?: string
  modalTitle: string
  buttonText: string
  deleteButtonText?: string
  commentAction: CommentAction
  initialValues?: IFormValues
  onDelete?(): void
  onSubmit(comment: IFormValues): void
}

export enum CommentAction {
  New = 'new',
  Edit = 'edit',
}

const validationSchema = yup.object().shape({
  comment: yup.string().required('Please add a comment.'),
})

const CommentsForm = (props: ICommentsFormProps) => {
  const {
    modalTitle,
    commentAction,
    buttonText,
    deleteButtonText = '',
    initialValues = { comment: '' },
    onDelete,
    onSubmit,
  } = props

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    React.useState(false)

  const handleDelete = () => {
    if (onDelete) {
      onDelete()
    }
  }

  return (
    <>
      <div className={props.className}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleChange, values, touched, errors }) => {
            return (
              <Form className="form">
                <Text tag="h3" color={colors.blue.dark}>
                  {modalTitle}
                </Text>
                <FastField
                  component={TextArea}
                  id="comment"
                  name="comment"
                  onChange={handleChange}
                  label="Comment"
                  value={values.comment}
                  error={touched.comment && errors.comment}
                  className="field"
                  placeholder="Comment"
                />
                <footer>
                  {commentAction === CommentAction.Edit &&
                    typeof onDelete === 'function' && (
                      <Button
                        styleType="danger"
                        label={deleteButtonText}
                        size="small"
                        type="button"
                        onClick={setIsDeleteConfirmationOpen}
                      />
                    )}
                  <Button
                    styleType="secondary"
                    label={buttonText}
                    size="small"
                    type="submit"
                  />
                </footer>
              </Form>
            )
          }}
        </Formik>
      </div>
      <ConfirmationModal
        isOpen={isDeleteConfirmationOpen}
        showCloseIcon={false}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        title="Delete comment"
        onConfirm={handleDelete}
      >
        <p>Are you sure you want to delete this comment?</p>
      </ConfirmationModal>
    </>
  )
}

const StyledCommentsForm = styled(CommentsForm)`
  margin: 10px;
  &.is-submitting {
    form {
      display: none;
    }
  }
  h3 {
    margin: 0 0 10px 0;
    border-bottom: 1px solid ${colors.grey.lighter};
  }
  .field {
    margin-bottom: 10px;
  }
  .field label {
    margin-right: 20px;
  }
  footer {
    display: flex;
    border-top: 1px solid ${colors.grey.lighter};
    text-align: right;
    margin-top: 10px;
    padding-top: 10px;

    > div:last-child {
      margin-left: 10px;
    }
  }
  footer button {
    font-size: 1rem;
    padding: 6px 8px;
  }
  .field .error {
    display: block;
    position: absolute;
    right: 10px;
    top: 150px;
    bottom: 30px;
    font-size: 12px;
  }
  #comment::placeholder {
    font-size: 14px;
    font-weight: normal;
  }
`

export default StyledCommentsForm
