import { createActions } from 'redux-actions'
import {
  ITokenRequest,
  ITokenResponse,
  IPasswordForgotRequest,
  IPasswordForgotResponse,
  IPasswordResetRequest,
} from './interfaces'
import { NAMESPACE } from './constants'

export const {
  [NAMESPACE]: {
    requestToken,
    requestTokenSuccess,
    requestLogout,
    loggedOut,
    requestTokenRefresh,
    requestTokenRefreshSuccess,
    requestPasswordForgot,
    requestPasswordForgotSuccess,
    requestPasswordReset,
    requestPasswordResetSuccess,
  },
} = createActions({
  [`${NAMESPACE}/request_token`]: (payload: ITokenRequest) => payload,
  [`${NAMESPACE}/request_token_success`]: (payload: ITokenResponse) => payload,
  [`${NAMESPACE}/request_logout`]: () => null,
  [`${NAMESPACE}/logged_out`]: () => null,
  [`${NAMESPACE}/request_token_refresh`]: () => null,
  [`${NAMESPACE}/request_token_refresh_success`]: (payload: ITokenResponse) =>
    payload,
  [`${NAMESPACE}/request_password_forgot`]: (payload: IPasswordForgotRequest) =>
    payload,
  [`${NAMESPACE}/request_password_forgot_success`]: (
    payload: IPasswordForgotResponse,
  ) => payload,
  [`${NAMESPACE}/request_password_reset`]: (payload: IPasswordResetRequest) =>
    payload,
  [`${NAMESPACE}/request_password_reset_success`]: (payload: any) => payload,
})
