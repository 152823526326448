import * as React from 'react'
import { DataTable, Anchor } from '../..'
import { IStatementResponse } from '../../../../state/modules/statements/interfaces'
import styled from 'styled-components'

interface IBookingTransactionsProps {
  statement: IStatementResponse
  currentSitePath: string
  className?: string
  formatCurrency(amount: number): string
  redirect(path: string): void
}

class BookingTransactions extends React.PureComponent<
  IBookingTransactionsProps,
  {}
> {
  public getColumns = () => [
    {
      Header: 'Booking',
      accessor: 'title',
      Cell: (row: any) => {
        const redirectTo = () => {
          this.props.redirect(
            `/${this.props.currentSitePath}/bookings/${row.original.id}`,
          )
        }
        return <Anchor onClick={redirectTo}>{row.original.title}</Anchor>
      },
    },
    {
      Header: 'Reference',
      accessor: 'reference',
    },
    {
      Header: 'Credit',
      accessor: 'credit',
    },
    {
      Header: 'Debit',
      accessor: 'debit',
    },
  ]

  public getData = () =>
    this.props.statement.booking_transactions.map(transaction => ({
      id: transaction.id,
      title: transaction.title,
      reference: transaction.reference,
      credit: this.props.formatCurrency(transaction.credit),
      debit: this.props.formatCurrency(transaction.debit),
      ledger: transaction.ledger,
    }))

  public getSubComponent = (row: any) => {
    /** @todo clean this up! */
    const ledger = row.original.ledger || []
    const { formatCurrency } = this.props
    return ledger.map((l: any, i: number) => {
      const style = {
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: '19%',
        marginLeft: '4.5%',
        fontSize: '0.8rem',
      }
      return (
        <div key={i} style={style}>
          <span
            style={{
              fontStyle: 'italic',
              color: '#4c84a0',
              width: '56%',
              display: 'flex',
            }}
          >
            {l.title}
          </span>
          <div style={{ width: '44%', display: 'flex' }}>
            <span style={{ width: '30%', textAlign: 'right' }}>
              {formatCurrency(l.credit)}
            </span>
            <span style={{ width: '68%', textAlign: 'right' }}>
              {formatCurrency(l.debit)}
            </span>
          </div>
        </div>
      )
    })
  }

  public render(): JSX.Element {
    const data = this.getData()
    return (
      <div className={this.props.className}>
        <DataTable
          manual={true}
          columns={this.getColumns()}
          data={data}
          pageSize={data.length}
          loading={false}
          SubComponent={this.getSubComponent}
        />
      </div>
    )
  }
}

const StyledBookingTransactions = styled(BookingTransactions)`
  .ReactTable {
    margin-bottom: 30px;
  }
`

export default StyledBookingTransactions
