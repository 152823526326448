import { TMT_URL } from '../state/constants'
import { getAuthHeaders } from './auth'

export const generatePaymentRequestToken = async (
  bookingID: string,
  bookingDate: string,
  site: string,
) => {
  const url = `${TMT_URL}/${site}/wp-json/jwt-auth/v1/token/paymentrequest`
  const data = {
    booking_id: bookingID,
    booking_date: bookingDate,
  }
  const authHeaders = getAuthHeaders()
  const tokenPromise: Promise<string> = new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('POST', url, true)
    xhr.setRequestHeader('Authorization', authHeaders.Authorization)
    xhr.setRequestHeader('content-type', 'application/json')
    xhr.send(JSON.stringify(data))
    xhr.onload = () => {
      const response = JSON.parse(xhr.response)
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(response.token)
      } else {
        reject(response.message)
      }
    }
    xhr.onerror = err => {
      // console.log(err);
    }
  })
  return tokenPromise
}
